import React from 'react';
import { Button } from '@material-ui/core';
import {
  TripWaypointRole,
  useExecuteOverrideGarageTimeMutation,
} from 'src/.gen/graphql';
import {
  convertHoursToMinutes,
  convertMinutesToHours,
} from 'src/shared/utils/conversions';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { ColumnInvoiceTimes, InvoiceTimesRow } from './utils';
import { useStylesTableTimestamps } from '../TripLog/TripLog';
import OverrideSelectTime, { SelectedTime } from './OverrideSelectTime';

interface Props {
  tripId: string;
  value: number;
  column: InvoiceTimesRow;
  row: ColumnInvoiceTimes;
}

const OverrideGarageTime: React.FC<Props> = ({
  tripId,
  value,
  column,
  row,
}) => {
  const classes = useStylesTableTimestamps();
  const [selectedGarageTime, setGarageTime] = React.useState<SelectedTime>();
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const [executeOverrideGarageTimeMutation] =
    useExecuteOverrideGarageTimeMutation();

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const handleSelectGarageTime = (value, column, row) => {
    const newGarageTime = {
      time: value,
      label: column.label,
      status: row.status,
    };
    setGarageTime(newGarageTime);
    setDialogVisible(true);
  };

  const handleOverrideGargeTime = async (values, manageCommandActions) => {
    function getGarageTimeType(key: string): TripWaypointRole | null {
      switch (key) {
        case 'Trip Started':
          return TripWaypointRole.Pickup;
        case 'Trip Completed':
          return TripWaypointRole.Dropoff;
        default:
          return null;
      }
    }

    const {
      data: { executeOverrideGarageTime },
    } = await executeOverrideGarageTimeMutation({
      variables: {
        command: {
          tripId,
          garageTimeType: getGarageTimeType(selectedGarageTime?.status),
          newGarageTime: convertMinutesToHours(values.newTime),
        },
      },
      refetchQueries: ['GetTripById'],
    });
    manageCommandActions(executeOverrideGarageTime, closeDialog);
  };

  return (
    <React.Fragment>
      <Button
        className={classes.button}
        variant="text"
        onClick={() => handleSelectGarageTime(value, column, row)}
      >
        {`${convertHoursToMinutes(value).toFixed(0)} min`}
      </Button>
      <GenericDialog
        openDialog={dialogVisible}
        setCloseDialog={closeDialog}
        dialogTitle={`Override ${selectedGarageTime?.label}`}
        maxWidth="sm"
      >
        <OverrideSelectTime
          selectedTime={selectedGarageTime}
          onCancel={closeDialog}
          onSubmit={handleOverrideGargeTime}
        />
      </GenericDialog>
    </React.Fragment>
  );
};

export default OverrideGarageTime;
