import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Contact } from 'src/.gen/graphql';

interface PassengerContactTripFormSectionProps {
  handleExistingContact: () => void;
  handleNewContact: () => void;
  duplicatedContact: Partial<Contact>;
}

const useStyles = makeStyles((theme: Theme) => ({
  errorText: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '0.5rem',
    paddingLeft: theme.spacing(1),
  },
  firstLine: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
  errorButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: '0.7rem',
    '&:hover': {
      borderColor: theme.palette.error.light,
      backgroundColor: theme.palette.error.light,
      color: theme.palette.textLight.light,
    },
  },
}));

const DuplicatedEmailWarning: React.FC<PassengerContactTripFormSectionProps> =
  ({ handleExistingContact, handleNewContact, duplicatedContact }) => {
    const classes = useStyles();
    return (
      <Fragment>
        <Grid item container spacing={3}>
          <Grid item xs={12} className={classes.errorText}>
            <Typography variant="body1">
              A contact with this e-mail address already exists:
            </Typography>
            <Typography
              variant="body1"
              className={classes.firstLine}
            >{`${duplicatedContact.name}`}</Typography>
            <Typography variant="body1">{`${duplicatedContact.email}`}</Typography>
            <Typography variant="body1">{`${duplicatedContact.phoneNumber}`}</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleExistingContact}
                className={classes.errorButton}
              >
                Use this contact information
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleNewContact}
                className={classes.errorButton}
              >
                Choose a different e-mail address
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

export default DuplicatedEmailWarning;
