import React from 'react';
import { TierType, UserRole, PaymentMethodRecord } from 'src/.gen/graphql';

type SubscriptionContextType = {
  subscriptionsEnabled: boolean;
  accountId?: string | null;
  userRole?: UserRole | null;
  subscriptionId?: string | null;
  currentTier?: TierType | null;
  paymentMethod?: PaymentMethodRecord | null;
};

const defaultValues: SubscriptionContextType = {
  subscriptionsEnabled: false,
  accountId: null,
  userRole: null,
  subscriptionId: null,
  currentTier: null,
  paymentMethod: null,
};

const SubscriptionContext =
  React.createContext<SubscriptionContextType>(defaultValues);

export default SubscriptionContext;
