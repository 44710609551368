import { ApolloQueryResult } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import {
  Exact,
  GetVehicleHourlyRatesQuery,
  useGetVehicleHourlyRatesQuery,
  VehicleClass,
} from 'src/.gen/graphql';
import { userAtom } from 'src/authentication/atoms/AuthState';

interface useVehicleHourlyRatesInterface {
  (): {
    vehicleHourlyRates: Partial<VehicleClass>[];
    refetchVehicleHourlyRates: () => void;
    asyncRefetchVehicleHourlyRates: (
      variables?: Partial<
        Exact<{
          accountId: string;
        }>
      >,
    ) => Promise<ApolloQueryResult<GetVehicleHourlyRatesQuery>>;
    loadingVehicleHourlyRates: boolean;
  };
}

export const useVehicleHourlyRates: useVehicleHourlyRatesInterface = () => {
  const user = useRecoilValue(userAtom);
  const { data, refetch, loading } = useGetVehicleHourlyRatesQuery({
    variables: {
      accountId: user?.accountId,
    },
    fetchPolicy: 'no-cache',
  });

  const vehicleHourlyRates =
    data?.vehicleClasses?.all?.items.map((item) => ({ ...item })) || [];

  const updateRates = (): void => {
    refetch();
  };

  return {
    vehicleHourlyRates,
    refetchVehicleHourlyRates: updateRates,
    asyncRefetchVehicleHourlyRates: refetch,
    loadingVehicleHourlyRates: loading,
  };
};
