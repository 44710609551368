import { Box, createMuiTheme, Grid } from '@material-ui/core';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2C93D0',
    },
    secondary: {
      main: '#2C3035',
    },
    text: {
      primary: '#06080E',
      secondary: '#7C7F83',
    },
  },
  typography: {
    caption: {
      fontSize: 12,
      fontWeight: 600,
    },
  },
});

const useStyles = makeStyles({
  mainContainer: {
    padding: '.8rem 1rem',
  },
  mapContainer: {
    height: '20rem',
    width: '100%',
  },
});

const LoadingTripMapTile: React.FC = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.mainContainer}>
        <Grid container>
          <Grid item container xs>
            <Grid
              item
              container
              xs={2}
              justify="center"
              alignItems="flex-start"
            >
              <LocationOnIcon fontSize="large" />
            </Grid>
            <Grid item xs={10}>
              <Skeleton width="60%" />
              <Skeleton width="100%" />
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            direction="column"
            container
            justify="center"
            alignItems="center"
          >
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <ArrowRightAltIcon fontSize="large" />
          </Grid>
          <Grid item container xs>
            <Grid
              item
              container
              xs={2}
              justify="center"
              alignItems="flex-start"
            >
              <LocationOnIcon fontSize="large" />
            </Grid>
            <Grid item xs={10}>
              <Skeleton width="60%" />
              <Skeleton width="100%" />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.mapContainer}>
        <Skeleton variant="rect" width="100%" height="100%" />
      </Box>
    </ThemeProvider>
  );
};

export default LoadingTripMapTile;
