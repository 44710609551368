import React, { Fragment, ReactNode } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

interface BaseEnhancedDialogProps {
  title: ReactNode;
  content: ReactNode;
  actions: ReactNode;
  dividers: boolean;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// ! WE SHOULD DEPRECATE THIS
const BaseEnhancedDialog: React.FC<Partial<BaseEnhancedDialogProps>> = ({
  title,
  content,
  actions,
  dividers,
  onClose,
}) => {
  return (
    <Fragment>
      {title && (
        <Box mt={1} ml={1}>
          <DialogTitle
            id="dialog-title"
            onClose={() => {
              if (onClose) onClose();
            }}
          >
            {title}
          </DialogTitle>
        </Box>
      )}
      {content && <DialogContent dividers={dividers}>{content}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Fragment>
  );
};

export default BaseEnhancedDialog;
