import React, { Fragment, useEffect, useState } from 'react';
import { Button, Grid, Typography, withStyles, Theme } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Trip, TripClientRecord } from 'src/.gen/graphql';
import { Skeleton } from '@material-ui/lab';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  tripUpdateClientState,
  tripClientFormIsValid,
} from 'src/trips/atoms/tripUpdateClientAtom';
import CapitalDriveStyledContainer from 'src/shared/components/CapitalDriveStyledContainer/CapitalDriveStyledContainer';
import ContactTripNotesInput from 'src/tripsNewClients/components/TripAdditionalInfo/ContactTripNotesInput/ContactTripNotesInput';
import { DeepPartial } from 'react-hook-form';
import TripClientCard from './TripClientCard';
import TripClientDialogForm from './TripClientDialogForm';
import DeleteTripClientDialog from './DeleteTripClientDialog';

interface TripContactAcordionProps {
  trip: Partial<Trip>;
  loading: boolean;
  defaultExpanded?: boolean;
  tripClients: DeepPartial<TripClientRecord>[];
}

const Accordion = withStyles({
  root: {
    borderTop: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    borderBottom: 'none',
    minHeight: 56,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    justifyContent: 'space-between',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))(MuiAccordionDetails);

const TripContactAcordion: React.FC<TripContactAcordionProps> = ({
  defaultExpanded,
  loading,
  trip,
  tripClients,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [tripClient, setTripClient] = useState<
    DeepPartial<TripClientRecord> | undefined
  >();
  const setTripUpdateState = useSetRecoilState(tripUpdateClientState);
  const tripFormIsValid = useRecoilValue(tripClientFormIsValid);
  useEffect(() => {
    if (dialogAction !== '') {
      setIsOpen(true);
      setTripUpdateState(false);
    }
  }, [dialogAction]);

  return (
    <Fragment>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        data-testid="trip_clients_accordion"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" color="textPrimary">
            {loading ? <Skeleton width="10rem" /> : 'Trip Clients'}
          </Typography>
          {!loading && (
            <Button
              data-testid="add_trip_client_button"
              variant="outlined"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setDialogAction('new');
              }}
            >
              Add Client
            </Button>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {tripClients &&
              tripClients.map((tripClient: DeepPartial<TripClientRecord>) => {
                return (
                  <TripClientCard
                    key={tripClient.id}
                    tripClient={tripClient}
                    onDelete={(tripClient) => {
                      setTripClient(tripClient);
                      setDialogAction('delete');
                    }}
                    onEdit={(tripClient) => {
                      setTripClient(tripClient);
                      setDialogAction('edit');
                    }}
                  />
                );
              })}
            <Grid item xs={12} md={12}>
              <CapitalDriveStyledContainer variant="default" title="Notes">
                {trip && trip.notes[0] && (
                  <ContactTripNotesInput tripNote={trip?.notes[0]} />
                )}
              </CapitalDriveStyledContainer>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <TripClientDialogForm
        action="new"
        isOpen={isOpen && dialogAction === 'new'}
        setIsOpen={setIsOpen}
        setDialogAction={setDialogAction}
        trip={trip}
        tripClient={tripClient}
        setTripClient={setTripClient}
      />
      {tripClient?.id ? (
        <TripClientDialogForm
          action="edit"
          isOpen={isOpen && dialogAction === 'edit'}
          setIsOpen={setIsOpen}
          setDialogAction={setDialogAction}
          trip={trip}
          tripClient={tripClient}
          setTripClient={setTripClient}
        />
      ) : null}
      <DeleteTripClientDialog
        isOpen={isOpen && dialogAction === 'delete'}
        setIsOpen={setIsOpen}
        setDialogAction={setDialogAction}
        tripFormIsValid={tripFormIsValid}
        tripClient={tripClient}
      />
    </Fragment>
  );
};

export default TripContactAcordion;
