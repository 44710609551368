import { DeepPartial } from 'react-hook-form';
import { ClientRole, TripClientRecord } from 'src/.gen/graphql';

export const getPassengerClient = (
  tripClients: DeepPartial<TripClientRecord>[],
  tripId: string,
): DeepPartial<TripClientRecord> => {
  const leadPassenger = tripClients?.find(
    (tripClient) => tripClient?.isPrimary && tripClient?.tripId === tripId,
  );
  const firstPassenger = tripClients?.find(
    (tripClient) =>
      tripClient?.role === ClientRole.Passenger &&
      tripClient?.tripId === tripId,
  );

  return leadPassenger || firstPassenger;
};
