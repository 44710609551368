import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  TextField,
  Typography,
  fade,
  makeStyles,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { Camelize } from 'src/shared/types/camelize';
import { timeUtils } from 'src/shared/utils/timeUtils';
import { CloseoutFromValues, SavoyaCostType } from './SavoyaPaymentDetails';
import SavoyaTripSummary from './TripLog/SavoyaTripSummary';
import {
  SavoyaHourlyQuote,
  SavoyaVendorAssignment,
  SavoyaVendorCase,
  SavoyaVendorExchanegRate,
  SavoyaVendorProfile,
} from '../types/SavoyaTripsTypes';
import SavoyaAdditionalChargesBreakdown from './TripRateBreakdown/SavoyaAdditionalChargesBreakdown';

interface RateExceptionFormProps {
  vendorAssignment: Camelize<SavoyaVendorAssignment>;
  vendorProfile: Camelize<SavoyaVendorProfile>;
  exchangeRate: Camelize<SavoyaVendorExchanegRate>;
  hourlyQuote: Camelize<SavoyaHourlyQuote>;
  vendorCase: Camelize<SavoyaVendorCase>;
  grandTotalAmount: number;
  totalIncidentals: number;
  onComplete: () => void;
  onClose: () => void;
}

const RateExceptionForm: React.FC<RateExceptionFormProps> = ({
  vendorAssignment,
  vendorProfile,
  exchangeRate,
  hourlyQuote,
  vendorCase,
  grandTotalAmount,
  totalIncidentals,
  onComplete,
  onClose,
}) => {
  const classes = useStyles();
  const [requestedAmount, setRequestedAmount] = useState<number>();
  const { values, isValid, isSubmitting, setFieldValue, submitForm } =
    useFormikContext<CloseoutFromValues>();

  useEffect(() => {
    const reportedHours = timeUtils.durationBetween(
      values?.outDateAdjustment,
      values?.inDateAdjustment,
    );
    if (values?.rateExceptionType === SavoyaCostType.Hourly) {
      const hours = Math.max(
        values.rateExceptionHourlyMinimumHours || 0,
        reportedHours || 0,
      );
      setRequestedAmount(
        (hours * values?.rateExceptionHourlyBase || 0) +
          (totalIncidentals || 0),
      );
    } else {
      setRequestedAmount(
        (values?.rateExceptionTransferBase || 0) + (totalIncidentals || 0),
      );
    }
  }, [values]);

  return (
    <Grid container spacing={1}>
      <Grid
        container
        item
        spacing={3}
        xs={12}
        justify="center"
        style={{ margin: 'auto', marginBottom: '0.5rem' }}
      >
        <Button
          variant={
            values?.rateExceptionType === SavoyaCostType.Hourly
              ? 'contained'
              : 'outlined'
          }
          color="primary"
          onClick={() => {
            setFieldValue('rateExceptionType', SavoyaCostType.Hourly);
          }}
          className={
            values?.rateExceptionType === SavoyaCostType.Hourly
              ? classes.selectedCost
              : classes.unselectedCost
          }
        >
          Hourly
        </Button>

        <Button
          variant={
            values?.rateExceptionType === SavoyaCostType.Transfer
              ? 'contained'
              : 'outlined'
          }
          color="primary"
          onClick={() =>
            setFieldValue('rateExceptionType', SavoyaCostType.Transfer)
          }
          className={
            values?.rateExceptionType === SavoyaCostType.Transfer
              ? classes.selectedCost
              : classes.unselectedCost
          }
        >
          Transfer
        </Button>
      </Grid>
      {values?.rateExceptionType === SavoyaCostType.Hourly ? (
        <Grid container item spacing={5}>
          <Grid item xs={12} md={6} className={classes.costFields}>
            <Field id="rateExceptionHourlyBase" name="rateExceptionHourlyBase">
              {({ field, form }) => (
                <TextField
                  {...field}
                  className={classes.costFields}
                  id="rateExceptionHourlyBase"
                  label="Hourly Base Rate"
                  data-testid="savoya_rate_exception_hourly_base"
                  placeholder="0.00"
                  type="number"
                  variant="outlined"
                  prev
                  fullWidth
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  size="small"
                  onChange={(e) => {
                    form.setFieldValue(
                      'rateExceptionHourlyBase',
                      parseFloat(e.target.value) || null,
                    );
                  }}
                  value={values?.rateExceptionHourlyBase}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              id="rateExceptionHourlyMinimumHours"
              name="rateExceptionHourlyMinimumHours"
            >
              {({ field, form }) => (
                <TextField
                  {...field}
                  className={classes.costFields}
                  id="rateExceptionHourlyMinimumHours"
                  label="Minimum Hours"
                  data-testid="savoya_rate_exception_hourly_min_hours"
                  placeholder="0.00"
                  type="number"
                  variant="outlined"
                  prev
                  fullWidth
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  size="small"
                  onChange={(e) => {
                    form.setFieldValue(
                      'rateExceptionHourlyMinimumHours',
                      parseFloat(e.target.value) || null,
                    );
                  }}
                  value={values?.rateExceptionHourlyMinimumHours}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      ) : null}
      {values?.rateExceptionType === SavoyaCostType.Transfer ? (
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="50%"
            marginLeft="25%"
          >
            <Field
              id="rateExceptionTransferBase"
              name="rateExceptionTransferBase"
            >
              {({ field, form }) => (
                <TextField
                  {...field}
                  id="rateExceptionTransferBase"
                  label="Transfer Base Rate"
                  data-testid="savoya_rate_exception_transfer_base"
                  placeholder="0.00"
                  type="number"
                  variant="outlined"
                  prev
                  fullWidth
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  size="small"
                  onChange={(e) => {
                    form.setFieldValue(
                      'rateExceptionTransferBase',
                      parseFloat(e.target.value) || null,
                    );
                  }}
                  value={values?.rateExceptionTransferBase}
                />
              )}
            </Field>
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12} md={6}>
        <SavoyaTripSummary
          vendorAssignment={vendorAssignment}
          editable={false}
          fullWidth
        />
        <Box
          className={classes.compensation}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <React.Fragment>
            <Typography variant="subtitle2" className={classes.text}>
              Compensation
            </Typography>
            <Typography variant="subtitle2" className={classes.text}>
              {values.compensationPercentage || '0.00'}%
            </Typography>
          </React.Fragment>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <SavoyaAdditionalChargesBreakdown
          vendorAssignment={vendorAssignment}
          vendorProfile={vendorProfile}
          exchangeRate={exchangeRate}
          hourlyQuote={hourlyQuote}
          vendorCase={vendorCase}
          loading={false}
          editable={false}
        />
        <Box
          className={classes.footer}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <React.Fragment>
            <Typography variant="subtitle1" color="initial">
              System Total
            </Typography>
            <Typography variant="subtitle1">
              {vendorAssignment?.currencyCode || 'USD'}{' '}
              {(grandTotalAmount || 0).toFixed(2)}
            </Typography>
          </React.Fragment>
        </Box>
      </Grid>
      <Grid xs={12}>
        <Box
          className={classes.footer}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <React.Fragment>
            <Typography variant="subtitle1" color="initial">
              Requested Total
            </Typography>
            <Typography variant="subtitle1">
              {vendorAssignment?.currencyCode || 'USD'}{' '}
              {(requestedAmount || 0).toFixed(2)}
            </Typography>
          </React.Fragment>
        </Box>
      </Grid>
      <Grid xs={12}>
        <Field id="rateExceptionReason" name="rateExceptionReason">
          {({ field, form }) => (
            <TextField
              {...field}
              id="rateExceptionReason"
              label="Exception Reasoning"
              data-testid="savoya_rate_exception_reason"
              type="text"
              variant="outlined"
              prev
              fullWidth
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              size="large"
              onChange={(e) => {
                form.setFieldValue('rateExceptionReason', e.target.value);
              }}
              value={values?.rateExceptionReason}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          <Button
            data-testid="account_cancel_button"
            variant="outlined"
            color="primary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="account_save_button"
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || !isValid}
            onClick={async () => {
              await submitForm();
              onComplete();
            }}
          >
            {isSubmitting ? <CircularProgress /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  selectedCost: {
    flex: 1,
    color: theme.palette.textLight.main,
    borderRadius: 0,
    fontSize: '0.8rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.textLight.main,
    },
  },
  unselectedCost: {
    flex: 1,
    color: theme.palette.primary.main,
    borderRadius: 0,
    fontSize: '0.8rem',
  },
  costFields: {
    padding: 1,
  },
  compensation: {
    color: theme.palette.textDark.main,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '15px',
    width: '96%',
    padding: '15px',
    marginRight: '10px',
    marginLeft: '10px',
    alignItems: 'center',
  },
  footer: {
    backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
    color: theme.palette.textDark.main,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '15px',
    width: '96%',
    padding: '15px',
    margin: '10px',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
  },
}));
export default RateExceptionForm;
