import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ProviderType, TierType, VendorRecord } from 'src/.gen/graphql';
import CapitalDriveTabBar, {
  TabOption,
} from 'src/shared/components/CapitalDriveTabBar/CapitalDriveTabBar';
import { DeepPartial } from 'react-hook-form';
import TabComponent from './TabComponent/TabComponent';
import HourlyRatesTab from './Tabs/HourlyRatesTab/HourlyRatesTab';
import TransferRatesTab from './Tabs/TransferRatesTab/TransferRatesTab';
import SavoyaRatesDashboard from './SavoyaRatesDashBoard';

export const useRatesStyles = makeStyles((theme) => ({
  root: { height: '100%', padding: '20px', marginTop: '30px' },
  logo: { marginTop: '30px' },
  header: {
    padding: '10px',
  },
  tabElement: {
    color: theme.palette.primary.main,
  },
  inputStyles: {
    border: 'none',
    fontSize: '16px',
    fontWeight: 400,
    height: '20px',
    backgroundColor: '#f0f0f0',
    width: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:focus': {
      outline: 'none !important',
      borderBottom: 'solid black 1px',
      cursor: 'auto',
      boxShadow: 'none',
      backgroundColor: 'none',
    },
  },
  formStyles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  smallFormStyles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

enum RatesTabIds {
  HOURLY = 0,
  TRANSFER = 1,
  SAVOYA = 2,
}

const RatesDashboard: FC<{ vendor: DeepPartial<VendorRecord> }> = ({
  vendor,
}) => {
  const classes = useRatesStyles();

  const savoyaProvider = vendor?.vendorProviders?.find(
    (provider) => provider.providerType === ProviderType.Savoya,
  );

  const tabs: TabOption[] = [
    {
      id: RatesTabIds.HOURLY,
      title: 'Hourly Rates',
    },
    {
      id: RatesTabIds.TRANSFER,
      title: 'Transfer Rates',
      minimumTier: TierType.Premium,
    },
  ];

  if (savoyaProvider) {
    tabs.push({
      id: RatesTabIds.SAVOYA,
      title: 'SAVOYA',
      style: {
        fontWeight: 'bold',
        color: 'black',
        fontFamily: `Lexend Giga, Inter, sans-serif`,
        fontSize: '1.3rem',
      },
    });
  }

  const [selectedTab, setSelectedTab] = React.useState<number | string>(
    RatesTabIds.HOURLY,
  );

  return (
    <Grid
      container
      direction="column"
      data-testid="rates_and_policies_container"
    >
      <Grid item container direction="row" justify="space-between">
        <Grid item xs={6}>
          <Box m={1}>
            <CapitalDriveTabBar
              tabs={tabs}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
            />
          </Box>
        </Grid>
      </Grid>

      <Card className={classes.root} variant="elevation">
        <TabComponent value={selectedTab} index={RatesTabIds.HOURLY}>
          <HourlyRatesTab />
        </TabComponent>
        <TabComponent value={selectedTab} index={RatesTabIds.TRANSFER}>
          <TransferRatesTab />
        </TabComponent>
        <TabComponent value={selectedTab} index={RatesTabIds.SAVOYA}>
          <SavoyaRatesDashboard vendor={vendor} />
        </TabComponent>
      </Card>
    </Grid>
  );
};

export default RatesDashboard;
