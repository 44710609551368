import { Box, Button, DialogActions, Grid, MenuItem } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { Fragment } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  TripCancellationReason,
  useCancelTripMutation,
} from 'src/.gen/graphql';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import { tripCommandDialogState } from 'src/trips/atoms/tripCommandDialogAtom';
import { handleTripCommandException } from 'src/trips/hooks/useTripCommand';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { WarningButtonTheme } from 'src/shared/components/ButtonThemeProviders';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import BaseTripCommandDialog from './BaseTripCommandDialog';

const cancelTripSchema = Yup.object().shape({
  reason: Yup.string().required('Required'),
  notes: Yup.string().nullable(true),
});

const CancelTripCommandDialog: React.FC = () => {
  const [tripCommandDialog, setTripCommandDialog] = useRecoilState(
    tripCommandDialogState,
  );
  const setTimestampsState = useSetRecoilState(tripTimestampsState);
  const { enqueueSnackbar } = useSnackbar();

  const [cancelTrip, { loading }] = useCancelTripMutation();
  const closeModal = (succeeded: boolean) => {
    setTripCommandDialog((tripCommandDialogState) => ({
      ...tripCommandDialogState,
      isOpen: false,
      succeeded,
    }));
  };

  const handleSubmit = async (values) => {
    const {
      data: { executeCancelTrip },
    } = await cancelTrip({
      variables: {
        ...values,
        tripId: tripCommandDialog.tripId,
      },
    });

    setTimestampsState((prev) => ({
      ...prev,
      refetchInvoiceSummary: true,
      updating: true,
    }));
    handleTripCommandException(executeCancelTrip, (succeeded, errors) => {
      if (!succeeded) {
        errors.forEach((errorMessage) => {
          enqueueSnackbar(errorMessage, {
            variant: 'error',
          });
          Sentry.captureException(new Error(errorMessage));
        });
        closeModal(false);
        return;
      }
      closeModal(executeCancelTrip.succeeded);
    });
  };

  return (
    <Formik
      initialValues={{
        reason: TripCancellationReason.CustomerCancelled,
        notes: '',
      }}
      validationSchema={cancelTripSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <BaseTripCommandDialog
            title="Cancel Trip"
            content={
              <Fragment>
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      type="text"
                      name="reason"
                      select
                      label="Reason"
                      variant="outlined"
                    >
                      <MenuItem
                        value={TripCancellationReason.CustomerCancelled}
                      >
                        {`Customer cancelled ${'    '}`}
                      </MenuItem>
                      <MenuItem value={TripCancellationReason.CustomerNoShow}>
                        {`Customer did not show ${' '}`}
                      </MenuItem>
                      <MenuItem value={TripCancellationReason.NoAvailability}>
                        {`No availability ${'       '}`}
                      </MenuItem>
                      <MenuItem value={TripCancellationReason.Other}>
                        {`Other ${'                 '}`}
                      </MenuItem>
                    </Field>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt={4} />
                    <Field
                      component={TextField}
                      label="Notes"
                      name="notes"
                      fullWidth
                      multiline
                      rows={3}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Fragment>
            }
            actions={
              <DialogActions>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    closeModal(false);
                  }}
                >
                  Close
                </Button>
                <WarningButtonTheme>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={loading && <CircularProgressIndicator />}
                    type="submit"
                  >
                    Confirm
                  </Button>
                </WarningButtonTheme>
              </DialogActions>
            }
          />
        </form>
      )}
    </Formik>
  );
};

export default CancelTripCommandDialog;
