import React from 'react';
import {
  TableContainer,
  Link,
  Paper,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  makeStyles,
  fade,
  Button,
  DialogActions,
} from '@material-ui/core';
import { format, utcToZonedTime } from 'date-fns-tz';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { Skeleton } from '@material-ui/lab';
import type { SavoyaVendorAssignment } from 'src/trips/types/SavoyaTripsTypes';
import type { Camelize } from 'src/shared/types/camelize';
import { DateTimePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import { addMinutes } from 'date-fns';
import moment from 'moment';
import { timeUtils } from 'src/shared/utils/timeUtils';
import { CloseoutFromValues } from '../SavoyaPaymentDetails';

interface SavoyaTripLogProps {
  vendorAssignment: Camelize<SavoyaVendorAssignment>;
  editable: boolean;
  fullWidth?: boolean;
}

type Column = {
  id: string;
  label: string;
};

export type TripLogRecord = {
  status: string;
  field: string;
  actual: Date;
  timeZone: string;
  type: TripLogTypeEnum;
};

enum TripLogTypeEnum {
  GarageOut = 'GarageOut',
  PassengerOnboard = 'PassengerOnboard',
  PassengerDropped = 'PassengerDropped',
  GarageIn = 'GarageIn',
}

export const SAVOYA_TRIP_LOG_COLUMNS: Column[] = [
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'actual',
    label: 'Actual',
  },
];

const SavoyaTripSummary: React.FC<SavoyaTripLogProps> = ({
  vendorAssignment,
  editable,
  fullWidth,
}) => {
  const { values, errors, setFieldValue } =
    useFormikContext<CloseoutFromValues>();
  const { reservation } = vendorAssignment || {};
  const classes = useStylesTableTimestamps();

  const [selectedTimeStamp, setTimestamp] = React.useState<string>(null);
  const [selectedLog, setSelectedLog] = React.useState<TripLogRecord>(null);
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);

  const driverReportedPassengerOnboard =
    !!vendorAssignment?.reservation?.onboardDateAdjustment?.reportedDate;
  const driverReportedPassengerDropped =
    !!vendorAssignment?.reservation?.dropoffDateAdjustment?.reportedDate;

  let defaultPickupTimeMessage = '';
  let defaultDropoffTimeMessage = '';
  if (
    vendorAssignment?.defaultGarageOutTime &&
    vendorAssignment?.defaultGarageInDuration
  ) {
    defaultPickupTimeMessage =
      "Pickup and dropoff are within the reservation's service area, so garage times have been set to 30 minutes.";
    defaultDropoffTimeMessage = defaultPickupTimeMessage;
  } else if (vendorAssignment?.defaultGarageOutTime) {
    defaultPickupTimeMessage =
      "Pickup is within the reservation's service area, so garage time has been set to 30 minutes.";
  } else if (vendorAssignment?.defaultGarageInDuration) {
    defaultDropoffTimeMessage =
      "Dropoff is within the reservation's service area, so garage time has been set to 30 minutes.";
  }

  const totalTime = timeUtils.durationBetween(
    values?.outDateAdjustment,
    values?.inDateAdjustment,
  );

  const tripLogs: TripLogRecord[] = [
    {
      status: 'Garage Out',
      field: 'outDateAdjustment',
      actual: values?.outDateAdjustment,
      timeZone:
        reservation?.stops?.[0]?.city?.timezone ||
        reservation?.stops?.[0]?.airport?.timezone ||
        reservation?.timezone,
      type: TripLogTypeEnum.GarageOut,
    },
    {
      status: 'Passenger Onboard',
      field: 'onboardDateAdjustment',
      actual: values?.onboardDateAdjustment,
      timeZone:
        reservation?.stops?.[0]?.city?.timezone ||
        reservation?.stops?.[0]?.airport?.timezone ||
        reservation?.timezone,
      type: TripLogTypeEnum.PassengerOnboard,
    },
    {
      status: 'Passenger Dropped',
      field: 'dropoffDateAdjustment',
      actual: values?.dropoffDateAdjustment,
      timeZone:
        reservation?.stops?.[0]?.city?.timezone ||
        reservation?.stops?.[0]?.airport?.timezone ||
        reservation?.timezone,
      type: TripLogTypeEnum.PassengerDropped,
    },
    {
      status: 'Garage In',
      field: 'inDateAdjustment',
      actual: values?.inDateAdjustment,
      timeZone:
        reservation?.stops?.[0]?.city?.timezone ||
        reservation?.stops?.[0]?.airport?.timezone ||
        reservation?.timezone,
      type: TripLogTypeEnum.GarageIn,
    },
  ];

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const handleEditTimestamp = (value: string, tripLogRecord: TripLogRecord) => {
    setTimestamp(value);
    setSelectedLog(tripLogRecord);
    setDialogVisible(true);
  };

  const renderContentColumn = (
    value: string,
    tripLogRecord: TripLogRecord,
    columnId: string,
    editable: boolean,
  ): React.ReactNode => {
    const { timeZone, type } = tripLogRecord;
    const isActualColumn = columnId === 'actual';
    // full date time pattern with timezone dd/MM/yy HH:mm (z)
    const dateTimePattern = 'HH:mm a (z)';
    let formattedValue =
      isActualColumn && !!value
        ? format(value, dateTimePattern, {
            timeZone,
          })
        : value;
    const isDefaultGarageIn =
      type === TripLogTypeEnum.GarageIn && !value && defaultDropoffTimeMessage;
    if (isDefaultGarageIn) {
      formattedValue = '30 Min After Dropoff';
    }
    const showEditButton = editable && !isDefaultGarageIn;
    if (isActualColumn && showEditButton) {
      return (
        <Link
          className={classes.timeStampLink}
          variant="body1"
          onClick={() => handleEditTimestamp(value, tripLogRecord)}
        >
          {formattedValue || 'Add Value'}
        </Link>
      );
    }
    return (
      <Typography variant="body1" style={{ fontSize: 14 }}>
        {formattedValue || '-'}
      </Typography>
    );
  };

  const renderDialogContent = (timeStamp: string, logRecord: TripLogRecord) => {
    let messageContent = null;
    if (
      editable &&
      logRecord?.type === TripLogTypeEnum.GarageOut &&
      defaultPickupTimeMessage
    ) {
      messageContent = (
        <Typography variant="subtitle2">{defaultPickupTimeMessage}</Typography>
      );
    }
    if (
      editable &&
      logRecord?.type === TripLogTypeEnum.GarageIn &&
      defaultDropoffTimeMessage
    ) {
      messageContent = (
        <Typography variant="subtitle2">{defaultDropoffTimeMessage}</Typography>
      );
    }
    if (
      editable &&
      logRecord?.type === TripLogTypeEnum.PassengerOnboard &&
      driverReportedPassengerOnboard
    ) {
      messageContent = (
        <Typography variant="subtitle2">
          This Timestamp was Reported in the Mobile App by the Driver
        </Typography>
      );
    }
    if (
      editable &&
      logRecord?.type === TripLogTypeEnum.PassengerDropped &&
      driverReportedPassengerDropped
    ) {
      messageContent = (
        <Typography variant="subtitle2">
          This Timestamp was Reported in the Mobile App by the Driver
        </Typography>
      );
    }
    if (messageContent) {
      return (
        <Grid container>
          <Grid xs={12}>{messageContent}</Grid>
          <Grid xs={12}>
            <DialogActions>
              <Button
                data-testid="account_cancel_button"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialogVisible(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      );
    }

    const err = errors[logRecord?.field];
    return (
      <Grid container>
        <Grid item xs={12}>
          <DateTimePicker
            clearable
            label={logRecord?.status}
            value={timeStamp || reservation?.pickupDate}
            onChange={(newValue) => {
              if (!newValue) {
                return;
              }
              const newTimestamp = moment(newValue).toISOString(true);
              setTimestamp(newTimestamp);

              setFieldValue(logRecord?.field, newValue);
            }}
            required
            inputVariant="outlined"
            fullWidth
            views={['date', 'hours', 'minutes']}
            openTo="minutes"
          />
        </Grid>
        {err ? (
          <Grid item xs={12}>
            <Typography color="error">{err}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <DialogActions>
            <Button
              data-testid="account_cancel_button"
              variant="outlined"
              color="primary"
              onClick={() => {
                setTimestamp(undefined);
                setDialogVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="outlined"
              disabled={!!err}
              onClick={() => {
                if (
                  logRecord?.field === 'dropoffDateAdjustment' &&
                  defaultDropoffTimeMessage
                ) {
                  const timestampDate = new Date(selectedTimeStamp);
                  const defaultGarageIn = addMinutes(timestampDate, 30);
                  const parsedGarageIn = moment(defaultGarageIn)
                    .parseZone()
                    .tz(logRecord?.timeZone, true)
                    .toISOString(true);
                  setFieldValue(
                    'inDateAdjustment',
                    utcToZonedTime(parsedGarageIn, logRecord?.timeZone),
                  );
                }
                setDialogVisible(false);
              }}
            >
              <Typography>Submit</Typography>
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item className={classes.grid} xs={12} md={fullWidth ? 12 : 7}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {SAVOYA_TRIP_LOG_COLUMNS.map((column, index) => (
                <TableCell key={index}>
                  {!reservation ? <Skeleton width="100%" /> : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {(!reservation ? Array.from(new Array(2)) : tripLogs).map(
              (tripLogRecord: TripLogRecord, index: number) => {
                if (!tripLogRecord) {
                  return (
                    <TableRow key={index} className={classes.tableRow}>
                      {Array.from(
                        new Array(SAVOYA_TRIP_LOG_COLUMNS.length),
                      ).map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton width="100%" />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                }
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    {SAVOYA_TRIP_LOG_COLUMNS.map((column) => {
                      const value = tripLogRecord[column.id];
                      return (
                        <TableCell key={column.id}>
                          {renderContentColumn(
                            value,
                            tripLogRecord,
                            column.id,
                            editable,
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              },
            )}
            {reservation ? (
              <TableRow className={classes.tableRow}>
                <TableCell>
                  <Typography variant="subtitle2">Total</Typography>
                </TableCell>
                <TableCell>
                  {totalTime ? (
                    <Typography variant="subtitle2">
                      {totalTime} Hours
                    </Typography>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <GenericDialog
        openDialog={dialogVisible}
        setCloseDialog={closeDialog}
        dialogTitle={`Edit ${selectedLog?.status ?? ''} Timestamp`}
        maxWidth="sm"
      >
        {renderDialogContent(selectedTimeStamp, selectedLog)}
      </GenericDialog>
    </Grid>
  );
};

export const useStylesTableTimestamps = makeStyles((theme) => ({
  grid: {
    padding: '10px',
    minHeight: '330px',
  },
  table: {
    minHeight: '330px',
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  timeStampLink: {
    textDecoration: 'underline',
    fontSize: theme.typography.body2.fontSize,
    padding: 0,
    cursor: 'pointer',
  },
  tableHead: {
    '& > .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      color: theme.palette.textDark.main,
      '& > .MuiTableCell-root.MuiTableCell-head:not(:first-child)': {
        textAlign: 'center',
      },
    },
  },
  tableBody: {
    '& > .MuiTableRow-root:last-child': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      alignItems: 'center',
      jsutifyContent: 'center',
    },
  },
  tableRow: {
    '& > .MuiTableCell-root.MuiTableCell-body(:first-child)': {
      width: '60%',
    },
    '& > .MuiTableCell-root.MuiTableCell-body:not(:first-child)': {
      textAlign: 'center',
    },
    '& > .MuiTableCell-root.MuiTableCell-body': {
      padding: theme.spacing(2),
      height: 40,
    },
  },
}));

export default SavoyaTripSummary;
