import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import clsx from 'clsx';
import {
  Button,
  Collapse,
  ListItem as MuiListItem,
  Typography as MuiTypography,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  icon?: FC<Record<string, unknown>>;
  info?: FC<Record<string, unknown>>;
  open?: boolean;
  title: string;
  hidden?: boolean;
  premiumIcon?: React.ReactNode;
}

const ListItem = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.textDark.main,
    '&:hover, &:focus': {
      color: theme.palette.textLight.main,
      background: theme.palette.secondary.main,
    },
  },
  selected: {
    '&.Mui-selected': {
      background: theme.palette.secondary.dark,
      color: theme.palette.textLight.main,
    },
    '&.Mui-selected:hover': {
      background: theme.palette.secondary.dark,
      color: theme.palette.textLight.main,
    },
    '&.Mui-selected:focus': {
      background: theme.palette.secondary.dark,
      color: theme.palette.textLight.main,
    },
  },
}))(MuiListItem);

const Typography = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.textDark.main,
    '&:hover, &:focus': {
      background: theme.palette.textLight.main,
    },
    marginRight: 'auto',
    marginLeft: theme.spacing(4),
  },
}))(MuiTypography);

const ListItemText = withStyles((theme: Theme) => ({
  root: {
    color: 'inherit',
    marginRight: 'auto',
    marginLeft: theme.spacing(4),
  },
}))(MuiListItemText);

const ListItemIcon = withStyles((theme: Theme) => ({
  root: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    minWidth: 45,
    marginLeft: theme.spacing(1),
  },
}))(MuiListItemIcon);

const useStyles = makeStyles((theme: Theme) => ({
  withoutLeftMargin: {
    marginLeft: 0,
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  mouseOverItem: {
    color: theme.palette.textLight.main,
    background: theme.palette.secondary.main,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.textLight.light,
    padding: '10px 30px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.textLight.light,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0rem',
    },
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0px',
    color: theme.palette.textLight.light,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.textLight.light,
    },
  },
}));

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  hidden,
  premiumIcon = null,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();
  const to = useHref(href);
  const locationPathname = location.pathname;

  const isActive = locationPathname === href;

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        button
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon size="20" />}
          <Typography variant="body1">{title}</Typography>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  if (hidden) return null;

  return (
    <ListItem
      selected={isActive}
      disableGutters
      key={title}
      {...rest}
      button
      onClick={() => {
        navigate(to);
      }}
    >
      {Icon && (
        <ListItemIcon>
          <Icon size="20" />
        </ListItemIcon>
      )}
      <ListItemText className={classes.withoutLeftMargin}>{title}</ListItemText>
      {Info && <Info />}
      {premiumIcon}
    </ListItem>
  );
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
