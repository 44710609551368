import { atom, RecoilState } from 'recoil';
import {
  initialTripMonitoringState,
  TripMonitoringState,
} from './tripMonitoringAtomTypes';

export const tripMonitoringState: RecoilState<TripMonitoringState> = atom({
  key: 'tripMonitoringState',
  default: initialTripMonitoringState,
});
