import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { Trip, TripClientRecord } from 'src/.gen/graphql';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import { pdfjs, Document, Page } from 'react-pdf';
import { DeepPartial } from 'react-hook-form';
import SendInvoicePDF from './TripEstimateTotal/SendInvoicePDF';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface DialogProps {
  trip: Partial<Trip>;
  tripClients: DeepPartial<TripClientRecord>[];
  pdf: string | undefined;
  loadingPDF;
  isPDFDialogOpen: boolean;
  setIsPDFDialogOpen: (open: boolean) => void;
}

const useClasses = makeStyles(() => ({
  loadingPDFContainer: {
    padding: '2rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  PDFContainer: {
    margin: 'auto',
    padding: 0,
    overflowY: 'scroll',
    width: '100%',
  },
  PDFTitleContainer: {
    borderBottom: '',
    // height: '2.5rem',
  },
}));

const InvoicePDFDialog: React.FC<DialogProps> = ({
  trip,
  tripClients,
  pdf,
  loadingPDF,
  isPDFDialogOpen,
  setIsPDFDialogOpen,
}) => {
  const classes = useClasses();
  return (
    <GenericDialog
      titleClassName={classes.PDFTitleContainer}
      className={classes.PDFContainer}
      openDialog={isPDFDialogOpen}
      setCloseDialog={() => setIsPDFDialogOpen(false)}
      dialogTitle={
        loadingPDF && pdf ? (
          'Generating PDF...'
        ) : (
          <SendInvoicePDF trip={trip} tripClients={tripClients} />
        )
      }
      maxWidth="sm"
      contentClassName={!loadingPDF && pdf ? classes.PDFContainer : undefined}
    >
      {loadingPDF && (
        <div className={classes.loadingPDFContainer}>
          <CircularProgressIndicator />
        </div>
      )}
      {!loadingPDF && pdf && (
        <div>
          <Divider />
          <Document
            loading={
              <div className={classes.loadingPDFContainer}>
                <CircularProgressIndicator />
              </div>
            }
            file={`data:application/pdf;base64,${pdf}`}
          >
            <Page pageNumber={1} />
          </Document>
        </div>
      )}
    </GenericDialog>
  );
};

export default InvoicePDFDialog;
