import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { RatePolicy, RatePolicyType } from 'src/.gen/graphql';
import ComplimentaryWaitTimeForm from './ComplimentaryWaitTimeForm/ComplimentaryWaitTimeForm';

interface ComplimentaryWaitTimeProps {
  getPolicies: (
    policies: RatePolicyType[],
  ) => Partial<Record<RatePolicyType, RatePolicy>>;
  refreshData: () => void;
}
const ComplimentaryWaitTime: FC<ComplimentaryWaitTimeProps> = ({
  getPolicies,
  refreshData,
}) => {
  const policies = getPolicies([
    RatePolicyType.WaitTime,
    RatePolicyType.AirportWaitTime,
  ]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Complimentary Wait Time</Typography>
      </Grid>
      <Grid item xs={12}>
        <ComplimentaryWaitTimeForm
          policies={policies}
          refreshData={refreshData}
        />
      </Grid>
    </Grid>
  );
};

export default ComplimentaryWaitTime;
