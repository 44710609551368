import React, { FC, ReactNode } from 'react';
// Material UI
import {
  DialogContent,
  Dialog,
  DialogProps,
  DialogTitle,
  Typography,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

interface GenericDialogProps {
  openDialog: boolean;
  setCloseDialog?: (newValue: boolean) => void;
  dialogTitle?: ReactNode;
  children: ReactNode;
  fullScreen?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  contentClassName?: string;
  className?: string;
  titleClassName?: string;
}

const GenericDialog: FC<GenericDialogProps> = ({
  openDialog,
  setCloseDialog,
  dialogTitle,
  fullScreen,
  maxWidth,
  children,
  contentClassName,
  titleClassName,
  className,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      className={className}
      open={openDialog}
      maxWidth={maxWidth || 'xl'}
      onClose={setCloseDialog}
      fullWidth
      fullScreen={fullScreen}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle className={titleClassName} disableTypography>
        {typeof dialogTitle === 'string' ? (
          <Typography variant="h5" className={classes.title}>
            {dialogTitle}
          </Typography>
        ) : (
          dialogTitle
        )}
        <IconButton
          onClick={() => setCloseDialog(false)}
          className={classes.closeButton}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent className={contentClassName}>{children}</DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginRight: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
}));
export default GenericDialog;
