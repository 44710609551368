import React, { FC } from 'react';
import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import {
  GenericVehicle,
  GenericVehicleClass,
  useGetAvailableVehiclesQuery,
  VehicleAvailability,
} from 'src/.gen/graphql';

type VehicleClassDropdownProps = {
  tripId: string;
  vehicle: Partial<GenericVehicle> | undefined;
  setSelectedVehicleClass: (vehicleClass: Partial<GenericVehicleClass>) => void;
  setSelectedVehicle: (vehicle: Partial<GenericVehicle>) => void;
};

const isVehicleAvailable = (vehicle): boolean =>
  vehicle.availability === VehicleAvailability.InService;

const VehicleClassDropdown: FC<VehicleClassDropdownProps> = ({
  vehicle,
  setSelectedVehicle,
  setSelectedVehicleClass,
  tripId,
}) => {
  const { data: vehicles, loading } = useGetAvailableVehiclesQuery({
    variables: {
      tripId,
    },
  });

  const availableCarsByClass = vehicles?.trips?.availableVehicles?.internal;

  React.useEffect(() => {
    const vehiclesAvailable = (availableCarsByClass || []).filter((v) =>
      isVehicleAvailable(v),
    );
    if (vehiclesAvailable.length > 0) {
      const vehicleDefault = vehiclesAvailable[0];
      if (vehiclesAvailable.length === 1) {
        setSelectedVehicle({
          id: vehicleDefault?.vehicle?.id,
          makeName: vehicleDefault?.vehicle?.makeName,
          modelName: vehicleDefault?.vehicle?.modelName,
          licenseNumber: vehicleDefault?.vehicle?.licenseNumber,
          year: vehicleDefault?.vehicle?.year,
        });
        if (vehicleDefault?.vehicle?.vehicleClassId) {
          setSelectedVehicleClass({
            id: vehicleDefault?.vehicle?.vehicleClassId,
            name: vehicleDefault?.vehicle?.vehicleClassName,
          });
        }
      }
    }
  }, [vehicles]);

  return (
    <FormControl fullWidth>
      {availableCarsByClass?.length ? (
        <TextField
          fullWidth
          label="Assigned Vehicle"
          name="assignedVehicle"
          onChange={(e) => {
            const vehicleSelected =
              vehicles?.trips?.availableVehicles?.internal?.find(
                (el) => el.vehicle.id === e.target.value,
              );
            setSelectedVehicle({
              id: vehicleSelected?.vehicle?.id,
              makeName: vehicleSelected?.vehicle?.makeName,
              modelName: vehicleSelected?.vehicle?.modelName,
              licenseNumber: vehicleSelected?.vehicle?.licenseNumber,
              year: vehicleSelected?.vehicle?.year,
            });
            if (vehicleSelected?.vehicle?.vehicleClassId) {
              setSelectedVehicleClass({
                id: vehicleSelected?.vehicle?.vehicleClassId,
                name: vehicleSelected?.vehicle?.vehicleClassName,
              });
            }
          }}
          select
          SelectProps={{ native: true }}
          value={vehicle?.id || 'undefined'}
          required
          variant="outlined"
        >
          {!vehicle && (
            <option key="undefined" value="undefined">
              {' '}
            </option>
          )}
          {availableCarsByClass.map((vehicle) => {
            const fallbackName = `${vehicle.vehicle.year || ''} ${
              vehicle.vehicle.modelName || ''
            } ${vehicle.vehicle.licenseNumber || ''}`;
            return (
              <option
                key={vehicle.vehicle.id}
                value={vehicle.vehicle.id}
                disabled={!isVehicleAvailable(vehicle)}
              >
                {vehicle?.vehicle?.name
                  ? `${vehicle?.vehicle?.name} (${vehicle?.vehicle?.licenseNumber})`
                  : fallbackName}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {`${!isVehicleAvailable(vehicle) ? '* Booked' : ''}`}
              </option>
            );
          })}
        </TextField>
      ) : null}
      {!loading && !availableCarsByClass?.length ? (
        <TextField
          variant="outlined"
          color="primary"
          value="No Vehicles Available for this Vehicle Class"
          disabled
        />
      ) : null}
      {loading ? (
        <TextField
          variant="outlined"
          color="primary"
          disabled
          InputProps={{
            startAdornment: <CircularProgress size="1.5rem" />,
          }}
        />
      ) : null}
    </FormControl>
  );
};

export default VehicleClassDropdown;
