import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { RatePolicy, RatePolicyType } from 'src/.gen/graphql';
import StandardGarageTimeForm from './StandardGarageTimeForm/StandardGarageTimeForm';

interface StandardGarageTimeProps {
  getPolicy: (policy: RatePolicyType) => Partial<RatePolicy>;
}

const StandardGarageTime: FC<StandardGarageTimeProps> = ({ getPolicy }) => {
  const valuesGarageTime = getPolicy(RatePolicyType.GarageTime);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Standard Garage Time</Typography>
      </Grid>
      <Grid item xs={12}>
        <StandardGarageTimeForm policy={valuesGarageTime} />
      </Grid>
    </Grid>
  );
};

export default StandardGarageTime;
