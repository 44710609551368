/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { ApolloCache, FieldPolicy, TypePolicies } from '@apollo/client';
import { FieldNode } from 'graphql';
import { Query } from 'src/.gen/graphql';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';

type Queries = Omit<Query, '__typename' | '_default'>;

type RootQueryNames = keyof Queries;

type QueryName<T> = T[keyof T];

type QueryType = QueryName<Queries>;

type Unarray<T> = T extends Array<infer U> ? U : T;

type FieldNames<T> = T extends unknown ? keyof Omit<T, '__typename'> : never;
// ======================================

// interface FieldArgs extends FieldPolicy {
//   persistByArgs?: boolean | string[];
// }

type Fields<T> = {
  name: FieldNames<T>;
  mergePagniation?: boolean;
  persistByArgs?: boolean | string[];
};

interface Policy {
  rootField: RootQueryNames;
  queryName: Unarray<QueryType>['__typename'];
  arrayFields: Fields<Queries[Policy['rootField']]>[];
  // args?: FieldArgs;
}

export const policies: Policy[] = [
  {
    rootField: 'clientQueries',
    queryName: 'ClientsQueries',
    arrayFields: [
      {
        name: 'searchClients',
        mergePagniation: true,
        persistByArgs: [
          'accountId',
          'primaryRole',
          'clientId',
          'orderBy',
          'companyId',
          'includeInactive',
          '!includeInactive',
        ],
      },
    ],
  },
  {
    rootField: 'clientQueries',
    queryName: 'ClientsQueries',
    arrayFields: [
      {
        name: 'searchClients',
        mergePagniation: true,
        persistByArgs: [
          'accountId',
          'primaryRole',
          'clientId',
          'orderBy',
          'companyId',
          'includeInactive',
          '!includeInactive',
        ],
      },
    ],
  },
  {
    rootField: 'companyQueries',
    queryName: 'CompanyQueries',
    arrayFields: [
      {
        name: 'search',
        mergePagniation: true,
        persistByArgs: [
          'orderBy',
          'companyId',
          'includeInactive',
          '!includeInactive',
        ],
      },
    ],
  },
  {
    rootField: 'customers',
    queryName: 'CustomerQueries',
    arrayFields: [
      { name: 'all' },
      { name: 'byId', mergePagniation: false, persistByArgs: ['id'] },
      {
        name: 'search',
        mergePagniation: true,
        persistByArgs: [
          'customerType',
          'orderBy',
          'customerId',
          '!customerType',
          'includeInactive',
          '!includeInactive',
        ],
      },
    ],
  },
  {
    rootField: 'trips',
    queryName: 'TripQueries',
    arrayFields: [
      {
        name: 'search',
        mergePagniation: true,
        persistByArgs: [
          'id',
          'filter.customerId',
          'filter.customerContactId',
          'filter.startDate',
          'filter.endDate',
          'filter.completionStatus',
          'filter.statuses',
          'filter.savoyaSharedTripStatuses',
          'filter.driverId',
          'filter.vehicleId',
          'accountId',
          'filter',
          'orderBy',
          'orderBy.pickupTime',
          'skip',
          'limit',
        ],
      },
    ],
  },
  {
    rootField: 'invoices',
    queryName: 'InvoiceQueries',
    arrayFields: [
      {
        name: 'getEstimatedSummary',
        mergePagniation: false,
        persistByArgs: ['tripId', 'vehicleClassId'],
      },
      {
        name: 'getSummaryByTripId',
        mergePagniation: false,
        persistByArgs: ['tripId'],
      },
    ],
  },
  {
    rootField: 'customerContacts',
    queryName: 'CustomerContactQueries',
    arrayFields: [
      { name: 'all', persistByArgs: ['customerId'] },
      {
        name: 'searchByAccountId',
        persistByArgs: ['accountId', 'includeInactive', '!includeInactive'],
      },
      {
        name: 'searchByContactEmailAndAccountId',
        persistByArgs: [
          'query',
          'accountId',
          'includeInactive',
          '!includeInactive',
        ],
      },
      {
        name: 'searchByContactNameAndAccountId',
        persistByArgs: [
          'query',
          'accountId',
          'includeInactive',
          '!includeInactive',
        ],
      },
      {
        name: 'search',
        mergePagniation: true,
        persistByArgs: [
          'accountId',
          'primaryRole',
          'contactId',
          'orderBy',
          'customerId',
          'includeInactive',
          '!includeInactive',
        ],
      },
    ],
  },
  {
    rootField: 'contacts',
    queryName: 'ContactQueries',
    arrayFields: [
      {
        name: 'search',
        persistByArgs: [
          'query',
          'includeInactive',
          '!includeInactive',
          'type',
          '!type',
        ],
      },
      { name: 'me', mergePagniation: false },
      { name: 'byId', mergePagniation: false },
    ],
  },
  {
    rootField: 'garages',
    queryName: 'GarageQueries',
    arrayFields: [
      { name: 'byId', mergePagniation: false, persistByArgs: ['id'] },
    ],
  },
  {
    rootField: 'vehicles',
    queryName: 'VehicleQueries',
    arrayFields: [
      { name: 'all' },
      {
        name: 'search',
        mergePagniation: true,
        persistByArgs: [
          'accountId',
          'vehicleClassId',
          'orderBy',
          'includeInactive',
          '!includeInactive',
        ],
      },
    ],
  },
  {
    rootField: 'vehicleClasses',
    queryName: 'VehicleClassQueries',
    arrayFields: [{ name: 'all' }],
  },
  {
    rootField: 'vehicleModels',
    queryName: 'VehicleModelQueries',
    arrayFields: [{ name: 'byMake', persistByArgs: ['makeId'] }],
  },
  {
    rootField: 'accounts',
    queryName: 'AccountQueries',
    arrayFields: [
      {
        name: 'search',
        persistByArgs: ['query', 'isFavorite', 'perPage', 'offset'],
      },
      { name: 'byId', mergePagniation: false },
    ],
  },
  {
    rootField: 'favoriteDrivers',
    queryName: 'FavoriteDriverQueries',
    arrayFields: [
      { name: 'search', mergePagniation: false },
      { name: 'byId', mergePagniation: false },
    ],
  },
  {
    rootField: 'media',
    queryName: 'MediaQueries',
    arrayFields: [
      { name: 'filesFromPath', mergePagniation: false, persistByArgs: ['key'] },
    ],
  },
  {
    rootField: 'airlines',
    queryName: 'AirlineQueries',
    arrayFields: [
      {
        name: 'airlinesAutocomplete',
        mergePagniation: false,
        persistByArgs: ['query'],
      },
    ],
  },
];

export const buildTypePolicies = (): TypePolicies => {
  const typePolicies = policies.reduce(
    (result, policy) => {
      const { rootField, queryName, arrayFields } = policy;
      // const { persistByArgs = false } = args || {};
      const fields = getFields(arrayFields);
      return {
        ...result,
        Query: {
          fields: {
            ...result.Query.fields,
            [rootField]: {
              keyArgs(obj, ctx) {
                const { variables, field } = ctx;
                const { selections } = field.selectionSet;
                const nestedField = getNestedField(selections, arrayFields);
                const { persistByArgs = [] } =
                  arrayFields.find(({ name }) => name === nestedField) || {};

                const confirmArgs =
                  Array.isArray(persistByArgs) && persistByArgs.length
                    ? _pick(variables, persistByArgs)
                    : {};

                return nestedField
                  ? JSON.stringify({
                      nestedField,
                      ...confirmArgs,
                    })
                  : false;
              },
            },
          },
        },
        [queryName]: { fields },
      };
    },
    { Query: { fields: {} } },
  );
  return {
    ...typePolicies,
    Garage: {
      fields: {
        location: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
  };
};

const getNestedField = (fieldSelections: FieldNode[], fields) => {
  const { name = { value: '' } } =
    fieldSelections.find(({ name }) =>
      fields.map(({ name }) => name).includes(name.value),
    ) || {};
  return name.value;
};

export const getFields = (
  items: Policy['arrayFields'],
): Record<string, FieldPolicy> => {
  return items.reduce((result, { name, mergePagniation = true }) => {
    return {
      ...result,
      [name]: offsetLimitPaginatedField(mergePagniation),
    };
  }, {});
};

const offsetLimitPaginatedField = (mergePagniation: boolean) => {
  if (!mergePagniation) return { keyArgs: false };

  return {
    read(existing, ctx) {
      if (!existing) return existing;
      const {
        args: { skip, perPage },
      } = ctx;
      const { items, ...rest } = existing;

      const result = rest;

      result.items = items.slice(skip, skip + perPage);

      if (!result.items.length) {
        return existing;
      }

      return result;
    },
    keyArgs: false, // Its overwritting the id if its a nested field, not persisting differents cache ids. dont know if its a bug or the expected behavior.
    merge(existing, incoming, ctx) {
      if (!incoming) return existing;
      if (!existing) return incoming;

      const { items, ...rest } = incoming;
      const {
        args: { skip, take },
      } = ctx;
      const result = rest;

      const merged = existing.items.slice(0).filter(ctx.canRead);

      const offsetDiff = Math.max(merged?.length - skip, 0);
      const end = skip + Math.min(take, items.length);

      for (let i = skip; i < end; ++i) {
        const item = items[i - skip];

        const existing = merged.find(
          (m) => ctx.readField('id', m) === ctx.readField('id', item),
        );

        if (!existing) {
          merged[i + offsetDiff] = item;
        }
      }
      const newItems = merged.filter((m) => m);
      result.items = newItems;
      return result;
    },
  };
};

interface InsertItemCacheProps {
  (
    data: any,
    previous: any,
    context: any,
    filterField?: (
      args: Record<string, any>,
      data?: Record<string, any>,
    ) => boolean,
  ): any;
}
export const insertItemCache: InsertItemCacheProps = (
  data,
  previous,
  { toReference, storeFieldName },
  filterField = () => false,
) => {
  const { args } = extractFieldNameAndArgs(storeFieldName);
  const returnPrevious = filterField(args, data);
  if (returnPrevious) {
    return previous;
  }

  const [obj] = getDataFromPath(data, 'errors');
  const { errors = null } = obj || {};

  if (errors) return previous;

  function handlerItems(lastItems) {
    const [item] = getDataFromPath(data, 'id');
    const newItem = toReference(item);
    return [...lastItems, newItem];
  }

  const response = buildResponseCache(previous, handlerItems);
  return response;
};

const extractFieldNameAndArgs = (key: string) => {
  if (!key.includes(':')) {
    return { fieldName: key, args: null };
  }
  const seperatorIndex = key.indexOf(':');
  const fieldName = key.slice(0, seperatorIndex);
  const args = convertKeyArgs(key);

  return { fieldName, args };
};

const convertKeyArgs = (key: string): Record<string, any> => {
  const seperatorIndex = key.indexOf(':');
  const keyArgs = key.slice(seperatorIndex + 1);

  const isLegacyArgs = keyArgs?.startsWith('(') && keyArgs.endsWith(')');

  const toParse = isLegacyArgs ? keyArgs.slice(1, keyArgs.length - 1) : keyArgs;

  const args = getSafe(() => JSON.parse(toParse), null);
  return args;
};

const getSafe = (parse, value) => {
  try {
    return parse();
  } catch (error) {
    return value;
  }
};

export const deleteItemCache = (
  cache: ApolloCache<unknown>,
  obj: any,
): void => {
  const normalizedId = cache.identify(obj);
  cache.evict({ id: normalizedId });
  cache.gc();
};

const buildResponseCache = (previous, handlerItems) => {
  const prevResult = _cloneDeep(previous);
  const [obj, path] = getDataFromPath(prevResult, 'items');

  if (!obj) {
    return prevResult;
  }

  const { items: lastItems } = obj || {};
  const keys = path.split('.');
  const keysReversed = keys.slice().reverse();
  const items = handlerItems(lastItems);

  return {
    ...prevResult,
    ...keysReversed.reduce((result, key, index) => {
      const actualValue = keys
        .slice(0, keys.length - index)
        .reduce((obj, i) => {
          return obj[i];
        }, prevResult);

      return !index
        ? {
            [key]: { ...actualValue, items },
          }
        : { [key]: { ...actualValue, ...result } };
    }, {}),
  };
};

const getDataFromPath = (obj, rootField) => {
  const path = findPath(obj, rootField);
  return [
    path.split('.').reduce((obj, i) => {
      return obj[i];
    }, obj),
    path,
  ];
};

const findPath = (ob, key) => {
  const path = [];
  const keyExists = (obj) => {
    if (!obj || typeof obj !== 'object') {
      return false;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      return true;
    }

    for (const k in obj) {
      path.push(k);
      const result = keyExists(obj[k]);
      if (result) {
        return result;
      }
      path.pop();
    }

    return false;
  };

  keyExists(ob);

  return path.join('.');
};
