import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { TripSegment, TripWaypoint } from 'src/.gen/graphql';
import { getEstimatedArrivalTime } from 'src/shared/utils/addressUtils';
import { convertMinutesToFormattedTime } from 'src/shared/utils/conversions';

interface WaypointsTimeAndMilesProps {
  pickupTime: Date;
  pickupWaypoint: Partial<TripWaypoint>;
  dropoffWaypoint: Partial<TripWaypoint>;
  segments?: Partial<TripSegment>[];
  overrideDropoffDetails: boolean;
  isSavoyaView?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentTime: {
    marginBottom: -theme.spacing(4),
    width: '100%',
  },
}));

const WaypointsTimeAndMiles: FC<WaypointsTimeAndMilesProps> = (props) => {
  const classes = useStyles();
  const {
    pickupTime,
    pickupWaypoint,
    dropoffWaypoint,
    segments,
    overrideDropoffDetails,
    isSavoyaView = false,
  } = props;
  let durationInMinutes;
  let distanceInMiles;
  let estimatedDurationInMinutes;
  let estimatedDistanceInMiles;

  (segments ?? []).forEach((segment) => {
    if (
      isSavoyaView &&
      (segment?.order === 0 || segment?.order === segments.length - 1)
    ) {
      return;
    }
    durationInMinutes = segment?.durationInMinutes
      ? (durationInMinutes ?? 0) + segment.durationInMinutes
      : undefined;
    distanceInMiles = segment?.distanceInMiles
      ? (distanceInMiles ?? 0) + segment.distanceInMiles
      : undefined;
    estimatedDurationInMinutes = segment?.estimatedDurationInMinutes
      ? (estimatedDurationInMinutes ?? 0) + segment.estimatedDurationInMinutes
      : undefined;
    estimatedDistanceInMiles = segment?.estimatedDistanceInMiles
      ? (estimatedDistanceInMiles ?? 0) + segment.estimatedDistanceInMiles
      : undefined;
  });

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      justifyContent="space-between"
      className={classes.contentTime}
    >
      <Typography color="textSecondary" variant="caption">
        {isSavoyaView ? '~' : null}
        {convertMinutesToFormattedTime(
          durationInMinutes ?? estimatedDurationInMinutes,
        )}
        {distanceInMiles || estimatedDistanceInMiles
          ? `(${(distanceInMiles ?? estimatedDistanceInMiles).toFixed(2)} mi)`
          : null}
      </Typography>
      {!overrideDropoffDetails ? (
        <Typography color="textSecondary" variant="caption">
          ETA:{' '}
          {getEstimatedArrivalTime(pickupTime, pickupWaypoint, dropoffWaypoint)}
        </Typography>
      ) : null}
    </Box>
  );
};

export default WaypointsTimeAndMiles;
