import { useState, useContext, useEffect } from 'react';
import { SubscriptionContext } from 'src/contexts';
import { TierType, UserRole } from 'src/.gen/graphql';

interface UseSubscriptionReturnType {
  accountSubscribed: boolean;
  accountExists: boolean;
  redirectexistingAccountToSubscribe: boolean;
  accountId: string;
  currentTier: TierType;
}

export const useSubscription = (): UseSubscriptionReturnType => {
  const {
    subscriptionsEnabled,
    subscriptionId,
    accountId,
    userRole,
    currentTier,
  } = useContext(SubscriptionContext);
  const [accountSubscribed, setAccountSubscribed] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [
    redirectexistingAccountToSubscribe,
    setRedirectexistingAccountToSubscribe,
  ] = useState(false);
  const redirectUser =
    subscriptionsEnabled &&
    accountId &&
    !subscriptionId &&
    userRole === UserRole.AccountOwner;

  useEffect(() => {
    if (accountId) {
      setAccountExists(true);
    }
    if (accountId && subscriptionId) {
      setAccountSubscribed(true);
    }
    if (redirectUser) {
      setRedirectexistingAccountToSubscribe(true);
    }
  }, [subscriptionId, accountId]);

  return {
    accountSubscribed,
    accountExists,
    redirectexistingAccountToSubscribe,
    accountId,
    currentTier,
  };
};
