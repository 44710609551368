import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import TabContainer from 'src/shared/components/CapitalDriveTabBar/CapitalDriveTabContainer';
import CapitalDriveTabBar from 'src/shared/components/CapitalDriveTabBar/CapitalDriveTabBar';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { DeepPartial } from 'react-hook-form';
import { InvoiceStatus, Trip, TripClientRecord } from '../../.gen/graphql';
import SelectPaymentMethod, { CHARGEABLE_STATUS } from './SelectPaymentMethod';
import TripRateBreakdown from './TripRateBreakdown/TripRateBreakdown';
import TripLog from './TripLog/TripLog';
import InvoiceTimes from './InvoiceTimes/InvoiceTimes';
import RatePoliciesParagraph from './RatePoliciesParagraph';
import useInvoiceSummary from './InvoiceTimes/hooks/useInvoiceSummary';

interface PaymentDetailsProps {
  trip: Partial<Trip>;
  tripClients: DeepPartial<TripClientRecord>[];
}
export const PAYMENT_DETAILS_TAB_ID = 0;
const PAYMENT_DETAILS_TABS = [
  { id: 0, title: 'Trip Total' },
  { id: 1, title: 'Invoices Times' },
  { id: 2, title: 'Trip Log' },
];

const PaymentDetailsView: React.FC<PaymentDetailsProps> = ({
  trip,
  tripClients,
}) => {
  const stripeReturnUrl = `${window.location.protocol}//${window.location.host}/trips/${trip?.id}`;
  const [selectedTab, setSelectedTab] = React.useState<number | string>(
    PAYMENT_DETAILS_TAB_ID,
  );
  const user = useRecoilValue(userAtom);
  const iOwnThisTrip = user?.accountId === trip?.accountId;

  useInvoiceSummary({
    tripId: trip?.id,
  });

  return (
    <Grid container>
      {!iOwnThisTrip && (
        <React.Fragment>
          <Grid item xs={12} md={6} style={{ padding: '4rem 2rem 1rem' }}>
            <Typography variant="h6" color="textPrimary">
              {trip ? 'Payment Method' : <Skeleton width="10rem" />}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {trip ? (
                `${trip?.sharedTrip?.paymentMethodType ?? ''}`
              ) : (
                <Skeleton width="10rem" />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: '4rem 2rem 1rem' }}>
            <Typography variant="h6" color="textPrimary">
              {trip ? 'Amount' : <Skeleton width="10rem" />}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {trip?.sharedTrip?.amount ? (
                `$${trip.sharedTrip?.amount.toFixed(2) ?? ''}`
              ) : (
                <Skeleton width="10rem" />
              )}
            </Typography>
          </Grid>
        </React.Fragment>
      )}
      {trip && iOwnThisTrip && (
        <Grid
          item
          xs={12}
          md={5}
          container
          justify="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            md={10}
            container
            justify="center"
            style={{ width: '100%' }}
          >
            <Typography variant="h6" color="textPrimary">
              {trip ? 'Payment Details' : <Skeleton width="10rem" />}
            </Typography>
            {trip && CHARGEABLE_STATUS.includes(trip.status) && (
              <SelectPaymentMethod
                trip={trip}
                tripClients={tripClients}
                display="inModal"
                modalBtnTitle="CHARGE CLIENT"
                returnUrl={stripeReturnUrl}
                withBalance
                chargeable
                showInvoice
              />
            )}
            {trip && !CHARGEABLE_STATUS.includes(trip.status) && (
              <SelectPaymentMethod
                trip={trip}
                tripClients={tripClients}
                display="default"
                returnUrl={stripeReturnUrl}
                showInvoice
                editable={
                  trip?.invoice?.status &&
                  trip?.invoice?.status !== InvoiceStatus.Paid
                }
              />
            )}
          </Grid>
        </Grid>
      )}
      {trip && iOwnThisTrip && (
        <Grid
          item
          xs={12}
          md={7}
          container
          justify="center"
          alignItems="flex-start"
          style={{ display: 'flex' }}
        >
          <Grid item xs={12} md={10}>
            <CapitalDriveTabBar
              tabs={PAYMENT_DETAILS_TABS}
              onTabChange={(newTab: number | string) => setSelectedTab(newTab)}
              selectedTab={selectedTab}
            />
          </Grid>
          <TabContainer selectedTab={selectedTab} tabId={0}>
            <Grid item xs={10} md={6} style={{ marginTop: '1rem' }}>
              <TripRateBreakdown trip={trip} />
            </Grid>
          </TabContainer>
          <TabContainer selectedTab={selectedTab} tabId={1}>
            <Grid item xs={10} md={10} style={{ marginTop: '1rem' }}>
              <InvoiceTimes trip={trip} />
            </Grid>
          </TabContainer>
          <TabContainer selectedTab={selectedTab} tabId={2}>
            <Grid item xs={10} md={10} style={{ marginTop: '1rem' }}>
              <TripLog trip={trip} />
            </Grid>
          </TabContainer>
        </Grid>
      )}
      {trip && iOwnThisTrip && (
        <Grid item xs={12} style={{ padding: '4rem 2rem 1rem' }}>
          <Typography variant="h6" color="textPrimary">
            {trip ? 'Rate Policies' : <Skeleton width="10rem" />}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {trip?.rateData ? (
              <RatePoliciesParagraph
                pickupTime={trip.pickupTime}
                rateData={trip?.rateData}
                vehicleClass={
                  trip?.vehicle?.vehicleClass || trip?.preferredVehicleClass
                }
              />
            ) : (
              <Skeleton width="10rem" />
            )}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentDetailsView;
