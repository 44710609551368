import { createMuiTheme } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';

// declare module '@material-ui/core/styles/createMuiTheme' {
//   interface Theme {
//     palette: {
//       warning: React.CSSProperties['color'];
//     }
//   }
//   interface ThemeOptions {
//     status?: {
//       warning: React.CSSProperties['color'];
//     };
//   }
// }

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    textDark?: Palette['primary'];
    textGray?: Palette['primary'];
    textLight?: Palette['primary'];
    textBlue?: Palette['primary'];
    backgroundColor?: Palette['primary'] & { secondary?: string };
    deleteAction?: Palette['primary'];
  }
  interface PaletteOptions {
    textDark?: PaletteOptions['primary'];
    textGray?: PaletteOptions['primary'];
    textLight?: PaletteOptions['primary'];
    textBlue?: PaletteOptions['primary'];
    backgroundColor?: PaletteOptions['primary'] & { secondary?: string };
    deleteAction?: PaletteOptions['primary'];
    gradient?: PaletteOptions['primary'] & {
      firstGradient: string;
      secondGradient: string;
      mainGradient: string;
    };
  }
}

enum FontWeight {
  light = 300,
  semiBold = 600,
  bold = 700,
  regular = 400,
  medium = 500,
}
// #E2E6E9
// #FAF1EA
const theme = createMuiTheme({
  palette: {
    /**
     *  Used to indicate the successful completion of an action that user triggered.
     */
    success: {
      main: green[500],
    },
    /**
     * Used to represent potentially dangerous actions or important messages
     */
    warning: {
      main: yellow[500],
    },
    /**
     * Used to represent interface elements that the user should be made aware of
     */
    error: {
      main: '#D30F32',
      '100': '#FFF5F7',
    },
    /**
     * Used to represent primary interface elements for a user?.
     * It's the color displayed most frequently across your app's screens and components.
     */
    primary: {
      // light: '#F2F6FF',
      // dark: '#000',
      dark: '#4f5154',
      main: '#966440',
      light: '#FAF5F0',
    },
    gradient: {
      firstGradient: '#d7d7d8',
      secondGradient: '#FAF7F0',
      mainGradient: 'linear-gradient(#d7d7d8, #FAF7F0)',
    },
    /**
     * Used to represent secondary interface elements for a user?.
     * It provides more ways to accent and distinguish your product.
     * Having it is optional.
     */
    secondary: {
      main: '#4f5154',
      light: '#e0e0e0',
      dark: '#000',
      // main: '#966440',
      // light: '#FAF5F0',
    },
    textDark: {
      light: '#222429',
      main: '#222429',
      dark: '#222429',
    },
    textGray: {
      light: '#84878A',
      main: '#84878A',
      dark: '#828282',
      contrastText: '#5A6275',
    },
    textLight: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
    },
    backgroundColor: {
      light: '#F4F6F8',
      main: '#474747',
      dark: '#000000',
      secondary: '#FAFBFD',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    // Extremely large text.
    h1: {
      fontWeight: FontWeight.bold,
      fontSize: 96,
    },
    // Used for the date in the dialog shown
    h2: {
      fontWeight: FontWeight.semiBold,
      fontSize: 60,
    },
    // Very large text.
    h3: {
      fontWeight: FontWeight.bold,
      fontSize: 48,
    },
    // Large text.
    h4: {
      fontWeight: FontWeight.semiBold,
      fontSize: 34,
    },
    // Used for large text in dialogs
    h5: {
      fontWeight: FontWeight.semiBold,
      fontSize: 24,
    },
    // Used for the primary text in app bars and dialogs
    h6: {
      fontWeight: FontWeight.semiBold,
      fontSize: 20,
    },
    // Used for the primary text in lists
    subtitle1: {
      fontWeight: FontWeight.bold,
      fontSize: 16,
    },
    // For medium emphasis text that's a little smaller than subtitle1.
    subtitle2: {
      fontWeight: FontWeight.semiBold,
      fontSize: 14,
    },
    // Used for emphasizing text that would otherwise be bodyText2.
    body1: {
      fontWeight: FontWeight.regular,
      fontSize: 16,
    },
    // The default text style for Material.
    body2: {
      fontWeight: FontWeight.regular,
      fontSize: 14,
    },
    // Used for text on button
    button: {
      fontWeight: FontWeight.medium,
      fontSize: 16,
      textTransform: 'capitalize',
    },
    // Used for auxiliary text associated with images.
    caption: {
      fontWeight: FontWeight.regular,
      fontSize: 12,
    },
    // Typically used for captions or to introduce a (larger) headline.
    overline: {
      fontWeight: FontWeight.semiBold,
      fontSize: 12,
    },
  },
  overrides: {
    MuiTableHead: {
      root: {
        '& tr': {
          '& th': {
            fontWeight: FontWeight.semiBold,
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        fontFamily: 'Inter, sans-serif',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0.75rem', // 12px
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(23, 27, 37, 0.2)', // backdrop in the designs
      },
    },
    MuiInputBase: {
      input: {
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
    MuiDrawer: {
      root: {
        background: `linear-gradient(to right, #d7d7d8, #FAF7F0)`,
      },
    },
  },
});

export default theme;
