import { FetchResult } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useRecoilState } from 'recoil';
import {
  ArriveDropoffMutation,
  ArriveGarageMutation,
  ArriveStopMutation,
  CirclingMutation,
  CloseTripMutation,
  DeclineNetworkConfirmationRequestMutation,
  DeclineNetworkOfferMutation,
  DepartPickupMutation,
  DepartStopMutation,
  EnRouteToPickupMutation,
  InvoicePaidMutation,
  InvoiceSubmittedMutation,
  RequestReassignmentMutation,
  RevokeNetworkInvitationMutation,
  RevokeNetworkSharedTripMutation,
  RollbackToOnsiteMutation,
  WaitingMutation,
  useArriveDropoffMutation,
  useArriveGarageMutation,
  useArriveStopMutation,
  useCirclingMutation,
  useCloseTripMutation,
  useDeclineNetworkConfirmationRequestMutation,
  useDeclineNetworkOfferMutation,
  useDepartPickupMutation,
  useDepartStopMutation,
  useEnRouteToPickupMutation,
  useInvoicePaidMutation,
  useInvoiceSubmittedMutation,
  useRequestReassignmentMutation,
  useRevokeNetworkSharedTripMutation,
  useRollbackToOnsiteMutation,
  useWaitingMutation,
} from 'src/.gen/graphql';
import { tripCommandDialogState } from 'src/trips/atoms/tripCommandDialogAtom';

type TripCommandResult =
  | EnRouteToPickupMutation
  | CirclingMutation
  | CloseTripMutation
  | DepartPickupMutation
  | ArriveDropoffMutation
  | ArriveStopMutation
  | DepartStopMutation
  | ArriveGarageMutation
  | InvoiceSubmittedMutation
  | InvoicePaidMutation
  | RequestReassignmentMutation
  | DeclineNetworkConfirmationRequestMutation
  | DeclineNetworkOfferMutation
  | RevokeNetworkInvitationMutation;

type ResultCommandMutation =
  | FetchResult<
      TripCommandResult,
      Record<string, unknown>,
      Record<string, unknown>
    >
  // | FetchResult<Record<string, unknown>, Record<string, unknown>>
  | undefined;

enum InvoicePaid {
  StartTripPreparation = 'StartTripPreparation',
  EnRouteToPickup = 'EnRouteToPickup',
  Circling = 'Circling',
  Waiting = 'Waiting',
  CloseTrip = 'CloseTrip',
  DepartPickup = 'DepartPickup',
  ArriveStop = 'ArriveStop',
  ArriveDropoff = 'ArriveDropoff',
  DepartStop = 'DepartStop',
  ArriveGarage = 'ArriveGarage',
  InvoiceSubmitted = 'InvoiceSubmitted',
  InvoicePaid = 'InvoicePaid',
  ReassignmentRequested = 'ReassignmentRequested',
  DepartDropoff = 'DepartDropoff',
  DeclineNetworkOffer = 'DeclineNetworkOffer',
  DeclineNetworkConfirmationRequest = 'DeclineNetworkConfirmationRequest',
  RevokeNetworkSharedTrip = 'RevokeNetworkSharedTrip',
  RollbackToOnsite = 'RollbackToOnsite',
}

const hasProperty = (object: TripCommandResult, property: string) =>
  Object.prototype.hasOwnProperty.call(object, property);

export const handleTripCommandException = (
  result: { succeeded: boolean; errors?: string[] },
  callback: (success: boolean, erros: string[]) => void,
): void => {
  const { succeeded, errors = [] } = result;

  callback(succeeded, errors);
};
export const handleMultipleTripCommandException = (
  result: { succeeded: boolean; errors?: string[] }[],
  callback: (success: boolean, erros: string[]) => void,
): void => {
  const { succeeded, errors = [] } = result.reduce(
    (accumulator, current) => {
      accumulator.succeeded = accumulator.succeeded && current.succeeded;
      accumulator.errors = [...accumulator.errors, ...(current.errors || [])];
      return accumulator;
    },
    { succeeded: true, errors: [] },
  );

  callback(succeeded, errors);
};

const useTripCommand = (): {
  onSubmit: () => Promise<void>;
  loading: boolean;
  closeModal: (succeeded: boolean) => void;
} => {
  const [tripCommandDialog, setTripCommandDialog] = useRecoilState(
    tripCommandDialogState,
  );
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const closeModal = (succeeded: boolean) => {
    setTripCommandDialog((tripCommandDialogState) => ({
      ...tripCommandDialogState,
      isOpen: false,
      succeeded,
    }));
  };
  const [enRouteToPickup, { loading: loadingEnRouteToPickup }] =
    useEnRouteToPickupMutation();
  const [circling, { loading: loadingCircling }] = useCirclingMutation();
  const [waiting, { loading: loadingWaiting }] = useWaitingMutation();
  const [closeTrip, { loading: loadingCloseTrip }] = useCloseTripMutation();
  const [departPickup, { loading: loadingDepartPickup }] =
    useDepartPickupMutation();
  const [arriveStop, { loading: loadingArriveStop }] = useArriveStopMutation();
  const [departStop, { loading: loadingDepartStop }] = useDepartStopMutation();
  const [arriveDropoff, { loading: loadingArriveDropoff }] =
    useArriveDropoffMutation();
  const [arriveGarage, { loading: loadingArriveGarage }] =
    useArriveGarageMutation();
  const [invoiceSubmitted, { loading: loadingInvoiceSubmitted }] =
    useInvoiceSubmittedMutation();
  const [invoicePaid, { loading: loadingInvoicePaid }] =
    useInvoicePaidMutation();
  const [requestReassignment, { loading: loadingReassignmentRequested }] =
    useRequestReassignmentMutation();
  const [declineNetworkOffer, { loading: loadingNetworkOfferDeclined }] =
    useDeclineNetworkOfferMutation();
  const [
    declineNetworkConfirmationRequest,
    { loading: loadingNetworkConfirmationDeclined },
  ] = useDeclineNetworkConfirmationRequestMutation();
  const [revokeNetworkSharedTrip, { loading: loadingSharedTripRevoked }] =
    useRevokeNetworkSharedTripMutation();
  const [rollbackToOnsite, { loading: loadingRollbackToOnsite }] =
    useRollbackToOnsiteMutation();

  const handleConfirm = async (): Promise<ResultCommandMutation> => {
    switch (tripCommandDialog.command.typeName) {
      case InvoicePaid.EnRouteToPickup:
        return enRouteToPickup({
          variables: { tripId: tripCommandDialog.tripId },
        });
      case InvoicePaid.Circling:
        return circling({ variables: { tripId: tripCommandDialog.tripId } });
      case InvoicePaid.Waiting:
        return waiting({ variables: { tripId: tripCommandDialog.tripId } });
      case InvoicePaid.CloseTrip:
        return closeTrip({ variables: { tripId: tripCommandDialog.tripId } });

      case InvoicePaid.DepartPickup:
        return departPickup({
          variables: { tripId: tripCommandDialog.tripId },
        });

      case InvoicePaid.ArriveStop:
        return arriveStop({ variables: { tripId: tripCommandDialog.tripId } });

      case InvoicePaid.ArriveDropoff: {
        const arriveDropoffResponse = await arriveDropoff({
          variables: { tripId: tripCommandDialog.tripId },
          refetchQueries: ['getSharedTripByOriginalTripId'],
        });
        if (tripCommandDialog?.isSavoyaTrip) {
          queryClient.invalidateQueries([
            'savoyaReservationAssignmentForFullTripView',
          ]);
        }
        return arriveDropoffResponse;
      }

      case InvoicePaid.DepartStop:
        return departStop({ variables: { tripId: tripCommandDialog.tripId } });

      case InvoicePaid.ArriveGarage:
        return arriveGarage({
          variables: { tripId: tripCommandDialog.tripId },
        });

      case InvoicePaid.InvoiceSubmitted: {
        const arriveGarageResponse = invoiceSubmitted({
          variables: { tripId: tripCommandDialog.tripId },
        });
        if (tripCommandDialog?.isSavoyaTrip) {
          queryClient.invalidateQueries([
            'savoyaReservationAssignmentForFullTripView',
          ]);
        }
        return arriveGarageResponse;
      }
      case InvoicePaid.InvoicePaid:
        return invoicePaid({
          variables: { tripId: tripCommandDialog.tripId },
        });
      case InvoicePaid.ReassignmentRequested:
        return requestReassignment({
          variables: { tripId: tripCommandDialog.tripId },
        });
      case InvoicePaid.DeclineNetworkOffer:
        return declineNetworkOffer({
          variables: { tripId: tripCommandDialog.tripId },
        });
      case InvoicePaid.DeclineNetworkConfirmationRequest:
        return declineNetworkConfirmationRequest({
          variables: { tripId: tripCommandDialog.tripId },
        });
      case InvoicePaid.RevokeNetworkSharedTrip:
        return revokeNetworkSharedTrip({
          variables: { tripId: tripCommandDialog.tripId },
        });
      case InvoicePaid.RollbackToOnsite:
        return rollbackToOnsite({
          variables: { tripId: tripCommandDialog.tripId },
        });
      default:
        return Promise.resolve(undefined);
    }
  };
  const onSubmit = async () => {
    const { data } = await handleConfirm();
    const operationName = Object.keys(data)[0];
    handleTripCommandException(data[operationName], (succeeded, errors) => {
      if (!succeeded) {
        errors.forEach((errorMessage) => {
          enqueueSnackbar(errorMessage, {
            variant: 'error',
          });
          Sentry.captureException(new Error(errorMessage));
        });
      } else if (hasProperty(data, 'executeEnRouteToPickup')) {
        closeModal(
          (data as EnRouteToPickupMutation).executeEnRouteToPickup.succeeded,
        );
      } else if (hasProperty(data, 'executeCircling')) {
        closeModal((data as CirclingMutation).executeCircling.succeeded);
      } else if (hasProperty(data, 'executeWaiting')) {
        closeModal((data as WaitingMutation).executeWaiting.succeeded);
      } else if (hasProperty(data, 'executeCloseTrip')) {
        closeModal((data as CloseTripMutation).executeCloseTrip.succeeded);
      } else if (hasProperty(data, 'executeDepartPickup')) {
        closeModal(
          (data as DepartPickupMutation).executeDepartPickup.succeeded,
        );
      } else if (hasProperty(data, 'executeArriveStop')) {
        closeModal((data as ArriveStopMutation).executeArriveStop.succeeded);
      } else if (hasProperty(data, 'executeArriveDropoff')) {
        closeModal(
          (data as ArriveDropoffMutation).executeArriveDropoff.succeeded,
        );
      } else if (hasProperty(data, 'executeArriveGarage')) {
        closeModal(
          (data as ArriveGarageMutation).executeArriveGarage.succeeded,
        );
      } else if (hasProperty(data, 'executeDepartStop')) {
        closeModal((data as DepartStopMutation).executeDepartStop.succeeded);
      } else if (hasProperty(data, 'executeInvoiceSubmitted')) {
        closeModal(
          (data as InvoiceSubmittedMutation).executeInvoiceSubmitted.succeeded,
        );
      } else if (hasProperty(data, 'executeInvoicePaid')) {
        closeModal((data as InvoicePaidMutation).executeInvoicePaid.succeeded);
      } else if (hasProperty(data, 'executeReassignmentRequested')) {
        closeModal(
          (data as RequestReassignmentMutation).executeReassignmentRequested
            .succeeded,
        );
      } else if (hasProperty(data, 'executeDeclineNetworkOffer')) {
        closeModal(
          (data as DeclineNetworkOfferMutation).executeDeclineNetworkOffer
            .succeeded,
        );
      } else if (
        hasProperty(data, 'executeDeclineNetworkConfirmationRequest')
      ) {
        closeModal(
          (data as DeclineNetworkConfirmationRequestMutation)
            .executeDeclineNetworkConfirmationRequest.succeeded,
        );
      } else if (hasProperty(data, 'executeRevokeNetworkSharedTrip')) {
        closeModal(
          (data as RevokeNetworkSharedTripMutation)
            .executeRevokeNetworkSharedTrip.succeeded,
        );
      } else if (hasProperty(data, 'executeRollbackToOnsite')) {
        closeModal(
          (data as RollbackToOnsiteMutation).executeRollbackToOnsite.succeeded,
        );
      }
    });
  };

  return {
    onSubmit,
    closeModal,
    loading:
      loadingArriveGarage ||
      loadingDepartStop ||
      loadingCloseTrip ||
      loadingArriveDropoff ||
      loadingArriveStop ||
      loadingCircling ||
      loadingWaiting ||
      loadingDepartPickup ||
      loadingInvoicePaid ||
      loadingInvoiceSubmitted ||
      loadingEnRouteToPickup ||
      loadingReassignmentRequested ||
      loadingNetworkOfferDeclined ||
      loadingNetworkConfirmationDeclined ||
      loadingRollbackToOnsite ||
      loadingSharedTripRevoked,
  };
};

export default useTripCommand;
