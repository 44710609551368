import React from 'react';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import DashboardLayout from 'src/shared/DashboardLayout/DashboardLayout';

import theme from 'src/shared/theme';
import PageHeader from 'src/shared/components/PageHeader/PageHeader';
import { Info, Stop, Remove, ExpandMore } from '@material-ui/icons';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  body: {
    paddingTop: '2rem',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  mobile: {
    paddingTop: '2rem',
  },
  pre: {
    background: '#f4f4f4',
    border: '1px ridge #ddd',
    color: '#666',
    'page-break-inside': 'avoid',
    'font-family': 'monospace',
    'font-size': '12px',
    'margin-bottom': '1.6em',
    'border-radius': '0.5rem',
    'max-width': '100%',
    overflow: 'auto',
    padding: '0.25rem',
    'word-wrap': 'break-word',
  },
  subHeader: {
    fontWeight: 'bold',
    overflowWrap: 'normal',
  },
  subText: {
    display: 'inline',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    textDecoration: 'underline',
  },
  subList: {
    marginLeft: '2rem',
    lineHeight: '0.5rem',
  },
}));

const SettingsView: React.FC = () => {
  const classes = useStyles();
  const { xs } = useBreakpoints();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <DashboardLayout>
      <Box bgcolor="white" height="100%" overflow="auto">
        <Box p={theme.spacing(0.5)}>
          <PageHeader
            className={classes.header}
            title="Frequently Asked Questions"
            pageName="FAQ"
          />
        </Box>
        <Box className={xs ? classes.mobile : classes.body}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel1'}
                elevation={0}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    What are the tabs on the left side of my home screen within
                    my portal?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vimeo.com/986663650/719a5dc8a5"
                          >
                            See Video Here
                          </a>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.subHeader}
                        >
                          Dashboard:{' '}
                        </Typography>
                        <Typography className={classes.subText}>
                          This shows your Next Trip, followed by all Upcoming
                          Trips, and to the far right, all Recent Trips that
                          have been completed within the last 48 hours.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.subHeader}
                        >
                          Available Trips:{' '}
                        </Typography>
                        <Typography display="inline">
                          All reservations here are available on a
                          first-come-first-serve basis, and can only be found
                          here once they have been offered to at least everyone
                          two separate times, unless previously rejected, upon
                          which they will not receive a second offer. Generally,
                          it is because other vendors/partners within Savoya’s
                          network are busy and may have missed the offer, or
                          were already booked!
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://vimeo.com/986663566/1c4dc3d917"
                              >
                                Desktop Version Video
                              </a>
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              The “Available Trips” tab can be found on both the
                              desktop and mobile version of your portal. All
                              reservations that have not been accepted by anyone
                              in Savoya’s network of certified chauffeurs, will
                              be available to “claim” in this area. It is
                              strongly recommended to check here frequently in
                              an effort to maximize your business’s revenue from
                              Savoya!
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.subHeader}
                        >
                          My Trips:{' '}
                        </Typography>
                        <Typography display="inline">
                          This tab will show ALL trips that you have access to:
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              Open offers for your company to accept or reject.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              Upcoming reservations that your company has
                              accepted, confirmed and committed to.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              Reservations that are awaiting Vehicle and/or
                              Chauffeur assignment.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              Reservations that are completed and need to be
                              Closed Out and Billed.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.subHeader}
                        >
                          Schedule:
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography
                              display="inline"
                              className={classes.subHeader}
                            >
                              CALENDAR:{' '}
                            </Typography>
                            <Typography display="inline">
                              This feature allows a primary user within your
                              company’s portal to see Accepted or Confirmed
                              trips that are upcoming.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography
                              display="inline"
                              className={classes.subHeader}
                            >
                              SETTINGS:{' '}
                            </Typography>
                            <Typography display="inline">
                              If you are an Independent Operator, you will see
                              this tab where you can customize your
                              availability. If you have a Garage Account with
                              Savoya, you will not see this feature.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <List
                            className={!xs ? classes.subList : null}
                            disablePadding
                          >
                            <ListItem>
                              <ListItemIcon>
                                <Remove />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography
                                  display="inline"
                                  className={classes.italic}
                                >
                                  Trip Preferences:{' '}
                                </Typography>
                                <Typography display="inline">
                                  If you do not want to be offered
                                  “Short-Notice” trips that start in 18 hours or
                                  less, you can opt out of them by selecting
                                  this feature. PLEASE NOTE: if you have
                                  previously accepted a reservation and Savoya’s
                                  customer modifies their reservation within
                                  that 18 hour window, our algorithm will see
                                  you are not available and cancel this booking.
                                  This can be adjusted at any point in time on
                                  the desktop version of the portal, as well as
                                  within your smartphone app.
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography
                                  display="inline"
                                  className={classes.italic}
                                >
                                  Blackout Preferences:{' '}
                                </Typography>
                                <Typography display="inline">
                                  If you wish to set yourself unavailable and
                                  remove your eligibility from trip offers
                                  within Savoya’s algorithm for a particular
                                  block of time or days, you can do so by adding
                                  them in this section. During that time, our
                                  algorithm will not offer your business
                                  reservations that occur during that block.
                                  These can be added and removed at any point in
                                  time.
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography
                                  display="inline"
                                  className={classes.italic}
                                >
                                  Weekly Preferences:{' '}
                                </Typography>
                                <Typography display="inline">
                                  If there are certain times of the day that you
                                  do not want to be available for reservation
                                  offers, you can add them here to remove your
                                  company’s eligibility as a potential solution
                                  for fulfillment during that block on a
                                  recurring basis. These can be added and
                                  removed at any point in time.
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography
                                  display="inline"
                                  className={classes.italic}
                                >
                                  Location Blacklist:{' '}
                                </Typography>
                                <Typography display="inline">
                                  If you do not want to be offered any trips
                                  that include a certain city or airport, you
                                  can add it to this list and you will not
                                  receive trips that have these locations
                                  included in the itinerary. These can be added
                                  and removed at any point in time.
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography
                                  display="inline"
                                  className={classes.italic}
                                >
                                  Location Preferences:{' '}
                                </Typography>
                                <Typography display="inline">
                                  Trips that take place in preferred locations
                                  will be given precedence during the offering
                                  process relative to how your Vendor Scorecard
                                  ranks your business within Savoya’s network.
                                  Only 1 city and 1 airport may be preferred at
                                  any given time. However, these can be adjusted
                                  at any point in time.
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography
                                  display="inline"
                                  className={classes.italic}
                                >
                                  Missed Opportunities:{' '}
                                </Typography>
                                <Typography display="inline">
                                  The figures represented here are on a rolling,
                                  90-day time frame, and indicate the revenue
                                  missed out on specific to each preference
                                  listed above. This can help you as a business
                                  owner make an informed decision about whether
                                  or not to reconsider the preferences entered
                                  above.
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </List>
                        </ListItem>
                      </List>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.subHeader}
                        >
                          Vendor Scorecard:{' '}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              This section displays uniquely curated metrics
                              based on the performance of trip execution over a
                              rolling, 90-day period, specifically the areas
                              marked with an asterisk (*). The overall condition
                              of your business’s Scorecard is influenced
                              entirely by your company, and dictates where your
                              business stands in Savoya’s network against other
                              vendors, specific to the market you serve. The
                              ranking helps dictate the order in which our
                              algorithm dispatches reservation offers in a
                              one-by-one fashion. In sum, the stronger your
                              business’s Scorecard, the better opportunity your
                              business has in obtaining more market share.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              The areas that are not marked with an asterisk (*)
                              are simply there as a barometer for you, as the
                              business owner, to better understand how much
                              business you’ve accepted against what our
                              algorithm has offered, the total revenue earned
                              over the last 90-days, and the total number of
                              trips that are influencing the Scorecard’s metrics
                              overall. If you have specific questions or
                              concerns surrounding this, please reach out to
                              your Vendor Relations Manager at{' '}
                            </Typography>
                            <Link
                              display="inline"
                              href="mailto:vendors@savoya.com"
                            >
                              <Typography display="inline">{` vendors@savoya.com `}</Typography>
                            </Link>
                            <Typography display="inline">!</Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.subHeader}
                        >
                          My Company:{' '}
                        </Typography>
                        <Typography display="inline">
                          All information here is relative to the business owner
                          and established company as recognized by your local
                          state and federal government for tax purposes. The
                          Contact information dictates what Savoya personnel
                          should contact the appropriate personnel within your
                          business. If there are any changes that need to be
                          made here, please send an email to
                        </Typography>
                        <Link display="inline" href="mailto:vendors@savoya.com">
                          <Typography display="inline">{` vendors@savoya.com `}</Typography>
                        </Link>
                        <Typography display="inline">.</Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.subHeader}
                        >
                          Vehicles:{' '}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography
                              display="inline"
                              className={classes.subHeader}
                            >
                              How do I add a vehicle to my portal to use on
                              future Savoya reservations?
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <List
                            className={!xs ? classes.subList : null}
                            disablePadding
                          >
                            <ListItem>
                              <ListItemIcon>
                                <Remove />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://vimeo.com/986663455/3fbe00809a"
                                  >
                                    See Video Here
                                  </a>
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Please note, upon adding a new vehicle, Savoya
                                  will be notified to verify insurance coverage
                                  of this vehicle. In order to protect our
                                  clients, Savoya’s system can only offer trips
                                  to vehicles that meet specific insurance
                                  standards. Once an updated COI showing
                                  appropriate coverage with your associated
                                  business for the new vehicle is verified, you
                                  can then assign the vehicle to upcoming and
                                  active reservations. Please ensure that you
                                  send an updated COI to{' '}
                                </Typography>
                                <Link
                                  display="inline"
                                  href="mailto:savoya@certrax.net"
                                >
                                  <Typography display="inline">{` savoya@certrax.net `}</Typography>
                                </Link>
                                <Typography display="inline">AND </Typography>
                                <Link
                                  display="inline"
                                  href="mailto:vendors@savoya.com"
                                >
                                  <Typography display="inline">{` vendors@savoya.com `}</Typography>
                                </Link>
                                <Typography display="inline">
                                  {' '}
                                  with the vehicle listed on the policy.
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </List>
                        </ListItem>
                      </List>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.subHeader}
                        >
                          Rates:{' '}
                        </Typography>
                        <Typography display="inline">
                          Here, you can set your hourly rates per vehicle, along
                          with specific transfer and point-to-point transfer
                          rates, along with their associated minimums and
                          cancellation policies.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel2'}
                elevation={0}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    How do I assign a chauffeur and a vehicle to an Active
                    Reservation?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vimeo.com/986663249/4ff98d7f17"
                          >
                            See Video Here
                          </a>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel3'}
                elevation={0}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    How do I create a new chauffeur profile if I have a Garage
                    Account with Savoya?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vimeo.com/986663331/71ff84bf2b"
                          >
                            See Video Here
                          </a>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography display="inline">
                          To create a new chauffeur profile, select the
                          ‘Chauffeurs’ option on the left-hand side of the menu.
                          Before creating the chauffeur, look through the list
                          provided to ensure that the chauffeur has not already
                          been created, any matching phone number or email
                          address will prompt an error when trying to create the
                          profile. If a profile does not exist for the
                          chauffeur, press the “+ Chauffeur” button, located in
                          the top left hand corner.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography display="inline">
                          Proceed to add the chauffeur’s First Name, Last Name,
                          Email address, Phone Number, and a Professional Photo
                          of the chauffeur. Once this information has been
                          entered, press “Create.” This will create the
                          chauffeur and send Savoya an approval request. Once
                          approved, the added chauffeur will become available
                          for trip assignment.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography display="inline">
                          Savoya Operations and Travelers will only reach out to
                          chauffeurs directly in relation to an active, ongoing
                          trip, if and only when necessary. A chauffeur’s direct
                          phone number is highly recommended to provide when
                          assigning a chauffeur to a specific reservation (or
                          when creating a chauffeur profile) as we are unable to
                          provide the contact information of our client without
                          their prior consent.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              Opting out of providing a direct contact number
                              for the assigned chauffeur can negatively impact
                              your Vendor Scorecard.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography display="inline">
                          The chauffeur’s email address that is entered will be
                          the email address a chauffeur will use to login to the
                          Savoya Chauffeur App to execute the trip. Upon
                          creating the chauffeur profile, the chauffeur will
                          receive an email to confirm and set a password for
                          their chauffeur account.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel4'}
                elevation={0}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    What are the Chauffeur Photo recommendations to make the
                    best client impression?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography display="inline">
                          All chauffeur headshots are encouraged to be
                          professional in appearance. This photo will be a
                          chauffeur’s first impression for Savoya&apos;s
                          passengers and will be a key way that a passenger will
                          identify their chauffeur at the pickup location.
                          Photos are recommended to follow the below guidelines:
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">ATTIRE</Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <List
                            className={!xs ? classes.subList : null}
                            disablePadding
                          >
                            <ListItem>
                              <ListItemIcon>
                                <Remove fontSize="large" />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Neutral colored suit, solid white shirt,
                                  dark-colored tie
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove fontSize="large" />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Clothing should be freshly pressed
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove fontSize="large" />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Hair and facial hair are groomed
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove fontSize="large" />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Avoid wearing sunglasses or tinted lenses
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </List>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">BACKGROUND</Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <List
                            className={!xs ? classes.subList : null}
                            disablePadding
                          >
                            <ListItem>
                              <ListItemIcon>
                                <Remove fontSize="large" />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Photo taken indoors with a neutral background
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </List>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">POSE</Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <List
                            className={!xs ? classes.subList : null}
                            disablePadding
                          >
                            <ListItem>
                              <ListItemIcon>
                                <Remove fontSize="large" />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Photo taken from the chest up
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove fontSize="large" />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Subtle smile is best
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <Remove fontSize="large" />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography display="inline">
                                  Avoid selfies
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </List>
                        </ListItem>
                      </List>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel5'}
                elevation={0}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    I have accepted a reservation offer, but I cannot see the
                    trip in my Savoya Chauffeur smartphone app.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          Only reservations occurring within the next 24 hours
                          will be visible within your smartphone app.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel6'}
                elevation={0}
                onChange={handleChange('panel6')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    How can I receive short notice trip offers (On/Off Feature)?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vimeo.com/986663152/31ae088c55"
                          >
                            See Video Here
                          </a>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          You can opt in and out of receiving short notice trip
                          offers if you have an “Independent” account with the
                          click of a button. If you have a “Garage” account, you
                          are opted in to receive short notice trip offers.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          Short notice trip offers occur within 18 hours of
                          pickup.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          If you are not opted in to receive short notice
                          notifications, you will NOT receive offers that were
                          booked and have a pickup occurring within 18 hours.
                          Please note, if there are any active trips you have
                          accepted in your portal that are modified within that
                          18-hour window, you WILL receive a cancellation
                          notification as the system considers this a “new
                          trip.” It is highly recommended to have short notice
                          offers turned on.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel7'}
                elevation={0}
                onChange={handleChange('panel7')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    How much complimentary wait time is provided to Savoya’s
                    customers?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography display="inline">
                          Savoya offers our clients 30 minutes of complimentary
                          wait time starting at the “Pick Up” time listed on the
                          reservation. If your business offers a different
                          amount of complimentary wait time, please email
                        </Typography>
                        <Link display="inline" href="mailto:vendors@savoya.com">
                          <Typography display="inline">{` vendors@savoya.com`}</Typography>
                        </Link>
                        <Typography display="inline">.</Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel8'}
                elevation={0}
                onChange={handleChange('panel8')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    How will I bill the trip if the passenger is not “On Board”
                    within the complimentary 30 minutes of wait time?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          If the reservation you accepted was quoted with a
                          Transfer Rate you provided and previously entered into
                          your portal, &quot;Transfer + Wait Time&quot; will be
                          initiated under these circumstances.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Remove fontSize="large" />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              &quot;Transfer + Wait Time&quot; is any additional
                              time compensated for while marked
                              &quot;On-Site&quot;. It is paid in increments of
                              15 minutes, or 0.25 hours, multiplied by the
                              hourly rate you provided for the &quot;Vehicle
                              Type&quot; requested and listed on the
                              reservation.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Remove fontSize="large" />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              This additional &quot;Transfer + Wait Time&quot;
                              will be added IN ADDITION and on top of the
                              base/transfer rate agreed upon when the
                              reservation was confirmed and accepted in your
                              portal.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Remove fontSize="large" />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography
                              display="inline"
                              className={classes.italic}
                            >
                              If wait time exceeds 30 minutes, it will NOT
                              convert the reservation from a Base/Transfer Rate
                              to an Hourly.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          If the reservation you accepted was quoted with an
                          Hourly Rate you provided and previously entered into
                          your portal, you will continue to be paid in
                          increments of 15 minutes, or 0.25 hours, multiplied by
                          the hourly rate you provided for the “Vehicle Type”
                          requested and listed on the reservation.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography display="inline" className={classes.italic}>
                          If your business operates under different conditions,
                          please email
                        </Typography>
                        <Link display="inline" href="mailto:vendors@savoya.com">
                          <Typography display="inline">{` vendors@savoya.com `}</Typography>
                        </Link>
                        <Typography display="inline" className={classes.italic}>
                          to adjust your portal accordingly.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel9'}
                elevation={0}
                onChange={handleChange('panel9')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    This is my first trip! How do I execute a trip in the
                    “Savoya Chauffeur” smart-phone application from start to
                    finish?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vimeo.com/986663049/b62a8336ab"
                          >
                            See Video Here
                          </a>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          Start Trip
                        </Typography>
                        <Typography display="inline">
                          : Mark “Start Trip” when you have left the garage. The
                          time between marking “Start Trip” and “I’m On Site”
                          objectively tracks garage-out time.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          On Site
                        </Typography>
                        <Typography display="inline">
                          : Select &quot;On Site&quot; when you have arrived at
                          a convenient location for the passenger to efficiently
                          and easily connect with you.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <List
                        className={!xs ? classes.subList : null}
                        disablePadding
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              You will be prompted to create a staging note.
                              Please be professional, precise, and concise with
                              your information.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography
                              display="inline"
                              className={classes.italic}
                            >
                              Example
                            </Typography>
                            <Typography display="inline">
                              : “Good day! My name is [NAME] and I am located at
                              the bottom of the escalator at Terminal B Baggage
                              Claim with Savoya signage. Please contact me if
                              you need any specific assistance.”
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Stop />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            <Typography display="inline">
                              Every passenger will receive your name, contact
                              number, along with the make, model, and license
                              plate number of the vehicle you will be operating.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          Pax Onboard
                        </Typography>
                        <Typography display="inline">
                          : Once you have loaded the passenger’s luggage
                          (if/when appropriate) and confirmed first stop and/or
                          drop-off location, select “Pax Onboard.”
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          Circling
                        </Typography>
                        <Typography display="inline">
                          : Select “Circling” if you have already marked “I’m
                          Onsite” OR “Waiting At Stop,” but are required to move
                          and come back. This allows the passenger to understand
                          that you have not left them, but were asked to move.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          Waiting At Stop
                        </Typography>
                        <Typography display="inline">
                          : If your reservation requires more than one stop,
                          once you have arrived at each stop, please mark
                          “Waiting At Stop.”
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          Final Stop
                        </Typography>
                        <Typography display="inline">
                          : Please mark “Final Stop,” if you have dropped the
                          passenger at the final drop location listed on your
                          reservation.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          Complete Trip
                        </Typography>
                        <Typography display="inline">
                          : Please ONLY select “Complete Trip” when you have
                          arrived back at your garage. The time between “Final
                          Stop” and “Complete Trip” objectively tracks your
                          garage-in time. If you mark “Complete Trip”
                          immediately upon selecting “Final Stop,” your drive
                          time will not be properly tracked which can only be
                          fixed by creating a rate exception. Rate exceptions
                          create manual work for the Savoya team and thus will
                          negatively impact your vendor scorecard which could
                          lead to fewer offers and less revenue.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          Add Incidentals
                        </Typography>
                        <Typography display="inline">
                          : Please add in all pertinent incidentals (Parking,
                          Airport Fees, Tolls, Misc [pet cleaning fees, costs
                          incurred by special instructions of passenger, etc],
                          tax [where appropriate]).
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel10'}
                elevation={0}
                onChange={handleChange('panel10')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    How do I close out a past reservation I have completed that
                    is in “Billable” status?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography display="inline">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vimeo.com/988600813/6313651a11?share=copy"
                          >
                            See Video Here
                          </a>
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          to learn how to close out a &quot;Billable&quot; trip
                          and when to &quot;Request a Rate Exception&quot;.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography>
                          You can Close Out a trip immediately upon selecting
                          “Complete Trip” within the mobile app IF you are an
                          Independent Operator. If you are a Garage owner, you
                          can do so in your Vendor Portal for your additional
                          chauffeurs, or within your Savoya Chauffeur app if you
                          were the assigned chauffeur to the reservation.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel11'}
                elevation={0}
                onChange={handleChange('panel11')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    What are the payment options and when will I receive
                    payment?
                  </Typography>
                </AccordionSummary>
                <AccordionSummary>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          V-Pay
                        </Typography>
                        <Typography display="inline">
                          : VPay cards are a one-time use American Express
                          credit card sent out every Thursday for trips
                          COMPLETED and CLOSED OUT from the week prior. In order
                          to process a Vpay Card, you will need to have a
                          merchant account setup with a credit card processor.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Info color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          display="inline"
                          className={classes.underline}
                        >
                          Monthly Check
                        </Typography>
                        <Typography display="inline">
                          : For all trips COMPLETED and CLOSED OUT within a
                          given month, starting on the first of each month up
                          until the end of the last day of that same month, a
                          check will be mailed out via USPS on the 15th day of
                          the following month (for example: all trips that were
                          completed and closed out in the month of October will
                          have a check mailed out on the 15th of November). Once
                          the check is mailed through USPS, it may take up to
                          7-10 business days to receive your check depending on
                          your location. If you have not received the check
                          within this time window, let us know and we will
                          verify your address, void the original check sent, and
                          re-issue your check.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionSummary>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default SettingsView;
