import React from 'react';
import { TextField, TextFieldProps, InputAdornment } from '@material-ui/core';
import { AdjustmentCalculation } from 'src/.gen/graphql';
import DiscountTypeSelector from './DiscountTypeSelector';

interface CurrencyInputProps {
  value: string | number;
  currency?: string;
  decimals?: number;
  percentageOption?: boolean;
  onCalculationOptionChange?: (newOption: AdjustmentCalculation) => void;
}

export type TextFieldExtendedProps = TextFieldProps & CurrencyInputProps;

const CurrencyField = React.forwardRef<
  HTMLInputElement,
  TextFieldExtendedProps
>((props, ref) => {
  const {
    currency = '$',
    onChange,
    InputProps,
    percentageOption = false,
    onCalculationOptionChange,
    ...rest
  } = props;

  return (
    <TextField
      inputRef={ref}
      placeholder="0.00"
      onChange={(e) => {
        const regex = /^\d+(\.\d{0,4})?$/;
        const { value } = e.target;
        if (!value || regex.test(value)) {
          onChange(e);
        }
      }}
      InputProps={{
        startAdornment: percentageOption ? (
          <InputAdornment position="start">
            <DiscountTypeSelector
              currency={currency}
              onChange={(newvalue) => onCalculationOptionChange(newvalue)}
            />
          </InputAdornment>
        ) : (
          <InputAdornment position="start">{currency}</InputAdornment>
        ),
        ...InputProps,
      }}
      {...rest}
    />
  );
});

export default CurrencyField;
