/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteInputChangeReason,
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import _debounce from 'lodash/debounce';
import { AutoCompleteOption } from './utils';

export interface BaseAutoCompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput' | 'options'
  > {
  useLazyQuery: (options?: any) => any[];
  executeLazyQuery?: (
    lazyQueryFunc: (options?: Record<string, unknown>) => void,
    query: string,
  ) => void;
  getOptionsFromData: (data: any) => T[];
  onChange?: (
    e: React.ChangeEvent,
    value: any,
    reason: AutocompleteChangeReason,
  ) => void;
  // Input props
  label: string;
  variant?: 'standard' | 'outlined';
  size?: 'medium' | 'small';
  error?: boolean;
  helperText?: string;
  manualQueryTrigger?: number;
  initialValue?: string;
}

function AsyncAutocomplete<
  T extends AutoCompleteOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: BaseAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>,
): JSX.Element {
  const {
    label,
    variant = 'outlined',
    useLazyQuery,
    executeLazyQuery = () => undefined,
    getOptionsFromData,
    size = 'medium',
    error = false,
    helperText = '',
    manualQueryTrigger,
    initialValue,
    ...rest
  } = props;
  const [open, setOpen] = React.useState(false);
  const [searchBar, setSearchBar] = React.useState(initialValue || '');
  const [lazyQuery, { data, loading }] = useLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const options = React.useMemo(
    () => getOptionsFromData(data),
    [data, manualQueryTrigger],
  );

  const debounceLazyQuery = React.useCallback(
    _debounce((query: string) => {
      executeLazyQuery(lazyQuery, query);
    }, 200),
    [executeLazyQuery],
  );

  React.useEffect(() => {
    if (open) {
      debounceLazyQuery(searchBar);
    }
  }, [open, searchBar, manualQueryTrigger]);

  React.useEffect(() => {
    if (initialValue) {
      setSearchBar(initialValue);
    }
  }, []);

  const handleQuery = React.useCallback(
    (
      e: React.ChangeEvent<Record<string, unknown>>,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (e?.type === 'click' && reason === 'reset') {
        return;
      }
      setSearchBar(value);
    },
    [],
  );

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      size={size}
      getOptionSelected={(option, value) => {
        return option.id === value?.id;
      }}
      getOptionLabel={(option) => {
        return option?.label ?? 'Not found label';
      }}
      onInputChange={handleQuery}
      options={options}
      loading={loading}
      fullWidth
      inputValue={searchBar}
      renderInput={(params) => (
        <TextField
          id={label}
          {...params}
          label={label}
          variant={variant}
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
}

export default AsyncAutocomplete;
