/* eslint-disable @typescript-eslint/no-explicit-any */
import { atom, RecoilState, selector } from 'recoil';
import {
  GenericDriver,
  GenericVehicle,
  GenericVehicleClass,
} from 'src/.gen/graphql';

// * This atom is to get trip current payment information
export interface TripAssignmentState {
  vehicleClass: Partial<GenericVehicleClass> | undefined;
  vehicle: Partial<GenericVehicle> | undefined;
  driver: Partial<GenericDriver> | undefined;
  isAssigned: boolean;
  preferredVehicleClass: Partial<GenericVehicleClass> | undefined;
}

export const defaultTripAssignmentState = {
  vehicleClass: undefined,
  vehicle: undefined,
  driver: undefined,
  isAssigned: false,
  preferredVehicleClass: undefined,
};

export const tripAssignmentAtom: RecoilState<TripAssignmentState> = atom({
  key: 'tripAssignmentState',
  default: defaultTripAssignmentState,
});
// * This selector is related to tripAssignmentAtom
export const tripAssignmentSelector = selector({
  key: 'tripAssignmentSelector',
  get: ({ get }) => {
    const state = get(tripAssignmentAtom);
    return state;
  },
  set: ({ set }, newState: Partial<TripAssignmentState>) => {
    set(tripAssignmentAtom, (prev) => ({
      ...prev,
      ...newState,
    }));
  },
});
