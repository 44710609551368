import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { BiMoney } from 'react-icons/bi';
import { FaCalendarDay, FaCar, FaClock } from 'react-icons/fa';
import { GiSteeringWheel } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  GenericDriver,
  GenericVehicle,
  SharedTripStatus,
  TripClientRecord,
  TripCompletionStatus,
  TripProviderSource,
  TripStatus,
  useAssignTripMutation,
  useMakeTripConfirmedMutation,
} from 'src/.gen/graphql';
import { userAtom, vendorAtom } from 'src/authentication/atoms/AuthState';
import AddressComponent from 'src/shared/components/AddressComponent';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import useDoubleClick from 'src/shared/hooks/useDoubleClick';
import {
  getActualEstimatedDurationInMinutes,
  getTotalStopWaypoints,
  parseWaypoint,
} from 'src/shared/utils/addressUtils';
import { convertMinutesToFormattedTime } from 'src/shared/utils/conversions';
import { shorten } from 'src/shared/utils/stringUtils';
import { getCompletionStatuses } from 'src/shared/utils/tripStatusUtils';
import { TripWithClientsAndSavoyaData } from 'src/trips/hooks/useSearchTripsPaginated';
import { handleTripCommandException } from 'src/trips/hooks/useTripCommand';
import {
  defaultTripAssignmentState,
  tripAssignmentSelector,
} from 'src/tripsNewClients/atoms/tripAssignmentAtom';
import TripAssignmentDialog from 'src/tripsNewClients/components/TripAdditionalInfo/TripAssignment/TripAssignmentDialog';
import { rejectableSharedTripStatuses } from 'src/trips/constants/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { confirmCancelledAssignment } from 'src/trips/api/SavoyaTripQueries';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { DeepPartial } from 'react-hook-form';
import { tripDateTimeFormat } from '../../utils/tripDateTimeFormat';
import AcceptOrClaimSharedTripBtn from '../AcceptOrClaimSharedTripBtn';
import NextTripInfoItem from '../NextTrip/NextTripInfoItem';
import { NON_EDITABLE_STATUS } from '../TripDetails';
import TripStatusBox from './TripStatusBox';
import { mapSavoyaStopToWaypoint } from '../TripMapTile/TripMapTile';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
  },
  withBottomGutter: {
    marginBottom: theme.spacing(1),
  },
  boxSpacer: {
    height: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  cardContainer: {
    display: 'flex',
    minHeight: '10rem',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'unset',
    },
    borderColor: theme.palette.secondary.main,
  },
  cardContent: {
    width: '100%',
  },
  content: {
    display: 'flex',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  selectedCard: {
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.main,
  },
  leftContainer: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0rem .5rem',
    minWidth: '130px',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: '0.5rem',
    maxWidth: '220px',
    '& p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      maxWidth: '50%',
    },
  },
  statusContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  titlePassanger: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.textGray.dark,
  },
  description: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  iconText: {
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: '0.25rem',
  },
  driverVehicleIcon: {
    verticalAlign: 'middle',
  },
  referenceNumber: {
    fontWeight: theme.typography.fontWeightLight,
    float: 'left',
  },
  sharedTripLabel: {
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
  },
  actionButtom: {
    minWidth: '4rem',
  },
  acceptBtn: {
    backgroundColor: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 4,
    color: theme.palette.primary.light,
  },
}));

interface TripListTileProps {
  trip: TripWithClientsAndSavoyaData;
  passengerClient: DeepPartial<TripClientRecord>;
  isAvailableTrip?: boolean;
  selectedTripId?: string | undefined;
  elevation?: number;
  withBottomGutter?: boolean;
  variant?: 'outlined' | 'elevation';
  onClick?: () => void;
}

const primaryDataTestId = 'trip_list_tile_card_content';

const getPrimaryButtonText = (status: string) => {
  switch (status) {
    case TripStatus.Quoted:
      return 'Accept Quote';
    case TripStatus.AwaitingAssignment:
      return 'Assign';
    default:
      return 'Edit';
  }
};

const TripListTile: React.FC<TripListTileProps> = ({
  trip,
  passengerClient,
  isAvailableTrip = false,
  onClick,
  selectedTripId,
  withBottomGutter = false,
  elevation = 1,
  variant,
}) => {
  const cardRef = useRef();
  const classes = useStyles();
  const user = useRecoilValue(userAtom);
  const vendor = useRecoilValue(vendorAtom);
  const [acceptQuote] = useMakeTripConfirmedMutation();
  const [isOpen, setIsOpen] = React.useState(false);
  const setTripAssignment = useSetRecoilState(tripAssignmentSelector);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sm } = useBreakpoints();

  const [assignTripToDriver] = useAssignTripMutation({
    refetchQueries: ['GetTripById'],
    onCompleted: ({ executeAssignTrip }) => {
      handleTripCommandException(
        executeAssignTrip,
        async (succeeded, errors) => {
          if (!succeeded) {
            errors.forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: 'error',
              });
            });
          }
          if (succeeded) {
            enqueueSnackbar('Success! Trip Assigned', {
              variant: 'success',
              preventDuplicate: true,
            });
            setTripAssignment(defaultTripAssignmentState);
            navigate(`/trips/${trip?.id}`);
          }
        },
      );
    },
  });

  useDoubleClick({
    onSingleClick: () => {
      if (onClick) {
        if (sm) {
          navigate(`/trips/${trip.id}`);
        } else {
          onClick();
        }
      } else {
        navigate(`/trips/${trip.id}`);
      }
    },
    onDoubleClick: () => {
      if (isAvailableTrip) {
        window
          .open(
            `${window.location.origin}/trips/${trip.id}?available=true`,
            '_blank',
          )
          .focus();
      } else {
        window
          .open(`${window.location.origin}/trips/${trip.id}`, '_blank')
          .focus();
      }
    },
    ref: cardRef,
    latency: 250,
  });

  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);
  const [confirmCancelLoading, setConfirmCancelLoading] = useState(false);
  const confirmCancellation = useMutation({
    mutationFn: confirmCancelledAssignment,
    onSuccess: () => {
      queryClient.invalidateQueries([
        'savoyaReservationAssignment',
        'savoyaReservationAssignments',
      ]);
      enqueueSnackbar('Cancellation Confirmed', { variant: 'success' });
      navigate('/');
    },
    onError: () =>
      enqueueSnackbar('Error Confirming Cancellation', { variant: 'error' }),
  });

  const savoyaAssignmentCancelled =
    !isAvailableTrip && trip?.savoyaAssignment?.status === 'savoya_canceled';
  const savoyaAssignmentCancelledConfirmed =
    trip?.savoyaAssignment?.status === 'savoya_canceled_confirmed';
  const tripUnassigned = getCompletionStatuses(
    TripCompletionStatus.Unassigned,
  ).includes(trip.status);
  const showEditButton = !NON_EDITABLE_STATUS.includes(trip?.status);
  const iOwnThisTrip = user?.accountId === trip?.accountId;
  const sharedWithMe =
    user?.id === trip?.sharedTrip?.contactId ||
    vendor?.id === trip?.sharedTrip?.vendorExternalId;

  const acceptedAwaitingAssignment =
    !isAvailableTrip &&
    trip?.sharedTrip?.status === SharedTripStatus.Accepted &&
    trip?.status === TripStatus.AwaitingAssignment;

  const handleAssignTrip = async (
    assignedVehicle: Partial<GenericVehicle>,
    assignedDriver: Partial<GenericDriver>,
  ) => {
    await assignTripToDriver({
      variables: {
        tripId: trip?.id,
        vehicleId: assignedVehicle?.id,
        driverId: assignedDriver?.id,
      },
    });
  };

  const handleEdit = () => {
    const { pickupTime, id: tripId } = trip;
    const pickupDateObject = new Date(pickupTime);

    switch (trip?.status) {
      case TripStatus.Quoted:
        if (trip) {
          acceptQuote({
            variables: {
              tripId: trip.id,
            },
            refetchQueries: ['GetTripById'],
          }).then(() => {
            navigate(
              `/trips/assign/${tripId}?pickupTime=${pickupDateObject}&travelDate=${pickupTime}`,
            );
          });
        }
        break;
      case TripStatus.AwaitingAssignment:
        if (trip?.providerSource === TripProviderSource.Savoya) {
          setIsOpen(true);
        } else {
          navigate(
            `/trips/assign/${tripId}?pickupTime=${pickupDateObject}&travelDate=${pickupTime}`,
          );
        }
        break;
      default:
        navigate(`/trips/edit/${trip?.id}`);
        break;
    }
  };

  const timezone =
    trip?.savoyaAssignment?.reservation?.stops?.[0]?.city?.timezone ||
    trip?.savoyaAssignment?.reservation?.stops?.[0]?.airport?.timezone ||
    trip?.savoyaAssignment?.reservation?.timezone;

  const pickupAddress = parseWaypoint(
    trip?.providerSource === TripProviderSource.Savoya
      ? mapSavoyaStopToWaypoint(
          trip?.savoyaAssignment?.reservation?.stops,
          trip?.pickupWaypoint,
          timezone,
        )
      : trip?.pickupWaypoint,
  );
  const dropoffAddress = parseWaypoint(
    trip?.providerSource === TripProviderSource.Savoya
      ? mapSavoyaStopToWaypoint(
          trip?.savoyaAssignment?.reservation?.stops,
          trip?.dropoffWaypoint,
          timezone,
        )
      : trip?.dropoffWaypoint,
    trip?.overrideDropoffWaypointDetails,
  );
  const savoyaEstimatedRevenue =
    (trip?.savoyaAssignment?.estimatedRevenue || 0) / 100;

  let tripLabel = iOwnThisTrip ? 'Shared Trip' : 'Received Trip';
  tripLabel = isAvailableTrip ? 'Available Trip' : tripLabel;

  if (
    trip?.providerSource === TripProviderSource.Savoya &&
    dropoffAddress?.placeName === ''
  ) {
    // NOTE: temporary fix for savoya trips with as directed stops
    // As directed savoya stops always shows JFK airport address, so we clear the address
    // for now until we fix the core issue
    dropoffAddress.city = '';
    dropoffAddress.state = '';
    dropoffAddress.postalCode = '';
  }

  const renderPassengerCount = (count: number) => {
    return count > 1 ? `+${count}` : '';
  };

  return (
    <Card
      data-testid="trip_list_tile_card"
      variant={variant || 'elevation'}
      ref={cardRef}
      className={clsx(
        classes.cardContainer,
        trip?.id === selectedTripId && classes.selectedCard,
        withBottomGutter && classes.withBottomGutter,
      )}
      elevation={elevation}
    >
      <CardContent
        className={classes.cardContent}
        data-testid={primaryDataTestId}
      >
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <div className={classes.content}>
              <div
                className={classes.leftContainer}
                data-testid={`${primaryDataTestId}_left_container`}
              >
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.titlePassanger}
                  data-testid={`${primaryDataTestId}_left_container_name`}
                >
                  {shorten(passengerClient?.client?.name ?? '            ', 15)}{' '}
                  {renderPassengerCount(trip?.passengerCount || 0)}
                </Typography>
                {trip?.isShared && (
                  <Typography
                    variant="body2"
                    className={classes.sharedTripLabel}
                  >
                    {tripLabel}
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  className={classes.description}
                  data-testid={`${primaryDataTestId}_left_container_trip_number`}
                >
                  {`#${trip?.tripNumber ?? 'No trip number'}`}
                </Typography>
                <div>
                  <FaCalendarDay
                    className={classes.driverVehicleIcon}
                    size={13}
                  />
                  <Typography
                    variant="caption"
                    className={classes.iconText}
                    data-testid={`${primaryDataTestId}_left_container_pickup_time`}
                  >
                    {tripDateTimeFormat(
                      trip?.pickupTime,
                      trip?.waypoints[0]?.savoyaStop?.city?.timezone,
                      'MM/dd/yyyy hh:mm a (zzz)',
                    )}
                  </Typography>
                </div>
                {trip?.providerSource === TripProviderSource.Savoya &&
                !!trip?.savoyaAssignment?.reservation?.quotedTime ? (
                  <div>
                    <FaClock className={classes.driverVehicleIcon} size={13} />
                    <Typography
                      variant="caption"
                      display="inline"
                      className={classes.iconText}
                      data-testid={`${primaryDataTestId}_left_container_duration`}
                    >
                      {`Trip Time: `}
                      {`${trip?.savoyaAssignment?.reservation?.quotedTime} Hours`}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <FaClock className={classes.driverVehicleIcon} size={13} />
                    <Typography
                      variant="caption"
                      display="inline"
                      className={classes.iconText}
                      data-testid={`${primaryDataTestId}_left_container_duration`}
                    >
                      {`Duration: `}
                      {convertMinutesToFormattedTime(
                        getActualEstimatedDurationInMinutes(trip),
                      )}
                    </Typography>
                  </div>
                )}
                {savoyaEstimatedRevenue ? (
                  <div>
                    <BiMoney className={classes.driverVehicleIcon} size={13} />
                    <Typography
                      variant="caption"
                      display="inline"
                      className={classes.iconText}
                      data-testid={`${primaryDataTestId}_left_container_duration`}
                    >
                      {`est. $${savoyaEstimatedRevenue.toFixed(2)}`}
                    </Typography>
                  </div>
                ) : null}
                {trip?.driver?.name && !tripUnassigned ? (
                  <div>
                    <GiSteeringWheel
                      className={classes.driverVehicleIcon}
                      size={13}
                    />
                    <Typography
                      variant="caption"
                      display="inline"
                      className={classes.iconText}
                      data-testid={`${primaryDataTestId}_left_container_duration`}
                    >
                      {trip?.driver?.name}
                    </Typography>
                  </div>
                ) : null}
                {trip?.vehicle?.makeName && !tripUnassigned ? (
                  <div>
                    <FaCar className={classes.driverVehicleIcon} size={13} />
                    <Typography
                      variant="caption"
                      className={classes.iconText}
                      data-testid={`${primaryDataTestId}_left_container_vehicle`}
                    >
                      {trip?.vehicle?.name ||
                        `${trip?.vehicle?.year} ${trip?.vehicle?.makeName} ${trip?.vehicle?.modelName}`}
                    </Typography>
                  </div>
                ) : null}
                <div className={classes.boxSpacer} />
                {showEditButton &&
                  !savoyaAssignmentCancelled &&
                  !savoyaAssignmentCancelledConfirmed &&
                  ((iOwnThisTrip && !acceptedAwaitingAssignment) ||
                    (sharedWithMe && acceptedAwaitingAssignment)) && (
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        data-testid={`trip_${getPrimaryButtonText(
                          trip?.status,
                        ).toLocaleLowerCase()}_button`}
                        onClick={handleEdit}
                        className={classes.actionButtom}
                      >
                        {getPrimaryButtonText(trip?.status)}
                      </Button>
                    </div>
                  )}
                {!iOwnThisTrip &&
                savoyaAssignmentCancelled &&
                rejectableSharedTripStatuses.includes(trip?.status) ? (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={async () => {
                        setShowConfirmCancelDialog(true);
                      }}
                    >
                      {confirmCancelLoading ? (
                        <CircularProgress size="1.5rem" />
                      ) : (
                        'Confirm Cancellation'
                      )}
                    </Button>
                  </Grid>
                ) : null}
                {!isAvailableTrip &&
                !savoyaAssignmentCancelled &&
                !savoyaAssignmentCancelledConfirmed &&
                trip?.status === TripStatus.PendingNetworkConfirmation &&
                !iOwnThisTrip ? (
                  <Grid item>
                    <AcceptOrClaimSharedTripBtn trip={trip} />
                  </Grid>
                ) : null}

                {isAvailableTrip &&
                !savoyaAssignmentCancelled &&
                !savoyaAssignmentCancelledConfirmed ? (
                  <Grid item>
                    <AcceptOrClaimSharedTripBtn trip={trip} action="claim" />
                  </Grid>
                ) : null}
              </div>
              <Divider orientation="vertical" flexItem />
              <div
                className={classes.rightContainer}
                data-testid={`${primaryDataTestId}_right_container`}
              >
                <NextTripInfoItem
                  label="Pickup"
                  showGroundWorkLogo={!!trip?.groundworkTrip}
                  value={<AddressComponent address={pickupAddress} forTile />}
                />
                <NextTripInfoItem
                  label="Stops"
                  value={`${getTotalStopWaypoints(trip?.waypoints)}`}
                  oneLine
                />
                <NextTripInfoItem
                  label="Dropoff"
                  value={<AddressComponent address={dropoffAddress} forTile />}
                />
                <div
                  className={classes.statusContainer}
                  data-testid={`${primaryDataTestId}_left_container_status_box`}
                >
                  <TripStatusBox
                    status={trip?.status}
                    savoyaSharedStatus={
                      trip?.sharedTrip?.savoyaAssignmentStatus
                    }
                    sharedStatus={trip?.sharedTrip?.status}
                    iOwnThisTrip={iOwnThisTrip}
                    isSavoyaTrip={
                      !!trip?.providerExternalId ||
                      trip?.providerSource === TripProviderSource.Savoya
                    }
                    isAvailableTrip={isAvailableTrip}
                  />
                </div>
              </div>
            </div>
          </Grid>
          {trip?.id &&
            !savoyaAssignmentCancelled &&
            !savoyaAssignmentCancelledConfirmed && (
              <TripAssignmentDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                trip={trip}
                onConfirm={handleAssignTrip}
                noCompUpgrade={
                  trip?.savoyaAssignment?.reservation?.noCompUpgrades
                }
              />
            )}
          <GenericDialog
            openDialog={showConfirmCancelDialog}
            setCloseDialog={() => setShowConfirmCancelDialog(false)}
            dialogTitle="Confirm Cancellation"
            maxWidth="sm"
          >
            <DialogActions>
              <Button
                onClick={() => setShowConfirmCancelDialog(false)}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  setConfirmCancelLoading(true);
                  await confirmCancellation.mutateAsync({
                    vendorAssignmentId: trip?.savoyaAssignment?.id,
                  });
                  setConfirmCancelLoading(false);
                }}
                disabled={confirmCancelLoading}
              >
                {confirmCancelLoading ? (
                  <CircularProgress size="2rem" />
                ) : (
                  'Confirm'
                )}
              </Button>
            </DialogActions>
          </GenericDialog>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TripListTile;
