const METERS_TO_MILES_RATE = 1609.34;
const SECONDS_TO_MINUTES_RATE = 60;
const SECONDS_TO_HOURS_RATE = 3600;
const SECONDS_TO_DAY_RATE = 86400;

export const convertMilesToMeters = (miles: number | undefined): number => {
  if (!miles) {
    return 0;
  }

  return miles * METERS_TO_MILES_RATE;
};

export const convertMinutesToSeconds = (
  minutes: number | undefined,
): number => {
  if (!minutes) {
    return 0;
  }

  return minutes * SECONDS_TO_MINUTES_RATE;
};

export const convertMinutesToHours = (minutes: number | undefined): number => {
  if (!minutes) {
    return 0;
  }

  return minutes / SECONDS_TO_MINUTES_RATE;
};

export const convertHoursToMinutes = (minutes: number | undefined): number => {
  if (!minutes) {
    return 0;
  }

  return minutes * SECONDS_TO_MINUTES_RATE;
};

export const convertMetersToMiles = (meters: number | undefined): number => {
  if (!meters) {
    return 0;
  }

  return meters / METERS_TO_MILES_RATE;
};

export const convertSecondsToMinutes = (
  seconds: number | undefined,
): number => {
  if (!seconds) {
    return 0;
  }

  return seconds / SECONDS_TO_MINUTES_RATE;
};

export const convertSecondsToFormattedTime = (
  seconds: number | undefined,
): string => {
  if (!seconds) {
    return '0min';
  }
  const days = Math.floor(seconds / SECONDS_TO_DAY_RATE);
  const hoursLeft = seconds - days * SECONDS_TO_DAY_RATE;
  const hours = Math.floor(hoursLeft / SECONDS_TO_HOURS_RATE);
  const minutesLeft = hoursLeft - hours * SECONDS_TO_HOURS_RATE;
  const minutes = Math.floor(minutesLeft / SECONDS_TO_MINUTES_RATE);

  return (
    (days > 0 ? `${days}d ` : '') +
    (hours > 0 ? `${hours}hr ` : '') +
    (minutes > 0 ? `${minutes}min ` : '')
  );
};

export const convertMinutesToFormattedTime = (
  minutesToConvert: number | undefined,
): string => {
  if (!minutesToConvert) {
    return '0min';
  }

  const seconds = minutesToConvert * SECONDS_TO_MINUTES_RATE;

  const days = Math.floor(seconds / SECONDS_TO_DAY_RATE);
  const hoursLeft = seconds - days * SECONDS_TO_DAY_RATE;
  const hours = Math.floor(hoursLeft / SECONDS_TO_HOURS_RATE);
  const minutesLeft = hoursLeft - hours * SECONDS_TO_HOURS_RATE;
  const minutes = Math.floor(minutesLeft / SECONDS_TO_MINUTES_RATE);

  return (
    (days > 0 ? `${days}d ` : '') +
    (hours > 0 ? `${hours}hr ` : '') +
    (minutes > 0 ? `${minutes}min ` : '')
  );
};

export const convertHoursToFormattedTime = (
  hours: number | undefined,
): string => {
  if (!hours) {
    return '0m';
  }

  const seconds = hours * SECONDS_TO_HOURS_RATE;
  const formattedTime = convertSecondsToFormattedTime(seconds);
  return formattedTime;
};

export const convertHoursToSeconds = (hours: number | undefined): number => {
  if (!hours) {
    return 0;
  }

  return hours * SECONDS_TO_HOURS_RATE;
};

export const convertMetersToFormatedDistance = (distance: number): string => {
  return `${convertMetersToMiles(distance).toFixed(0)}miles`;
};
