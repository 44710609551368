import Box from '@material-ui/core/Box';
import React, { FC } from 'react';
import { TableRate } from 'src/rates/hooks/useTransferRates';

interface TransferRatesSummaryProps {
  tableRate: TableRate;
}

const TransferRatesSummary: FC<TransferRatesSummaryProps> = ({ tableRate }) => {
  return (
    <Box flexDirection="column" width="100%" padding="4px">
      {(tableRate.vehicleType || '').split(',').map((item: string) => {
        const splittedVehicleTypeItem = (item || '').split('-');
        return (
          <Box
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
            width="100%"
            borderBottom="1px solid #c4c4c4"
          >
            <p style={{ fontSize: '0.6rem' }}>{splittedVehicleTypeItem[0]}</p>
            <p style={{ fontSize: '0.6rem', marginLeft: '5px' }}>
              {splittedVehicleTypeItem.pop()}
            </p>
          </Box>
        );
      })}
    </Box>
  );
};

export default TransferRatesSummary;
