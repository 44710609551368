import React from 'react';
import {
  Typography,
  Button,
  Theme,
  DialogActions,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { green, red } from '@material-ui/core/colors';
import useTripCommand from 'src/trips/hooks/useTripCommand';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import { useRecoilValue } from 'recoil';
import { tripCommandDialogState } from 'src/trips/atoms/tripCommandDialogAtom';
import BaseTripCommandDialog from './BaseTripCommandDialog';

interface ConfirmTripCommandDialogProps {
  title: string;
  body?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  confirmButton: {
    backgroundColor: green[400],
    color: '#ffffff',
    float: 'right',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  cancelButton: {
    backgroundColor: red[400],
    color: '#ffffff',
    float: 'left',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: red[500],
    },
  },
  bodyText: {
    fontStyle: 'italic',
    marginTop: '1rem',
  },
}));

const ConfirmTripCommandDialog: React.FC<ConfirmTripCommandDialogProps> = ({
  title,
  body,
}) => {
  const classes = useStyles();
  const { closeModal, onSubmit, loading } = useTripCommand();
  const { customCallback } = useRecoilValue(tripCommandDialogState);

  return (
    <BaseTripCommandDialog
      content={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
              {title}
            </Typography>
          </Grid>
          {body ? (
            <Grid item xs={12}>
              <Typography
                className={classes.bodyText}
                variant="body1"
                align="center"
              >
                {body}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      }
      actions={
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              closeModal(false);
            }}
            variant="contained"
          >
            Cancel
          </Button>

          <Button
            className={classes.confirmButton}
            onClick={async () => {
              await onSubmit();
              if (customCallback) {
                customCallback();
              }
            }}
            color="primary"
            startIcon={loading && <CircularProgressIndicator />}
          >
            Confirm
          </Button>
        </DialogActions>
      }
    />
  );
};

export default ConfirmTripCommandDialog;
