import moment, { unitOfTime } from 'moment-timezone';

type DateTimeType = Date | number | string;

export const timeUtils = {
  roundUpToQuarterHours: (minutes: number): number => {
    return Math.ceil(minutes / 15.0) / 4.0;
  },
  minutesBetween: (time1: DateTimeType, time2: DateTimeType): number => {
    if (time1 === undefined || time2 === undefined) {
      return NaN;
    }
    return moment(time1).diff(moment(time2), 'minutes');
  },
  durationBetween: (time1: DateTimeType, time2: DateTimeType): number => {
    const diffInMinutes = timeUtils.minutesBetween(time2, time1);
    return timeUtils.roundUpToQuarterHours(diffInMinutes);
  },
  offset: (
    date: DateTimeType,
    amount: Duration | number | string | null | undefined,
    unit: unitOfTime.DurationConstructor,
  ): string | undefined => {
    if (date) {
      return moment(date).add(amount, unit).toISOString();
    }
    return undefined;
  },
};
