import { ProductType } from 'src/.gen/graphql';

export const APP_PRODUCT_TYPE_KEY = 'x-cd-app-product-type';

interface UserProductVariantReturnType {
  setAppProductType: (productType: string) => void;
  clearAppProductType: () => void;
  isSavoyaProductType: boolean;
}

export const useUserProductVariant = (): UserProductVariantReturnType => {
  // TODO: this is a temporary solution to set the product type
  // We will need to refactor this when we have a CD and Savoya user variant at the same time
  const setAppProductType = (productType: ProductType) => {
    localStorage.setItem(APP_PRODUCT_TYPE_KEY, productType);
  };

  const isSavoyaProductType =
    localStorage.getItem(APP_PRODUCT_TYPE_KEY) === ProductType.Savoya;

  const clearAppProductType = () => {
    localStorage.removeItem(APP_PRODUCT_TYPE_KEY);
  };

  return {
    setAppProductType,
    clearAppProductType,
    isSavoyaProductType,
  };
};
