import React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import {
  Box,
  Link,
  Typography,
  makeStyles,
  Theme,
  LinearProgress,
} from '@material-ui/core';
import { flatten } from 'lodash';

interface FilesDropzoneProps {
  options: DropzoneOptions;
  uploading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
}));

const FilesDropzone: FC<FilesDropzoneProps> = (props) => {
  const { options, uploading } = props;
  const classes = useStyles();
  const filesText = options?.multiple ? 'files' : 'a file';

  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

  const acceptedFileTypes = flatten(Object.values(options?.accept));

  return (
    <div
      className={clsx({
        [classes.dropZone]: true,
        [classes.dragActive]: isDragActive,
      })}
      {...getRootProps()}
    >
      <input data-testid="file_dropzone" {...getInputProps()} />
      <div>
        <img
          alt="Select file"
          className={classes.image}
          src="/static/images/undraw_add_file2_gvbb.svg"
        />
      </div>
      <div>
        <Typography gutterBottom variant="h6">
          {`Select ${filesText}`}
        </Typography>
        <Box mt={2}>
          <Typography color="textPrimary" variant="body2">
            {`Drop ${filesText} here or `}{' '}
            <Link underline="always">browse</Link>
            {' through your machine'}
          </Typography>
          {acceptedFileTypes?.length && (
            <Typography color="textPrimary" variant="body2">
              {`The formats allowed to be uploaded are: ${acceptedFileTypes.join(
                ', ',
              )}`}
            </Typography>
          )}
          {uploading ? (
            <Box marginTop={1} marginLeft={1}>
              <LinearProgress />
            </Box>
          ) : null}
        </Box>
      </div>
    </div>
  );
};

export default FilesDropzone;
