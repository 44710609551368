import React, { FC } from 'react';
import { useVehicleHourlyRates } from 'src/rates/hooks/useVehicleHourlyRates';
import {
  useUpdateVehicleClassMutation,
  useUpdateVehicleHourlyRateMutation,
  VehicleClass,
} from 'src/.gen/graphql';
import CurrencyField from 'src/shared/components/CurrencyField';
import GeneralTable from 'src/shared/components/GeneralTable/GeneralTable';
import { useAccountRatePolicyDetails } from 'src/tripsNewClients/components/hooks/useAccountRatePolicyDetails';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { useSnackbar } from 'notistack';
import HoursField from 'src/shared/components/HoursField';
import CustomEditableCell from '../../CustomEditableCell/CustomEditableCell';

const StandardHourlyRatesTable: FC = () => {
  const { vehicleHourlyRates, loadingVehicleHourlyRates } =
    useVehicleHourlyRates();
  const [updateVehicleHourlyRate] = useUpdateVehicleHourlyRateMutation();
  const [updateVehicleClass] = useUpdateVehicleClassMutation();
  const user = useRecoilValue(userAtom);
  const accountPolicies = useAccountRatePolicyDetails(user?.accountId);
  const { enqueueSnackbar } = useSnackbar();

  const updateRate = async (rowData, hourlyRate: number) => {
    const { id } = rowData;
    if (Number.isNaN(hourlyRate) || hourlyRate <= 0) {
      enqueueSnackbar('Rate per hour must be a positive number larger than 0', {
        variant: 'error',
      });
      return;
    }
    await updateVehicleHourlyRate({
      variables: {
        hourlyRate,
        id,
      },
      update(cache) {
        cache.modify({
          id: cache.identify(rowData),
          fields: {
            hourlyRate() {
              return hourlyRate;
            },
          },
        });
      },
    });
  };
  const updateCancellationPolicy = async (
    rowData: Partial<VehicleClass>,
    cancelHours: string,
  ) => {
    const { id, minimumHours } = rowData;
    const cancelHoursParsed = parseInt(cancelHours);
    if (
      Number.isNaN(cancelHoursParsed) ||
      cancelHoursParsed <= 0 ||
      cancelHoursParsed > 168
    ) {
      enqueueSnackbar(
        'Cancellation Policy must be between 1 hour and 1 week (168 hours)',
        {
          variant: 'error',
        },
      );
      return;
    }
    await updateVehicleClass({
      variables: {
        vehicleClass: {
          id,
          cancellationPolicyHours: cancelHoursParsed,
          minimumHours,
        },
      },
      refetchQueries: ['getVehicleHourlyRates'],
    });
  };

  const updateMinHoursPolicy = async (
    rowData: Partial<VehicleClass>,
    minimumHours: string,
  ) => {
    const { id, cancellationPolicyHours } = rowData;
    const minimumHoursParsed = parseInt(minimumHours);
    if (
      Number.isNaN(minimumHoursParsed) ||
      minimumHoursParsed <= 0 ||
      minimumHoursParsed > 10
    ) {
      enqueueSnackbar(
        'Minimum Booking Hours must be a positive number between 1 and 10',
        {
          variant: 'error',
        },
      );
      return;
    }
    await updateVehicleClass({
      variables: {
        vehicleClass: {
          id,
          minimumHours: minimumHoursParsed,
          cancellationPolicyHours,
        },
      },
      refetchQueries: ['getVehicleHourlyRates'],
    });
  };

  return (
    <GeneralTable
      columns={getTableHeaders(
        updateRate,
        updateCancellationPolicy,
        updateMinHoursPolicy,
        accountPolicies?.minimumBookingHours,
        accountPolicies?.cancellationPolicy,
      )}
      rows={vehicleHourlyRates.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      })}
      hasPagination={false}
      hasSelection={false}
      clickable={false}
      loading={loadingVehicleHourlyRates}
    />
  );
};

const getTableHeaders = (
  updateRate,
  updateCancellationPolicy,
  updateMinHoursPolicy,
  defaultMinimumHours,
  defaultCancellationPolicyHours,
) => [
  { label: 'Vehicle Type', id: 'name' },
  {
    label: 'Rate per hour',
    id: 'hourlyRate',
    format: (hourlyRate, rowData) => {
      return (
        <CustomEditableCell
          type="text"
          inputType="currency"
          value={hourlyRate}
          onSubmit={(newValue: number) => updateRate(rowData, newValue)}
          customInputComponent={CurrencyField}
        />
      );
    },
  },
  {
    label: 'Minimum Booking Hours',
    id: 'minimumHours',
    format: (minimumHours, rowData) => {
      return (
        <CustomEditableCell
          type="number"
          inputType="normal"
          value={minimumHours || defaultMinimumHours}
          onSubmit={(newValue: number) =>
            updateMinHoursPolicy(rowData, newValue)
          }
          customInputComponent={HoursField}
        />
      );
    },
  },
  {
    label: 'Cancellation Policy',
    id: 'cancellationPolicyHours',
    format: (cancellationPolicyHours, rowData) => {
      return (
        <CustomEditableCell
          type="number"
          inputType="normal"
          value={cancellationPolicyHours || defaultCancellationPolicyHours}
          onSubmit={(newValue: number) =>
            updateCancellationPolicy(rowData, newValue)
          }
          customInputComponent={HoursField}
        />
      );
    },
  },
];
export default StandardHourlyRatesTable;
