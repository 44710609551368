import { Card, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { TableRate, useTransferRates } from 'src/rates/hooks/useTransferRates';
import {
  useDeleteRateSetMutation,
  useGetClientsByRateSetsQuery,
  useGetCompaniesByRateSetsQuery,
} from 'src/.gen/graphql';
import { deleteItemCache } from 'src/shared/utils/cacheBuilder';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { ratesMapPlaces } from 'src/rates/atoms/RatesFormAtom';
import { useRecoilValue } from 'recoil';
import NewTransferRateFormulary, {
  EditionInitialValuesInterface,
} from './NewTransferRateFormulary/NewTransferRateFormulary';
import TransferRatesTable from './TransferRatesTable/TransferRatesTable';
import RateLocationMap from './NewTransferRateFormulary/RateLocationMap/RateLocationMap';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    padding: '10px',
  },
}));

const TransferRates: FC = () => {
  const classes = useStyles();

  const { transferRates: fetchedTransferRates, refetchRates } =
    useTransferRates();
  const [transferRates, setTransferRates] = useState<TableRate[]>([]);
  const [transferRateIds, setTransferRateIds] = useState<string[]>([]);
  const [tranferRatesWithClients, setTransferRatesWithClients] = useState<
    TableRate[]
  >([]);

  const { data: transferRateCompanies, loading: companiesLoading } =
    useGetCompaniesByRateSetsQuery({
      variables: { rateSetIds: transferRateIds },
      skip: !transferRateIds?.length,
    });
  const { data: transferRateClients, loading: clientsLoading } =
    useGetClientsByRateSetsQuery({
      variables: { rateSetIds: transferRateIds },
      skip: !transferRateIds?.length,
    });
  const [creatingMode, setCreatingMode] = useState<boolean>(false);
  const [editingMode, setEditingMode] = useState<boolean>(false);
  const [editingInfo, setEditingInfo] =
    useState<EditionInitialValuesInterface>();
  const [deleteTransferRate, { data: deleteData, loading: deleteLoading }] =
    useDeleteRateSetMutation();

  const mapValues = useRecoilValue(ratesMapPlaces);

  React.useEffect(() => {
    if (!deleteLoading && deleteData?.deleteRateSet?.success) {
      refetchRates();
      setTransferRates([]);
      setTransferRateIds([]);
    }
  }, [deleteData, deleteLoading]);

  React.useEffect(() => {
    if (fetchedTransferRates.length) {
      setTransferRates(fetchedTransferRates);
      setTransferRateIds(fetchedTransferRates?.map((rate) => rate?.id));
    } else {
      setTransferRates([]);
      setTransferRateIds([]);
    }
  }, [fetchedTransferRates]);

  const onDelete = async (id: string) => {
    await deleteTransferRate({
      variables: { id },
      update(cache) {
        deleteItemCache(cache, { id, __typename: 'RateSet' });
      },
    });
  };

  React.useEffect(() => {
    if (clientsLoading || companiesLoading) {
      return;
    }
    if (
      transferRateCompanies?.companyQueries?.getCompaniesByRateSets?.length &&
      transferRateClients?.clientQueries?.getClientsByRateSetIds?.length
    ) {
      const transferRatesWithCompaniesAndClients = transferRates?.map(
        (transferRate) => {
          const companies =
            transferRateCompanies?.companyQueries?.getCompaniesByRateSets?.find(
              (transferRateCompanies) =>
                transferRateCompanies?.rateSetId === transferRate?.id,
            );
          const clients =
            transferRateClients?.clientQueries?.getClientsByRateSetIds?.find(
              (transferRateClients) =>
                transferRateClients?.rateSetId === transferRate?.id,
            );
          return Object.assign(transferRate, {
            companies: companies
              ? companies?.companies
                  ?.map((company) => company?.name)
                  ?.join(', ')
              : 'All',
            clients: clients
              ? clients?.clients?.map((client) => client?.name)?.join(', ')
              : 'All',
          });
        },
      );
      setTransferRatesWithClients(transferRatesWithCompaniesAndClients);
    } else if (
      transferRateCompanies?.companyQueries?.getCompaniesByRateSets?.length
    ) {
      const transferRatesWithCompanies = transferRates?.map((transferRate) => {
        const companies =
          transferRateCompanies?.companyQueries?.getCompaniesByRateSets?.find(
            (transferRateCompanies) =>
              transferRateCompanies?.rateSetId === transferRate?.id,
          );
        return Object.assign(transferRate, {
          companies: companies
            ? companies?.companies?.map((company) => company?.name)?.join(', ')
            : 'All',
          clients: 'All',
        });
      });
      setTransferRatesWithClients(transferRatesWithCompanies);
    } else if (
      transferRateClients?.clientQueries?.getClientsByRateSetIds?.length
    ) {
      const transferRatesWithClients = transferRates?.map((transferRate) => {
        const clients =
          transferRateClients?.clientQueries?.getClientsByRateSetIds?.find(
            (transferRateClients) =>
              transferRateClients?.rateSetId === transferRate?.id,
          );
        return Object.assign(transferRate, {
          clients: clients
            ? clients?.clients?.map((client) => client?.name)?.join(', ')
            : 'All',
          companies: 'All',
        });
      });
      setTransferRatesWithClients(transferRatesWithClients);
    } else {
      const transferRatesWithNoCustomers = transferRates?.map(
        (transferRate) => {
          return Object.assign(transferRate, {
            companies: 'All',
            clients: 'All',
          });
        },
      );
      setTransferRatesWithClients(transferRatesWithNoCustomers);
    }
  }, [transferRateCompanies, transferRateClients]);

  return clientsLoading || companiesLoading ? (
    <CircularProgress color="secondary" />
  ) : (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} data-testid="transfer_rates_list">
          <TransferRatesTable
            onCreate={() => setCreatingMode(true)}
            onEdit={(initialRate) => {
              setCreatingMode(false);
              setEditingMode(true);
              setEditingInfo(initialRate);
            }}
            onDelete={onDelete}
            transferRates={tranferRatesWithClients}
            transferRateClients={
              transferRateClients?.clientQueries?.getClientsByRateSetIds
            }
            transferRateCompanies={
              transferRateCompanies?.companyQueries?.getCompaniesByRateSets
            }
          />
        </Grid>
      </Grid>
      <GenericDialog
        openDialog={!!(creatingMode || editingMode)}
        setCloseDialog={() => {
          setCreatingMode(false);
          setEditingMode(false);
          setEditingInfo(null);
        }}
        maxWidth="xl"
      >
        <Grid container justify="center" spacing={1}>
          {!!(creatingMode && !editingMode) && (
            <Grid item xs={12} md={7}>
              <Card className={classes.header}>
                <NewTransferRateFormulary
                  editing={false}
                  closeForm={() => setCreatingMode(false)}
                  refetchRates={refetchRates}
                />
              </Card>
            </Grid>
          )}
          {!!(!creatingMode && editingMode && editingInfo) && (
            <Grid item xs={12} md={7}>
              <Card className={classes.header}>
                <NewTransferRateFormulary
                  editing
                  initialRate={editingInfo}
                  closeForm={() => setEditingMode(false)}
                  refetchRates={refetchRates}
                />
              </Card>
            </Grid>
          )}
          {!!(
            mapValues.firstLocation.coordinates ||
            mapValues.secondLocation.coordinates
          ) && (
            <Grid item xs={12} md={5} data-testid="transfer_rates_map">
              <RateLocationMap />
            </Grid>
          )}
        </Grid>
      </GenericDialog>
    </React.Fragment>
  );
};

export default TransferRates;
