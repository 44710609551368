import React, { FC, useEffect, useState } from 'react';
import ReactMapboxGl, { GeoJSONLayer, Image } from 'react-mapbox-gl';
import { LinePaint } from 'mapbox-gl';
import { makeStyles } from '@material-ui/core';
import { useFetch } from 'src/shared/hooks/useFetch';
import { getBounds } from 'src/shared/utils/mapUtils';
import arrowRight from 'src/shared/assets/images/arrowUp.png';
import { mapBoxConfig } from 'src/config';
import Markers from './Markers';

const useStyles = makeStyles(() => ({
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '15px',
  },
}));

export type MarkerDetails = {
  title: string;
  address?: string;
  waitTime?: string;
  role?: string;
};

interface MapProps {
  route?: string;
  routeCoordinates?: number[][];
  sensorRouteCoordinates?: number[][];
  calcRoute?: boolean;
  getRouteTimesAndDistances?: (
    timesAndDistances: Record<string, number>[],
  ) => void;
  dateToFetch?: string;
  markerDetails?: MarkerDetails[] | undefined;
}

const Map = ReactMapboxGl({
  accessToken: mapBoxConfig.token,
});

const MapComponent: FC<MapProps> = (props) => {
  const {
    route,
    routeCoordinates,
    sensorRouteCoordinates,
    calcRoute,
    getRouteTimesAndDistances,
    dateToFetch,
    markerDetails,
  } = props;

  const classes = useStyles();
  const [routeCoordinatesArray, setRouteCoordinatesArray] = useState([]);
  const [mapboxUrl, setMapboxUrl] = useState(null);
  const [mapboxMapUrl, setMapboxMapUrl] = useState(null);
  const [skipMapBoxCall, setSkipMapBoxCall] = useState(true);
  const [skipMapBoxMapCall, setSkipMapBoxMapCall] = useState(true);

  const getMapboxUrl = (values) => {
    const { waypoints } = values;
    const waypointsURL = waypoints.reduce((acc, curr) => {
      return `${acc ? `${acc};` : acc}${curr[0]},${curr[1]}`;
    }, '');

    const BASE_URL = `https://api.mapbox.com/directions/v5/mapbox/driving/`;
    const coordinatesString = `${waypointsURL}`;
    const result = BASE_URL + coordinatesString;
    return result;
  };

  const getCoordinatesFromPayload = (apiPayload) => {
    try {
      if (apiPayload?.routes[0]) {
        return apiPayload?.routes[0].geometry?.coordinates || [];
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  const getDistancesFromPayload = (apiPayload) => {
    try {
      if (apiPayload?.routes[0]) {
        return apiPayload?.routes[0].legs.map((el) => ({
          distance: el.distance || 0,
          duration: el.duration,
        }));
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const {
    data: rawRoute,
    updateUrl: updateRouteUrl,
    isLoading,
    updateParams,
  } = useFetch({
    initialUrl: mapboxUrl,
    initialParams: {
      overview: 'full',
      geometries: 'geojson',
      annotations: 'duration',
      access_token: mapBoxConfig.token,
    },
    skip: skipMapBoxCall,
  });
  const {
    data: rawMapRoute,
    updateUrl: updateMapRouteUrl,
    isLoading: isLoadingMap,
    updateParams: updateMapParams,
  } = useFetch({
    initialUrl: mapboxUrl,
    initialParams: {
      overview: 'full',
      geometries: 'geojson',
      annotations: 'duration',
      access_token: mapBoxConfig.token,
    },
    skip: skipMapBoxMapCall,
  });

  useEffect(() => {
    if (mapboxUrl) {
      updateRouteUrl(mapboxUrl);
      setSkipMapBoxCall(false);
    }
  }, [mapboxUrl]);
  useEffect(() => {
    if (mapboxMapUrl) {
      updateMapRouteUrl(mapboxMapUrl);
      setSkipMapBoxMapCall(false);
    }
  }, [mapboxMapUrl]);

  useEffect(() => {
    if (calcRoute && routeCoordinates.length > 1) {
      const newUrl = getMapboxUrl({
        waypoints: routeCoordinates,
      });
      const newMapUrl = getMapboxUrl({
        waypoints: routeCoordinates.slice(1, -1),
      });

      if (newUrl !== mapboxUrl) {
        setMapboxUrl(newUrl);
      }
      if (newMapUrl !== mapboxMapUrl) {
        setMapboxMapUrl(newMapUrl);
      }
    }
  }, [routeCoordinates, dateToFetch]);

  useEffect(() => {
    if (dateToFetch) {
      const newParams = {
        overview: 'full',
        geometries: 'geojson',
        annotations: 'duration',
        depart_at: dateToFetch || null,
        access_token: mapBoxConfig.token,
      };
      updateParams(newParams);
      updateMapParams(newParams);
    }
  }, [dateToFetch]);

  useEffect(() => {
    if (rawRoute && !isLoading) {
      const distancesAndTimes = getDistancesFromPayload(rawRoute);
      if (getRouteTimesAndDistances)
        getRouteTimesAndDistances(distancesAndTimes);
    }
  }, [rawRoute, isLoading]);

  useEffect(() => {
    if (rawMapRoute && !isLoadingMap) {
      const formated = getCoordinatesFromPayload(rawMapRoute);
      setRouteCoordinatesArray(formated);
    }
  }, [rawMapRoute, isLoadingMap]);

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates:
            (calcRoute ? routeCoordinatesArray : routeCoordinates) || [],
        },
      },
    ],
  };

  const sensorGeojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: sensorRouteCoordinates || [],
        },
      },
    ],
  };

  const linePaint: LinePaint = {
    'line-color': 'rgb(50,125,255)',
    'line-width': 3,
  };
  const sensorLinePaint: LinePaint = {
    'line-color': 'rgb(150,100,64)',
    'line-width': 3,
  };

  return (
    <div className={classes.mapContainer} data-testid="trip_map_waypoint">
      <Map
        style="mapbox://styles/mapbox/streets-v11"
        movingMethod="jumpTo"
        fitBounds={getBounds(
          calcRoute,
          routeCoordinatesArray,
          routeCoordinates,
        )}
        fitBoundsOptions={{
          padding: { top: 50, bottom: 20, left: 30, right: 30 },
          duration: 0,
        }}
        containerStyle={{
          height: '100%',
          maxHeight: '100vh',
          maxWidth: '100vw',
          width: '100%',
        }}
      >
        <React.Fragment>
          {/* Waypoint dots */}
          <Image
            id="uparrow"
            url={arrowRight}
            sdf
            options={{
              sdf: true,
            }}
          />
          <Markers
            routeCoordinates={routeCoordinates.slice(1, -1)}
            markerDetails={markerDetails}
          />
          {(!Array.isArray(sensorRouteCoordinates) ||
            (Array.isArray(sensorRouteCoordinates) &&
              !(sensorRouteCoordinates.length > 0))) &&
            route && <GeoJSONLayer data={geojson} linePaint={linePaint} />}
          {Array.isArray(sensorRouteCoordinates) &&
            sensorRouteCoordinates.length > 0 && (
              <React.Fragment>
                <GeoJSONLayer data={geojson} linePaint={linePaint} />
                <GeoJSONLayer
                  data={sensorGeojson}
                  linePaint={sensorLinePaint}
                />
              </React.Fragment>
            )}
        </React.Fragment>
      </Map>
    </div>
  );
};

export default MapComponent;
