import React, { FC } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { PaymentMethodType, Trip } from 'src/.gen/graphql';
import { splitByCamelCase } from 'src/shared/utils/stringUtils';

type OtherPaymentOptionsProps = {
  trip: Partial<Trip>;
  setCurrentMethod: (
    methodId: string,
    methodType: PaymentMethodType,
    methodDescription: string | undefined,
  ) => void;
  editing: boolean;
};

const OtherPaymentOptions: FC<OtherPaymentOptionsProps> = ({
  trip,
  setCurrentMethod,
  editing = false,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    trip.paymentMethodType || undefined,
  );

  React.useEffect(() => {
    if (!editing) {
      setSelectedPaymentMethod(trip.paymentMethodType || undefined);
    }
  }, [editing]);

  return (
    <FormControl
      style={{
        minWidth: 120,
        width: '100%',
        paddingTop: '1rem',
        paddingLeft: '0.5rem',
      }}
    >
      <Select
        value={selectedPaymentMethod}
        onChange={(e) => {
          e.preventDefault();
          const paymentMethodType = e.target.value;
          setCurrentMethod(
            undefined,
            paymentMethodType as PaymentMethodType,
            undefined,
          );
          setSelectedPaymentMethod(paymentMethodType as PaymentMethodType);
        }}
        inputProps={{
          name: 'paymentMethod',
        }}
        variant="outlined"
        disabled={!editing}
      >
        {Object.keys(PaymentMethodType)
          .filter((key) => key !== PaymentMethodType.CreditCard)
          .map((key) => (
            <MenuItem
              value={PaymentMethodType[key]}
              key={PaymentMethodType[key]}
            >
              <em>{splitByCamelCase(PaymentMethodType[key])}</em>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default OtherPaymentOptions;
