import React, { FC } from 'react';
import ReactMapboxGl, { GeoJSONLayer, Marker } from 'react-mapbox-gl';
import { makeStyles } from '@material-ui/core';
import { ratesMapPlaces } from 'src/rates/atoms/RatesFormAtom';
import { useRecoilValue } from 'recoil';
import { ZoneType } from 'src/.gen/graphql';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import { LinePaint } from 'mapbox-gl';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { mapBoxConfig } from 'src/config';

const useStyles = makeStyles(() => ({
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  smallMapContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    minHeight: '35vh',
  },
}));

export type MarkerDetails = {
  title: string;
  address?: string;
  waitTime?: string;
};

interface MapProps {
  latitude?: number;
  longitude?: number;
}

const Map = ReactMapboxGl({
  accessToken: mapBoxConfig.token,
});

const createGeoJSONCircle = (center, radiusInKm, points = 64) => {
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [ret],
        },
      },
    ],
  };
};

const getLocationCircle = (field: 'first' | 'second', mapValues) => {
  const location = mapValues[`${field}Location`];
  if (!location?.coordinates || !location?.radiusInKm) return {};
  if (location?.zoneType !== ZoneType.Area) return {};
  return createGeoJSONCircle(location?.coordinates, location?.radiusInKm || 0);
};

const RateLocationMap: FC<MapProps> = () => {
  const classes = useStyles();
  const [markersCoordinates, setMarkersCoordinates] = React.useState<
    Array<Array<number>>
  >([
    [1, 1],
    [1, 1],
  ]);
  const mapValues = useRecoilValue(ratesMapPlaces);
  const { sm } = useBreakpoints();

  React.useEffect(() => {
    if (
      mapValues.firstLocation.coordinates ||
      mapValues.secondLocation.coordinates
    ) {
      setMarkersCoordinates([
        mapValues.firstLocation.coordinates ||
          mapValues.secondLocation.coordinates || [1, 1],
        mapValues.secondLocation.coordinates ||
          mapValues.firstLocation.coordinates || [1, 1],
      ]);
    }
  }, [mapValues]);

  const renderMakers = () => {
    return (markersCoordinates || []).map((marker, index) => (
      <Marker key={index} coordinates={marker} anchor="bottom">
        <React.Fragment>
          <img
            alt="Marker"
            src="https://icons.iconarchive.com/icons/paomedia/small-n-flat/32/map-marker-icon.png"
          />
        </React.Fragment>
      </Marker>
    ));
  };

  const radiusLinePaint: LinePaint = {
    'line-color': 'rgb(155,0,0)',
    'line-width': 2,
  };

  const getBoundaries = () => {
    if (markersCoordinates[0].toString() === markersCoordinates[1].toString()) {
      const lat = Number(markersCoordinates[0][1]);
      const lon = Number(markersCoordinates[0][0]);
      return [
        [lon - 0.3, lat + 0.3],
        [lon + 0.3, lat - 0.3],
      ];
    }
    return markersCoordinates;
  };

  return (
    <div className={sm ? classes.smallMapContainer : classes.mapContainer}>
      {(mapValues.firstLocation.coordinates ||
        mapValues.secondLocation.coordinates) && (
        <Map
          style="mapbox://styles/mapbox/streets-v11"
          movingMethod="jumpTo"
          fitBounds={getBoundaries() as FitBounds}
          fitBoundsOptions={{
            padding: { top: 80, bottom: 50, left: 50, right: 50 },
            duration: 0,
          }}
          containerStyle={{
            height: '100%',
            maxHeight: '100vh',
            maxWidth: '100vw',
            width: '100%',
          }}
        >
          <React.Fragment>{renderMakers()}</React.Fragment>
          {mapValues?.firstLocation?.radiusInKm &&
            mapValues?.firstLocation?.zoneType === ZoneType.Area && (
              <GeoJSONLayer
                data={getLocationCircle('first', mapValues)}
                linePaint={radiusLinePaint}
              />
            )}
          {mapValues?.secondLocation?.radiusInKm &&
            mapValues?.secondLocation?.zoneType === ZoneType.Area && (
              <GeoJSONLayer
                data={getLocationCircle('second', mapValues)}
                linePaint={radiusLinePaint}
              />
            )}
        </Map>
      )}
    </div>
  );
};

export default RateLocationMap;
