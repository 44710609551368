import { Grid, Link, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import SavoyaStandardHourlyRates from '../../SavoyaStandardHourlyRates/SavoyaStandardHourlyRates';

const SavoyaHourlyRatesTab: FC<{ canEdit: boolean }> = ({ canEdit }) => {
  return (
    <React.Fragment>
      <Grid data-testid="standard_rates_container">
        <SavoyaStandardHourlyRates canEdit={canEdit} />
      </Grid>
      {canEdit ? (
        <Grid item xs={12}>
          <Typography display="inline" color="error">
            {' '}
            WARNING:{' '}
          </Typography>
          <Typography display="inline">
            If you need to charge a different rate for any special event, please
            do not update the rates in this section. Otherwise, you will risk
            losing trip offers. Savoya manages Special Event rates; please
            contact
          </Typography>
          <Link display="inline" href="mailto:vendors@savoya.com">
            <Typography display="inline">{` vendors@savoya.com `}</Typography>
          </Link>
          <Typography display="inline">to discuss event rates.</Typography>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

export default SavoyaHourlyRatesTab;
