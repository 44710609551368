import React from 'react';
import * as Yup from 'yup';
import {
  TextField,
  Grid,
  Box,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import CurrencyField from '../../../shared/components/CurrencyField';
import SelectTripRefund from './SelectTripRefund';

interface RefundArgs {
  tripId: string;
  amountRefunded: number;
  refundReason: string;
  note: string;
}

const defaultInitialValues = {
  amountRefunded: 0,
  refundReason: '',
  note: '',
};
interface FormProps {
  initialValues?: Partial<RefundArgs>;
  onSubmit: (values: Partial<RefundArgs>, actions) => void;
  onCancel: () => void;
  loading?: boolean;
}

const TripRefundForm: React.FC<FormProps> = (props) => {
  const {
    initialValues = defaultInitialValues,
    onSubmit,
    onCancel,
    loading = false,
  } = props;
  const {
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      amountRefunded: Yup.number().positive(
        'The amount must be greater than zero',
      ),
      refundReason: Yup.string().required('Select at least one option'),
    }),
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CurrencyField
            fullWidth
            label="Dollar Amount"
            name="amountRefunded"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.amountRefunded}
            variant="outlined"
            error={Boolean(touched.amountRefunded && errors.amountRefunded)}
            helperText={touched.amountRefunded && errors.amountRefunded}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectTripRefund
            label="Select the refund reason"
            onChange={(value) => setFieldValue('refundReason', value)}
            value={values.refundReason}
            error={Boolean(touched.refundReason && errors.refundReason)}
            helperText={touched.refundReason && errors.refundReason}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={Boolean(touched?.note && errors?.note)}
            fullWidth
            helperText={touched?.note && errors?.note}
            label="Refund Note (Optional)"
            name="note"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.note}
            variant="outlined"
            multiline
            rows={4}
            rowsMax={8}
          />
        </Grid>
      </Grid>
      {loading && (
        <Grid
          container
          item
          spacing={1}
          style={{ marginBottom: '2rem' }}
          xs={12}
          justify="center"
        >
          <Typography
            variant="body1"
            color="textPrimary"
            style={{ fontStyle: 'italic' }}
          >
            Applying refund... Please wait
          </Typography>
        </Grid>
      )}
      <Box mt={3}>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || loading}
          >
            {isSubmitting ? 'Loading...' : 'Save'}
          </Button>
        </DialogActions>
      </Box>
    </form>
  );
};

export default TripRefundForm;
