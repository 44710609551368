/* eslint-disable no-nested-ternary */
import { Paper, Theme, Typography, fade, makeStyles } from '@material-ui/core';
import React from 'react';

type FilledVariantsType = 'primary' | 'default';
type OutlinedVariantsType = 'primary-outlined' | 'default-outlined';

type Props = {
  variant?: FilledVariantsType | OutlinedVariantsType;
  title?: string;
};

const getContainerStyles = (theme: Theme, props: Props) => {
  let bgColor = theme.palette.backgroundColor.light;
  let borderColor = '';

  if (props.variant.includes('primary')) {
    bgColor = theme.palette.backgroundColor.secondary;
  }

  if (props.variant === 'primary-outlined') {
    bgColor = '';
    borderColor = `1px solid ${theme.palette.primary.main}`;
  }

  if (props.variant === 'default-outlined') {
    borderColor = `1px solid ${fade(theme.palette.backgroundColor.dark, 0.3)}`;
  }
  return { bgColor, borderColor };
};

const getTitleStyles = (theme: Theme, props: Props) => {
  let borderColor = '';

  if (props.variant === 'primary-outlined') {
    borderColor = theme.palette.primary.main;
  }

  if (props.variant === 'default-outlined') {
    borderColor = theme.palette.backgroundColor.dark;
  }
  return { borderColor };
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: (props) => {
    const { bgColor, borderColor } = getContainerStyles(theme, props);
    return {
      backgroundColor: bgColor,
      padding: '1rem',
      width: '100%',
      minHeight: '2rem',
      border: borderColor,
    };
  },
  title: (props) => {
    const { borderColor } = getTitleStyles(theme, props);

    return {
      color: borderColor,
    };
  },
}));

const CapitalDriveStyledContainer: React.FC<Props> = ({
  variant = 'default',
  title,
  children,
}) => {
  const classes = useStyles({ variant });
  return (
    <Paper elevation={0} className={classes.container}>
      {title && (
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
      )}
      {children}
    </Paper>
  );
};

export default CapitalDriveStyledContainer;
