import React from 'react';
import {
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
// import { useGetRateTypesQuery } from 'src/.gen/graphql';
// import { useRecoilValue } from 'recoil';
// import { userAtom } from 'src/authentication/atoms/AuthState';

interface InputProps {
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const SelectTripRefund: React.FC<InputProps> = (props) => {
  const {
    label = '',
    error = false,
    helperText = '',
    value = '',
    onChange = () => null,
    disabled = false,
  } = props;
  // const user = useRecoilValue(userAtom);
  // TO DO: CREATE AN ENUM IN THE BACKEND FOR THE REFUND REASON
  // const { data } = useGetRateTypesQuery({
  //   variables: {
  //     accountId: user?.accountId,
  //   },
  // });
  const data = {
    refundReasons: {
      all: {
        items: [
          { id: 'Customer compensation', name: 'Customer compensation' },
          { id: 'Rate Complaint', name: 'Rate Complaint' },
          { id: 'Overcharge', name: 'Overcharge' },
          { id: 'Other', name: 'Other' },
        ],
      },
    },
  };

  const options = React.useMemo(() => {
    return data?.refundReasons?.all?.items || [];
  }, [data]);

  return (
    <FormControl component="fieldset" variant="standard" error={error}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup>
        {options.map((refundReason) => (
          <FormControlLabel
            key={refundReason.id}
            disabled={disabled}
            control={
              <Checkbox
                checked={refundReason.id === value}
                onChange={() => onChange(refundReason.id)}
                name="gilad"
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircle />}
              />
            }
            label={refundReason.name}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectTripRefund;
