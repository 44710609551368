import React, { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Typography, useTheme } from '@material-ui/core';

import {
  PaymentMethodType,
  Trip,
  useExecuteBeginPaymentMethodSetupMutation,
  useGetSetupClientIdForTripLazyQuery,
  useGetTripClientsByTripQuery,
} from 'src/.gen/graphql';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { useSnackbar } from 'notistack';
import RegisteredPaymentOptions from './RegisteredPaymentOption';
import SetupForm from './SetupForm';

type StripePaymentMethodProps = {
  trip: Partial<Trip>;
  returnUrl: string;
  setCurrentMethod: (
    methodId: string,
    methodType: PaymentMethodType,
    methodDescription: string | undefined,
  ) => void;
  editable: boolean;
  editing: boolean;
  setInProgress: (inProgress: boolean) => void;
  setupInProgress: boolean;
};

const StripePaymentMethod: FC<StripePaymentMethodProps> = ({
  trip,
  returnUrl,
  setCurrentMethod,
  editable = true,
  editing = false,
  setInProgress,
  setupInProgress = false,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [clientSecret, setClientSecret] = React.useState(null);
  const user = useRecoilValue(userAtom);
  const { data: tripClientsData, loading: tripClientsLoading } =
    useGetTripClientsByTripQuery({ variables: { tripId: trip?.id } });
  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
    { stripeAccount: user?.account?.stripeAccountId },
  );

  const [beginPaymentMethodSetup] = useExecuteBeginPaymentMethodSetupMutation();
  const [
    getSetupClientIdForTrip,
    { data: clientSecretData, loading: loadingClientSecret },
  ] = useGetSetupClientIdForTripLazyQuery();

  const beginSetup = async () => {
    const response = await beginPaymentMethodSetup({
      variables: { tripId: trip.id },
    });

    if (response?.data?.executeBeginPaymentMethodSetup?.succeeded) {
      setInProgress(true);
      await getSetupClientIdForTrip({ variables: { tripId: trip.id } });
    } else {
      enqueueSnackbar(
        response?.data?.executeBeginPaymentMethodSetup?.errors[0],
        { variant: 'error' },
      );
    }
  };

  React.useEffect(() => {
    if (
      !loadingClientSecret &&
      clientSecretData?.stripe?.getSetupClientIdForTrip?.clientSecret
    ) {
      setClientSecret(
        clientSecretData.stripe.getSetupClientIdForTrip.clientSecret,
      );
    }
  }, [clientSecretData, loadingClientSecret]);

  React.useEffect(() => {
    if (trip?.id && editable) {
      const clientSecretParam = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret',
      );

      if (clientSecretParam) {
        setInProgress(true);
        setClientSecret(clientSecretParam);
      }
    }
  }, []);

  const appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      colorPrimary: theme.palette.primary.main,
      colorBackground: theme.palette.background.default,
      colorText: theme.palette.text.primary,
      colorDanger: theme.palette.error.main,
      fontFamily: theme.typography.fontFamily,
      spacingUnit: '4px',
      borderRadius: '4px',
      // See all possible variables below
    },
  };
  const options = {
    // passing the client secret obtained in step 2
    clientSecret,
    // Fully customizable with appearance API.
    appearance,
  };

  return trip?.id && !tripClientsLoading ? (
    <React.Fragment>
      {setupInProgress ? (
        clientSecret && (
          <Elements
            stripe={stripePromise}
            options={options as StripeElementsOptions}
          >
            <SetupForm
              returnUrl={returnUrl}
              trip={trip}
              tripClients={tripClientsData?.clientQueries?.getTripClientsByTrip}
              closeSetup={(newPaymentMethodId, newPaymentMethodDescription) => {
                if (newPaymentMethodId) {
                  setCurrentMethod(
                    newPaymentMethodId,
                    PaymentMethodType.CreditCard,
                    newPaymentMethodDescription,
                  );
                }
                setInProgress(false);
              }}
            />
          </Elements>
        )
      ) : (
        <RegisteredPaymentOptions
          beginSetup={beginSetup}
          trip={trip}
          setCurrentMethod={setCurrentMethod}
          editing={editing}
        />
      )}
    </React.Fragment>
  ) : (
    <Typography variant="h5">Loading</Typography>
  );
};

export default StripePaymentMethod;
