import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  makeStyles,
  Theme,
  Button,
} from '@material-ui/core';

import { TripSegment, TripWaypoint, TripWaypointRole } from 'src/.gen/graphql';
import AddressComponent from 'src/shared/components/AddressComponent';
import {
  getEstimatedArrivalTime,
  parseWaypoint,
} from 'src/shared/utils/addressUtils';
import { convertMinutesToFormattedTime } from 'src/shared/utils/conversions';
import EmptyData from 'src/shared/components/EmptyData';
import { BiMapPin } from 'react-icons/bi';
import { GoPrimitiveDot } from 'react-icons/go';
import theme from 'src/shared/theme';
import NextTripInfoItem from '../NextTrip/NextTripInfoItem';
import { ExtendedTripWaypoint } from './TripMapTile';

interface WaypointsResumeProps {
  pickupTime: Date;
  waypoints: ExtendedTripWaypoint[];
  pickupWaypoint: Partial<TripWaypoint>;
  dropoffWaypoint: ExtendedTripWaypoint;
  segments?: Partial<TripSegment>[];
  overrideDropoffDetails: boolean;
  hideTimeAndMiles?: boolean;
  summarizeStops?: boolean;
  isSavoyaView?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('lg')]: {
      marginTop: '1rem',
    },
    justifyContent: 'space-evenly',
  },
  scrollable: {
    maxHeight: 280,
    overflowY: 'scroll',
  },
  content: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  contentAddress: {
    maxWidth: '80%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: '100%',
    },
    flexDirection: 'row',
    display: 'flex',
  },
  contentTime: {
    marginBottom: -theme.spacing(4),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: 5,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const TripWaypointsResume: React.FC<WaypointsResumeProps> = ({
  pickupTime,
  waypoints,
  pickupWaypoint,
  dropoffWaypoint,
  segments,
  overrideDropoffDetails = false,
  hideTimeAndMiles = false,
  summarizeStops = false,
  isSavoyaView = false,
}) => {
  const classes = useStyles();
  const [showStops, setShowStops] = useState<boolean>(!summarizeStops);
  const [actualWaypoints, setActualWaypoints] = useState<
    ExtendedTripWaypoint[]
  >([]);
  const [stopWaypoints, setStopWaypoints] = useState<ExtendedTripWaypoint[]>(
    [],
  );
  const [dropoffWaypointAndStop, setDropoffWaypointAndStop] = useState<
    ExtendedTripWaypoint | undefined
  >(dropoffWaypoint);

  useEffect(() => {
    const actual: ExtendedTripWaypoint[] = [];
    const stops: ExtendedTripWaypoint[] = [];
    if (waypoints?.length > 0) {
      if (isSavoyaView) {
        // NOTE: if the view is Savoya, we override the dropoff waypoint
        // with the modified savoya dropoff waypoint (has extended data for the savoya view)
        setDropoffWaypointAndStop(waypoints[waypoints?.length - 2]);
      }
      waypoints.forEach((waypoint) => {
        if (waypoint.role !== TripWaypointRole.Garage) {
          if (showStops) {
            actual.push(waypoint);
          } else if (waypoint.role !== TripWaypointRole.Stop) {
            actual.push(waypoint);
          }
        }
        if (waypoint.role === TripWaypointRole.Stop) {
          stops.push(waypoint);
        }
      });
      Promise.all([actual, stops]);
      setActualWaypoints(actual);
      setStopWaypoints(stops);
    }
  }, [waypoints, showStops]);

  const numberOfStops = stopWaypoints.length;

  const getLabel = (role, order) => {
    if (TripWaypointRole.Stop === role) {
      return `${role} ${order - 1}`;
    }
    return role;
  };

  const getWaypoint = (
    waypoint: ExtendedTripWaypoint,
  ): ExtendedTripWaypoint => {
    switch (waypoint.role) {
      case TripWaypointRole.Pickup:
        return pickupWaypoint;
      case TripWaypointRole.Dropoff:
        return dropoffWaypointAndStop;
      default:
        return waypoint;
    }
  };

  const getActualSegments = () => {
    const garageIds = waypoints
      .filter(({ role }) => TripWaypointRole.Garage === role)
      .map(({ id }) => id);

    return segments.filter(
      ({ startWaypointId, endWaypointId }) =>
        !(
          garageIds.includes(startWaypointId) ||
          garageIds.includes(endWaypointId)
        ),
    );
  };

  if (!actualWaypoints?.length) {
    return (
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <EmptyData
          label="No current locations"
          height={150}
          path="/static/images/undraw_destinations_fpv7.svg"
        />
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction={isSavoyaView ? 'row' : 'column'}
      spacing={2}
      data-testid="trip_waypoints_resume"
      className={[classes.container, showStops ? classes.scrollable : ''].join(
        ' ',
      )}
      style={{ height: '100%' }}
    >
      {actualWaypoints.map((waypoint, index) => {
        const segment = getActualSegments().find(
          ({ startWaypointId }) => startWaypointId === waypoint.id,
        );
        const actualWaypoint = getWaypoint(waypoint);
        const endWaypoint = waypoints.find(
          ({ id }) => id === segment?.endWaypointId,
        );
        const address = parseWaypoint(actualWaypoint, overrideDropoffDetails);
        const isFirst = index === 0;
        const showStopsButton =
          numberOfStops > 0 && isFirst && isSavoyaView && !showStops;
        const gridWidth = showStops ? 12 : 5;

        return (
          <Fragment key={`${waypoint.id}-${index}`}>
            <Grid
              item
              xs={12}
              sm={isSavoyaView ? gridWidth : 12}
              md={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Box className={classes.content}>
                <Box className={classes.contentAddress}>
                  <div className={classes.iconContainer}>
                    <BiMapPin />
                  </div>
                  <NextTripInfoItem
                    label={getLabel(waypoint?.role, index + 1)}
                    value={<AddressComponent address={address} />}
                    travelTime={waypoint.estimatedDurationInMinutes}
                    note={waypoint.note}
                  />
                </Box>
                {segment && !hideTimeAndMiles ? (
                  <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    className={classes.contentTime}
                  >
                    <Typography color="textSecondary" variant="caption">
                      {convertMinutesToFormattedTime(
                        segment.durationInMinutes ??
                          segment.estimatedDurationInMinutes,
                      )}
                      {segment.distanceInMiles ||
                      segment.estimatedDistanceInMiles
                        ? `(${(
                            segment?.distanceInMiles ??
                            segment?.estimatedDistanceInMiles
                          ).toFixed(2)} mi)`
                        : null}
                    </Typography>
                    {!overrideDropoffDetails && (
                      <Typography color="textSecondary" variant="caption">
                        ETA:{' '}
                        {getEstimatedArrivalTime(
                          pickupTime,
                          pickupWaypoint,
                          endWaypoint,
                        )}
                      </Typography>
                    )}
                  </Box>
                ) : null}
              </Box>
            </Grid>
            {showStopsButton ? (
              <Grid
                item
                xs={12}
                sm={isSavoyaView ? 2 : 12}
                md={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Button
                  size="small"
                  color="default"
                  variant="outlined"
                  onClick={() => setShowStops(!showStops)}
                >
                  <GoPrimitiveDot color={theme.palette.primary.main} />
                  <span
                    style={{ fontWeight: 700, marginLeft: '10px' }}
                  >{`${numberOfStops} ${
                    numberOfStops > 1 ? 'Stops' : 'Stop'
                  }`}</span>
                </Button>
              </Grid>
            ) : null}
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default TripWaypointsResume;
