import React from 'react';
import * as Yup from 'yup';
import {
  Grid,
  TextField,
  DialogActions,
  Box,
  Button,
  FormControl,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { DatePicker } from '@material-ui/pickers';
import { format, parse } from 'date-fns';
import { SavoyaVehicleType } from 'src/rates/api/SavoyaRateQueries';
import { usCurrencyValidator } from 'src/rates/utils/currencyValidation';
import { SavoyaLocation } from 'src/trips/types/SavoyaTripsTypes';
import SavoyaLocationAutocomplete from './SavoyaLocationAutocomplete';

export interface SavoyaTransferRateInitialValues {
  id: number;
  vehicleType: SavoyaVehicleType;
  transferCost: string;
  point1: Partial<SavoyaLocation>;
  point2: Partial<SavoyaLocation>;
  cancellationHours: string;
  startDate: string;
  endDate: string;
}

interface FormProps {
  action: 'edit' | 'create';
  initialValues?: SavoyaTransferRateInitialValues;
  vehicleTypes: SavoyaVehicleType[];
  onSubmit: (values: SavoyaTransferRateInitialValues) => void;
  onCancel: () => void;
}

const defaultInitialValues: SavoyaTransferRateInitialValues = {
  id: null,
  vehicleType: null,
  transferCost: null,
  cancellationHours: null,
  point1: null,
  point2: null,
  startDate: null,
  endDate: null,
};

const useStyles = makeStyles(() => ({
  radioButtonsContainer: {
    marginTop: '10px',
    marginRight: '10px',
  },
  radioButton: {
    margin: '0',
    padding: '0',
  },
  MainInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  SmallMainInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '0.8rem',
  },
  smallRadioButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '10px 0px',
    padding: '0px 10px',
    width: '100%',
  },
  toggleOption: {
    // fontSize: '10px',
    padding: '0',
    margin: '0',
    fontSize: '0.8rem !important',
  },
}));

const SavoyaTransferRateBaseForm: React.FC<FormProps> = (props) => {
  const {
    initialValues = defaultInitialValues,
    vehicleTypes,
    action,
    onSubmit,
    onCancel,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { sm } = useBreakpoints();

  const onFromPointSelected = (selected: {
    label: string;
    value: SavoyaLocation;
  }) => {
    setFieldValue(`point1.displayName`, selected?.value?.displayName);
    setFieldValue(
      `point1.displayNameWithCountry`,
      selected?.value?.displayNameWithCountry,
    );
    setFieldValue(`point1.id`, `${selected?.value?.id}`);
    setFieldValue(`point1.serviceAreaId`, `${selected?.value?.serviceAreaId}`);
    setFieldValue(`point1.serviceAreaName`, selected?.value?.serviceAreaName);
    setFieldValue(`point1.timezone`, selected?.value?.timezone);
    setFieldValue(`point1.type`, selected?.value?.type);
  };
  const onToPointSelected = (selected: {
    label: string;
    value: SavoyaLocation;
  }) => {
    setFieldValue(`point2.displayName`, selected?.value?.displayName);
    setFieldValue(
      `point2.displayNameWithCountry`,
      selected?.value?.displayNameWithCountry,
    );
    setFieldValue(`point2.id`, `${selected?.value?.id}`);
    setFieldValue(`point2.serviceAreaId`, `${selected?.value?.serviceAreaId}`);
    setFieldValue(`point2.serviceAreaName`, selected?.value?.serviceAreaName);
    setFieldValue(`point2.timezone`, selected?.value?.timezone);
    setFieldValue(`point2.type`, selected?.value?.type);
  };

  const editing = action === 'edit';

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      vehicleType:
        initialValues?.vehicleType || vehicleTypes?.length
          ? vehicleTypes[0]
          : null,
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      vehicleType: Yup.object().shape({
        id: Yup.string()
          .required('Vehicle Type Required')
          .typeError('Vehicle Type Required'),
      }),
      point1: Yup.object().shape({
        displayName: Yup.string()
          .required('From Location Required')
          .typeError('From Location Required'),
      }),
      point2: Yup.object().shape({
        displayName: Yup.string()
          .required('To Location Required')
          .typeError('To Location Required'),
      }),
      transferCost: Yup.number()
        .required('Transfer Amount is Required')
        .typeError('Transfer Amount is Required'),
      cancellationHours: Yup.number()
        .moreThan(0, 'Must be greater than 0')
        .lessThan(48, 'Maximum Cancellation Hours: 48')
        .required('Cancellation Hours is required')
        .typeError('Cancellation Hours is required'),
      startDate: Yup.string().nullable(),
      endDate: Yup.string().nullable(),
    }),
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      try {
        /** Mapear todos lso values una vez finalizado */
        await onSubmit({ ...values });
        actions.setStatus({ success: true });
        actions.setSubmitting(false);
      } catch (err) {
        enqueueSnackbar(`Error while updating/creating`, {
          variant: 'error',
        });
      } finally {
        actions.setStatus({ success: false });
        actions.setSubmitting(false);
      }
    },
  });

  const {
    errors,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmitting,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} data-testid="savoya_transfer_rate_form">
        <Grid
          container
          item
          xs={12}
          direction="row"
          spacing={2}
          className={
            sm ? classes.SmallMainInputContainer : classes.MainInputContainer
          }
        >
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Grid item xs={12} md={12}>
                {vehicleTypes?.length ? (
                  <TextField
                    fullWidth
                    label="Select Vehicle Type"
                    name="vehicleType"
                    onChange={(e) => {
                      e.preventDefault();
                      const vehicleTypeSelected = vehicleTypes.find(
                        (el) => el.id?.toString() === e.target.value,
                      );
                      setFieldValue('vehicleType', vehicleTypeSelected);
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={values?.vehicleType?.id}
                    required
                    variant="outlined"
                    disabled={editing}
                  >
                    {vehicleTypes?.map((vehicleClass) => (
                      <option key={vehicleClass.id} value={vehicleClass.id}>
                        {vehicleClass.name}
                      </option>
                    ))}
                  </TextField>
                ) : null}
              </Grid>
            </FormControl>
          </Grid>
          <Grid container item direction="row" spacing={1}>
            <Grid item xs={6}>
              <TextField
                error={Boolean(touched.transferCost && errors.transferCost)}
                fullWidth
                helperText={touched.transferCost && errors.transferCost}
                label="Amount"
                name="transferCost"
                required
                type="number"
                onBlur={handleBlur}
                onChange={(e) => {
                  if (usCurrencyValidator(e.target.value)) {
                    return handleChange(e);
                  }
                  return null;
                }}
                value={values.transferCost}
                variant="outlined"
                inputProps={{
                  'data-testid': 'transfer_amount_field',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(
                  touched.cancellationHours && errors.cancellationHours,
                )}
                fullWidth
                helperText={
                  touched.cancellationHours && errors.cancellationHours
                }
                label="Cancellation Hours"
                name="cancellationHours"
                type="number"
                required
                onBlur={(e) => {
                  e.target.value = Math.round(
                    parseFloat(e.target.value),
                  ).toFixed(0);
                  setFieldValue('cancellationHours', e.target.value);
                  return handleBlur(e);
                }}
                onChange={handleChange}
                value={values.cancellationHours}
                variant="outlined"
                inputProps={{
                  'data-testid': 'cancellation_hours_field',
                }}
              />
            </Grid>
          </Grid>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={12}>
              <SavoyaLocationAutocomplete
                label="From"
                fieldName="point1"
                setFieldTouched={setFieldTouched}
                type={initialValues?.point1?.type || 'city'}
                onSelected={onFromPointSelected}
                disabled={editing}
                value={{
                  label: values?.point1?.displayName,
                  value: values?.point1,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={12}>
              <SavoyaLocationAutocomplete
                label="To"
                fieldName="point2"
                setFieldTouched={setFieldTouched}
                type={initialValues?.point2?.type || 'city'}
                onSelected={onToPointSelected}
                disabled={editing}
                value={{
                  label: values?.point2?.displayName,
                  value: values?.point2,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item direction="row" spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                clearable
                label="Start Date"
                value={
                  values.startDate
                    ? parse(
                        values.startDate,
                        'yyyy-MM-dd',
                        new Date(values.startDate),
                      )
                    : null
                }
                onChange={(newValue) => {
                  const parsedDate = newValue
                    ? format(newValue, 'yyyy-MM-dd')
                    : null;
                  setFieldValue('startDate', parsedDate);
                }}
                required
                inputVariant="outlined"
                fullWidth
                format="MM/dd/yyyy"
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                clearable
                label="End Date"
                value={
                  values.endDate
                    ? parse(
                        values.endDate,
                        'yyyy-MM-dd',
                        new Date(values.endDate),
                      )
                    : null
                }
                onChange={(newValue) => {
                  const parsedDate = newValue
                    ? format(newValue, 'yyyy-MM-dd')
                    : null;
                  setFieldValue('endDate', parsedDate);
                }}
                inputVariant="outlined"
                fullWidth
                format="MM/dd/yyyy"
              />
            </Grid>
          </Grid>
        </Grid>

        <Box mt={3}>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
              data-testid="client_cancel_button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting || !formik.isValid}
              data-testid="client_save_button"
            >
              {isSubmitting ? 'Loading...' : 'Save'}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </FormikProvider>
  );
};

export default SavoyaTransferRateBaseForm;
