import React from 'react';
import { useRecoilValue } from 'recoil';
import { TierType } from 'src/.gen/graphql';
import { subscriptionPlanAtom } from 'src/authentication/atoms/AuthState';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PaidFeatureEntities } from 'src/shared/types/subscription';
import PermissionContext from './PermissionContext';

const limitations = {
  [TierType.Free]: {
    [PaidFeatureEntities.USERS]: 1,
    [PaidFeatureEntities.VEHICLES]: 1,
  },
  [TierType.Premium]: {
    [PaidFeatureEntities.USERS]: 5,
    [PaidFeatureEntities.VEHICLES]: 5,
  },
  [TierType.Platinum]: {
    [PaidFeatureEntities.USERS]: 20,
    [PaidFeatureEntities.VEHICLES]: 20,
  },
};

const PermissionProvider: React.FunctionComponent = ({ children }) => {
  const { subscriptions: subscriptionsEnabled } = useFlags();
  const subscriptionPlan = useRecoilValue(subscriptionPlanAtom);
  const currentTier = subscriptionPlan?.currentTier ?? TierType.Free;

  const isTierCompliant = (tierType: TierType) => {
    // Disable when the account is in trial period or the feature flag is not set
    if (!subscriptionsEnabled) {
      return true;
    }
    switch (tierType) {
      case TierType.Platinum:
        return currentTier === TierType.Platinum;
      case TierType.Premium:
        return [TierType.Platinum, TierType.Premium].includes(currentTier);
      default:
        return true;
    }
  };

  const isLimitCompliant = (
    tierType: TierType,
    entity: PaidFeatureEntities,
    entityCount: number,
  ) => {
    // Disable when the feature flag is not set
    if (!subscriptionsEnabled) {
      return true;
    }
    return limitations[tierType][entity] > entityCount;
  };

  return (
    <PermissionContext.Provider
      value={{
        isTierCompliant,
        isLimitCompliant,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
