import React, { FC } from 'react';
import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import {
  DriverAvailability,
  GenericDriver,
  useGetAvailableDriversQuery,
} from 'src/.gen/graphql';

type DriversDropdownProps = {
  tripId: string;
  driver: Partial<GenericDriver> | undefined;
  setSelectedDriver: (driver: Partial<GenericDriver>) => void;
};

const isDriverAvailable = (driver): boolean =>
  driver.availability === DriverAvailability.Available;

const DriversDropdown: FC<DriversDropdownProps> = ({
  driver,
  setSelectedDriver,
  tripId,
}) => {
  const { data: drivers, loading } = useGetAvailableDriversQuery({
    variables: {
      tripId,
    },
  });

  const availableDriversByClass = drivers?.trips?.availableDrivers?.internal;

  React.useEffect(() => {
    const driversAvailable = (
      drivers?.trips?.availableDrivers?.internal || []
    ).filter((d) => isDriverAvailable(d));
    if (driversAvailable.length > 0) {
      const driverDefault = driversAvailable[0];
      if (driversAvailable.length === 1) {
        setSelectedDriver({
          id: driverDefault?.driver?.id,
          name: driverDefault?.driver?.name,
        });
      }
    }
  }, [drivers]);

  return (
    <FormControl fullWidth>
      {availableDriversByClass?.length ? (
        <TextField
          fullWidth
          label="Assigned Chauffeur"
          name="assignedDriver"
          onChange={(e) => {
            const driverSelected = availableDriversByClass?.find(
              (el) => el.driver.id === e.target.value,
            );

            if (e.target.value) {
              setSelectedDriver({
                id: driverSelected?.driver?.id,
                name: driverSelected?.driver?.name,
              });
            }
          }}
          select
          SelectProps={{ native: true }}
          value={driver?.id || 'undefined'}
          required
          variant="outlined"
        >
          {!driver && (
            <option key="undefined" value="undefined">
              {' '}
            </option>
          )}
          {availableDriversByClass.map((driverOption) => {
            return (
              <option
                key={driverOption.driver.id}
                value={driverOption.driver.id}
                disabled={!isDriverAvailable(driverOption)}
              >
                {driverOption?.driver?.name || ''}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {`${!isDriverAvailable(driverOption) ? '* Booked' : ''}`}
              </option>
            );
          })}
        </TextField>
      ) : null}
      {!loading && !availableDriversByClass?.length ? (
        <TextField
          variant="outlined"
          color="primary"
          value="No Drivers Available"
          disabled
        />
      ) : null}
      {loading ? (
        <TextField
          variant="outlined"
          color="primary"
          disabled
          InputProps={{
            startAdornment: <CircularProgress size="1.5rem" />,
          }}
        />
      ) : null}
    </FormControl>
  );
};

export default DriversDropdown;
