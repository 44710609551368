import React, { FC } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import {
  useUpdateTripMutation,
  useExecuteAcceptNetworkOfferMutation,
  useExecuteConfirmNetworkRequestMutation,
  TripStatus,
  Trip,
  ProviderType,
  VendorType,
} from 'src/.gen/graphql';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { vendorAtom } from 'src/authentication/atoms/AuthState';
import { useSearchParams } from 'react-router-dom';
import { fetchVendorReservationAssignment } from '../api/SavoyaTripQueries';
import { getPreferredDriverWarning } from '../utils/preferredDriverWarning';

const useStyles = makeStyles((theme: Theme) => ({
  acceptBtn: {
    backgroundColor: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 4,
    color: theme.palette.primary.light,
  },
}));

type AcceptOrClaimSharedTripBtnProps = {
  trip: Partial<Trip>;
  action?: 'accept' | 'claim';
};

const AcceptOrClaimShareTripDialog: FC<AcceptOrClaimSharedTripBtnProps> = ({
  trip,
  action,
}) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const vendor = useRecoilValue(vendorAtom);
  const savoyaProvider = vendor?.vendorProviders?.find(
    (provider) => provider?.providerType === ProviderType.Savoya,
  );
  const navigate = useNavigate();

  const {
    isFetching,
    isLoading,
    data: savoyaReservationAssignment,
  } = useQuery({
    queryKey: [
      'savoyaReservationAssignmentForPreferredDriverWarning',
      { reservationId: trip?.providerExternalId || trip?.tripNumber },
    ],
    queryFn: () =>
      fetchVendorReservationAssignment(
        trip?.providerExternalId || trip?.tripNumber?.toString(),
      ),
    enabled: !!(trip?.tripNumber || trip?.providerExternalId),
    refetchOnWindowFocus: false,
  });

  const savoyaReservationAssignmentLoading = isFetching || isLoading;

  const { enqueueSnackbar } = useSnackbar();
  const [updateReferenceNumber, { loading: loadingReferenceNumber }] =
    useUpdateTripMutation();
  const [acceptNetworkOffer, { loading: loadingAccept }] =
    useExecuteAcceptNetworkOfferMutation();
  const [confirmNetworkRequest, { loading: loadingConfirm }] =
    useExecuteConfirmNetworkRequestMutation();
  const loading = loadingAccept || loadingConfirm || loadingReferenceNumber;
  const [referenceNumber, setReferenceNumber] = React.useState<string>(
    trip.referenceNumber || '',
  );

  const handleReferenceNumber = async (reference: string) => {
    try {
      const {
        data: { updateTrip: result },
      } = await updateReferenceNumber({
        variables: {
          trip: {
            id: trip.id,
            referenceNumber: reference,
            accountId: trip.accountId,
          },
        },
      });

      if (!result?.success) {
        enqueueSnackbar(`Error while saving the Confirmation Number`, {
          variant: 'error',
        });
      }
    } catch (err) {
      enqueueSnackbar(`Error while saving the Confirmation Number`, {
        variant: 'error',
      });
    }
  };

  const handleAcceptSharedTrip = async () => {
    try {
      const {
        data: { executeAcceptNetworkOffer: result },
      } = await acceptNetworkOffer({
        variables: {
          command: {
            tripId: trip.id,
            claim: action === 'claim',
            driverId: trip.driverId || trip?.driver?.id,
            vehicleId: trip.vehicleId || trip?.vehicle?.id,
          },
        },
      });

      if (!result?.succeeded) {
        enqueueSnackbar(result?.errors[0], {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Your invitation has been accepted correctly', {
          variant: 'success',
        });
        searchParams.delete('available');
        setSearchParams(searchParams);
        if (window.location.href?.includes(`/trips/${result?.trip?.id}`)) {
          window.location.reload();
        } else {
          navigate(`/trips/${result?.trip?.id}`);
        }
      }
    } catch (err) {
      enqueueSnackbar(`Shared Trip error while accepting`, {
        variant: 'error',
      });
    }
  };

  const handleConfirmSharedTripRequest = async () => {
    try {
      const {
        data: { executeConfirmNetworkRequest: result },
      } = await confirmNetworkRequest({
        variables: {
          command: {
            tripId: trip.id,
            driverId: trip.driverId || trip?.driver?.id,
            vehicleId: trip.vehicleId || trip?.vehicle?.id,
            claim: action === 'claim',
          },
        },
      });
      if (!result?.succeeded) {
        enqueueSnackbar(result?.errors[0], {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Your invitation has been accepted correctly', {
          variant: 'success',
        });
        searchParams.delete('available');
        setSearchParams(searchParams);
        if (window.location.href?.includes(`/trips/${result?.trip?.id}`)) {
          window.location.reload();
        } else {
          navigate(`/trips/${result?.trip?.id}`);
        }
      }
    } catch (err) {
      enqueueSnackbar(`Shared Trip error while accepting`, {
        variant: 'error',
      });
    }
  };

  function handleConfirmation(): void {
    switch (trip.status) {
      case TripStatus.PendingNetworkConfirmation:
        handleConfirmSharedTripRequest();
        break;
      case TripStatus.OfferedToNetwork:
        handleAcceptSharedTrip();
        break;
      default:
        enqueueSnackbar(`Shared Trip error while accepting`, {
          variant: 'error',
        });
        break;
    }
  }

  function onNo(): void {
    handleConfirmation();
  }

  async function onYes(referenceNumber: string): Promise<void> {
    if (!referenceNumber) {
      enqueueSnackbar('Reference number is required', { variant: 'error' });
      return;
    }
    await handleReferenceNumber(referenceNumber);
    await handleConfirmation();
  }

  return savoyaReservationAssignmentLoading ? (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle1" gutterBottom>
        {savoyaProvider?.vendorType === VendorType.Garage
          ? 'Would you like to save a Confirmation number to this trip'
          : 'Please add a Confirmation Number'}
      </Typography>
      <TextField
        key="referenceNumber"
        value={referenceNumber}
        onChange={(event) => {
          setReferenceNumber(event.target.value);
        }}
        fullWidth
        label="Confirmation Number"
        name="referenceNumber"
        variant="outlined"
        size="small"
      />
      {savoyaReservationAssignment?.preferredDrivers?.length ? (
        <Box mt={1}>
          <Alert severity="error">
            <Typography color="error">
              {getPreferredDriverWarning(
                savoyaReservationAssignment?.preferredDrivers,
              )}
            </Typography>
          </Alert>
        </Box>
      ) : null}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        paddingTop="2rem"
        paddingBottom="1rem"
      >
        {savoyaProvider?.vendorType === VendorType.Garage ? (
          <Button
            onClick={() => onNo()}
            size="small"
            variant="outlined"
            color="primary"
            disabled={loading}
          >
            {loading ? 'Loading' : 'No, proceed without it'}
          </Button>
        ) : null}
        <Button
          onClick={() => onYes(referenceNumber)}
          size="small"
          variant="contained"
          color="primary"
          className={classes.acceptBtn}
          disabled={loading || !referenceNumber}
        >
          {loading ? 'Loading' : 'Save, and proceed'}
        </Button>
      </Box>
    </Box>
  );
};

export default AcceptOrClaimShareTripDialog;
