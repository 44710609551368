import { format, utcToZonedTime } from 'date-fns-tz';

export const tripDateTimeFormat = (
  pickupTime: string,
  timeZone: string,
  formatPattern = "eeee MMMM dd, yyyy 'at' hh:mm a (zzz)",
): string => {
  if (!pickupTime) {
    return '---';
  }

  if (!timeZone) {
    return format(new Date(pickupTime), formatPattern);
  }

  return format(utcToZonedTime(pickupTime, timeZone), formatPattern, {
    timeZone,
  });
};
