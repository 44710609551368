/* eslint-disable @typescript-eslint/no-explicit-any */
export interface GeneralTableValues {
  pagination: {
    currentPage: number;
    rowsPerPage: number;
  };
  offset: number;
  selectedItems?: string[];
  filter: Record<string, string | number | any>;
}

export const generalTableValues: GeneralTableValues = {
  pagination: {
    currentPage: 0,
    rowsPerPage: 25,
  },
  offset: 0,
  selectedItems: [],
  filter: {},
};
