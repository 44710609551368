import React from 'react';
import { UserRecord } from 'src/.gen/graphql';

type MobileAuthContextType = {
  isMobileAuthenticated: boolean;
  authenticatedFromMobile: boolean;
  user: UserRecord | undefined;
  clearMobileTokens: () => void;
};

// Default behaviour for the Mobile Auth Provider Context
// The user mobile session will not be granted unless a provider says otherwise
const defaultBehaviour: MobileAuthContextType = {
  isMobileAuthenticated: false,
  authenticatedFromMobile: false,
  user: undefined,
  clearMobileTokens: () => {
    // Clear the tokens
  },
};

// Create the Mobile Auth Context
const MobileAuthContext =
  React.createContext<MobileAuthContextType>(defaultBehaviour);

export default MobileAuthContext;
