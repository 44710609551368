import React, { Fragment, useState } from 'react';
import _snakeCase from 'lodash/snakeCase';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
  Box,
} from '@material-ui/core';
import {
  SavoyaSharedTripStatus,
  TripCommandMeta,
  TripEventType,
  TripStatus,
  TripWaypointRole,
  useGetAvailableCommandsByTripIdLazyQuery,
} from 'src/.gen/graphql';
import {
  getDialogContentFromTripCommand,
  tripCommandDialogState,
} from 'src/trips/atoms/tripCommandDialogAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  filterTripCommands,
  parseNameCommand,
} from 'src/shared/utils/tripUtils';
import { userAtom } from 'src/authentication/atoms/AuthState';
import DiscardTrip from './TripForm/DiscardTrip';

interface TripActionsMenuPropsWithTripId {
  tripId: string;
  tripStatus: TripStatus;
  tripNumber: number;
  type: 'WithTripid';
  isSavoya: boolean;
  isShared: boolean;
  savoyaTripId: string;
  savoyaSharedTripStatus: SavoyaSharedTripStatus;
  savoyaLostRevenueText?: string;
}

interface TripActionsMenuPropsFetchCommands {
  availableCommands: Array<TripCommandMeta>;
  isNextDropOff: boolean;
  tripId: string;
  tripStatus: TripStatus;
  tripNumber: number;
  type: 'WithCommands';
  isSavoya: boolean;
  savoyaTripId: string;
  isShared: boolean;
  savoyaSharedTripStatus: SavoyaSharedTripStatus;
  savoyaLostRevenueText?: string;
}

const showLostRevenueCommands = [
  'DeclineNetworkOffer',
  'DeclineNetworkConfirmationRequest',
];

type TripActionsMenuProps =
  | TripActionsMenuPropsFetchCommands
  | TripActionsMenuPropsWithTripId;

const TripActionsMenu: React.FC<TripActionsMenuProps> = (props) => {
  const {
    tripId,
    tripStatus,
    isSavoya,
    isShared,
    savoyaTripId,
    savoyaSharedTripStatus,
    savoyaLostRevenueText,
  } = props;
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [tripCommands, setTripCommands] = useState<
    Array<TripCommandMeta> | undefined
  >(undefined);
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);

  const [getAvailableCommands, { data, error, loading }] =
    useGetAvailableCommandsByTripIdLazyQuery({ fetchPolicy: 'no-cache' });

  const [{ succeeded }, setTripCommandDialog] = useRecoilState(
    tripCommandDialogState,
  );
  const iOwnThisTrip = user?.accountId === data?.trips?.byId?.accountId;

  React.useEffect(() => {
    if (props.type === 'WithCommands') {
      let filteredCommands = props?.availableCommands?.filter((command) =>
        filterTripCommands(
          tripStatus,
          savoyaSharedTripStatus,
          command,
          iOwnThisTrip,
          isShared,
          !!savoyaTripId,
        ),
      );
      if (props.isNextDropOff || isSavoya) {
        filteredCommands = filteredCommands.filter(
          (command) => command.events[0] !== TripEventType.ArriveStop,
        );
      } else {
        filteredCommands = filteredCommands.filter(
          (command) => command.events[0] !== TripEventType.ArriveDropoff,
        );
      }
      if (tripStatus === TripStatus.Quoted) {
        filteredCommands = filteredCommands.filter(
          (command) =>
            !['ExpireQuote', 'CancelTrip'].includes(command.typeName),
        );
      }
      setTripCommands(filteredCommands);
    } else if (props.type === 'WithTripid') {
      getAvailableCommands({ variables: { tripId: props.tripId } });
    }
  }, [tripId, succeeded, props]);

  React.useEffect(() => {
    if (data?.trips?.byId) {
      const {
        currentOrNextWaypoint,
        availableCommands,
        sharedAvailableCommands,
      } = data.trips.byId;
      const isNextDropOff =
        currentOrNextWaypoint?.role === TripWaypointRole.Dropoff;
      let filteredCommands = (
        iOwnThisTrip ? availableCommands : sharedAvailableCommands
      ).filter((command) =>
        filterTripCommands(
          tripStatus,
          savoyaSharedTripStatus,
          command,
          iOwnThisTrip,
          isShared,
          !!savoyaTripId,
        ),
      );
      if (isNextDropOff || isSavoya) {
        filteredCommands = filteredCommands.filter(
          (command) => command.events[0] !== TripEventType.ArriveStop,
        );
      } else {
        filteredCommands = filteredCommands.filter(
          (command) => command.events[0] !== TripEventType.ArriveDropoff,
        );
      }
      if (tripStatus === TripStatus.Quoted) {
        filteredCommands = filteredCommands.filter(
          (command) =>
            !['ExpireQuote', 'CancelTrip'].includes(command.typeName),
        );
      }
      setTripCommands(filteredCommands);
    }
  }, [data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getEditActionText = () => {
    switch (tripStatus) {
      case TripStatus.Quoted:
        return 'Edit Quote';
      default:
        return 'Edit Trip';
    }
  };

  const showTripActions =
    iOwnThisTrip || tripStatus === TripStatus.Draft || tripCommands?.length;

  return showTripActions ? (
    <Fragment>
      <Button
        data-testid="trip_actions_menu_button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={Boolean(error || loading)}
        style={{ minWidth: 0 }}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        data-testid="trip_actions_menu"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(tripStatus === TripStatus.AwaitingAssignment ||
          tripStatus === TripStatus.Quoted) &&
        iOwnThisTrip ? (
          <Tooltip title="Edit this trip">
            <MenuItem
              data-testid="edit_trip_button"
              onClick={() =>
                navigate({
                  pathname: `/trips/edit/${tripId}`,
                })
              }
            >
              {getEditActionText()}
            </MenuItem>
          </Tooltip>
        ) : null}
        {tripStatus === TripStatus.Draft && (
          <Box>
            <DiscardTrip
              tripId={tripId}
              tripStatus={tripStatus}
              customButton={MenuItem}
            />
          </Box>
        )}
        {Array.isArray(tripCommands) &&
          tripCommands?.map((tripCommand, i) => (
            <Tooltip
              key={tripCommand.typeName}
              title={tripCommand.description}
              aria-label={tripCommand.description}
              placement={i !== 1 ? 'top' : 'bottom'}
              enterDelay={600}
              leaveDelay={200}
            >
              <MenuItem
                data-testid={`${_snakeCase(
                  tripCommand.typeName,
                )}_command_button`}
                onClick={() => {
                  setTripCommandDialog((tripCommandDialog) => ({
                    ...tripCommandDialog,
                    isOpen: true,
                    tripId,
                    isSavoyaTrip: isSavoya,
                    savoyaTripId:
                      savoyaTripId || data?.trips?.byId?.providerExternalId,
                    command: tripCommand,
                    dialogContent: getDialogContentFromTripCommand(
                      tripCommand,
                      isSavoya,
                      showLostRevenueCommands.includes(tripCommand?.typeName)
                        ? savoyaLostRevenueText
                        : null,
                    ),
                    succeeded: false,
                    customCallback: () => {
                      navigate(`/trips/${tripId}`);
                    },
                  }));
                  handleClose();
                }}
                disabled={tripCommand.disabled}
              >
                {parseNameCommand(tripCommand, tripStatus, isSavoya)}
              </MenuItem>
            </Tooltip>
          ))}
        {tripStatus !== TripStatus.Draft && iOwnThisTrip && (
          <Box>
            <Divider />
            <Tooltip title="Book a return trip">
              <MenuItem
                data-testid="book_return_trip_button"
                onClick={() =>
                  navigate({
                    pathname: `/trips/new/${tripId}`,
                    search: `?tripType=returnTrip`,
                  })
                }
              >
                Book Return Trip
              </MenuItem>
            </Tooltip>
            <Tooltip title="Book a similar trip">
              <MenuItem
                data-testid="book_similar_trip_button"
                onClick={() =>
                  navigate(`/trips/new/${tripId}?tripType=duplicate`, {
                    replace: true,
                  })
                }
              >
                Book Similar Trip
              </MenuItem>
            </Tooltip>
          </Box>
        )}
      </Menu>
    </Fragment>
  ) : null;
};

export default TripActionsMenu;
