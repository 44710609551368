import React from 'react';
import {
  Grid,
  Box,
  DialogActions,
  Button,
  TextField,
  useTheme,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { RateCalculation, VehicleClass } from 'src/.gen/graphql';
import VehicleClassDropdown from 'src/tripsNewClients/components/TripAdditionalInfo/TripAssignment/components/VehicleClassDropdown';
import { toNumber } from 'lodash';

interface FormProps {
  preferredVehicleClass: Partial<VehicleClass>;
  onSubmit: (values: Partial<VehicleClass>, actions) => void;
  onCancel: () => void;
}

const UseDifferentRateForm: React.FC<FormProps> = (props) => {
  const { preferredVehicleClass, onSubmit, onCancel } = props;
  const theme = useTheme();
  const [methodMenu, setMethodMenu] = React.useState(RateCalculation.Flat);

  const [vehicleClass, setVehicleClass] = React.useState<
    Partial<VehicleClass> | undefined
  >(preferredVehicleClass);

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          vehicleClass,
          preferredClassId: preferredVehicleClass.id,
          method: RateCalculation.Flat,
          flatRate: null,
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => {
          return (
            <Form>
              <Grid container direction="column" spacing={3}>
                <Grid
                  container
                  item
                  spacing={3}
                  xs={12}
                  justify="center"
                  style={{ margin: 'auto' }}
                >
                  <Button
                    variant={
                      methodMenu === RateCalculation.Flat
                        ? 'contained'
                        : 'outlined'
                    }
                    color="primary"
                    onClick={() => {
                      setFieldValue('method', RateCalculation.Flat);
                      setMethodMenu(RateCalculation.Flat);
                    }}
                    style={{
                      flex: 1,
                      color:
                        methodMenu === RateCalculation.Flat
                          ? theme.palette.textLight.main
                          : theme.palette.primary.main,
                      borderRadius: 0,
                      fontSize: '0.8rem',
                    }}
                  >
                    Flat Rate
                  </Button>
                  <Button
                    variant={
                      methodMenu !== RateCalculation.Flat
                        ? 'contained'
                        : 'outlined'
                    }
                    color="primary"
                    onClick={() => {
                      setFieldValue('method', RateCalculation.PerHour);
                      setMethodMenu(RateCalculation.PerHour);
                    }}
                    style={{
                      flex: 1,
                      color:
                        methodMenu !== RateCalculation.Flat
                          ? theme.palette.textLight.main
                          : theme.palette.primary.main,
                      borderRadius: 0,
                      fontSize: '0.8rem',
                    }}
                  >
                    Use Different Vehicle Class Rate
                  </Button>
                </Grid>
                <Grid item>
                  {methodMenu === RateCalculation.PerHour ? (
                    <Grid item xs={12}>
                      <VehicleClassDropdown
                        selectedClass={vehicleClass}
                        setSelectedClass={(vehicleClass) => {
                          setVehicleClass(vehicleClass);
                          setFieldValue('preferredClassId', vehicleClass?.id);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Field
                        id="flatRate"
                        component={TextField}
                        fullWidth
                        required
                        label="Flat Rate"
                        name="flatRate"
                        variant="outlined"
                        onChange={(event) => {
                          const flatRate =
                            event.target.value && toNumber(event.target.value);
                          if (
                            typeof flatRate === 'number' &&
                            !Number.isNaN(flatRate)
                          ) {
                            setFieldValue('flatRate', flatRate);
                          } else {
                            setFieldValue('flatRate', null);
                          }
                        }}
                      />
                    </Grid>
                  )}
                  <Box mt={3}>
                    <DialogActions>
                      <Button variant="outlined" onClick={onCancel}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Loading...' : 'Save'}
                      </Button>
                    </DialogActions>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default UseDifferentRateForm;
