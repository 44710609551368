import { Grid, Box, Typography, GridSize, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import _snakeCase from 'lodash/snakeCase';

interface TripPassengerCardDataItemProps {
  label: string;
  value: ReactNode;
  loading?: boolean;
  xs?: boolean | GridSize;
}

const TripPassengerCardDataItem: React.FC<TripPassengerCardDataItemProps> = ({
  label,
  value,
  loading = false,
  xs = 12,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      xs={xs}
      md={6}
      direction="column"
      data-testid={`trip_passenger_card_${_snakeCase(label)}_item`}
    >
      <Grid item>
        <Box>
          <Typography variant="subtitle2">
            {loading ? <Skeleton /> : `${label}:`}
          </Typography>
        </Box>
      </Grid>
      <Grid item className={classes.containerDescripton}>
        <Box>
          {loading && (
            <Typography>
              <Skeleton />
            </Typography>
          )}
          {typeof value === 'string' && (
            <Typography variant="body2" className={classes.oneLine}>
              {value}
            </Typography>
          )}
          {typeof value !== 'string' && value}
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  containerDescripton: {
    width: '100%',
  },
  oneLine: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
});

export default TripPassengerCardDataItem;
