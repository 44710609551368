import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useDefaultRates } from 'src/rates/hooks/useDefaultRates';
import TransferRates from '../../TransferRates/TransferRates';
import ComplimentaryWaitTime from '../../ComplimentaryWaitTime/ComplimentaryWaitTime';
import AdditionalWaitTime from '../../AdditionalWaitTime/AdditionalWaitTime';

const TransferRatesTab: FC = () => {
  const {
    getPolicies,
    refetchDefaultRates,
    revertToDynamicForExcessWaitTimeFlag,
    rateSetId,
  } = useDefaultRates();

  return (
    <React.Fragment>
      <Grid item data-testid="transfer_rates_container">
        <TransferRates />
      </Grid>
      <Grid item data-testid="complementary_wait_time_container">
        <ComplimentaryWaitTime
          getPolicies={getPolicies}
          refreshData={refetchDefaultRates}
        />
      </Grid>
      <Grid item data-testid="additional_wait_time_container">
        <AdditionalWaitTime
          refreshData={refetchDefaultRates}
          rateId={rateSetId}
          revertToDynamicForExcessWaitTimeFlag={
            revertToDynamicForExcessWaitTimeFlag
          }
        />
      </Grid>
    </React.Fragment>
  );
};

export default TransferRatesTab;
