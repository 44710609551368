import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { savoyaRatesDialogsState } from 'src/rates/atoms/SavoyaRateFormState';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  SavoyaVehicleType,
  deleteTransferCost,
  fetchTransferRates,
  fetchVehicleTypes,
} from 'src/rates/api/SavoyaRateQueries';
import Dinero from 'dinero.js';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { useSnackbar } from 'notistack';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { SavoyaLocation } from 'src/trips/types/SavoyaTripsTypes';
import SavoyaTransferRatesTable from './SavoyaTransferRatesTable/SavoyaTransferRatesTable';
import AddSavoyaTransferRateDialog from './SavoyaTransferRateDialog/AddSavoyaTransferRateDialog';
import EditSavoyaTransferRateDialog from './SavoyaTransferRateDialog/EditSavoyaTransferRateDialog';
import { SavoyaTransferRateInitialValues } from './SavoyaTransferRateBaseForm';
import SavoyaLocationAutocomplete from './SavoyaLocationAutocomplete';

const SavoyaTransferRates: FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { sm } = useBreakpoints();
  const [pageSize, setPageSize] = useState(5);
  const [toLocation, setTolocation] = useState<Partial<SavoyaLocation>>();
  const [fromLocation, setFromLocaiton] =
    useState<Partial<SavoyaLocation>>(null);
  const [vehicleType, setVehicleType] =
    useState<Partial<SavoyaVehicleType>>(null);
  const {
    isLoading: transferRatesLoading,
    data: transferRates,
    isPreviousData,
  } = useQuery({
    queryKey: [
      'transferRates',
      { page, pageSize, fromLocation, toLocation, vehicleType },
    ],
    keepPreviousData: true,
    queryFn: () =>
      fetchTransferRates(page, pageSize, fromLocation, toLocation, vehicleType),
  });

  const { isLoading: vehicleTypesLoading, data: vehicleTypes } = useQuery({
    queryKey: ['vehcileTypes'],
    queryFn: fetchVehicleTypes,
  });

  const setDialogState = useSetRecoilState(savoyaRatesDialogsState);
  const [editingInfo, setEditingInfo] =
    useState<SavoyaTransferRateInitialValues | null>(null);

  const queryClient = useQueryClient();
  const deleteSavoyaTransferRate = useMutation({
    mutationFn: deleteTransferCost,
    onSuccess: () => {
      queryClient.invalidateQueries(['transferRates']);
      enqueueSnackbar('Transfer Rate Removed', { variant: 'success' });
    },
    onError: () =>
      enqueueSnackbar('Error Removing Transfer Rate', { variant: 'error' }),
  });
  const onDelete = async (id: number) => {
    await deleteSavoyaTransferRate.mutateAsync({ id });
  };

  const editableFiledsGridsSize = canEdit ? 3 : 4;

  return (
    <React.Fragment>
      <Grid container xs={12} spacing={2}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          alignContent="center"
          alignItems="center"
        >
          {canEdit ? (
            <Grid item xs={sm ? 12 : 2} alignItems="center">
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() =>
                  setDialogState((prev) => ({
                    ...prev,
                    openCreateTransferRateDialog: true,
                  }))
                }
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusCircleIcon />
                  </SvgIcon>
                }
                style={{ height: 55 }}
              >
                Transfer Rate
              </Button>
            </Grid>
          ) : null}
          <Grid container item xs={sm ? 12 : 9} spacing={2}>
            <Grid item xs={sm ? 12 : editableFiledsGridsSize}>
              <SavoyaLocationAutocomplete
                label="From"
                fieldName="point1"
                setFieldTouched={() => null}
                type="city"
                onSelected={(location) => {
                  setFromLocaiton(location?.value);
                }}
                disabled={false}
                value={
                  fromLocation
                    ? { value: fromLocation, label: fromLocation?.displayName }
                    : null
                }
              />
            </Grid>
            <Grid item xs={sm ? 12 : editableFiledsGridsSize}>
              <SavoyaLocationAutocomplete
                label="To"
                fieldName="point2"
                setFieldTouched={() => null}
                type="city"
                onSelected={(location) => {
                  setTolocation(location?.value);
                }}
                disabled={false}
                value={
                  toLocation
                    ? { value: toLocation, label: toLocation?.displayName }
                    : null
                }
              />
            </Grid>
            <Grid item xs={sm ? 12 : editableFiledsGridsSize}>
              {vehicleTypes?.length ? (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Vehicle Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vehicleType?.id}
                    label="Vehicle Type"
                    onChange={(e) => {
                      const selectedVehicleType = vehicleTypes?.find(
                        (vehicleType) => vehicleType.id === e?.target?.value,
                      );
                      setVehicleType(selectedVehicleType);
                    }}
                  >
                    <MenuItem value={null}>
                      <em>Clear Selection</em>
                    </MenuItem>
                    {vehicleTypes?.map((vehicleClass) => (
                      <MenuItem value={vehicleClass.id}>
                        {vehicleClass.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} data-testid="savoya_transfer_rates_list">
          <SavoyaTransferRatesTable
            canEdit={canEdit}
            onEdit={(initialRate) => {
              setEditingInfo({
                id: initialRate?.id,
                point1: { displayName: initialRate?.point1Name },
                point2: { displayName: initialRate?.point2Name },
                vehicleType: initialRate?.vehicleType,
                endDate: initialRate?.endDate,
                startDate: initialRate?.startDate,
                transferCost: Dinero({
                  amount: parseInt(initialRate?.transferCost) || 0,
                  currency: 'USD',
                })
                  .toUnit()
                  .toFixed(2),
                cancellationHours: initialRate?.cancellationHours,
              });
              setDialogState((prev) => ({
                ...prev,
                openEditTransferRateDialog: true,
              }));
            }}
            loading={
              transferRatesLoading || isPreviousData || vehicleTypesLoading
            }
            onDelete={onDelete}
            transferRates={transferRates}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        </Grid>
      </Grid>
      <AddSavoyaTransferRateDialog
        vehicleTypes={vehicleTypes}
        page={page}
        pageSize={pageSize}
        fromLocation={fromLocation}
        toLocation={toLocation}
        vehicleType={vehicleType}
      />
      <EditSavoyaTransferRateDialog
        initialValues={editingInfo}
        vehicleTypes={vehicleTypes}
        page={page}
        pageSize={pageSize}
        fromLocation={fromLocation}
        toLocation={toLocation}
        vehicleType={vehicleType}
      />
    </React.Fragment>
  );
};

export default SavoyaTransferRates;
