import {
  useAuth0,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';
import React, { ComponentType, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import {
  Contact,
  UserRecord,
  SubscriptionRecord,
  useLinkUserIdentityMutation,
  useMeLazyQuery,
  UserRole,
  UserIdentityRole,
  VendorRecord,
  ProductType,
} from 'src/.gen/graphql';
import { accountTypeUtils } from 'src/shared/utils/accountTypeUtils';
import { auth0Config, cubeJsConfig } from 'src/config';
import cubejs, { CubejsApi } from '@cubejs-client/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSubscription } from 'src/shared/hooks/useSubscription';
import { useMobileAuth } from 'src/shared/hooks/useMobileAuth';
import { useUserProductVariant } from 'src/shared/hooks/useUserProductVariant';
import type { AccountTypes } from 'src/shared/types/accountTypes';

export interface TokenState {
  token?: string | undefined;
}

export interface AuthState {
  user?: Contact | undefined;
}

export const tokenAtom = atom<string | undefined>({
  key: 'tokenState',
  default: localStorage.getItem('token') ?? undefined,
});

export const userAtom = atom<Partial<Contact & UserRecord> | undefined>({
  key: 'userState',
  default: undefined,
});

export const vendorAtom = atom<Partial<VendorRecord> | undefined>({
  key: 'vendorState',
  default: undefined,
});

export const accountTypesAtom = atom<Partial<AccountTypes> | undefined>({
  key: 'vendorProvidersState',
  default: undefined,
});

export const subscriptionPlanAtom = atom<
  Partial<SubscriptionRecord> | undefined
>({
  key: 'subscriptionPlanState',
  default: undefined,
});

export const cubejsApiAtom = atom<CubejsApi>({
  key: 'cubejsApiState',
  default: undefined,
});

export const emailNotVerifiedAtom = atom<boolean>({
  key: 'emailNotVerifiedState',
  default: false,
});

export const useGetMeEffect = (): boolean => {
  const { isAuthenticated, isLoading, logout } = useAuth0();
  const { isMobileAuthenticated, authenticatedFromMobile, clearMobileTokens } =
    useMobileAuth();
  const { setAppProductType, clearAppProductType, isSavoyaProductType } =
    useUserProductVariant();
  const { subscriptions: subscriptionsEnabled } = useFlags();
  const [token, setToken] = useRecoilState(tokenAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const setVendor = useSetRecoilState(vendorAtom);
  const setAccountTypes = useSetRecoilState(accountTypesAtom);
  const [subscriptionPlan, setSubscriptionPlan] =
    useRecoilState(subscriptionPlanAtom);
  const setCubejsApi = useSetRecoilState(cubejsApiAtom);
  const setEmailNotVerified = useSetRecoilState(emailNotVerifiedAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const [
    getMe,
    { data: dataMe, loading: loadingMe, called: getMeCalled, refetch },
  ] = useMeLazyQuery({ fetchPolicy: 'cache-and-network' });
  const [linkIdentity, { data: linkData, called: linkCalled }] =
    useLinkUserIdentityMutation();

  // logout and redirect
  const logoutRedirect = async (returnTo) => {
    clearAppProductType();
    clearMobileTokens();
    logout({ returnTo });
  };

  // getMe only once
  React.useEffect(() => {
    const userIsAuthenticated = isAuthenticated || isMobileAuthenticated;
    if (
      token &&
      userIsAuthenticated &&
      !user &&
      !subscriptionPlan &&
      !getMeCalled
    ) {
      getMe();
    }
  }, [token, user, isAuthenticated, isMobileAuthenticated, getMeCalled]);

  React.useEffect(() => {
    if (dataMe && getMeCalled) {
      if (dataMe.contacts.me || dataMe.usersQueries.getUserByToken) {
        const meUser = dataMe?.contacts?.me as Contact & UserRecord;
        const userIdentityRecord = dataMe?.usersQueries?.getUserByToken;
        const subscription = dataMe?.subscriptionByAccountId;
        const vendor = dataMe?.vendorsQueries?.getVendorByToken;
        const accountTypes = accountTypeUtils.types(vendor?.vendorProviders);
        const isSavoyaVendor = accountTypes.SAVOYA;
        const isSavoyaUser =
          userIdentityRecord?.isSavoyaUser ||
          userIdentityRecord?.productType?.includes(ProductType.Savoya);

        setAppProductType(
          isSavoyaUser ? ProductType.Savoya : ProductType.CapitalDrive,
        );
        const currentUser = {
          ...meUser,
          name: meUser?.name || userIdentityRecord?.name,
          phoneNumber: meUser?.phoneNumber || userIdentityRecord?.phoneNumber,
          role: userIdentityRecord?.role,
          vendorId: userIdentityRecord?.vendorId,
        };

        if (isSavoyaUser || isSavoyaVendor) {
          // Note: this is a temporary, if the account is a savoya account
          // we need to set the account id to the vendor id
          currentUser.accountId = userIdentityRecord?.vendorId;
          currentUser.isSavoyaUser = isSavoyaUser;
        }

        setUser(currentUser);
        setVendor(vendor);
        setAccountTypes(accountTypes);
        if (!isSavoyaUser || !isSavoyaVendor) {
          setSubscriptionPlan(subscription as SubscriptionRecord);
          if (meUser?.analyticsToken) {
            setCubejsApi(
              cubejs(meUser?.analyticsToken, {
                apiUrl: cubeJsConfig.cubeJsApiUrl,
              }),
            );
          }
        }

        // Note: if the user is a savoya driver, we need to redirect to unauthorized page
        if (
          isSavoyaUser &&
          userIdentityRecord?.role === UserIdentityRole.Driver
        ) {
          navigate('/savoya-driver');
        }
      }
      if (!dataMe?.contacts?.me) {
        linkIdentity();
      }
    }
  }, [dataMe, getMeCalled]);

  // side effects by linkData result
  React.useEffect(() => {
    if (!isSavoyaProductType) {
      if (linkData?.linkUserIdentity?.userIdentity?.id) {
        refetch();
      } else if (linkCalled) {
        // redirect to email not verified view
        setEmailNotVerified(true);
        logoutRedirect(auth0Config.nonVerifiedEmailLink);
      }
    }
  }, [linkData]);

  // redirect to subscribe and onboard if it is a new user
  React.useEffect(() => {
    const userRole = dataMe?.contacts?.me?.userRole;
    const accountId = dataMe?.contacts?.me?.accountId;
    const userIdentityRecord = dataMe?.usersQueries?.getUserByToken;
    const subscription = dataMe?.subscriptionByAccountId;

    const isSavoyaUser =
      userIdentityRecord?.isSavoyaUser ||
      userIdentityRecord?.productType?.includes(ProductType.Savoya);

    if (
      getMeCalled &&
      userIdentityRecord?.id &&
      (!isSavoyaUser || !isSavoyaProductType)
    ) {
      if (
        (location.pathname.includes('/subscriptions') ||
          location.pathname.includes('/onboarding') ||
          location.pathname.includes('/savoya-driver') ||
          location.pathname.includes('verified-email')) &&
        userRole === UserRole.AccountOwner &&
        !accountId &&
        !subscription
      ) {
        navigate(subscriptionsEnabled ? '/subscriptions' : '/onboarding');
      }
    }
  }, [dataMe, getMeCalled]);

  React.useEffect(() => {
    const userIsAuthenticated =
      isAuthenticated || isMobileAuthenticated || authenticatedFromMobile;
    if (!isLoading && !userIsAuthenticated) {
      setToken(null);
      localStorage.removeItem('token');
    }
  }, [
    isAuthenticated,
    isMobileAuthenticated,
    authenticatedFromMobile,
    isLoading,
  ]);

  return loadingMe || !user;
};

export const useLogout = (): (() => void) => {
  const { logout } = useAuth0();
  const { clearMobileTokens } = useMobileAuth();
  const { clearAppProductType } = useUserProductVariant();
  const logoutCallback = () => {
    clearMobileTokens();
    clearAppProductType();
    logout({
      returnTo: window.location.origin,
    });
  };
  return logoutCallback;
};

const defaultOnRedirecting = (): JSX.Element => <Fragment />;

/**
 * @ignore
 */
const defaultReturnTo = (): string => {
  const returnUrl = `${window.location.pathname}${window.location.search}`;
  return returnUrl;
};

export const withCustomAuthenticationRequired = <
  P extends Record<string, unknown>,
>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): React.FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const {
      isAuthenticated,
      isLoading,
      loginWithRedirect,
      getAccessTokenSilently,
    } = useAuth0();
    const { isMobileAuthenticated } = useMobileAuth();
    const navigate = useNavigate();
    const loading = useGetMeEffect();
    const [token, setToken] = useRecoilState(tokenAtom);
    const { redirectexistingAccountToSubscribe } = useSubscription();
    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      loginOptions = {},
    } = options;

    // Get the token and store it on local storage
    React.useEffect(() => {
      if (isMobileAuthenticated) {
        return;
      }
      const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
        localStorage.setItem('token', accessToken);
      };
      if (!token && !isLoading) {
        getAccessToken();
      }
    }, [token, isAuthenticated, isMobileAuthenticated, isLoading]);

    React.useEffect(() => {
      if (isLoading || isAuthenticated || isMobileAuthenticated) {
        return;
      }
      const opts = {
        ...loginOptions,
        appState: {
          ...loginOptions.appState,
          returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
        },
      };
      (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
    }, [
      isLoading,
      isAuthenticated,
      isMobileAuthenticated,
      loginWithRedirect,
      loginOptions,
      returnTo,
    ]);

    React.useEffect(() => {
      if (isAuthenticated && redirectexistingAccountToSubscribe) {
        navigate('/subscriptions');
      }
    }, [isAuthenticated, redirectexistingAccountToSubscribe]);

    const userIsAuthenticated = isAuthenticated || isMobileAuthenticated;
    return userIsAuthenticated && !loading ? (
      <Component {...props} />
    ) : (
      onRedirecting()
    );
  };
};
