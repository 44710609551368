import { cloneDeep } from 'lodash';
import { SavoyaDriver } from '../types/SavoyaTripsTypes';

export function getPreferredDriverWarning(
  preferredDrivers: SavoyaDriver[],
): string | null {
  const preferredDriversClone = cloneDeep(preferredDrivers);
  if (preferredDriversClone?.length) {
    if (preferredDriversClone?.length === 1) {
      const driver = preferredDriversClone[0];
      const driverName =
        driver?.fullName ?? `${driver?.firstName} ${driver?.lastName}`;
      return `${driverName} was requested for this trip. Accepting this trip is agreeing to use them. If you must use a different chauffeur, do not accept this trip`;
    }

    const lastDriver = preferredDriversClone?.pop();
    const lastDriverName =
      lastDriver?.fullName ??
      `${lastDriver?.firstName} ${lastDriver?.lastName}`;

    const secondLastDriver = preferredDriversClone?.pop();
    const secondLastDriverrName =
      secondLastDriver?.fullName ??
      `${secondLastDriver?.firstName} ${secondLastDriver?.lastName}`;

    const remainingDriverString = preferredDriversClone?.reduce(
      (acc, driver) => {
        const driverName =
          driver?.fullName ?? `${driver?.firstName} ${driver?.lastName}`;
        return `${acc}${driverName}, `;
      },
      '',
    );

    const fullDriverString = `${remainingDriverString}${secondLastDriverrName} or ${lastDriverName}`;
    return `${fullDriverString} are requested on this trip. Accepting this trip is agreeing to use one of them. If you must use a different driver, do not accept this trip.`;
  }
  return null;
}
