import React from 'react';
import { Box, Typography, Theme, styled } from '@material-ui/core';

interface ComponentProps {
  label: string;
  height: number | string;
  path?: string;
}

const EmptyData: React.FC<ComponentProps> = ({
  label = 'Title',
  height = 120,
  path = '/static/images/undraw_Empty_re_opql.svg',
}) => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Image alt="Select file" height={height} src={path} />
      <Typography color="textSecondary" variant="body2">
        {label}
      </Typography>
    </Box>
  );
};

const Image = styled('img')(
  ({ theme, height }: { theme: Theme; height: string | number }) => ({
    marginBottom: theme.spacing(1),
    objectFit: 'contain',
    maxWidth: '100%',
    height,
  }),
);

export default EmptyData;
