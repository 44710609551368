/* eslint-disable @typescript-eslint/no-explicit-any */
import queryString from 'query-string';
import { AddressInput, Location } from 'src/.gen/graphql';
import { getIATCodeFromPlaceName } from '../utils/addressUtils';

const GEOCODING_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const DRIVING_BASE_URL = 'https://api.mapbox.com/directions/v5/mapbox';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const USABLE_LOCATION_TYPES = ['address', 'poi'];

export type GeoSource = 'mapbox' | 'google';

export const retriveDirections = (
  coordiantes: number[][],
  params = {},
  profile = 'driving',
): Promise<any> => {
  const defaultParams = {
    overview: 'full',
    geometries: 'geojson',
    annotations: 'duration',
    access_token: MAPBOX_TOKEN,
  };
  return fetch(
    `${DRIVING_BASE_URL}/${profile}/${getCoordinates(
      coordiantes,
    )}?${queryString.stringify({ ...defaultParams, ...params })}`,
  )
    .then((res) => res.json())
    .then((res) => res.routes[0]);
};

function getCoordinates(coordiantes: number[][]): string {
  return coordiantes.reduce((acc, curr) => {
    return `${acc ? `${acc};` : acc}${curr[0]},${curr[1]}`;
  }, '');
}

export const searchLocation = ({
  value,
  biasCoords = [0, 0],
}: {
  value: string;
  biasCoords: number[];
}): Promise<any[]> => {
  const isAirport = /^[A-Za-z]{3}$/.test(value);
  const criteria = createCriteria({ isAirport, biasCoords });
  try {
    const fullURL = `${GEOCODING_BASE_URL}/${encodeURI(
      value,
    )}.json?${criteria}`;
    return fetch(fullURL)
      .then((res) => res.json())
      .then((res) => res.features);
  } catch (error) {
    return Promise.resolve([]);
  }
};

interface FilterCriteria {
  isAirport?: boolean;
  biasCoords: number[];
}

function createCriteria(filter: FilterCriteria): string {
  const { isAirport, biasCoords } = filter;

  const criteria: Record<string, unknown> = {
    cachebuster: 1619029262742,
    fuzzyMatch: true,
    autocomplete: true,
    country: 'mx,us',
    access_token: MAPBOX_TOKEN,
    type: 'address,poi',
  };

  if (isAirport) {
    criteria.type = 'poi';
  }

  if (biasCoords && biasCoords.length === 2 && !isAirport) {
    criteria.proximity = biasCoords.join(',');
  }

  return queryString.stringify(criteria);
}

export function sortByAirport(
  a: Record<string, any>,
  b: Record<string, any>,
): number {
  if (
    a?.properties?.category === 'airport' &&
    b?.properties?.category !== 'airport'
  ) {
    return -1;
  }
  if (
    b?.properties?.category === 'airport' &&
    a?.properties?.category !== 'airport'
  ) {
    return 1;
  }
  return 0;
}

export function getUsableAddress(option: Record<string, any>): boolean {
  const optionMainType = option.place_type.pop();
  if (USABLE_LOCATION_TYPES.includes(optionMainType)) {
    return true;
  }
  return false;
}

export enum FieldsToSet {
  addressLine1 = 'addressLine1',
  city = 'city',
  state = 'state',
  country = 'country',
  postalCode = 'postalCode',
  longitude = 'longitude',
  latitude = 'latitude',
  // Geo Provider
  placeName = 'placeName',
  placeType = 'placeType',
  placeFoursquareId = 'placeFoursquareId',
  placeWikidataId = 'placeWikidataId',
  placeCategory = 'placeCategory',
  address = 'address',
}

export type ParsedLocation = {
  addressLine1: string;
  postalCode: string;
  city: string;
  state: string;
  longitude: number;
  latitude: number;
  coordinates: {
    type: string;
    coordinates: number[];
  };
  country: string;
  placeType: string;
  placeName: string;
  placeFoursquareId: string;
  placeWikidataId: string;
  address: string;
  placeCategory: string;
};

export enum LocationTypes {
  addressInput = 'addressInput',
  ownedLocationInput = 'ownedLocationInput',
  upsertPlaceInput = 'upsertPlaceInput',
}

const defaultFields = Object.keys(FieldsToSet);
const addressInputFields = defaultFields.slice(
  defaultFields.indexOf('addressLine1'),
  defaultFields.indexOf('placeName'),
);

const ownedLocationFields = defaultFields.slice(
  defaultFields.indexOf('addressLine1'),
  defaultFields.indexOf('placeType'),
);

const upsertPlaceFields = defaultFields.slice(
  defaultFields.indexOf('addressLine1'),
  defaultFields.indexOf('address'),
);

const parsers = {
  [LocationTypes.addressInput]: addressInputFields,
  [LocationTypes.ownedLocationInput]: ownedLocationFields,
  [LocationTypes.upsertPlaceInput]: {
    1: upsertPlaceFields,
    2: [...upsertPlaceFields, 'coordinates'].filter(
      (key) => key !== 'latitude' && key !== 'longitude',
    ),
  },
  default: defaultFields,
};

export function parseGeoOptionToLocation(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  source: GeoSource,
  locationType?: LocationTypes,
  version = 1,
): ParsedLocation {
  if (!data) {
    return null;
  }

  let geoParser = mapboxParser;

  if (source === 'google') {
    geoParser = googleParser;
  }
  const filedsByType = parsers[locationType] ?? parsers.default;
  const fields = Array.isArray(filedsByType)
    ? filedsByType
    : filedsByType[version];

  return fields.reduce((res, field) => {
    const value = geoParser[field](data);

    if (!value) {
      return res;
    }
    return { ...res, [field]: value };
  }, {});
}

const mapboxParser = {
  addressLine1: (values) =>
    parseAddressLine1(
      values?.address,
      values?.text,
      values?.properties?.address,
    ),
  postalCode: (values) =>
    values?.context ? getContextInfo('id', 'postcode', values?.context) : '',
  city: (values) => {
    const place =
      values?.context && getContextInfo('id', 'place', values?.context);
    const locality =
      values?.context && getContextInfo('id', 'locality', values?.context);
    return locality || place || '';
  },
  state: (values) =>
    values?.context ? getContextInfo('id', 'region', values?.context) : '',
  longitude: (values) =>
    values?.center && values.center.length > 0 ? values?.center[0] : 0,
  latitude: (values) =>
    values?.center && values.center.length > 1 ? values?.center[1] : 0,
  coordinates: (values) => ({
    type: 'Point',
    coordinates: values?.center,
  }),
  country: (values) =>
    values?.context ? getContextInfo('id', 'country', values?.context) : '',
  placeType: (values) => (values?.place_type || []).join(', '),
  placeName: (values) =>
    parseName(
      values?.place_name,
      values?.matching_text,
      values?.properties?.category === 'airport',
    ),
  placeFoursquareId: (values) => values?.properties?.foursquare || '',
  placeWikidataId: (values) => values?.properties?.wikidata || '',
  address: (values) =>
    parseAddressLine1(
      values?.address,
      values?.text,
      values?.properties?.addressProperty,
    ),
  placeCategory: (values) => values?.properties?.category || '',
};

const googleParser = {
  addressLine1: (placeDetails) => placeDetails?.formatted_address,
  postalCode: (placeDetails) => {
    return placeDetails?.address_components
      ? getContextInfo('types', 'postal_code', placeDetails?.address_components)
      : '';
  },
  city: (placeDetails) => {
    const place =
      placeDetails?.address_components &&
      getContextInfo('types', 'place', placeDetails?.address_components);
    const locality =
      placeDetails?.address_components &&
      getContextInfo('types', 'locality', placeDetails?.address_components);
    return locality || place || '';
  },
  state: (placeDetails) =>
    placeDetails?.context
      ? getContextInfo(
          'types',
          'administrative_area_level_1',
          placeDetails?.address_components,
        )
      : '',
  longitude: (placeDetails) => {
    return placeDetails?.geometry?.location?.lng ?? 0;
  },
  latitude: (placeDetails) => {
    return placeDetails?.geometry?.location?.lat ?? 0;
  },
  coordinates: (placeDetails) => ({
    type: 'Point',
    coordinates: [
      placeDetails?.geometry?.location?.lng ?? 0,
      placeDetails?.geometry?.location?.lat ?? 0,
    ],
  }),
  country: (placeDetails) =>
    placeDetails?.context
      ? getContextInfo('types', 'country', placeDetails?.address_components)
      : '',
  placeType: (placeDetails) => (placeDetails?.types || []).join(', '),
  placeName: (placeDetails) => `${placeDetails?.description}`,
  placeFoursquareId: () => null,
  placeWikidataId: () => null,
  address: (placeDetails) =>
    parseAddressLine1(
      placeDetails?.address,
      placeDetails?.text,
      placeDetails?.properties?.formatted_address,
    ),
  placeCategory: (placeDetails) =>
    placeDetails?.types?.includes('airport')
      ? 'airport'
      : (placeDetails?.types || []).join(', '),
};

const getContextInfo = (key, value, context) => {
  const element = context.find((el) => el[key].includes(value));
  return element?.text || '';
};

const parseAddressLine1 = (address, text, addressProperty) => {
  if (addressProperty) return addressProperty;
  if (!address) return '';
  return `${address} ${text || ''}`;
};

const parseName = (address, matchingText, isAirport) => {
  const elements = address?.split(',');
  const placeName = elements?.length ? elements[0] : '';
  if (isAirport) {
    const iataCode = getIATCodeFromPlaceName(placeName);
    return `${placeName}${
      matchingText && !iataCode ? ` (${matchingText})` : ''
    }`;
  }
  return placeName;
};

export const parseLocationToAddressInput = (
  location: Partial<Location>,
): Partial<AddressInput> => ({
  id: location?.id,
  addressLine1: location?.addressLine1 ?? '',
  addressLine2: location?.addressLine2 ?? '',
  city: location?.city ?? '',
  country: location?.country ?? '',
  postalCode: location?.postalCode,
  state: location?.state,
  category: location?.category ?? undefined,
});
