import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { useDefaultRates } from 'src/rates/hooks/useDefaultRates';
import { useGarageCoordinates } from 'src/rates/hooks/useGarageCoordinates';
import CancellationPolicy from '../../Cancellation Policy/CancellationPolicy';
import IsochroneMap from '../../IsochroneMap/IsochroneMap';
import MinimumBookingTime from '../../MinimumBookingHours/MinimumBookingHours';
import { useRatesStyles } from '../../RatesDashboard';
import StandardGarageTime from '../../StandardGarageTime/StandardGarageTime';
import StandardHourlyRates from '../../StandardHourlyRates/StandardHourlyRates';

const HourlyRatesTab: FC = () => {
  const classes = useRatesStyles();

  const { getPolicy, currentMeteredRateIncrement, rateSetId } =
    useDefaultRates();

  const { garageCoordinates } = useGarageCoordinates();

  return (
    <React.Fragment>
      <Grid item data-testid="standard_rates_container">
        <StandardHourlyRates />
      </Grid>
      <Grid item>
        <div
          className={classes.header}
          data-testid="minimum_booking_time_container"
        >
          <MinimumBookingTime
            getPolicy={getPolicy}
            rateData={{ currentMeteredRateIncrement, rateSetId }}
          />
        </div>
      </Grid>
      <Grid item>
        <div
          className={classes.header}
          data-testid="cancellation_policy_container"
        >
          <CancellationPolicy getPolicy={getPolicy} />
        </div>
      </Grid>
      <Grid item>
        <div
          className={classes.header}
          data-testid="standar_garage_time_container"
        >
          <StandardGarageTime getPolicy={getPolicy} />
        </div>
      </Grid>
      {!!garageCoordinates && (
        <Grid item>
          <div
            className={classes.header}
            data-testid="standard_garage_time_map"
          >
            <IsochroneMap
              coordinates={garageCoordinates}
              getPolicy={getPolicy}
            />
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default HourlyRatesTab;
