import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { AddressInformation } from 'src/shared/utils/addressUtils';
import VerifiedFlightDetails from './VerifiedFlightDetails';

export interface AddressComponentProps {
  address: AddressInformation;
  forTile?: boolean;
}

const useStyles = makeStyles((theme) => ({
  titleLabel: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const AddressComponent: React.FC<AddressComponentProps> = ({
  address,
  forTile,
}) => {
  const classes = useStyles();
  const formatAddress = [
    address?.placeName,
    address?.city,
    address?.state,
    address?.postalCode,
  ]
    .filter((value) => value)
    .join(', ');
  let airlineInfoString = `${address?.airline?.iata} - ${
    address?.airline?.name || ''
  }`;
  if (address?.airline?.flightNumber) {
    airlineInfoString = `${airlineInfoString} #${address?.airline?.flightNumber}`;
  }
  if (address?.airline?.tailNumber) {
    airlineInfoString = `${address?.airline?.iata} - ${address?.airline?.name} Tail Number #${address?.airline?.tailNumber}`;
  }
  return (
    <Box>
      <Typography
        className={classes.titleLabel}
        variant="body2"
        color="textPrimary"
      >
        {address?.streetName || address?.placeName}
      </Typography>
      {address?.airline?.name ? (
        <div>
          <Typography variant="body2" component="p">
            {airlineInfoString}
          </Typography>
          <VerifiedFlightDetails
            verifiedFlight={address.verifiedFlight}
            flightTime={
              address?.flightTime || address?.verifiedFlight?.scheduledTime
            }
            isPrivateFlight={address.isPrivateFlight}
            forTile={forTile}
          />
        </div>
      ) : (
        <Typography variant="caption" color="textSecondary" component="p">
          {formatAddress}
        </Typography>
      )}
    </Box>
  );
};

export default AddressComponent;
