import React from 'react';
import { styled, fade, Theme } from '@material-ui/core';
import ReactMapboxGl, { GeoJSONLayer, Marker } from 'react-mapbox-gl';
import NavigationIcon from '@material-ui/icons/Navigation';
import { LinePaint } from 'mapbox-gl';
import { DistancesAndTime, MonitoringSummary } from 'src/.gen/graphql';
import { mapBoxConfig } from 'src/config';
import axios from 'axios';
import {
  getBounds,
  getMapboxUrl,
  getMonitoringWaypointsArray,
  getCoordinatesFromPayload,
  getDistancesFromPayload,
} from 'src/shared/utils/mapUtils';
import { useSetRecoilState } from 'recoil';
import { tripMonitoringState } from 'src/trips/atoms/tripMonitoringAtom/tripMonitoringAtom';
import { addMinutes } from 'date-fns';
import { convertSecondsToMinutes } from 'src/shared/utils/conversions';

interface Props {
  monitoringSummary: MonitoringSummary;
}

const Map = ReactMapboxGl({
  accessToken: mapBoxConfig.token,
});

const TripMonitoringMap: React.FC<Props> = ({ monitoringSummary }) => {
  const [routeCoordinatesArray, setRouteCoordinatesArray] = React.useState([]);
  const setMonitoringState = useSetRecoilState(tripMonitoringState);
  const waypointsArray = getMonitoringWaypointsArray(monitoringSummary);

  const saveTripMonitoringDetails = (timeAndDistance: DistancesAndTime) => {
    const duration = Math.round(
      convertSecondsToMinutes(timeAndDistance.duration),
    );
    setMonitoringState((prev) => ({
      ...prev,
      estimatedArrivalTime: addMinutes(new Date(), duration),
      duration,
    }));
  };

  React.useEffect(() => {
    if (waypointsArray.length >= 2) {
      const mapboxUrl = getMapboxUrl(waypointsArray);

      axios
        .get(mapboxUrl, {
          params: {
            overview: 'full',
            geometries: 'geojson',
            access_token: mapBoxConfig.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const formatted = getCoordinatesFromPayload(response.data);
            const [timeAndDistance] = getDistancesFromPayload(response.data);
            saveTripMonitoringDetails(timeAndDistance);
            setRouteCoordinatesArray(formatted);
          }
        });
    }
  }, [monitoringSummary]);

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: routeCoordinatesArray,
        },
      },
    ],
  };

  const linePaint: LinePaint = {
    'line-color': '#966440',
    'line-width': 3,
  };

  return (
    <Map
      style="mapbox://styles/mapbox/streets-v11"
      movingMethod="jumpTo"
      containerStyle={{
        height: '100%',
        maxHeight: '100vh',
        maxWidth: '100vw',
        minHeight: 250,
        width: '100%',
      }}
      fitBoundsOptions={{
        padding: { top: 50, bottom: 20, left: 30, right: 30 },
        duration: 0,
      }}
      fitBounds={getBounds(true, routeCoordinatesArray, waypointsArray)}
    >
      {routeCoordinatesArray.length > 0 && (
        <React.Fragment>
          <Marker coordinates={routeCoordinatesArray[0]}>
            <Navigation heading={monitoringSummary?.position?.heading ?? 0}>
              <NavigationIcon fontSize="inherit" />
              <div className="inner-circle" />
              <div className="outer-circle" />
            </Navigation>
          </Marker>
          <Marker
            coordinates={
              routeCoordinatesArray[routeCoordinatesArray.length - 1]
            }
          >
            <Destination />
          </Marker>
        </React.Fragment>
      )}
      <GeoJSONLayer data={geojson} linePaint={linePaint} />
    </Map>
  );
};

const Destination = styled('div')(({ theme }: { theme: Theme }) => ({
  height: '12px',
  width: '12px',
  transform: 'translateY(50%)',
  backgroundColor: theme.palette.backgroundColor.light,
  border: `3px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
}));

const Navigation = styled('div')(
  ({ theme, heading }: { theme: Theme; heading: number }) => ({
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.textLight.main,
    transform: `translateY(50%) rotate(${heading}deg)`,
    position: 'relative',
    '& > svg': {
      zIndex: 3,
      position: 'relative',
    },
    '& > .inner-circle': {
      position: 'absolute',
      width: 25,
      height: 25,
      backgroundColor: fade(theme.palette.primary.main, 0.6),
      zIndex: 2,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '50%',
    },
    '& > .outer-circle': {
      position: 'absolute',
      width: 45,
      height: 45,
      backgroundColor: fade(theme.palette.primary.main, 0.3),
      zIndex: 1,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '50%',
    },
  }),
);

export default TripMonitoringMap;
