import React from 'react';
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';

interface HoursInputProps {
  value: string | number;
}

export type TextFieldExtendedProps = TextFieldProps & HoursInputProps;

const HoursField = React.forwardRef<HTMLInputElement, TextFieldExtendedProps>(
  (props, ref) => {
    const { onChange, InputProps, ...rest } = props;

    return (
      <TextField
        inputRef={ref}
        placeholder="0"
        type="number"
        style={{ width: '5rem' }}
        onChange={(e) => {
          const regex = /^\d+$/;
          const { value } = e.target;
          if (!value || regex.test(value)) {
            onChange(e);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <InputLabel>Hours</InputLabel>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...rest}
      />
    );
  },
);

export default HoursField;
