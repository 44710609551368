import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { WarningButtonTheme, BaseButtonTheme } from '../ButtonThemeProviders';

interface ConfirmationDialogProps {
  open: boolean;
  title?: string;
  confirmationText?: JSX.Element | string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  warning?: boolean;
  setOpen: (newValue: boolean) => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  confirmationText,
  confirmButtonText,
  cancelButtonText,
  warning,
  setOpen,
  onConfirm,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const ButtonTheme = warning ? WarningButtonTheme : BaseButtonTheme;

  return (
    <Dialog open={open}>
      {!!title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {!!confirmationText && (
          <DialogContentText id="alert-dialog-description">
            {confirmationText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            if (onCancel) {
              onCancel();
            }
            setOpen(false);
          }}
        >
          {cancelButtonText || 'Cancel'}
        </Button>
        <ButtonTheme>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                if (onConfirm) {
                  setLoading(true);
                  await onConfirm();
                }
                setOpen(false);
                setLoading(false);
              }}
            >
              {confirmButtonText || 'Confirm'}
            </Button>
          )}
        </ButtonTheme>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
