import React from 'react';
import { Button } from '@material-ui/core';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import CreateIcon from '@material-ui/icons/Create';
import { useSetRecoilState } from 'recoil';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import AddTripAdjustment from '../TripAdjustments/AddTripAdjustment';

interface Props {
  tripId: string;
  vehicleClassId?: string;
}

const TripAdjustmentButton: React.FC<Props> = ({ tripId, vehicleClassId }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const setTimestampsState = useSetRecoilState(tripTimestampsState);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = () => {
    setTimestampsState((prev) => ({
      ...prev,
      refetchInvoiceSummary: true,
      updating: true,
    }));
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setOpenDialog(true)}
        startIcon={<CreateIcon fontSize="small" />}
      >
        Edit Adjustments
      </Button>
      <GenericDialog
        openDialog={openDialog}
        setCloseDialog={() => setOpenDialog(false)}
        maxWidth="md"
      >
        <AddTripAdjustment
          vehicleClassId={vehicleClassId}
          tripId={tripId}
          onCancel={handleCloseDialog}
          onSubmit={onSubmit}
        />
      </GenericDialog>
    </React.Fragment>
  );
};

export default TripAdjustmentButton;
