import React from 'react';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import DateFnsUtils from '@date-io/date-fns';
import { jssPreset, StylesProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/shared/components/GlobalStyles';
import ScrollReset from 'src/shared/components/ScrollReset';
import CookiesNotification from 'src/shared/components/CookiesNotification';
import GoogleAnalytics from 'src/shared/components/GoogleAnalytics';
import { Auth0Provider } from '@auth0/auth0-react';
import { appConfig, auth0Config } from 'src/config';
import { useSnackbar } from 'notistack';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import FullStory from 'react-fullstory';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { QueryParamProvider } from 'use-query-params';

import TripCommandDialog from 'src/trips/components/TripCommandDialog/TripCommandDialog';
import { useRecoilState, useRecoilValue } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SomethingWentWrongView from './shared/views/errors/SomethingWentWrongView';
import { buildTypePolicies } from './shared/utils/cacheBuilder';
import {
  tokenAtom,
  userAtom,
  accountTypesAtom,
} from './authentication/atoms/AuthState';
import Router from './Router';
import { removeTypenameFromMutationLink } from './shared/utils/removeTypenameFromMutation';
import {
  MobileAuthProvider,
  SubscriptionProvider,
  PermissionProvider,
} from './contexts';
import { useMobileAuth } from './shared/hooks/useMobileAuth';
import 'react-smartbanner/dist/main.css';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const queryClient = new QueryClient();

const App: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [, setToken] = useRecoilState(tokenAtom);

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        if (message?.startsWith('Context creation failed')) {
          localStorage.removeItem('token');
          setToken(undefined);
        }
        if (extensions?.userError) {
          enqueueSnackbar(message, {
            variant: 'error',
            preventDuplicate: true,
          });
          Sentry.captureException(new Error(message));
        }
      });
    }
    if (networkError) {
      const networkMessage = `[Network error]: ${networkError}`;
      enqueueSnackbar(networkMessage, {
        variant: 'error',
        preventDuplicate: true,
      });
      Sentry.captureException(new Error(networkMessage));
    }
  });

  const httpLink = new HttpLink({
    uri: appConfig.apiUrl,
    credentials: 'include',
  });

  const authLink = setContext(async () => {
    const token = localStorage.getItem('token');
    const headers = { 'x-app-id': 'capital-drive' };
    return {
      headers: token
        ? {
            ...headers,
            authorization: `Bearer ${token}`,
          }
        : headers,
    };
  });

  const client = new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: from([removeTypenameFromMutationLink, errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: buildTypePolicies(),
    }),
  });

  // FULL STORY
  const ORG_ID = '3K02X';
  const NAMESPACE = 'FS';

  const onRedirectCallback = (appState) => {
    window.location.replace(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.client_id}
      redirectUri={window.location.origin}
      audience={auth0Config.audience}
      cacheLocation="localstorage"
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
    >
      <ApolloProvider client={client}>
        <MobileAuthProvider>
          <QueryClientProvider client={queryClient}>
            <SubscriptionProvider>
              <PermissionProvider>
                <FullStory org={ORG_ID} namespace={NAMESPACE} />
                <StylesProvider jss={jss}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <BrowserRouter>
                      <Sentry.ErrorBoundary
                        fallback={<SomethingWentWrongView />}
                      >
                        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                          <GlobalStyles />
                          <ScrollReset />
                          <GoogleAnalytics />
                          <CookiesNotification />
                          <Router />
                        </QueryParamProvider>
                      </Sentry.ErrorBoundary>
                    </BrowserRouter>
                    <TripCommandDialog />
                  </MuiPickersUtilsProvider>
                </StylesProvider>
              </PermissionProvider>
            </SubscriptionProvider>
          </QueryClientProvider>
        </MobileAuthProvider>
      </ApolloProvider>
    </Auth0Provider>
  );
};

const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useRecoilValue(userAtom);
  const accountTypes = useRecoilValue(accountTypesAtom);
  const { authenticatedFromMobile } = useMobileAuth();

  React.useEffect(() => {
    if (!authenticatedFromMobile) {
      window.Appcues?.identify(user?.id, {
        email: user?.email,
        displayName: user?.name,
      });
      window.Appcues?.page();
    }
  }, [location.pathname, authenticatedFromMobile]);

  React.useEffect(() => {
    if (user && (!accountTypes.SAVOYA || !authenticatedFromMobile)) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'bkbj4s66',
        name: user.name, // Full name
        email: user.email, // Email address
        created_at: new Date(user.createdAt).getTime(),
        user_id: user.id,
        company: {
          company_id: user?.accountId,
          name: user?.account?.name,
        },
        alignment: 'left',
        horizontal_padding: 20,
        vertical_padding: 30,
      });
    }
  }, [user, accountTypes, authenticatedFromMobile]);

  React.useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update');
    }
  }, [location]);

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate, location],
  );
  // after the call is made the location content changes so its necesary to "refetch"/request the new location
  const newLocation = useLocation();
  return children({ history: adaptedHistory, newLocation });
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_KEY,
})(App);
