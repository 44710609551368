import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RatePolicy, useUpdateDefaultRatesMutation } from 'src/.gen/graphql';
import { useRatesStyles } from '../../RatesDashboard';
import TextLineInput from '../../TextLineInput/TextLineInput';

interface Inputs {
  hours: number;
}
interface CancellationPolicyFormProps {
  policy: Partial<RatePolicy>;
}
const CancellationPolicyForm: FC<CancellationPolicyFormProps> = ({
  policy,
}) => {
  const classes = useRatesStyles();

  const { register, setValue, handleSubmit } = useForm();

  const [updateDefaultRate] = useUpdateDefaultRatesMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!policy) {
      return;
    }
    const { id } = policy;
    await updateDefaultRate({
      variables: { id, thresholdValue: Number(data.hours) },
    });
  };

  const hours = register('hours');

  React.useEffect(() => {
    if (policy) {
      const { thresholdValue } = policy;
      if (thresholdValue) {
        setValue('hours', thresholdValue);
      }
    }
  }, [policy]);

  return (
    <TextLineInput
      text="Trips cancelled less than $hours hours before the trip start time are billable at the transfer rate or minimum booking hours. This value can be configured individually for each vehicle class"
      inputs={[
        {
          component: () => (
            <input
              data-testid="cancellation_policy_hours_field"
              className={classes.inputStyles}
              defaultValue={0}
              type="number"
              onChange={hours.onChange}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                }
              }}
              ref={hours.ref}
              name={hours.name}
              onBlur={(e) => {
                hours.onBlur(e);
                handleSubmit(onSubmit)();
              }}
            />
          ),
          id: '$hours',
        },
      ]}
    />
  );
};

export default CancellationPolicyForm;
