import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { savoyaRatesDialogsState } from 'src/rates/atoms/SavoyaRateFormState';
import {
  SavoyaTransferCostResponse,
  SavoyaVehicleType,
  addTransferCost,
} from 'src/rates/api/SavoyaRateQueries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import { SavoyaLocation } from 'src/trips/types/SavoyaTripsTypes';
import SavoyaTransferRateBaseForm from '../SavoyaTransferRateBaseForm';

interface DialogProps {
  vehicleTypes: SavoyaVehicleType[];
  page: number;
  pageSize: number;
  toLocation: Partial<SavoyaLocation>;
  fromLocation: Partial<SavoyaLocation>;
  vehicleType: Partial<SavoyaVehicleType>;
}

const AddSavoyaTransferRateDialog: FC<DialogProps> = ({
  vehicleTypes,
  page,
  pageSize,
  toLocation,
  fromLocation,
  vehicleType,
}) => {
  const [dialogState, setDialogState] = useRecoilState(savoyaRatesDialogsState);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const addSavoyaTransferRate = useMutation({
    mutationFn: addTransferCost,
    onSuccess: (newRate) => {
      queryClient.setQueryData(
        [
          'transferRates',
          { page, pageSize, fromLocation, toLocation, vehicleType },
        ],
        (oldData: SavoyaTransferCostResponse) => {
          const newState = cloneDeep(oldData);
          newState?.paginated?.records?.unshift(newRate);
          return newState;
        },
      );
      enqueueSnackbar('Transfer Rate Added', { variant: 'success' });
      closeDialog();
    },
    onError: () =>
      enqueueSnackbar('Error Adding Transfer Rate', { variant: 'error' }),
  });
  const closeDialog = () =>
    setDialogState((prev) => ({
      ...prev,
      openCreateTransferRateDialog: false,
    }));

  const onSubmit = async (values) => {
    await addSavoyaTransferRate.mutateAsync({
      ...values,
      transferCost: Math.round(values.transferCost * 100),
    });
  };

  return (
    <GenericDialog
      dialogTitle="New Transfer Rate"
      openDialog={dialogState.openCreateTransferRateDialog}
      setCloseDialog={closeDialog}
      maxWidth="sm"
    >
      <SavoyaTransferRateBaseForm
        action="create"
        vehicleTypes={vehicleTypes}
        onSubmit={onSubmit}
        onCancel={closeDialog}
      />
    </GenericDialog>
  );
};

export default AddSavoyaTransferRateDialog;
