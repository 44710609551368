import { ZoneType, AreaType } from 'src/.gen/graphql';

export interface MapLocation {
  coordinates?: Array<number>;
  radiusInKm?: number;
  zoneType: ZoneType;
  geoType: AreaType;
}

export interface RatesMapCoordinates {
  firstLocation?: MapLocation;
  secondLocation?: MapLocation;
}

export const defaultRatesMapCoordinates: RatesMapCoordinates = {
  firstLocation: {
    zoneType: ZoneType.Address,
    geoType: AreaType.Place,
  },
  secondLocation: {
    zoneType: ZoneType.Address,
    geoType: AreaType.Place,
  },
};
