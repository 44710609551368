import { Button, Card, CardContent, Divider, Grid } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React, { FC, Fragment, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom, vendorAtom } from 'src/authentication/atoms/AuthState';
import PageHeader from 'src/shared/components/PageHeader/PageHeader';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { tripCommandDialogState } from 'src/trips/atoms/tripCommandDialogAtom';
import NotesInput from 'src/trips/components/NotesInput';
import TripMapTile from 'src/trips/components/TripMapTile/TripMapTile';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  InsertTripNote,
  Trip,
  TripStatus,
  useGetStreamTokenQuery,
  useGetTripByIdQuery,
  useInsertTripNoteMutation,
} from '../../.gen/graphql';
import { tripInvoiceUpdatedAtom } from '../atoms/tripInvoiceUpdatedAtom';
import { tripUpdateClientState } from '../atoms/tripUpdateClientAtom';
import ActivitiesDashboard from './ActivitiesDashboard';
import PaymentDetailsView from './PaymentDetails';
import { CHARGEABLE_STATUS } from './SelectPaymentMethod';
import TripContactAcordion from './TripClientAcordion/TripClientAcordion';
import TripPassengerCard from './TripPassengerCard/TripPassengerCard';

interface TripDetailsViewProps {
  tripId: string;
  showEditButton?: boolean;
  showViewButton?: boolean;
  showTripContactsAccordion?: boolean;
  showPaymentDetails?: boolean;
}

export const NON_EDITABLE_STATUS = [
  TripStatus.TransportComplete,
  TripStatus.TripComplete,
  TripStatus.Paid,
  TripStatus.Closed,
  TripStatus.Cancelled,
  TripStatus.Billed,
];

const TripDetailsView: FC<TripDetailsViewProps> = ({
  tripId,
  showEditButton = true,
  showViewButton = true,
  showTripContactsAccordion = true,
  showPaymentDetails = false,
}) => {
  const user = useRecoilValue(userAtom);
  const vendor = useRecoilValue(vendorAtom);
  const { sm } = useBreakpoints();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: tokenResult,
    refetch: refetchActivity,
    loading: loadingActivity,
  } = useGetStreamTokenQuery({
    variables: {
      id: tripId,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    loading: isTripLoading,
    data: trip,
    refetch,
  } = useGetTripByIdQuery({
    variables: {
      tripId,
    },
    fetchPolicy: 'no-cache',
  });

  const iOwnThisTrip = user?.accountId === trip?.trips?.byId?.accountId;

  const [insertTripNote] = useInsertTripNoteMutation({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!isTripLoading) {
      if (!trip?.trips?.byId?.id) {
        enqueueSnackbar('Trip does not exist', { variant: 'error' });

        navigate('/');
      } else {
        const { sharedTrip } = trip?.trips?.byId;
        const tripOwnedByMe = user?.accountId === trip?.trips?.byId?.accountId;
        const tripSharedWithMe = vendor?.id === sharedTrip?.vendorExternalId;

        if (!tripOwnedByMe && !tripSharedWithMe) {
          enqueueSnackbar('You do not have access to this trip', {
            variant: 'error',
          });
          navigate('/');
        }
      }
    }
  }, [trip]);

  const tripClients = trip?.clientQueries?.getTripClientsByTrip;

  const { succeeded } = useRecoilValue(tripCommandDialogState);
  const tripContactsChanged = useRecoilValue(tripUpdateClientState);
  const tripInvoiceChanged = useRecoilValue(tripInvoiceUpdatedAtom);

  React.useEffect(() => {
    if (tripContactsChanged === true || tripInvoiceChanged === true) {
      refetch({ tripId });
    }
  }, [tripContactsChanged, tripInvoiceChanged]);

  React.useEffect(() => {
    if (succeeded) {
      refetch({ tripId });
      refetchActivity();
    }
  }, [succeeded]);

  const onSubmitNote = async (note: string) => {
    const tripNote: InsertTripNote = {
      tripId,
      content: note,
      authorId: user?.id,
      time: new Date(),
    };
    await insertTripNote({ variables: { tripNote } });
  };

  // SCROLL REF
  const scrollRef = useRef();
  const goToPaymentButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        if (scrollRef.current) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (scrollRef.current as any).scrollIntoView({ behavior: 'smooth' });
        }
      }}
    >
      Go To Payment
    </Button>
  );

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={2}
      >
        {sm && (
          <Grid item>
            <Card>
              <CardContent>
                <PageHeader
                  pageName={`Trip #${trip?.trips?.byId?.tripNumber}`}
                  icon={<LocationOnIcon />}
                  loading={false}
                  breadcrumbs={[{ url: '/trips/list', label: 'Trips' }]}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item>
          <TripPassengerCard
            showEditButton={
              showEditButton &&
              !NON_EDITABLE_STATUS.includes(trip?.trips?.byId?.status)
            }
            showViewButton={showViewButton}
            trip={trip?.trips?.byId as Trip}
            type="WithTrip"
            loading={isTripLoading}
            paymentButton={
              CHARGEABLE_STATUS.includes(trip?.trips?.byId?.status) &&
              iOwnThisTrip &&
              showPaymentDetails
                ? goToPaymentButton
                : undefined
            }
            tripClients={trip?.clientQueries?.getTripClientsByTrip}
          />
        </Grid>
        <Grid item>
          <Card>
            <TripMapTile trip={trip?.trips?.byId as Trip} type="WithTrip" />
            <CardContent>
              {!isTripLoading && showTripContactsAccordion && (
                <TripContactAcordion
                  defaultExpanded
                  trip={trip?.trips?.byId as Trip}
                  loading={!trip?.trips?.byId}
                  tripClients={tripClients}
                />
              )}
              {showPaymentDetails && (
                <React.Fragment>
                  <Divider
                    style={{ width: '100%', marginBottom: '1rem' }}
                    ref={scrollRef}
                  />
                  <PaymentDetailsView
                    trip={trip?.trips?.byId as Trip}
                    tripClients={trip?.clientQueries?.getTripClientsByTrip}
                  />
                  <Divider style={{ width: '100%', margin: '1rem 0' }} />
                </React.Fragment>
              )}
              <NotesInput onSubmit={onSubmitNote} />
              {!loadingActivity && (
                <ActivitiesDashboard
                  trip={trip?.trips?.byId as Trip}
                  token={tokenResult?.getStream?.getFeedToken?.token}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TripDetailsView;
