import { InvoiceSummary } from 'src/.gen/graphql';

import { atom, RecoilState } from 'recoil';

export interface TripTimestampsValues {
  refetchInvoiceSummary: boolean;
  loading: boolean;
  updating: boolean;
  summary?: InvoiceSummary;
}

export const initialTriptimestampsValue: TripTimestampsValues = {
  loading: true,
  updating: false,
  refetchInvoiceSummary: true,
};

export const tripTimestampsState: RecoilState<TripTimestampsValues> = atom({
  key: 'tripTimestampsState',
  default: initialTriptimestampsValue,
});
