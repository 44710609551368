import { Button, Grid, Typography } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';
import { DeepPartial } from 'react-hook-form';
import {
  ClientRole,
  CreditCardProcessor,
  Trip,
  TripClientRecord,
  useInvoiceSubmittedMutation,
} from 'src/.gen/graphql';
import { ActivatePaymentProcessorButton } from 'src/shared/components/ActivatePaymentProcessorButton';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';

type InvoiceConfirmationProps = {
  trip: Partial<Trip>;
  tripClients: DeepPartial<TripClientRecord>[];
  closeModal: () => void;
  activeProcesser: boolean;
  processorType: CreditCardProcessor;
  loading: boolean;
};

export const InvoiceConfirmation: FC<InvoiceConfirmationProps> = (props) => {
  const {
    trip,
    tripClients,
    closeModal,
    activeProcesser,
    loading,
    processorType,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [sendInvoiceToPassenger, { loading: passengarLoading }] =
    useInvoiceSubmittedMutation({
      variables: { tripId: trip.id, sendToBooker: false },
      refetchQueries: ['GetTripById'],
    });
  const [sendSquareInvoiceToBooker, { loading: bookerLoading }] =
    useInvoiceSubmittedMutation({
      variables: { tripId: trip.id, sendToBooker: true },
      refetchQueries: ['GetTripById'],
    });
  const bookingClient = tripClients?.find(
    (tripClient) => tripClient?.role === ClientRole.Booker,
  );
  return activeProcesser ? (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="body1">
          Send Invoice to Booker or Passenger of the Trip through{' '}
          {processorType}
        </Typography>
      </Grid>
      <Grid container item spacing={1} justify="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              const response = await sendInvoiceToPassenger();
              if (response?.data?.executeInvoiceSubmitted?.succeeded) {
                enqueueSnackbar(`Invoice Sent Successfully`, {
                  variant: 'success',
                });
              } else {
                enqueueSnackbar(`Failed to Send Invoice`, {
                  variant: 'error',
                });
              }
              closeModal();
            }}
            endIcon={
              passengarLoading ? <CircularProgressIndicator /> : <Send />
            }
          >
            Send To Passenger
          </Button>
        </Grid>
        {bookingClient ? (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                const response = await sendSquareInvoiceToBooker({
                  variables: { tripId: trip.id, sendToBooker: true },
                  refetchQueries: ['GetTripById'],
                });
                if (response?.data?.executeInvoiceSubmitted?.succeeded) {
                  enqueueSnackbar(`Invoice Sent Successfully`, {
                    variant: 'success',
                  });
                } else {
                  enqueueSnackbar(`Failed to Send Invoice`, {
                    variant: 'error',
                  });
                }
                closeModal();
              }}
              endIcon={bookerLoading ? <CircularProgressIndicator /> : <Send />}
            >
              Send To Booker
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  ) : (
    <ActivatePaymentProcessorButton loading={loading} type="Generic" />
  );
};
