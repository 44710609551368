import { pick } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  RatePolicy,
  RatePolicyType,
  useGetDefaultRatesQuery,
} from 'src/.gen/graphql';
import { userAtom } from 'src/authentication/atoms/AuthState';

interface useDefaultRatesResultInterface {
  defaultRates: unknown;
  refetchDefaultRates: () => void;
  rateSetId: string;
  currentMeteredRateIncrement: number;
  revertToDynamicForExcessWaitTimeFlag: boolean;
  getPolicy: (policy: RatePolicyType) => Partial<RatePolicy>;
  getPolicies: (
    policies: RatePolicyType[],
  ) => Partial<Record<RatePolicyType, RatePolicy>>;
}

export const useDefaultRates = (): useDefaultRatesResultInterface => {
  const [defaultRates, setDefaultRates] = React.useState(null);
  const [rateSetId, setRateSetId] = React.useState(null);
  const [currentMeteredRateIncrement, setCurrentMeteredRateIncrement] =
    React.useState(null);
  const [
    revertToDynamicForExcessWaitTimeFlag,
    setRevertToDynamicForExcessWaitTimeFlag,
  ] = React.useState(false);

  const user = useRecoilValue(userAtom);
  const { data: defaultRatesPayload, refetch: refetchDefaultRatesPayload } =
    useGetDefaultRatesQuery({
      variables: {
        accountId: user?.accountId,
      },
    });

  React.useEffect(() => {
    if (defaultRatesPayload) {
      try {
        const {
          ratePolicies: ratePoliciesArray,
          id,
          meteredRateIncrement,
          revertToDynamicForExcessWaitTime,
        } = defaultRatesPayload.rates.searchDefaultRates.items[0].rates[0];
        setRateSetId(id);
        setCurrentMeteredRateIncrement(meteredRateIncrement);
        setRevertToDynamicForExcessWaitTimeFlag(
          revertToDynamicForExcessWaitTime,
        );
        const ratePoliciesObject = ratePoliciesArray.reduce(
          (accumulator, current) => {
            accumulator[current.type] = { ...current, rateId: 1 };
            return accumulator;
          },
          {},
        );
        setDefaultRates(ratePoliciesObject);
      } catch (_err) {
        setDefaultRates(null);
      }
    }
  }, [defaultRatesPayload]);

  const refreshData = () => {
    setDefaultRates(null);
    refetchDefaultRatesPayload();
  };

  const getPolicy = (policy: RatePolicyType): Partial<RatePolicy> =>
    !defaultRates ? null : defaultRates[policy];

  const getPolicies = (
    policies: RatePolicyType[],
  ): Partial<Record<RatePolicyType, RatePolicy>> =>
    !defaultRates ? {} : pick(defaultRates, policies);

  return {
    defaultRates,
    refetchDefaultRates: refreshData,
    currentMeteredRateIncrement,
    revertToDynamicForExcessWaitTimeFlag,
    rateSetId,
    getPolicy,
    getPolicies,
  };
};
