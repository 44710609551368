import React from 'react';
import { Button } from '@material-ui/core';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { useSetRecoilState } from 'recoil';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import UseDifferentRate from 'src/trips/components/UseDifferentRate/UseDifferentRate';
import { Trip } from 'src/.gen/graphql';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

interface Props {
  trip: Partial<Trip>;
}

const UseDifferentRateButton: React.FC<Props> = ({ trip }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const setTimestampsState = useSetRecoilState(tripTimestampsState);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = () => {
    setTimestampsState((prev) => ({
      ...prev,
      refetchInvoiceSummary: true,
      updating: true,
    }));
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setOpenDialog(true)}
        startIcon={<AttachMoneyIcon fontSize="small" />}
      >
        Change Rate
      </Button>
      <GenericDialog
        openDialog={openDialog}
        setCloseDialog={() => setOpenDialog(false)}
        dialogTitle="Use Different Vehicle Rate"
        maxWidth="sm"
      >
        <UseDifferentRate
          trip={trip}
          onCancel={handleCloseDialog}
          onSubmit={onSubmit}
        />
      </GenericDialog>
    </React.Fragment>
  );
};

export default UseDifferentRateButton;
