import { ZoneType } from 'src/.gen/graphql';
import * as yup from 'yup';

const TransferRateFormValidation = yup.object({
  firstLocationRadiusInKm: yup.number().when('firstLocationZoneType', {
    is: (val) => val === ZoneType.Area,
    then: yup.number().moreThan(0, 'Radius shoud be greater than 0'),
  }),
  secondLocationRadiusInKm: yup.number().when('secondLocationZoneType', {
    is: (val) => val === ZoneType.Area,
    then: yup.number().moreThan(0, 'Radius shoud be greater than 0'),
  }),
  firstLocation: yup
    .object({
      placeName: yup.string().required('Required address'),
    })
    .required('Location Required')
    .typeError('Invalid Location'),
  secondLocation: yup
    .object({
      placeName: yup.string().required('Required address'),
    })
    .required('Location Required')
    .typeError('Invalid Location'),
});

export default TransferRateFormValidation;
