import {
  Grid,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
  },
  cardContainer: {
    height: '10rem',
  },
  withBottomGutter: {
    marginBottom: theme.spacing(1),
  },
  leftContainer: {
    flex: 6,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0rem .5rem',
  },
  rightContainer: {
    flex: 10,

    padding: '0rem .5rem',
  },
  statusContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  tripStatusBox: {
    borderRadius: '0.3rem',
    padding: '0.2rem 0.4rem',
    marginTop: '0.8rem',
  },
}));

interface LoadingTripListTileProps {
  variant?: 'outlined' | 'elevation';
}

const LoadingListTile: React.FC<LoadingTripListTileProps> = ({
  variant = 'elevation',
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer} elevation={1} variant={variant}>
      <CardContent>
        <Grid container>
          <Grid item container xs={12}>
            <div className={classes.leftContainer}>
              <Typography variant="body1" color="primary">
                <Skeleton />
              </Typography>
              <Typography variant="caption" color="primary">
                <Skeleton />
              </Typography>
              <Typography variant="caption" color="primary">
                <Skeleton />
              </Typography>

              <div className={classes.statusContainer}>
                <Typography
                  align="left"
                  variant="caption"
                  className={classes.tripStatusBox}
                >
                  <Skeleton width="4rem" />
                </Typography>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.rightContainer}>
              <Typography variant="body1" color="textPrimary">
                <Skeleton width="60%" />
              </Typography>

              <Typography variant="body1" color="textSecondary">
                <Skeleton />
              </Typography>

              <Typography variant="body1" color="textPrimary">
                <Skeleton width="60%" />
              </Typography>

              <Typography variant="body1" color="textSecondary">
                <Skeleton />
              </Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LoadingListTile;
