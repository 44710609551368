import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { format, isBefore } from 'date-fns';
import React from 'react';
import {
  InvoiceSummary,
  InvoiceType,
  RateCalculation,
  RateCategory,
  TransactionItemSummary,
  TransactionType,
  Trip,
  TripStatus,
} from 'src/.gen/graphql';
import { splitByCamelCase } from 'src/shared/utils/stringUtils';
import BaseRateOverrideField from '../BaseRateOverrideField';
import CurrencyValue from './CurencyValue';

interface RateBreakdownRow {
  addonId?: string; // for now we are not using it directly, but for example if we need an id on the row, we can use either the addonId, or the line entity id
  name: string;
  value: string | React.ReactNode;
  highlight?: boolean;
  type?: TransactionType;
  transaction?: TransactionItemSummary;
}

interface Column {
  id: keyof RateBreakdownRow;
  label: string;
}

const RATE_BREAKDOWN_HOURLY: Column[] = [
  { id: 'name', label: 'Type' },
  { id: 'value', label: 'Hourly' },
];

const RATE_BREAKDOWN_TRANSFER: Column[] = [
  { id: 'name', label: 'Type' },
  { id: 'value', label: 'Transfer' },
];

const RATE_BREAKDOWN_FLAT: Column[] = [
  { id: 'name', label: 'Type' },
  { id: 'value', label: 'Flat' },
];

const RATE_BREAKDOWN_SAVOYA: Column[] = [
  { id: 'name', label: 'Additional Charges' },
  { id: 'value', label: 'Amount' },
];

export const getTitleRateBrekadown = (invoice: InvoiceSummary): string => {
  if (invoice.type === InvoiceType.Payable) {
    return 'Trip Total';
  }
  return 'Estimated Trip Total';
};

export const getRateBreakdownColumns = (
  calculation: RateCalculation,
  flatRateOverride: boolean,
  savoya?: boolean,
): Column[] => {
  if (savoya) {
    return RATE_BREAKDOWN_SAVOYA;
  }
  switch (calculation) {
    case RateCalculation.Flat:
      if (flatRateOverride) return RATE_BREAKDOWN_FLAT;
      return RATE_BREAKDOWN_TRANSFER;
    case RateCalculation.PerHour:
      return RATE_BREAKDOWN_HOURLY;
    default:
      return [];
  }
};

enum RowValues {
  tripDuration = 'tripDuration',
  baseRate = 'baseRate',
  waitTotal = 'waitTotal',
  subtotal = 'subtotal',
  discountItems = 'discountItems',
  otherItems = 'otherItems',
  total = 'total',
  grandTotal = 'grandTotal',
  transactions = 'transactions',
  balance = 'balance',
}

enum SavoyaRowValues {
  waitTotal = 'waitTotal',
  discountItems = 'discountItems',
  otherItems = 'otherItems',
  discountTotal = 'discountTotal',
}

const defaultRows = Object.keys(RowValues);
const defaultSavoyaRows = Object.keys(SavoyaRowValues);

const sortByDate = (
  trans1: TransactionItemSummary,
  trans2: TransactionItemSummary,
) => {
  const date1 = new Date(trans1.createdAt);
  const date2 = new Date(trans2.createdAt);
  if (isBefore(date1, date2)) {
    return -1;
  }
  if (isBefore(date2, date1)) {
    return 1;
  }

  return 0;
};

export const getRateBreakdownRows = (
  summary: InvoiceSummary,
  trip: Partial<Trip>,
  deleteTripAddon: (tripAddonId: string) => Promise<void>,
  savoya?: boolean,
): RateBreakdownRow[] => {
  if (!summary) {
    return [];
  }

  if (summary.calculation === RateCalculation.Flat) {
    return (savoya ? defaultSavoyaRows : defaultRows)
      .slice(1)
      .flatMap(getRowData(summary, trip, deleteTripAddon, savoya))
      .filter((v) => !!v);
  }
  return (savoya ? defaultSavoyaRows : defaultRows)
    .filter((key) => ['waitTime', 'waitTotal'].indexOf(key) === -1)
    .flatMap(getRowData(summary, trip, deleteTripAddon, savoya))
    .filter((v) => !!v);
};

const getRowData =
  (
    summary: InvoiceSummary,
    trip: Partial<Trip>,
    deleteTripAddon,
    savoya?: boolean,
  ) =>
  (row: RowValues | SavoyaRowValues): RateBreakdownRow | RateBreakdownRow[] => {
    let result: RateBreakdownRow | RateBreakdownRow[];
    const discountItems = summary.otherItems.filter(
      ({ category }) => category === RateCategory.Discount,
    );
    const otherItems = summary.otherItems.filter(
      ({ category }) => category !== RateCategory.Discount,
    );
    const paymentItems = summary.transactions.filter(({ type }) =>
      savoya ? false : type === TransactionType.Payment,
    );
    const refundItems = summary.transactions.filter(({ type }) =>
      savoya ? false : type === TransactionType.Refund,
    );

    const discountTotal =
      otherItems.reduce((acc, item) => acc + item.total, 0) +
      discountItems.reduce((acc, item) => acc + item.total, 0);

    const total = discountTotal + summary.baseTotal + summary.waitTotal;

    switch (true) {
      case row === RowValues.tripDuration:
        result = {
          name: 'Trip Duration',
          value: `${summary.baseQuantity?.toFixed(2)} hrs`,
        };
        break;
      case row === RowValues.waitTotal && summary.waitTotal > 0:
        result = {
          name: 'Additional Wait Time',
          value: (
            <CurrencyValue sign="+" value={summary.waitTotal?.toFixed(2)} />
          ),
        };
        break;
      case row === RowValues.baseRate:
        result = {
          name: 'Base Rate',
          value: (
            <BaseRateOverrideField
              baseRateValue={summary.baseUnitPrice}
              calculation={summary.calculation}
              overrided={!trip?.flatRateOverride && !!trip?.baseRateOverride}
              tripId={trip?.id}
              tripStatus={trip?.status}
              flatRateOverrided={!!trip?.flatRateOverride}
            />
          ),
        };
        break;
      case row === RowValues.subtotal:
        result = {
          name: 'Trip Subtotal',
          highlight: true,
          value: (
            <CurrencyValue
              sign="="
              value={(summary.baseTotal + summary.waitTotal ?? 0)?.toFixed(2)}
              bold
            />
          ),
        };
        break;
      case row === RowValues.otherItems:
        result = otherItems.map(({ name, description, total, addonId }) => ({
          name: `${name} ${description ? `- ${description}` : ''}`,
          value: (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              {![
                TripStatus.Billed,
                TripStatus.Cancelled,
                TripStatus.Paid,
              ].includes(trip?.status) &&
                !savoya && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      if (addonId) {
                        deleteTripAddon(addonId);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              <CurrencyValue sign="+" value={total?.toFixed(2)} />
            </Box>
          ),
        }));
        break;
      case row === RowValues.total:
        result = {
          name: 'Trip Total',
          highlight: true,
          value: <CurrencyValue sign="=" value={total?.toFixed(2)} bold />,
        };
        break;
      case row === RowValues.discountItems:
        result = discountItems.map(({ name, description, total, addonId }) => ({
          name: `${name} ${description ? `- ${description}` : ''}`,
          value: (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              {![
                TripStatus.Billed,
                TripStatus.Cancelled,
                TripStatus.Paid,
              ].includes(trip?.status) && (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    if (addonId) {
                      deleteTripAddon(addonId);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <CurrencyValue sign="-" value={Math.abs(total)?.toFixed(2)} />
            </Box>
          ),
        }));
        break;
      case row === RowValues.transactions:
        result = [...paymentItems, ...refundItems]
          .sort(sortByDate)
          .map((trans) => {
            const {
              createdAt,
              type,
              amount,
              paymentMethod,
              paymentMethodType,
            } = trans;
            return {
              name: `${format(new Date(createdAt), 'MM/dd/yyyy')} - ${type} - ${
                paymentMethod
                  ? paymentMethod.description.split('-').pop()
                  : splitByCamelCase(paymentMethodType)
              }`,
              type,
              transaction: { ...trans },
              value: (
                <CurrencyValue
                  sign={type === TransactionType.Payment ? '+' : '-'}
                  value={amount?.toFixed(2)}
                />
              ),
            };
          });
        break;
      case row === RowValues.grandTotal:
        result = {
          name: 'Grand Total',
          value: (
            <CurrencyValue sign="=" value={summary.total?.toFixed(2)} bold />
          ),
        };
        break;
      case row === RowValues.balance:
        result = {
          name: 'Balance',
          value: (
            <CurrencyValue sign="" value={summary.balance?.toFixed(2)} bold />
          ),
        };
        break;
      case row === SavoyaRowValues.discountTotal:
        result = {
          name: 'Total incidentals',
          value: (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <CurrencyValue sign="" value={discountTotal?.toFixed(2)} bold />
            </Box>
          ),
        };
        break;
      default:
        break;
    }
    return result;
  };
