import React, { useState } from 'react';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { GenericDriver, GenericVehicle, Trip } from 'src/.gen/graphql';
import TripAssignmentForm from './TripAssignmentForm';

interface TripAssignmentDialogProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  trip: Partial<Trip>;
  onConfirm?: (
    vehicle: Partial<GenericVehicle>,
    driver: Partial<GenericDriver>,
  ) => Promise<void>;
  noCompUpgrade?: boolean;
}

const TripAssignmentDialog: React.FC<TripAssignmentDialogProps> = ({
  isOpen,
  setIsOpen,
  trip,
  onConfirm,
  noCompUpgrade,
}) => {
  const { sm } = useBreakpoints();

  const onCancel = () => {
    setIsOpen(false);
  };

  const [loading, setLoading] = useState<boolean>(false);

  const onSuccess = async (vehicle: GenericVehicle, driver: GenericDriver) => {
    setLoading(true);
    if (onConfirm) {
      await onConfirm(vehicle, driver);
    }
    setLoading(false);
    setIsOpen(false);
  };

  return (
    <GenericDialog
      setCloseDialog={onCancel}
      dialogTitle="Trip Assignment."
      openDialog={isOpen}
      maxWidth={sm ? 'xl' : 'xs'}
    >
      <TripAssignmentForm
        trip={trip}
        onCancel={onCancel}
        onSuccess={onSuccess}
        loading={loading}
        noCompUpgrade={noCompUpgrade}
      />
    </GenericDialog>
  );
};

export default TripAssignmentDialog;
