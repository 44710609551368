import React, { FC } from 'react';
import StandardHourlyRatesTable from './StandardHourlyRatesTable/StandardHourlyRatesTable';

const StandardHourlyRates: FC = () => {
  return (
    <div data-testid="standard_rates_list">
      <StandardHourlyRatesTable />
    </div>
  );
};

export default StandardHourlyRates;
