import {
  Typography,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  Box,
  Divider,
  Grid,
} from '@material-ui/core';
import { FieldArray, Field, FormikProps } from 'formik';
import React from 'react';
import {
  AdjustmentCalculation,
  GetAccountAdjustmentsQuery,
} from 'src/.gen/graphql';

export type AccountAdjustmentsResult =
  GetAccountAdjustmentsQuery['accountAdjustments']['all']['items'];
interface Props extends FormikProps<{ currentAdjustments }> {
  validateAdjustmentsRef: (id: string) => void;
}

const SelectTripAdjustmentsMobile: React.FC<Props> = ({
  values,
  setFieldValue,
  validateAdjustmentsRef,
}) => {
  const classes = useStyles();
  return (
    <FieldArray
      name="currentAdjustments"
      render={() => (
        <React.Fragment>
          {values?.currentAdjustments?.map((row, index) => (
            <Box>
              <Grid className={classes.container} container>
                <Grid justify="space-between" container>
                  <Field
                    as="h5"
                    name={`currentAdjustments.${index}.rateType.name`}
                  >
                    <Typography variant="body1">{row.rateType.name}</Typography>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name={`currentAdjustments.${index}.amount`}>
                    {({ field, meta }) => (
                      <TextField
                        className={classes.input}
                        name={`currentAdjustments.${index}.amount`}
                        variant="outlined"
                        label="AMOUNT"
                        {...field}
                        error={!!meta.error && meta.touched}
                        helperText={
                          !!meta.error && meta.touched ? meta.error : ''
                        }
                        onChange={(e) => {
                          const regex = /^\d+(\.\d{0,2})?$/;
                          const { value } = e.target;
                          if (!value || regex.test(value)) {
                            setFieldValue(
                              `currentAdjustments.${index}.amount`,
                              value,
                            );
                          }
                          validateAdjustmentsRef(row.id);
                        }}
                        fullWidth
                        placeholder="Enter amount"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <Field
                              name={`currentAdjustments.${index}.calculation`}
                              data-testid="currentAdjustments_calculation_field"
                            >
                              {() => (
                                <Select
                                  onChange={(e) => {
                                    setFieldValue(
                                      `currentAdjustments.${index}.calculation`,
                                      e.target.value,
                                    );
                                    validateAdjustmentsRef(row.id);
                                  }}
                                  value={row.calculation || ''}
                                >
                                  <MenuItem value={AdjustmentCalculation.Flat}>
                                    $
                                  </MenuItem>
                                  <MenuItem
                                    value={AdjustmentCalculation.Percentage}
                                  >
                                    %
                                  </MenuItem>
                                </Select>
                              )}
                            </Field>
                          ),
                        }}
                        value={row.amount || ''}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name={`currentAdjustments.${index}.note`}>
                    {({ field, meta }) => (
                      <TextField
                        variant="outlined"
                        label="NOTE"
                        placeholder="Enter note"
                        {...field}
                        error={!!meta.error && meta.touched}
                        helperText={
                          !!meta.error && meta.touched ? meta.error : ''
                        }
                        onChange={(
                          e: React.ChangeEvent<{
                            value: string;
                          }>,
                        ) => {
                          setFieldValue(
                            `currentAdjustments.${index}.note`,
                            e.target.value,
                          );
                          validateAdjustmentsRef(row.id);
                        }}
                        type="text"
                        value={row?.note?.length > 0 ? row?.note : ''}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Divider
                style={{
                  height: 1,
                  marginLeft: 8,
                  marginRight: 8,
                }}
              />
            </Box>
          ))}
        </React.Fragment>
      )}
    />
  );
};

export default SelectTripAdjustmentsMobile;

const useStyles = makeStyles((theme) => ({
  tableHead: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.1em',
    fontSize: theme.typography.caption.fontSize,
  },
  container: {
    gap: theme.spacing(2),
    padding: `0 ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
  input: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
}));
