import React from 'react';
import { TierType } from 'src/.gen/graphql';
import { PaidFeatureEntities } from 'src/shared/types/subscription';

type PermissionContextType = {
  isTierCompliant: (tierType: TierType) => boolean;
  isLimitCompliant: (
    tierType: TierType,
    entity: PaidFeatureEntities,
    entityCount: number,
  ) => boolean;
};

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider.
// The permission will not be granted unless a provider says otherwise
const defaultBehaviour: PermissionContextType = {
  isTierCompliant: () => false,
  isLimitCompliant: () => false,
};

// Create the context
const PermissionContext =
  React.createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;
