import { InputAdornment, Tooltip } from '@material-ui/core';
import React from 'react';

import { AirplanemodeActive, LocationCity, PinDrop } from '@material-ui/icons';

interface SavoyaLocationAutocompleteTooltipProps {
  setLocationType: (locationType: 'city' | 'airport') => void;
  locationType: 'city' | 'airport' | 'preferences';
}

const renderIcon = (type: 'city' | 'airport' | 'preferences', props) => {
  switch (type) {
    case 'city':
      return <LocationCity {...props} />;
    case 'airport':
      return <AirplanemodeActive {...props} />;
    case 'preferences':
      return <PinDrop {...props} />;
    default:
      return null;
  }
};

const SavoyaLocationAutocompleteTooltip: React.FC<SavoyaLocationAutocompleteTooltipProps> =
  ({ setLocationType, locationType }) => {
    return (
      <Tooltip title="Change Search Type">
        <InputAdornment
          position="start"
          onClick={() => {
            setLocationType(locationType === 'city' ? 'airport' : 'city');
          }}
          style={{ cursor: 'pointer' }}
        >
          {renderIcon(locationType, { color: 'primary' })}
        </InputAdornment>
      </Tooltip>
    );
  };

export default SavoyaLocationAutocompleteTooltip;
