import {
  SavoyaSharedTripStatus,
  SharedTripStatus,
  TripCommandMeta,
  TripStatus,
} from 'src/.gen/graphql';
import { splitByCamelCase } from './stringUtils';

const SAVOYA_COMMANDS = [
  'AssignTrip',
  'Waiting',
  'DepartPickup',
  'ArriveDropoff',
  'AcceptNetworkOffer',
  'ConfirmNetworkRequest',
  'DeclineNetworkOffer',
  'DeclineNetworkConfirmationRequest',
  'ReassignmentRequested',
];

const completedStatuses = [
  TripStatus.Paid,
  TripStatus.Billed,
  TripStatus.TripComplete,
];
export const parseNameCommand = (
  command: TripCommandMeta,
  status: TripStatus,
  isSavoyaTrip: boolean,
): string => {
  const { name, typeName } = command;

  switch (true) {
    case typeName === 'CancelTrip' && status === TripStatus.Draft:
      return 'Discard Trip';
    case typeName === 'Waiting':
      return 'Waiting At Stop';
    case typeName === 'ArriveDropoff' && status === TripStatus.Waiting:
      return 'Final Stop';
    case (typeName === 'DeclineNetworkOffer' ||
      typeName === 'DeclineNetworkConfirmationRequest') &&
      isSavoyaTrip:
      return 'Reject Trip';
    default:
      return name;
  }
};

type TypeNameButton = 'Save' | 'Discard' | 'Update';

const defaultNames = {
  Save: 'Save Quote',
  Discard: 'Cancel',
  Update: 'Update',
};

export const parseButtonLabelEditView = (
  type: TypeNameButton,
  status: TripStatus,
): string => {
  switch (true) {
    case type === 'Discard' && !status:
      return 'Cancel';
    case type === 'Discard' &&
      (status === TripStatus.Draft || status === TripStatus.AwaitingAssignment):
      return 'Cancel';
    case type === 'Discard' && status === TripStatus.Quoted:
      return 'Discard Quote';
    case type === 'Update' &&
      (!status || status === TripStatus.Draft || status === TripStatus.Quoted):
      return 'Submit Trip Request';
    default:
      return defaultNames[type];
  }
};

export const displayButtonEditView = (
  type: TypeNameButton,
  status: TripStatus,
): boolean => {
  switch (true) {
    case type === 'Save' && status && status !== TripStatus.Draft:
    case type === 'Save' && !status:
      return false;
    default:
      return true;
  }
};

export const filterTripCommands = (
  status: TripStatus,
  savoyaSharedStatus: SavoyaSharedTripStatus,
  command: TripCommandMeta,
  iOwnThisTrip: boolean,
  isShared?: boolean,
  isSavoyaTrip?: boolean,
): boolean => {
  if (command.hidden) {
    return false;
  }

  if (
    isSavoyaTrip &&
    (savoyaSharedStatus === SavoyaSharedTripStatus.Cancelled ||
      status === TripStatus.TripComplete ||
      status === TripStatus.Billed ||
      status === TripStatus.Paid)
  ) {
    return false;
  }

  if (isSavoyaTrip && !SAVOYA_COMMANDS.includes(command.typeName)) {
    return false;
  }

  // Hiding this on the Web UI for now although it is a valid action for mobile
  if (status === TripStatus.Waiting) {
    if (command.typeName === 'Circling') {
      return false;
    }
  }
  if (command.typeName === 'ArriveGarage') {
    if (status === TripStatus.TransportComplete) {
      return true;
    }
    return false;
  }

  if (!iOwnThisTrip) {
    switch (true) {
      case command.typeName === 'ReassignmentRequested' && isShared:
        return false;
      case command.typeName === 'RevokeNetworkSharedTrip':
        return false;
      case command.typeName === 'RecordTransaction' ||
        command.typeName === 'SendQuote':
        return false;
      case command.typeName === 'CancelTrip':
        return false;
      case command.typeName === 'InvoiceSubmitted':
        return false;
      case command.typeName === 'InvoicePaid':
        return false;
      default:
        return true;
    }
  }

  switch (true) {
    case command.typeName === 'ReassignmentRequested' && isShared:
      return false;
    case command.typeName === 'DeclineNetworkOffer':
      return false;
    case command.typeName === 'DeclineNetworkConfirmationRequest':
      return false;
    case command.typeName === 'RecordTransaction' ||
      command.typeName === 'SendQuote':
      return false;
    case command.typeName === 'CancelTrip' && status === TripStatus.Draft:
      return false;
    case command.typeName === 'InvoiceSubmitted':
      return false;
    case command.typeName === 'InvoicePaid':
      return false;
    default:
      return true;
  }
};

export function getStatusLabel(
  status: TripStatus | SharedTripStatus,
  savoyaSharedStatus?: SavoyaSharedTripStatus,
  isSavoyaTrip?: boolean,
  isAvailableTrip?: boolean,
): string {
  if (savoyaSharedStatus === SavoyaSharedTripStatus.ModPending) {
    return 'Modification Pending';
  }
  if (
    savoyaSharedStatus === SavoyaSharedTripStatus.Cancelled &&
    !completedStatuses.includes(status as TripStatus) &&
    !isAvailableTrip
  ) {
    return 'Cancelled';
  }
  if (isSavoyaTrip || !!savoyaSharedStatus) {
    if (status === TripStatus.Billed) {
      return 'Closed Out';
    }
    if (status === TripStatus.PendingNetworkConfirmation) {
      return 'Pending Trip Offer';
    }
    if (status === TripStatus.AwaitingAssignment) {
      return 'Pending Assignment';
    }
    if (status === TripStatus.TripComplete) {
      return 'Billable';
    }
  }

  switch (status) {
    case TripStatus.EnRouteToPickup:
      return 'En Route';
    case TripStatus.OnLocation:
      return 'Driver Onsite';
    case TripStatus.TransportComplete:
      return 'Passenger Dropped';
    case TripStatus.TripComplete:
      return 'Completed';
    case TripStatus.Confirmed:
      return 'Driver Assigned';
    default:
      return splitByCamelCase(status);
  }
}

export function getTripStatusTimestampLabel(
  status: TripStatus,
  sharedSavoyaStatus?: SavoyaSharedTripStatus,
): string {
  switch (status) {
    case TripStatus.EnRouteToPickup:
      return 'Garage Out';
    case TripStatus.TripComplete:
      return 'Garage In';
    default:
      return getStatusLabel(status, sharedSavoyaStatus);
  }
}

export function filteredTripStatuses(): string[] {
  return Object.keys(TripStatus).filter(
    (k) => k !== 'EnRouteToGarage' && k !== 'Preparing',
  );
}

export function completedStatus(): boolean {
  return true;
}
