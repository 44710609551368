import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  subscriptionPlanAtom,
  userAtom,
} from 'src/authentication/atoms/AuthState';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SubscriptionContext from './SubscriptionContext';

const SubscriptionProvider: React.FunctionComponent = ({ children }) => {
  const { subscriptions: subscriptionsEnabled = false } = useFlags();
  const user = useRecoilValue(userAtom);
  const subscriptionPlan = useRecoilValue(subscriptionPlanAtom);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionsEnabled,
        accountId: user?.accountId,
        userRole: user?.userRole,
        subscriptionId: subscriptionPlan?.id,
        currentTier: subscriptionPlan?.currentTier,
        paymentMethod: subscriptionPlan?.paymentMethod,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
