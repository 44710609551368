import React from 'react';
import { Typography } from '@material-ui/core';
import { Trip } from 'src/.gen/graphql';

export const vehicleLabel = (
  trip: Partial<Trip> | undefined,
): JSX.Element | string => {
  if (!trip) {
    return '';
  }

  return (
    <Typography variant="body2">
      {trip?.vehicle?.modelName ?? 'Unassigned'}{' '}
      <span
        style={{
          color: '#808080',
          fontSize: '0.8rem',
        }}
      >
        ({trip?.vehicle?.licenseNumber ?? ''})
      </span>
    </Typography>
  );
};
