import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  Grid,
  TextField,
  DialogActions,
  Box,
  Button,
  FormControl,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { DatePicker } from '@material-ui/pickers';
import { format, parse } from 'date-fns';
import { SavoyaVehicleType } from 'src/rates/api/SavoyaRateQueries';
import { usCurrencyValidator } from 'src/rates/utils/currencyValidation';

export type SavoyaHourlyFormInitialValues = {
  vehicleType?: SavoyaVehicleType;
  amount?: string;
  cancellationHours?: number;
  minimum?: number;
  startDate?: string;
  endDate?: string;
};

interface FormProps {
  action: 'edit' | 'create';
  initialValues?: SavoyaHourlyFormInitialValues;
  vehicleTypes: SavoyaVehicleType[];
  onSubmit: (values: SavoyaHourlyFormInitialValues) => Promise<void>;
  onCancel: () => void;
}

const defaultInitialValues: SavoyaHourlyFormInitialValues = {
  vehicleType: null,
  amount: null,
  cancellationHours: null,
  minimum: null,
  startDate: null,
  endDate: null,
};

const useStyles = makeStyles(() => ({
  MainInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  SmallMainInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '0.8rem',
  },
}));

const SavoyaHourlyRateBaseForm: React.FC<FormProps> = (props) => {
  const {
    action,
    initialValues = defaultInitialValues,
    onSubmit,
    onCancel,
    vehicleTypes,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { sm } = useBreakpoints();
  const [vehicleType, setVehicleType] = useState(initialValues.vehicleType);

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      vehicleType: Yup.object().shape({
        id: Yup.string()
          .required('Vehicle Type Required')
          .typeError('Vehicle Type Required'),
      }),
      amount: Yup.number()
        .required('Transfer Amount is Required')
        .typeError('Transfer Amount is Required'),
      cancellationHours: Yup.number()
        .moreThan(0, 'Must be greater than 0')
        .lessThan(48, 'Maximum Cancellation Hours: 48')
        .required('Cancellation Hours is required')
        .typeError('Cancellation Hours is required'),
      minimum: Yup.number()
        .moreThan(0, 'Must be greater than 0')
        .lessThan(48, 'Maximum Hours: 48')
        .required('Minimum Hours is required')
        .typeError('Minimum Hours is required'),
      startDate: Yup.string()
        .required('From Date Required')
        .typeError('From Date Require'),
      endDate: Yup.string().nullable(),
    }),
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      try {
        await onSubmit({ ...values });
        actions.setStatus({ success: true });
      } catch (err) {
        enqueueSnackbar(`Error while updating/creating`, {
          variant: 'error',
        });
      } finally {
        actions.setStatus({ success: false });
        actions.setSubmitting(false);
      }
    },
  });

  const {
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmitting,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} data-testid="savoya_transfer_rate_form">
        <Grid
          container
          item
          xs={12}
          direction="row"
          spacing={2}
          className={
            sm ? classes.SmallMainInputContainer : classes.MainInputContainer
          }
        >
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Grid item xs={12} md={12}>
                {vehicleTypes?.length ? (
                  <TextField
                    fullWidth
                    label="Select Vehicle Type"
                    name="vehicleType"
                    onChange={(e) => {
                      e.preventDefault();
                      const vehicleTypeSelected = vehicleTypes.find(
                        (el) => el.id?.toString() === e.target.value,
                      );
                      setVehicleType(vehicleTypeSelected);
                      setFieldValue('vehicleType', vehicleTypeSelected);
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={
                      values?.vehicleType?.id ||
                      vehicleType?.id ||
                      vehicleTypes?.length
                        ? vehicleTypes[0]
                        : null
                    }
                    required
                    variant="outlined"
                    disabled={action === 'edit'}
                  >
                    {vehicleTypes?.map((vehicleClass) => (
                      <option key={vehicleClass.id} value={vehicleClass.id}>
                        {vehicleClass.name}
                      </option>
                    ))}
                  </TextField>
                ) : null}
              </Grid>
            </FormControl>
          </Grid>
          <Grid container item direction="row" spacing={1}>
            <Grid item xs={4}>
              <TextField
                error={Boolean(touched.amount && errors.amount)}
                fullWidth
                helperText={touched.amount && errors.amount}
                label="Amount"
                name="amount"
                required
                onBlur={handleBlur}
                onChange={(e) => {
                  if (usCurrencyValidator(e.target.value)) {
                    return handleChange(e);
                  }
                  return null;
                }}
                value={values?.amount}
                variant="outlined"
                inputProps={{
                  'data-testid': 'hourly_amount_field',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={Boolean(
                  touched.cancellationHours && errors.cancellationHours,
                )}
                fullWidth
                helperText={
                  touched.cancellationHours && errors.cancellationHours
                }
                label="Cancellation Hours"
                name="cancellationHours"
                type="number"
                required
                onBlur={(e) => {
                  e.target.value = Math.round(
                    parseFloat(e.target.value),
                  ).toFixed(0);
                  setFieldValue('cancellationHours', e.target.value);
                  return handleBlur(e);
                }}
                onChange={handleChange}
                value={values.cancellationHours}
                variant="outlined"
                inputProps={{
                  'data-testid': 'cancellation_hours_field',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={Boolean(
                  touched.cancellationHours && errors.cancellationHours,
                )}
                fullWidth
                helperText={
                  touched.cancellationHours && errors.cancellationHours
                }
                label="Minimum Hours"
                name="minimum"
                type="number"
                required
                onBlur={(e) => {
                  e.target.value = (
                    Math.round(parseFloat(e.target.value) * 4) / 4
                  ).toFixed(2);
                  setFieldValue('minimum', e.target.value);
                  return handleBlur(e);
                }}
                onChange={handleChange}
                value={values.minimum}
                variant="outlined"
                inputProps={{
                  'data-testid': 'minimum_hours_field',
                }}
              />
            </Grid>
          </Grid>
          <Grid container item direction="row" spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                clearable
                label="Start Date"
                value={
                  values.startDate
                    ? parse(
                        values.startDate,
                        'yyyy-MM-dd',
                        new Date(values.startDate),
                      )
                    : null
                }
                onChange={(newValue) => {
                  const parsedDate = newValue
                    ? format(newValue, 'yyyy-MM-dd')
                    : null;
                  setFieldValue('startDate', parsedDate);
                }}
                required
                inputVariant="outlined"
                fullWidth
                format="yyyy-MM-dd"
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                clearable
                label="End Date"
                value={
                  values.endDate
                    ? parse(
                        values.endDate,
                        'yyyy-MM-dd',
                        new Date(values.endDate),
                      )
                    : null
                }
                onChange={(newValue) => {
                  const parsedDate = newValue
                    ? format(newValue, 'yyyy-MM-dd')
                    : null;
                  setFieldValue('endDate', parsedDate);
                }}
                inputVariant="outlined"
                fullWidth
                format="MM/dd/yyyy"
              />
            </Grid>
          </Grid>
        </Grid>

        <Box mt={3}>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
              data-testid="client_cancel_button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting || !formik.isValid}
              data-testid="client_save_button"
            >
              {isSubmitting ? <CircularProgress color="secondary" /> : 'Save'}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </FormikProvider>
  );
};

export default SavoyaHourlyRateBaseForm;
