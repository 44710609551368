import { IconButton, styled, Theme } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import {
  RateCalculation,
  TripStatus,
  useExecuteOverrideBaseRateMutation,
} from 'src/.gen/graphql';
import { useRatesStyles } from 'src/rates/components/RatesDashboard';
import TextLineInput from 'src/rates/components/TextLineInput/TextLineInput';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { tripTimestampsState } from '../atoms/tripTimestampsAtom';
import { COMPLETED_STATUS } from './TripRateBreakdown/TripRateBreakdown';

interface Props {
  baseRateValue: number;
  calculation: RateCalculation;
  overrided: boolean;
  tripId: string;
  tripStatus: TripStatus;
  flatRateOverrided: boolean;
}

interface Inputs {
  baseRate: number;
}

const CustomForm = styled('form')(
  ({ theme, overrided }: { theme: Theme; overrided?: boolean }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'auto',
    flexDirection: 'row',
    '& .MuiTypography-body1': {
      fontSize: '14px',
    },
    '& input': {
      fontSize: '14px',
      backgroundColor: overrided
        ? theme.palette.primary.main
        : theme.palette.backgroundColor.main,
      color: theme.palette.textLight.main,
      width: '50px',
    },
    '& div': {
      flexWrap: 'nowrap',
    },
  }),
);

const ResetButton = styled(IconButton)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: 'auto',
  flexDirection: 'row',
  '& .MuiSvgIcon-root': {
    width: '0.7em',
    height: '0.7em',
  },
}));

const BaseRateOverrideField: React.FC<Props> = ({
  baseRateValue,
  calculation,
  overrided,
  tripId,
  tripStatus,
  flatRateOverrided,
}) => {
  const classes = useRatesStyles();
  const { sm } = useBreakpoints();
  const setTimestampsState = useSetRecoilState(tripTimestampsState);
  const tripCompleted = COMPLETED_STATUS.includes(tripStatus);

  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
      baseRate: 0,
    },
  });

  const [executeOverrideBaseRate] = useExecuteOverrideBaseRateMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const valid = Number(data.baseRate || 0) !== baseRateValue;
    if (valid) {
      const result = await executeOverrideBaseRate({
        variables: {
          command: {
            tripId,
            newBaseRate: Number(data.baseRate) || baseRateValue,
          },
        },
        refetchQueries: ['GetTripById'],
      });

      if (result.data.executeOverrideBaseRate.succeeded) {
        setTimestampsState((prev) => ({
          ...prev,
          refetchInvoiceSummary: true,
          updating: true,
        }));
      }
    }
  };

  const resetBaseRate = async () => {
    const result = await executeOverrideBaseRate({
      variables: {
        command: {
          tripId,
          newBaseRate: undefined,
        },
      },
      refetchQueries: ['GetTripById', 'getEstimatedSummary'],
    });

    if (result.data.executeOverrideBaseRate.succeeded) {
      setTimestampsState((prev) => ({
        ...prev,
        refetchInvoiceSummary: true,
        updating: true,
      }));
    }
  };

  const baseRate = register('baseRate');

  React.useEffect(() => {
    if (baseRateValue) {
      setValue('baseRate', baseRateValue);
    }
  }, [baseRateValue]);

  return (
    <React.Fragment>
      <CustomForm
        overrided={overrided}
        className={sm ? classes.smallFormStyles : classes.formStyles}
        data-testid="override_base_rate_form"
        onSubmit={(e) => e.preventDefault()}
      >
        {overrided && (
          <ResetButton
            color="primary"
            aria-label="Reset"
            style={{ fontSize: '14px' }}
            onClick={resetBaseRate}
          >
            <Refresh />
          </ResetButton>
        )}
        <TextLineInput
          text={
            RateCalculation.PerHour === calculation
              ? 'x $$baseRate/hr'
              : '$$baseRate'
          }
          className="textSize"
          inputs={[
            {
              component: () => (
                <input
                  data-testid="override_base_rate_field"
                  className={classes.inputStyles}
                  defaultValue={0}
                  type="number"
                  onChange={baseRate.onChange}
                  ref={baseRate.ref}
                  name={baseRate.name}
                  onBlur={(e) => {
                    baseRate.onBlur(e);
                    handleSubmit(onSubmit)();
                  }}
                  disabled={flatRateOverrided || tripCompleted}
                  onFocus={(event) => event.target.select()}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              ),
              id: '$baseRate',
            },
          ]}
        />
      </CustomForm>
    </React.Fragment>
  );
};

export default BaseRateOverrideField;
