import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  inputStyles: {
    margin: '3px',
  },
}));

interface InputSettings {
  component: FC;
  id: string;
}

interface TextLineInputProps {
  inputs: Array<InputSettings>;
  text: string;
  className?: string;
}

const TextLineInput: FC<TextLineInputProps> = ({ inputs, text, className }) => {
  const classes = useStyles();

  const renderElements = (): Array<React.ReactNode> => {
    const elements = [];

    inputs.reduce((remainingText, input, currentIndex) => {
      const { component: Component, id } = input;
      const texts = remainingText.split(id);
      if (texts[0]) {
        const words = texts[0].split(' ');
        words.forEach((word) => {
          elements.push(
            <Typography key={`text_${currentIndex}`} variant="body1">
              {word}
            </Typography>,
          );
        });
      }

      if (texts.length > 1) {
        elements.push(<Component />);
        if (currentIndex === inputs.length - 1 && texts[1]) {
          const words = texts[1].split(' ');
          words.forEach((word) => {
            elements.push(
              <Typography key={`text_${currentIndex}`} variant="body1">
                {word}
              </Typography>,
            );
          });
        }
      }

      return texts[1];
    }, text);

    return elements;
  };

  return (
    <div className={clsx(classes.mainContainer, className)}>
      {renderElements().map((e, eIndex) => {
        return (
          <span key={`span_${eIndex}`} className={classes.inputStyles}>
            {' '}
            {e}{' '}
          </span>
        );
      })}
    </div>
  );
};

export default TextLineInput;
