import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Typography,
  DialogActions,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { Fragment, useState } from 'react';
import * as yup from 'yup';
import {
  ClientRole,
  EmailTypeEnum,
  PhoneTypeEnum,
  Trip,
  TripClientRecord,
  TripNotificationSubscription,
  useGetClientsByEmailAndAccountLazyQuery,
  useGetNotificationSubscriptionByTripClientIdLazyQuery,
  useInsertClientMutation,
  useUpdateClientMutation,
  useUpdateTripNotificationSubscriptionByTripClientMutation,
} from 'src/.gen/graphql';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import BaseEnhancedDialog from 'src/shared/components/EnhancedDialog/BaseEnhandedDialog';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  duplicatedEmailClient,
  tripUpdateClientState,
} from 'src/trips/atoms/tripUpdateClientAtom';
import {
  parseNotificationSubscriptions,
  parseNotificationTypesSubscribed,
} from 'src/trips/atoms/tripFormAtom/tripFormAtomParseUtils';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { phoneRegExp } from 'src/shared/utils/phoneRegExp';
import { DeepPartial } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { isNaN } from 'lodash';
import DuplicatedEmailWarning from '../TripForm/DuplicatedEmailWarning';
import PassengerContactTripNotificationSubscriptions from '../TripForm/PassengerContactTripNotificationSubscriptions';

interface TripClientDialogFormProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  setDialogAction: (newValue: string) => void;
  setTripClient: (newValue: DeepPartial<TripClientRecord> | null) => void;
  trip: Partial<Trip>;
  tripClient: DeepPartial<TripClientRecord> | undefined;
  action: 'edit' | 'new';
}

const TripClientDialogForm: React.FC<TripClientDialogFormProps> = ({
  isOpen,
  setIsOpen,
  setDialogAction,
  setTripClient,
  trip,
  action,
  tripClient,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [emailValidated, setValidatedEmail] = useState(true);
  const [tripClientNotifications, setTripClientNotifications] =
    useState<DeepPartial<TripNotificationSubscription>[]>(undefined);

  const [updateTripNotificationSubscriptions] =
    useUpdateTripNotificationSubscriptionByTripClientMutation();

  const [addTripClient] = useInsertClientMutation({ errorPolicy: 'all' });
  const [loading, setLoading] = useState(false);

  const [updateTripClient] = useUpdateClientMutation({ errorPolicy: 'all' });
  const setTripClienState = useSetRecoilState(tripUpdateClientState);
  const user = useRecoilValue(userAtom);
  const duplicatedEmailError = useRecoilValue(duplicatedEmailClient);
  const setDuplicatedEmailError = useSetRecoilState(duplicatedEmailClient);
  const [
    validateUniqueEmail,
    {
      data: validEmailData,
      loading: validatingEmail,
      called: calledEmailValidation,
      refetch: refetchValidEmail,
    },
  ] = useGetClientsByEmailAndAccountLazyQuery();

  const [getTripClientNotificaiton, { data: tripClientNotificationsResponse }] =
    useGetNotificationSubscriptionByTripClientIdLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  React.useEffect(() => {
    if (tripClient?.id) {
      getTripClientNotificaiton({
        variables: { tripClientId: tripClient?.id },
      });
    }
  }, [tripClient]);

  React.useEffect(() => {
    if (
      !isNaN(
        tripClientNotificationsResponse?.tripNotificationSubscriptions
          ?.byTripClientId?.total,
      )
    ) {
      setTripClientNotifications(
        tripClientNotificationsResponse?.tripNotificationSubscriptions
          ?.byTripClientId?.items,
      );
    }
  }, [tripClientNotificationsResponse]);

  React.useEffect(() => {
    if (
      !validatingEmail &&
      validEmailData?.clientQueries?.getByEmailAndAccount?.length
    ) {
      const email = validEmailData?.clientQueries?.getByEmailAndAccount[0]
        ?.emails?.length
        ? validEmailData?.clientQueries?.getByEmailAndAccount[0]?.emails[0]
            ?.email
        : null;
      const phoneNumber = validEmailData?.clientQueries?.getByEmailAndAccount[0]
        ?.emails?.length
        ? validEmailData?.clientQueries?.getByEmailAndAccount[0]
            ?.phoneNumbers[0]?.phoneNumber
        : null;
      const name = validEmailData?.clientQueries?.getByEmailAndAccount[0]?.name;

      setValidatedEmail(false);
      setDuplicatedEmailError(
        {
          name,
          phoneNumber,
          email,
          id: validEmailData?.clientQueries?.getByEmailAndAccount[0]?.id,
        } || {},
      );
    }
  }, [validEmailData, validatingEmail]);

  const handleExistingContact = (setFieldValue) => {
    setFieldValue('existingClientId', duplicatedEmailError.id);
    setFieldValue('email', duplicatedEmailError.email);
    setFieldValue('name', duplicatedEmailError.name);
    setFieldValue('phoneNumber', duplicatedEmailError.phoneNumber);
    setDuplicatedEmailError({});
    setValidatedEmail(true);
  };

  const handleNewContact = (setFieldValue) => {
    setFieldValue('existingClientId', undefined);
    setFieldValue('email', '');
    setFieldValue('name', '');
    setFieldValue('phoneNumber', '');
    setDuplicatedEmailError({});
    setValidatedEmail(false);
  };

  const validateEmail = async (email) => {
    if (calledEmailValidation) {
      const { data: validatedEmail } = await refetchValidEmail({
        query: email,
        accountId: user?.accountId,
      });
      const clientEmail = validatedEmail?.clientQueries?.getByEmailAndAccount[0]
        ?.emails?.length
        ? validEmailData?.clientQueries?.getByEmailAndAccount[0]?.emails[0]
            ?.email
        : null;
      const clientPhoneNumber = validatedEmail?.clientQueries
        ?.getByEmailAndAccount[0]?.emails?.length
        ? validEmailData?.clientQueries?.getByEmailAndAccount[0]
            ?.phoneNumbers[0]?.phoneNumber
        : null;
      const clientName =
        validatedEmail?.clientQueries?.getByEmailAndAccount[0]?.name;

      setDuplicatedEmailError(
        {
          name: clientName,
          email: clientEmail,
          phoneNumber: clientPhoneNumber,
          id: validatedEmail?.clientQueries?.getByEmailAndAccount[0]?.id,
        } || {},
      );
      setValidatedEmail(
        !validatedEmail?.clientQueries?.getByEmailAndAccount?.length,
      );
    } else if (email?.length) {
      await validateUniqueEmail({
        variables: { query: email, accountId: user?.accountId },
      });
    }
  };

  const onSuccess = () => {
    setDialogAction('');
    setIsOpen(false);
    setTripClientNotifications(null);
    setTripClient(null);
    setTripClienState(true);
    setLoading(false);
  };

  React.useEffect(() => {
    setDuplicatedEmailError(undefined);
    if (!emailValidated) setValidatedEmail(true);
  }, [isOpen]);

  return action === 'new' || tripClientNotifications ? (
    <Dialog
      open={isOpen}
      onClose={() => {
        setDialogAction('');
        setIsOpen(false);
        setTripClientNotifications(null);
        setTripClient(null);
      }}
    >
      <Formik
        initialValues={
          action === 'new'
            ? {
                customerContactId: tripClient?.clientId,
                role: ClientRole.Passenger,
                email: '',
                phoneNumber: '',
                name: '',
                overrideCustomerContactInfo: 'false',
                existingClientId: undefined,
                notificationTypesSubscribed: {},
              }
            : {
                customerContactId: tripClient?.clientId,
                role: tripClient?.role,
                email: tripClient?.client?.emails?.length
                  ? tripClient?.client?.emails[0]?.email
                  : null,
                phoneNumber: tripClient?.client?.phoneNumbers?.length
                  ? tripClient?.client?.phoneNumbers[0]?.phoneNumber
                  : null,
                name: tripClient?.client?.name,
                existingClientId: tripClient?.clientId,
                notificationTypesSubscribed: parseNotificationSubscriptions(
                  tripClientNotifications,
                ),
              }
        }
        validationSchema={yup.object().shape({
          customerContactId: yup.string(),
          name: yup.string().required('This is a required field'),
          role: yup.string(),
          email: yup
            .string()
            .email('Please enter a valid email')
            .required('This is a required field'),
          phoneNumber: yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('This is a required field'),
          overrideCustomerContactInfo: yup.boolean(),
        })}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            const notificationSubscriptions = parseNotificationTypesSubscribed(
              values.notificationTypesSubscribed,
              tripClient?.id,
            );
            if (action === 'new') {
              if (values?.existingClientId) {
                const addTripClient = await updateTripClient({
                  variables: {
                    updateClientId: values?.existingClientId,
                    tripClient: {
                      tripId: trip?.id,
                      role: values?.role,
                      isPrimary: false,
                    },
                  },
                });
                const newTripClientId = addTripClient?.data?.updateClient
                  ?.tripClients?.length
                  ? addTripClient?.data?.updateClient?.tripClients[0]?.id
                  : null;
                if (!newTripClientId) {
                  enqueueSnackbar('Failed To Create New Trip Client', {
                    variant: 'error',
                  });
                  return;
                }

                await updateTripNotificationSubscriptions({
                  variables: {
                    tripClientId: newTripClientId,
                    tripNotificationSubscriptions: notificationSubscriptions,
                  },
                  refetchQueries: ['GetTripById'],
                });
                enqueueSnackbar(`Trip Client Updated`, { variant: 'success' });
                onSuccess();
              } else {
                const newTripClient = await addTripClient({
                  variables: {
                    name: values?.name,
                    emails: [
                      { email: values?.email, type: EmailTypeEnum.Primary },
                    ],
                    phoneNumbers: [
                      {
                        phoneNumber: values?.phoneNumber,
                        type: PhoneTypeEnum.Primary,
                      },
                    ],
                    primaryRole: values?.role,
                    accountId: user?.accountId,
                    tripClient: {
                      tripId: trip?.id,
                      role: values?.role,
                      isPrimary: false,
                    },
                    isActive: true,
                  },
                });
                const newTripClientId = newTripClient?.data?.insertClient
                  ?.tripClients?.length
                  ? newTripClient?.data?.insertClient?.tripClients[0]?.id
                  : null;
                if (!newTripClientId) {
                  enqueueSnackbar('Failed To Create New Trip Client', {
                    variant: 'error',
                  });
                  return;
                }

                await updateTripNotificationSubscriptions({
                  variables: {
                    tripClientId: newTripClientId,
                    tripNotificationSubscriptions: notificationSubscriptions,
                  },
                  refetchQueries: ['GetTripById'],
                });
                enqueueSnackbar(`Trip Client Updated`, { variant: 'success' });
                onSuccess();
              }
            } else {
              await updateTripNotificationSubscriptions({
                variables: {
                  tripClientId: tripClient?.id,
                  tripNotificationSubscriptions: notificationSubscriptions,
                },
                refetchQueries: ['GetNotificationSubscriptionByTripClientId'],
              });
              await updateTripClient({
                variables: {
                  updateClientId: tripClient?.clientId,
                  name: values?.name,
                  phoneNumbers: [
                    {
                      phoneNumber: values?.phoneNumber,
                      type: PhoneTypeEnum.Primary,
                    },
                  ],
                  primaryRole: values?.role,
                  tripClientRole: {
                    tripClientId: tripClient?.id,
                    role: values?.role,
                  },
                },
              });
              enqueueSnackbar(`Trip Client Updated`, { variant: 'success' });
              onSuccess();
            }
          } catch (e) {
            enqueueSnackbar(
              `Error ${action === 'new' ? 'Creating' : 'Updating'} Trip Client`,
              { variant: 'error' },
            );
          }
        }}
      >
        {({ setFieldValue, values, isValid }) => (
          <Form data-tesid="trip_client_form">
            <BaseEnhancedDialog
              onClose={() => {
                setDialogAction('');
                setIsOpen(false);
                setTripClientNotifications(null);
                setTripClient(null);
              }}
              title={
                <Grid container alignItems="center">
                  <Grid item xs={action === 'edit' ? 12 : 6}>
                    <Box>
                      {action === 'new' && (
                        <Typography variant="h6">New Trip Client</Typography>
                      )}
                      {action === 'edit' && (
                        <Typography variant="h6">Update Trip Client</Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              }
              content={
                <Grid container spacing={3}>
                  <Fragment>
                    <Grid item xs={6}>
                      <Field
                        data-testid="trip_client_role_select_field"
                        component={TextField}
                        type="text"
                        name="role"
                        label="Role"
                        disabled={action === 'edit' && tripClient?.isPrimary}
                        select
                        fullWidth
                        variant="outlined"
                      >
                        <MenuItem value={ClientRole.Passenger}>
                          Passenger
                        </MenuItem>
                        <MenuItem value={ClientRole.Booker}>Booker</MenuItem>
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        data-testid="trip_client_name_field"
                        fullWidth
                        name="name"
                        label="Name"
                        type="text"
                        required
                        component={TextField}
                        variant="outlined"
                      />
                    </Grid>
                  </Fragment>
                  <Grid item xs={6}>
                    <Field
                      data-testid="trip_client_email_field"
                      fullWidth
                      component={TextField}
                      name="email"
                      label="Email"
                      type="text"
                      required
                      variant="outlined"
                      disabled={
                        (values.email && action === 'edit') ||
                        !!duplicatedEmailError?.email
                      }
                      onBlur={(e) =>
                        action === 'new' && validateEmail(e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      data-testid="trip_client_phone_field"
                      fullWidth
                      component={TextField}
                      type="text"
                      label="Phone number"
                      required
                      name="phoneNumber"
                      variant="outlined"
                    />
                  </Grid>
                  {duplicatedEmailError?.email && (
                    <Grid item xs={12}>
                      <DuplicatedEmailWarning
                        duplicatedContact={duplicatedEmailError}
                        handleExistingContact={() =>
                          handleExistingContact(setFieldValue)
                        }
                        handleNewContact={() => handleNewContact(setFieldValue)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PassengerContactTripNotificationSubscriptions
                      fieldName="notificationTypesSubscribed"
                      setFieldValue={setFieldValue}
                      action={action}
                      subscriptions={values?.notificationTypesSubscribed}
                    />
                  </Grid>
                </Grid>
              }
              actions={
                <DialogActions>
                  <Button
                    data-testid="trip_client_cancel_button"
                    onClick={() => {
                      setDialogAction('');
                      setIsOpen(false);
                      setTripClientNotifications(null);
                      setTripClient(null);
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid="trip_client_save_button"
                    type="submit"
                    disabled={
                      loading ||
                      (action === 'new' && !emailValidated) ||
                      !isValid
                    }
                    color="primary"
                    variant="contained"
                    startIcon={loading && <CircularProgressIndicator />}
                  >
                    {loading ? 'Loading...' : 'Confirm'}
                  </Button>
                </DialogActions>
              }
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  ) : null;
};

export default TripClientDialogForm;
