import React, { Fragment, useState } from 'react';
import { camel } from 'case';
import { Marker, Popup } from 'react-mapbox-gl';
import { MarkerDetails } from './MapWaypoint';
import pickupMarkerImage from '../../assets/images/pickup-map-marker.png';
import dropoffMarkerImage from '../../assets/images/dropoff-map-marker.png';
import stopMarkerImage from '../../assets/images/stop-map-marker.png';

interface MarkersProps {
  routeCoordinates?: number[][];
  markerDetails?: MarkerDetails[] | undefined;
}

const Markers: React.FC<MarkersProps> = ({
  routeCoordinates,
  markerDetails,
}) => {
  const markers = [];
  const defaultMarkerImage =
    'https://icons.iconarchive.com/icons/paomedia/small-n-flat/32/map-marker-icon.png';
  const markerImage = {
    pickup: pickupMarkerImage,
    dropoff: dropoffMarkerImage,
    stop: stopMarkerImage,
  };

  (routeCoordinates || []).forEach((marker) => {
    if (
      Array.isArray(marker) &&
      marker.length === 2 &&
      typeof marker[0] === 'number' &&
      typeof marker[1] === 'number'
    ) {
      markers.push(marker);
    }
  });
  const [popupInfo, setPopupInfo] = useState(undefined);
  return (
    <Fragment>
      {markers.map((marker, index) => {
        const stopType = markerDetails?.length
          ? markerDetails[index]?.role?.toLowerCase()
          : null;
        return (
          <Marker
            key={index}
            coordinates={marker}
            anchor="bottom"
            onClick={() =>
              setPopupInfo({
                coordinates: marker,
                title: markerDetails ? markerDetails[index].title : '[Title]',
                address: markerDetails
                  ? markerDetails[index].address
                  : '[Address]',
                waitTime: markerDetails
                  ? markerDetails[index].waitTime
                  : '[Wait Time]',
                role: markerDetails ? markerDetails[index].role : '[Type]',
              })
            }
          >
            <img
              alt={stopType ? `${camel(stopType)} Marker` : 'Marker'}
              width={48}
              height={48}
              src={markerImage[stopType] || defaultMarkerImage}
            />
          </Marker>
        );
      })}
      {!!popupInfo && (
        <Popup
          coordinates={popupInfo.coordinates}
          onClick={() => setPopupInfo(undefined)}
        >
          <h3>{popupInfo.role}</h3>
          <h3>{popupInfo.title}</h3>
          <h5>{popupInfo.address}</h5>
          <h4>Wait time: {popupInfo.waitTime}</h4>
        </Popup>
      )}
    </Fragment>
  );
};

export default Markers;
