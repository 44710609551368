import {
  Button,
  DialogActions,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { green, red } from '@material-ui/core/colors';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { Fragment } from 'react';
import { useRecoilState } from 'recoil';
import { useDeclineNetworkOfferMutation } from 'src/.gen/graphql';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import { tripCommandDialogState } from 'src/trips/atoms/tripCommandDialogAtom';
import { handleTripCommandException } from 'src/trips/hooks/useTripCommand';
import BaseTripCommandDialog from './BaseTripCommandDialog';

const useStyles = makeStyles((theme: Theme) => ({
  confirmButton: {
    backgroundColor: green[400],
    color: '#ffffff',
    float: 'right',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  cancelButton: {
    backgroundColor: red[400],
    color: '#ffffff',
    float: 'left',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: red[500],
    },
  },
  bodyText: {
    fontStyle: 'italic',
    marginTop: '1rem',
  },
}));

const DeclineNetworkOfferTripCommandDialog: React.FC<{
  isSavoyaTrip: boolean;
  body?: string;
}> = ({ isSavoyaTrip, body }) => {
  const [tripCommandDialog, setTripCommandDialog] = useRecoilState(
    tripCommandDialogState,
  );
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [declineTrip, { loading }] = useDeclineNetworkOfferMutation();
  const closeModal = (succeeded: boolean) => {
    setTripCommandDialog((tripCommandDialogState) => ({
      ...tripCommandDialogState,
      isOpen: false,
      loading: false,
      succeeded,
    }));
  };

  const handleSubmit = async (values) => {
    const {
      data: { executeDeclineNetworkOffer },
    } = await declineTrip({
      variables: {
        ...values,
        tripId: tripCommandDialog.tripId,
      },
    });

    handleTripCommandException(
      executeDeclineNetworkOffer,
      (succeeded, errors) => {
        if (!succeeded) {
          errors.forEach((errorMessage) => {
            enqueueSnackbar(errorMessage, {
              variant: 'error',
            });
            Sentry.captureException(new Error(errorMessage));
          });
          closeModal(false);
          return;
        }
        closeModal(executeDeclineNetworkOffer.succeeded);
        // remove query params and redirect to homepage
        window.location.replace(
          `${window.location.href
            .split('?')[0]
            .replace(window.location.pathname, '/')}`,
        );
      },
    );
  };

  const getSavoyaBodyText = (bodyText: string) => {
    if (bodyText) {
      return (
        <Grid item xs={12}>
          <Typography
            className={classes.bodyText}
            variant="body1"
            align="center"
          >
            {body}
          </Typography>
        </Grid>
      );
    }
    return null;
  };

  return (
    <Formik
      initialValues={{
        note: '',
      }}
      onSubmit={handleSubmit}
    >
      <Form>
        <BaseTripCommandDialog
          title={
            isSavoyaTrip
              ? 'Are you sure you want to Reject this Trip Offer?'
              : 'Decline Trip'
          }
          content={
            !isSavoyaTrip ? (
              <Fragment>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Field
                      component={TextField}
                      label="Note"
                      name="note"
                      fullWidth
                      multiline
                      minRows={3}
                      rows={3}
                      maxRows={6}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              getSavoyaBodyText(body)
            )
          }
          actions={
            <DialogActions>
              <Button
                className={classes.cancelButton}
                onClick={() => {
                  closeModal(false);
                }}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                startIcon={loading && <CircularProgressIndicator />}
                className={classes.confirmButton}
                type="submit"
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          }
        />
      </Form>
    </Formik>
  );
};

export default DeclineNetworkOfferTripCommandDialog;
