import { InvoiceSummary } from 'src/.gen/graphql';

import { atom, RecoilState } from 'recoil';

export interface EstimatedInvoiceValues {
  summary?: InvoiceSummary;
  vehicleClassId?: string | undefined;
  tripId?: string | undefined;
}

export const initialEstimatedInvoiceValue: EstimatedInvoiceValues = {
  vehicleClassId: undefined,
  tripId: undefined,
};

export const estimatedInvoiceState: RecoilState<EstimatedInvoiceValues> = atom({
  key: 'estimatedInvoiceState',
  default: initialEstimatedInvoiceValue,
});
