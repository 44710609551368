import React from 'react';
import { Box, Theme, makeStyles } from '@material-ui/core';
import usePermission from 'src/shared/hooks/usePermission';
import { TierType } from 'src/.gen/graphql';

interface RestrictedBackdropProps {
  minimumTier?: TierType;
}
const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    userSelect: 'none',
    pointerEvents: 'none',
    backgroundColor: 'rgba(160,160,160,0.5)',
    backdropFilter: 'blur(3px)',
    filter: 'blur(3px)',
    '-webkit-filter': 'blur(3px)',
  },
}));

const RestrictedBackdrop: React.FC<RestrictedBackdropProps> = ({
  minimumTier,
  children,
}) => {
  const classes = useStyles();
  const { tierCompliant: allowed } = usePermission({
    tierType: minimumTier,
  });

  if (allowed) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <Box
      className={classes.backdrop}
      id="restricted-feature-backdrop"
      data-testid="restricted_feature_backdrop"
    >
      {children}
    </Box>
  );
};

export default RestrictedBackdrop;
