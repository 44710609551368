import { CircularProgress, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Autocomplete } from '@material-ui/lab';
import { fetchLocations } from 'src/rates/api/SavoyaRateQueries';
import { isString } from 'lodash';
import { SavoyaLocation } from 'src/trips/types/SavoyaTripsTypes';
import SavoyaLocationAutocompleteTooltip from './SavoyaLocationAutoCompleteTooltip';

interface SavoyaLocationAutocompleteProps {
  value?: { label: string; value: Partial<SavoyaLocation> };
  disabled?: boolean;
  label: string;
  size?: 'small' | 'medium';
  setFieldTouched?: (
    fieldName: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => void;
  fieldName?: string;
  type: 'airport' | 'city' | 'preferences';
  preferenceType?: 'airport' | 'city';
  lockType?: boolean;
  onSelected: (value: {
    value: Partial<SavoyaLocation>;
    label: string;
  }) => void;
}

const SavoyaLocationAutocomplete: React.FC<SavoyaLocationAutocompleteProps> = ({
  setFieldTouched,
  value,
  size,
  disabled,
  fieldName,
  type,
  preferenceType,
  label,
  lockType,
  onSelected,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [locationType, setLocationType] = useState(type);

  const { data, isInitialLoading } = useQuery(
    ['locations', { inputValue, locationType }],
    () => fetchLocations(inputValue, locationType, preferenceType),
    {
      enabled: inputValue.length > 2,
      staleTime: 10 * 1000,
    },
  );

  const getOptionsFromData = React.useCallback((data) => {
    return (
      data?.map((location) => ({
        label: location?.displayName,
        value: location,
      })) ?? []
    );
  }, []);

  const options = React.useMemo(() => getOptionsFromData(data), [data]);

  return (
    <Autocomplete
      onInputChange={(_, value) => setInputValue(value)}
      options={options || []}
      getOptionLabel={(option) => {
        return option?.label;
      }}
      getOptionSelected={(option, value) => {
        return option?.value?.id === value?.value?.id;
      }}
      onChange={(_, value) => {
        if (setFieldTouched) {
          setFieldTouched(fieldName, true, true);
        }
        if (!isString(value)) {
          onSelected(value);
        }
      }}
      value={value || null}
      inputValue={inputValue}
      loading={isInitialLoading}
      disabled={disabled}
      renderInput={(params) => {
        return (
          <TextField
            id={label}
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size={size}
            InputProps={{
              ...params.InputProps,
              startAdornment: !lockType ? (
                <SavoyaLocationAutocompleteTooltip
                  setLocationType={setLocationType}
                  locationType={locationType}
                />
              ) : null,
              endAdornment: (
                <React.Fragment>
                  {isInitialLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default SavoyaLocationAutocomplete;
