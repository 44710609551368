import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { RatePolicy, RatePolicyType } from 'src/.gen/graphql';
import CancellationPolicyForm from './CancellationPolicyForm/CancellationPolicyForm';

interface CancellationPolicyProps {
  getPolicy: (policy: RatePolicyType) => Partial<RatePolicy>;
}

const CancellationPolicy: FC<CancellationPolicyProps> = ({ getPolicy }) => {
  const policy = getPolicy(RatePolicyType.Cancellation);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Cancellation Policy</Typography>
      </Grid>
      <Grid item xs={12}>
        <CancellationPolicyForm policy={policy} />
      </Grid>
    </Grid>
  );
};

export default CancellationPolicy;
