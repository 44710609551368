import {
  IconButton,
  SvgIcon,
  Tooltip,
  Drawer,
  makeStyles,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Search as SearchIcon,
  X as XIcon,
  XCircle as XCircleIcon,
} from 'react-feather';
import {
  Trip,
  useGetTripClientsByTripIdsLazyQuery,
  useSearchIndexedTripsLazyQuery,
} from 'src/.gen/graphql';
import TripListTile from 'src/trips/components/TripListTile/TripListTile';
import LoadingListTile from 'src/trips/components/TripListTile/LoadingListTile';
import wait from 'src/shared/utils/wait';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { getPassengerClient } from 'src/shared/lib/clientHelpers';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%',
  },
  closeDrawer: {
    float: 'right',
  },
  margin: {
    marginBottom: 50,
  },
  drawerTitle: {
    margin: 'auto auto',
  },
}));

const Search: React.FC = () => {
  const shimmersArray = new Array(12).fill(0);
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const classes = useStyles();

  const [
    getTripClients,
    { loading: clientsLoading, data: clientDataResponse },
  ] = useGetTripClientsByTripIdsLazyQuery();

  const [
    searchIndexed,
    { data: indexedData, loading: tripsLoading, error, called },
  ] = useSearchIndexedTripsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const data = indexedData?.trips?.searchIndexed?.items || [];
  const loading = tripsLoading || clientsLoading;

  const searchIndex = () => {
    searchIndexed({
      variables: {
        filter: query,
        accountId: user?.accountId,
      },
    });
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (query !== '') {
      wait(300).then(() => {
        searchIndex();
      });
    }
  }, [query]);

  React.useEffect(() => {
    if (data?.length) {
      getTripClients({ variables: { tripIds: data?.map((trip) => trip?.id) } });
    }
  }, [data]);

  const clientData = clientDataResponse?.clientQueries?.getTripClientsByTripIds;

  return (
    <Fragment>
      <Tooltip title="search">
        <IconButton
          data-testid="search_trip_button"
          color="inherit"
          onClick={toggleOpen}
        >
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer anchor="right" classes={{ paper: classes.drawer }} open={open}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Box
                  width="100%"
                  height="100%"
                  justifyItems="center"
                  alignItems="center"
                  display="flex"
                >
                  <Typography variant="h4" color="textPrimary">
                    Search
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={classes.closeDrawer}
                  variant="h4"
                  color="textPrimary"
                >
                  <IconButton
                    data-testid="search_trip_close_button"
                    onClick={() => {
                      toggleOpen();
                      setQuery('');
                    }}
                  >
                    <XIcon />
                  </IconButton>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' && query !== '') {
                      searchIndex();
                    }
                  }}
                  inputProps={{
                    'data-testid': 'search_trip_field',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!loading && (
                          <IconButton
                            onClick={() => {
                              setQuery('');
                            }}
                          >
                            {query !== '' && called ? (
                              <XCircleIcon />
                            ) : (
                              <SearchIcon />
                            )}
                          </IconButton>
                        )}
                        {loading && <CircularProgressIndicator />}
                      </InputAdornment>
                    ),
                  }}
                  onChange={async (event) => {
                    setQuery(event.target.value);
                  }}
                  placeholder="Search people &amp; places"
                  value={query}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} data-testid="search_trip_list">
                {!loading &&
                  query !== '' &&
                  data &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((trip: Trip, index) => {
                    const tripClients = clientData?.filter(
                      (tripClient) => tripClient?.tripId === trip?.id,
                    );
                    const passengerClient = getPassengerClient(
                      tripClients,
                      trip?.id,
                    );
                    return (
                      <Grid
                        key={trip.id}
                        item
                        xs={12}
                        data-testid={`search_trip_list_item_${index}`}
                      >
                        <TripListTile
                          variant="outlined"
                          trip={{ ...trip, tripClients, show: true }}
                          passengerClient={passengerClient}
                          onClick={() => {
                            navigate(`/trips/${trip.id}`);
                            toggleOpen();
                          }}
                        />
                      </Grid>
                    );
                  })}
                {!loading &&
                  query !== '' &&
                  data &&
                  Array.isArray(data) &&
                  data.length === 0 && (
                    <Grid
                      container
                      alignContent="center"
                      justify="center"
                      data-testid="search_trip_list_not_found"
                    >
                      <Typography variant="body2" color="textSecondary">
                        There are no trips that match your entry
                      </Typography>
                    </Grid>
                  )}
                {loading &&
                  !error &&
                  shimmersArray.map((e, i) => (
                    <Grid
                      key={`shimmerTrips${i}`}
                      item
                      xs={12}
                      data-testid={`search_trip_list_loading_${i}`}
                    >
                      <LoadingListTile />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </Fragment>
  );
};

export default Search;
