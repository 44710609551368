import React from 'react';
import { Grid, Typography, Box, makeStyles, Theme } from '@material-ui/core';
import {
  TripStatusTimeCorrectionReason,
  TripStatusTimestamp,
  useExecuteCorrectTripTimestampMutation,
} from 'src/.gen/graphql';
import { format, isSameDay } from 'date-fns';
import { useSetRecoilState } from 'recoil';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import { useSnackbar } from 'notistack';
import { handleMultipleTripCommandException } from 'src/trips/hooks/useTripCommand';
import AdjustTimestampForm from './AdjustTimestampForm';

interface Props {
  timestamp: Partial<TripStatusTimestamp>;
  timestamps: Partial<TripStatusTimestamp>[];
  closeDialog: () => void;
}
const AdjustTimestamp: React.FC<Props> = ({
  timestamp,
  closeDialog,
  timestamps,
}) => {
  const classes = useStyles();
  const setTimestampsState = useSetRecoilState(tripTimestampsState);
  const [executeCorrectTripStatusTimestamp] =
    useExecuteCorrectTripTimestampMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleErrors = (errors) => {
    errors.forEach((errorMessage) => {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    });
  };

  const correctTripStatusTimestamp = async (values) => {
    const { id: currentTimestampId } = timestamp;
    const currentTimestampIndex = timestamps.findIndex(
      ({ id }) => id === currentTimestampId,
    );

    const prevEffectiveTime =
      currentTimestampIndex > 0
        ? new Date(timestamps[currentTimestampIndex - 1].effectiveTime)
        : null;

    const nextEffectiveTime =
      currentTimestampIndex < timestamps.length - 1
        ? new Date(timestamps[currentTimestampIndex + 1].effectiveTime)
        : null;

    const validations = [
      {
        condition:
          currentTimestampIndex === 0 ||
          prevEffectiveTime <= values.newTimestamp,
        error: "You can't select a time before previous event",
      },
      {
        condition:
          currentTimestampIndex === timestamps.length - 1 ||
          !isSameDay(nextEffectiveTime, values.newTimestamp) ||
          nextEffectiveTime > values.newTimestamp,
        error: "You can't select a time after next event",
      },
    ];

    const validationResult = validations.reduce(
      (accumulator, currentValidation) => {
        accumulator.isValid =
          accumulator.isValid && currentValidation.condition;
        if (!currentValidation.condition) {
          accumulator.errors = [...accumulator.errors, currentValidation.error];
        }
        return accumulator;
      },
      { isValid: true, errors: [] },
    );
    if (!validationResult.isValid) {
      handleErrors(validationResult.errors);
      return;
    }

    const timestampUpdateCommands = timestamps
      .slice(currentTimestampIndex)
      .map((timestamp, timestampIndex) => {
        const newDate = values.newTimestamp.getDate();
        const newMonth = values.newTimestamp.getMonth();
        const newYear = values.newTimestamp.getFullYear();
        const newEffectiveTime = new Date(timestamp.effectiveTime);
        newEffectiveTime.setDate(newDate);
        newEffectiveTime.setMonth(newMonth);
        newEffectiveTime.setFullYear(newYear);
        if (timestampIndex === 0) {
          const newHours = values.newTimestamp.getHours();
          const newMinutes = values.newTimestamp.getMinutes();
          newEffectiveTime.setHours(newHours);
          newEffectiveTime.setMinutes(newMinutes);
        }
        return {
          timestampId: timestamp.id,
          newTimestamp: newEffectiveTime,
          note: values.note,
          reason: values.reason,
        };
      })
      .map((command) => {
        return executeCorrectTripStatusTimestamp({
          variables: {
            command,
          },
          refetchQueries: ['GetTripById'],
        });
      });

    const updatingResults = (await Promise.all(timestampUpdateCommands)).map(
      ({ data }) => data.executeCorrectTripTimestamp,
    );

    handleMultipleTripCommandException(updatingResults, (succeeded, errors) => {
      if (!succeeded) {
        handleErrors(errors);
        return;
      }
      setTimestampsState((prev) => ({
        ...prev,
        refetchInvoiceSummary: true,
        updating: true,
      }));
      closeDialog();
      enqueueSnackbar('Adjust time successfully!', {
        variant: 'success',
      });
    });
  };

  return (
    <Grid item xs={12}>
      <Box marginBottom={2}>
        <Typography variant="body2" component="p">
          <Typography component="span" className={classes.reportedTimeLabel}>
            Reported Time:
          </Typography>
          {format(new Date(timestamp.recordedTime), 'MM/dd/yyyy hh:mm aaaa')}
        </Typography>
      </Box>
      <AdjustTimestampForm
        initialValues={{
          reason: TripStatusTimeCorrectionReason.IncorrectTimestamp,
          note: '',
          newTimestamp: new Date(
            timestamp.correctedTime ?? timestamp.effectiveTime,
          ),
        }}
        onCancel={closeDialog}
        onSubmit={correctTripStatusTimestamp}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  reportedTimeLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(1),
  },
}));

export default AdjustTimestamp;
