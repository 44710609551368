import React from 'react';
import { Container, Grid, Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import DashboardLayout from 'src/shared/DashboardLayout/DashboardLayout';
import Page from 'src/shared/components/Page';

const PaymentViewLoading: React.FC = () => {
  return (
    <DashboardLayout hideNavbar>
      <Page title="Payment Details">
        <Container maxWidth="lg">
          <Box mt={4} mb={4}>
            <Typography
              align="left"
              variant="h4"
              color="textPrimary"
              component="h1"
            >
              Payment Details
            </Typography>
          </Box>
        </Container>
        <Container maxWidth="lg">
          <Box mt={2}>
            <Grid container spacing={3} justify="flex-start">
              <Grid item md={8} xl={8} xs={12}>
                <Skeleton
                  variant="rect"
                  height={650}
                  width="100%"
                  component="div"
                />
              </Grid>
              <Grid item md={4} xl={4} xs={12}>
                <Skeleton
                  variant="rect"
                  height={400}
                  width="100%"
                  component="div"
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </DashboardLayout>
  );
};
export default PaymentViewLoading;
