import React, { Fragment, useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { useRecoilValue } from 'recoil';
import DashboardLayout from '../DashboardLayout/DashboardLayout';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
}));

const LoadingScreen: FC = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  const Wrapper = user && isAuthenticated ? DashboardLayout : Fragment;

  return (
    <Wrapper>
      <div key="loadingScreen" className={classes.root}>
        <Box width={400}>
          <LinearProgress color="secondary" />
        </Box>
      </div>
    </Wrapper>
  );
};

export default LoadingScreen;
