import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { savoyaRatesDialogsState } from 'src/rates/atoms/SavoyaRateFormState';
import {
  SavoyaTransferCostResponse,
  SavoyaVehicleType,
  updateTransferCost,
} from 'src/rates/api/SavoyaRateQueries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import { SavoyaLocation } from 'src/trips/types/SavoyaTripsTypes';
import SavoyaTransferRateBaseForm, {
  SavoyaTransferRateInitialValues,
} from '../SavoyaTransferRateBaseForm';

interface DialogProps {
  initialValues: SavoyaTransferRateInitialValues;
  vehicleTypes: SavoyaVehicleType[];
  page: number;
  pageSize: number;
  toLocation: Partial<SavoyaLocation>;
  fromLocation: Partial<SavoyaLocation>;
  vehicleType: Partial<SavoyaVehicleType>;
}
const EditSavoyaTransferRateDialog: FC<DialogProps> = ({
  initialValues,
  vehicleTypes,
  page,
  pageSize,
  toLocation,
  fromLocation,
  vehicleType,
}) => {
  const [dialogState, setDialogState] = useRecoilState(savoyaRatesDialogsState);
  const closeDialog = () =>
    setDialogState((prev) => ({
      ...prev,
      openEditTransferRateDialog: false,
    }));
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const updateSavoyaTransferRate = useMutation({
    mutationFn: updateTransferCost,
    onSuccess: (newRate) => {
      queryClient.setQueryData(
        [
          'transferRates',
          { page, pageSize, fromLocation, toLocation, vehicleType },
        ],
        (oldData: SavoyaTransferCostResponse) => {
          const newState = cloneDeep(oldData);
          const oldValue = newState?.paginated?.records?.find(
            (record) => record?.id === newRate?.id,
          );
          newState?.paginated?.records?.splice(
            newState?.paginated?.records?.indexOf(oldValue),
            1,
            newRate,
          );

          return newState;
        },
      );
      enqueueSnackbar('Transfer Rate Updated', { variant: 'success' });
      closeDialog();
    },
    onError: () => {
      enqueueSnackbar('Error Updating Transfer Rate', { variant: 'error' });
    },
  });

  const onSubmit = async (values) => {
    await updateSavoyaTransferRate.mutateAsync({
      ...values,
      transferCost: Math.round(values?.transferCost * 100),
    });
  };

  return (
    <GenericDialog
      dialogTitle="Edit Transfer Rate"
      openDialog={dialogState.openEditTransferRateDialog}
      setCloseDialog={closeDialog}
      maxWidth="sm"
    >
      <SavoyaTransferRateBaseForm
        action="edit"
        vehicleTypes={vehicleTypes}
        onSubmit={onSubmit}
        onCancel={closeDialog}
        initialValues={initialValues}
      />
    </GenericDialog>
  );
};

export default EditSavoyaTransferRateDialog;
