import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { DeepPartial } from 'react-hook-form';
import { TripClientRecord } from 'src/.gen/graphql';

interface TripContactCardProps {
  tripClient: DeepPartial<TripClientRecord>;
  onDelete: (tripClient: DeepPartial<TripClientRecord>) => void;
  onEdit: (tripClient: DeepPartial<TripClientRecord>) => void;
}

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: '#F4F4F4',
  },
  icon: {
    fontSize: '0.9rem',
  },
  iconButton: {
    padding: '0.2rem',
    float: 'right',
  },
});

const TripClientCard: React.FC<TripContactCardProps> = ({
  tripClient,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const clientEmail = tripClient?.client?.emails
    ? tripClient?.client?.emails[0]?.email
    : null;
  const clientPhone = tripClient?.client?.emails
    ? tripClient?.client?.phoneNumbers[0]?.phoneNumber
    : null;
  return (
    <Grid item xs={12} md={6}>
      <Card
        className={classes.mainContainer}
        elevation={0}
        data-testid="trip_client_card"
      >
        <CardContent>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                variant="caption"
                display="block"
              >
                {tripClient?.role}
                <Box ml={2} display="inline">
                  {tripClient?.isPrimary !== true && (
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        onDelete(tripClient);
                      }}
                    >
                      <Delete className={classes.icon} />
                    </IconButton>
                  )}
                  <IconButton
                    data-testid="trip_client_edit_button"
                    className={classes.iconButton}
                    onClick={() => {
                      onEdit(tripClient);
                    }}
                  >
                    <Edit className={classes.icon} />
                  </IconButton>
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color="textSecondary"
                variant="subtitle1"
                data-testid="trip_client_name"
              >
                {tripClient?.client?.name}
              </Typography>
            </Grid>
            <Grid item>
              <a
                href={`mailto:${clientEmail}`}
                data-testid="trip_client_email_link"
              >
                <Typography color="textSecondary" variant="subtitle2">
                  {clientEmail}
                </Typography>
              </a>
            </Grid>
            <Grid item>
              <a
                href={`tel:${clientPhone}`}
                data-testid="trip_client_phone_link"
              >
                <Typography color="textSecondary" variant="subtitle2">
                  {clientPhone}
                </Typography>
              </a>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TripClientCard;
