import { atom, RecoilState } from 'recoil';
import {
  RatesMapCoordinates,
  defaultRatesMapCoordinates,
} from './RatesFormAtomTypes';

export const ratesMapPlaces: RecoilState<RatesMapCoordinates> = atom({
  key: 'RatesMapCoordinates',
  default: defaultRatesMapCoordinates,
});
