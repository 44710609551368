import { TripCompletionStatus, TripStatus } from 'src/.gen/graphql';

const statusArray: string[] = [
  'Draft',
  'AwaitingAssignment',
  'OfferedToNetwork',
  'PendingNetworkConfirmation',
  'NetworkUnavailable',
  'Confirmed',
  'PendingChangeRequest',
  'EnRouteToPickup',
  'OnLocation',
  'Circling',
  'PassengerOnboard',
  'Waiting',
  'TransportComplete',
  'TripComplete',
  'Billed',
  'Paid',
  'Cancelled',
  'Quoted',
  'Closed',
];

const enumVals = Object.keys(TripStatus).filter(
  (k) => k !== 'EnRouteToGarage' && k !== 'Preparing',
);

if (statusArray.length !== enumVals.length) {
  throw new Error(
    'Cannot load the trip status helper because some statuses present in the TripStatus enumeration are not in the ordered status array',
  );
}

const completeStatuses: TripStatus[] = [
  TripStatus.TransportComplete,
  TripStatus.Billed,
  TripStatus.Paid,
  TripStatus.Closed,
];

const unassignedStatuses = [
  TripStatus.AwaitingAssignment,
  TripStatus.Draft,
  TripStatus.NetworkUnavailable,
  TripStatus.OfferedToNetwork,
  TripStatus.PendingNetworkConfirmation,
  TripStatus.Quoted,
];

const inProcessStatuses: TripStatus[] = statusArray
  .slice(
    statusArray.indexOf(TripStatus.EnRouteToPickup),
    statusArray.indexOf(TripStatus.TransportComplete),
  )
  .map((s) => s as TripStatus);

export function getCompletionStatuses(
  completionStatus: TripCompletionStatus,
): TripStatus[] {
  if (completionStatus === TripCompletionStatus.Draft)
    return [TripStatus.Draft];
  if (completionStatus === TripCompletionStatus.Closed)
    return [TripStatus.Closed];
  if (completionStatus === TripCompletionStatus.Complete) {
    return completeStatuses;
  }
  if (completionStatus === TripCompletionStatus.InProcess) {
    return inProcessStatuses;
  }
  if (completionStatus === TripCompletionStatus.CompleteOrClosed) {
    return [...completeStatuses, TripStatus.TripComplete];
  }
  if (completionStatus === TripCompletionStatus.Unassigned) {
    return unassignedStatuses;
  }
  throw new Error(`Unknown completion status ${completionStatus}`);
}
