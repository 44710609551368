import {
  Grid,
  Typography,
  CircularProgress,
  Divider,
  Button,
  Checkbox,
  Chip,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  AreaType,
  InsertTransferRateSet,
  LocationMatchType,
  UpsertPlaceInput,
  VehicleClassOverrideLite,
  ZoneType,
  useCreateTransferRateSetMutation,
  UpdateTransferRateSet,
  useUpdateTransferRateSetMutation,
  useGetClientsByNameAndAccountLazyQuery,
  useFindCompanyByAccountAndNameLazyQuery,
} from 'src/.gen/graphql';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { ratesMapPlaces } from 'src/rates/atoms/RatesFormAtom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { defaultRatesMapCoordinates } from 'src/rates/atoms/RatesFormAtomTypes';
import { coordinatesFromLocationToLatLon } from 'src/rates/hooks/useTransferRates';
import AsyncAutocomplete from 'src/shared/components/AutoComplete/AsyncAutocomplete';
import { AutoCompleteOption } from 'src/shared/components/AutoComplete/utils';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { differenceBy } from 'lodash';
import { useTransferRateFormStyles } from './NewTrasferRateFormularyStyles';
import RateLocationInput from './RateLocationInput/RateLocationInput';
import TransferRateFormValidation from './TransferRateFormValidation';
import VehicleRatesTable from './VehicleRatesTable/VehicleRatesTable';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface NewTransferRateFormularyProps {
  editing: false;
  closeForm: () => void;
  refetchRates?: () => void;
}

interface UpdateTransferRateFormularyProps {
  editing: true;
  initialRate: EditionInitialValuesInterface;
  closeForm: () => void;
  refetchRates?: () => void;
}

export interface InitialValuesInterface {
  firstLocation: UpsertPlaceInput;
  secondLocation: UpsertPlaceInput;
  firstLocationZoneType: ZoneType;
  firstLocationRadiusInKm: number;
  firstLocationGeoType: AreaType;
  firstLocationMatchType: LocationMatchType;
  secondLocationZoneType: ZoneType;
  secondLocationRadiusInKm: number;
  secondLocationGeoType: AreaType;
  secondLocationMatchType: LocationMatchType;
  vehicleClassOverride: Array<VehicleClassOverrideLite>;
  maxStops: number;
  customers: Array<AutoCompleteOption>;
  clients: Array<AutoCompleteOption>;
  companies: Array<AutoCompleteOption>;
}

const initialValues: InitialValuesInterface = {
  firstLocation: { placeName: '', coordinates: [0, 0] },
  secondLocation: { placeName: '', coordinates: [0, 0] },
  firstLocationZoneType: ZoneType.Area,
  firstLocationRadiusInKm: 0,
  firstLocationGeoType: AreaType.Radius,
  firstLocationMatchType: LocationMatchType.FromOrTo,
  secondLocationZoneType: ZoneType.Area,
  secondLocationRadiusInKm: 0,
  secondLocationGeoType: AreaType.Radius,
  secondLocationMatchType: LocationMatchType.FromOrTo,
  vehicleClassOverride: [],
  maxStops: 1,
  customers: [],
  clients: [],
  companies: [],
};

export interface EditionInitialValuesInterface {
  firstLocation: UpsertPlaceInput;
  secondLocation: UpsertPlaceInput;
  firstLocationZoneType: ZoneType;
  firstLocationRadiusInKm: number;
  firstLocationGeoType: AreaType;
  firstLocationMatchType: LocationMatchType;
  secondLocationZoneType: ZoneType;
  secondLocationRadiusInKm: number;
  secondLocationGeoType: AreaType;
  secondLocationMatchType: LocationMatchType;
  vehicleClassOverride: Array<VehicleClassOverrideLite>;
  maxStops: number;
  // UPDATE ADDED
  id: string;
  name?: string;
  tripRuleId: string;
  firstLocationId?: string;
  secondLocationId?: string;
  customers: Array<AutoCompleteOption>;
  clients: Array<AutoCompleteOption>;
  companies: Array<AutoCompleteOption>;
}

interface editionInitialValuesFunc {
  (initialRate: EditionInitialValuesInterface): EditionInitialValuesInterface;
}
const editionInitialValues: editionInitialValuesFunc = (initialRate) => {
  return {
    firstLocation: initialRate.firstLocation || {
      placeName: '',
      coordinates: [0, 0],
    },
    secondLocation: initialRate.secondLocation || {
      placeName: '',
      coordinates: [0, 0],
    },
    firstLocationZoneType:
      initialRate.firstLocationZoneType || ZoneType.Address,
    firstLocationRadiusInKm: initialRate.firstLocationRadiusInKm || 0,
    firstLocationGeoType: initialRate.firstLocationGeoType || AreaType.Place,
    firstLocationMatchType:
      initialRate.firstLocationMatchType || LocationMatchType.FromOrTo,
    secondLocationZoneType:
      initialRate.secondLocationZoneType || ZoneType.Address,
    secondLocationRadiusInKm: initialRate.secondLocationRadiusInKm || 0,
    secondLocationGeoType: initialRate.secondLocationGeoType || AreaType.Place,
    secondLocationMatchType:
      initialRate.secondLocationMatchType || LocationMatchType.FromOrTo,
    vehicleClassOverride: initialRate.vehicleClassOverride || [],
    maxStops: initialRate.maxStops || 1,
    // ADDED FOR UPDATE
    id: initialRate.id,
    name: initialRate.name,
    tripRuleId: initialRate.tripRuleId,
    firstLocationId: initialRate.firstLocationId,
    secondLocationId: initialRate.secondLocationId,
    customers: initialRate.customers,
    clients: initialRate.clients,
    companies: initialRate.companies,
  };
};

const NewTransferRateFormulary: FC<
  NewTransferRateFormularyProps | UpdateTransferRateFormularyProps
> = (props) => {
  const { editing, closeForm, refetchRates } = props;
  const classes = useTransferRateFormStyles();
  const user = useRecoilValue(userAtom);
  const setMapCoordinates = useSetRecoilState(ratesMapPlaces);
  const { sm } = useBreakpoints();

  const [
    createTransferRate,
    { loading: creatingTransferRate, data: newTransferRate },
  ] = useCreateTransferRateSetMutation();
  React.useEffect(() => {
    if (newTransferRate?.insertTransferRateSet?.success) {
      closeForm();
      cleanMapValues();
      if (refetchRates) refetchRates();
    }
  }, [newTransferRate]);

  const [
    updateTransferRate,
    { loading: updatingTransferRate, data: updatedTransferRate },
  ] = useUpdateTransferRateSetMutation();

  React.useEffect(() => {
    if (updatedTransferRate?.updateTransferRateSet?.success) {
      closeForm();
      cleanMapValues();
      if (refetchRates) refetchRates();
    }
  }, [updatedTransferRate]);

  const autocompletePayloadParse = (payload) => {
    const { latitude, longitude } = coordinatesFromLocationToLatLon(payload);

    const newPayload = { ...payload };
    const coordinates = {
      type: 'Point',
      coordinates: [longitude || 0, latitude || 0],
    };

    delete newPayload.latitude;
    delete newPayload.longitude;
    newPayload.coordinates = coordinates;
    return newPayload;
  };

  const parseCustomers = (elements: AutoCompleteOption[]): string[] => {
    return elements.filter(({ id }) => id !== 'all').map(({ id }) => id);
  };

  const cleanMapValues = () => {
    setMapCoordinates(defaultRatesMapCoordinates);
  };

  return (
    <Formik
      initialValues={
        // eslint-disable-next-line react/destructuring-assignment
        editing ? editionInitialValues(props.initialRate) : initialValues
      }
      validationSchema={TransferRateFormValidation}
      onSubmit={(values) => {
        if (editing) {
          const editValues: EditionInitialValuesInterface =
            values as EditionInitialValuesInterface;
          const payload: UpdateTransferRateSet = {
            id: editValues.id,
            // UPDATE ADDED
            name: editValues.name,
            tripRuleId: editValues.tripRuleId,
            firstLocationId: editValues.firstLocationId,
            secondLocationId: editValues.secondLocationId,
            accountId: user?.accountId,
            maxStops: editValues.maxStops,
            firstLocation: autocompletePayloadParse(editValues.firstLocation),
            firstLocationZoneType: editValues.firstLocationZoneType,
            firstLocationRadiusInKm: editValues.firstLocationRadiusInKm,
            firstLocationGeoType: editValues.firstLocationGeoType,
            firstLocationMatchType: LocationMatchType.FromOrTo,
            secondLocation: autocompletePayloadParse(editValues.secondLocation),
            secondLocationZoneType: editValues.secondLocationZoneType,
            secondLocationRadiusInKm: editValues.secondLocationRadiusInKm,
            secondLocationGeoType: editValues.secondLocationGeoType,
            secondLocationMatchType: LocationMatchType.FromOrTo,
            vehicleClassOverride: editValues.vehicleClassOverride.map((el) => {
              return {
                vehicleClassId: el.vehicleClassId,
                amount: el.amount,
                id: el.id,
              };
            }),
            customers: parseCustomers(values.customers),
            clients: parseCustomers(values.clients),
            companies: parseCustomers(values.companies),
          };

          updateTransferRate({
            variables: { newRateSet: payload },
            refetchQueries: ['GetCompaniesByRateSets', 'GetClientsByRateSets'],
          });
        } else {
          const payload: InsertTransferRateSet = {
            accountId: user?.accountId,
            name: 'Transfer rate set',
            description: `${values.firstLocation?.placeName || 'from'} - ${
              values.secondLocation?.placeName || 'to'
            }`,
            maxStops: values.maxStops,
            firstLocation: autocompletePayloadParse(values.firstLocation),
            firstLocationZoneType: values.firstLocationZoneType,
            firstLocationRadiusInKm: values.firstLocationRadiusInKm,
            firstLocationGeoType: values.firstLocationGeoType,
            firstLocationMatchType: LocationMatchType.FromOrTo,
            secondLocation: autocompletePayloadParse(values.secondLocation),
            secondLocationZoneType: values.secondLocationZoneType,
            secondLocationRadiusInKm: values.secondLocationRadiusInKm,
            secondLocationGeoType: values.secondLocationGeoType,
            secondLocationMatchType: LocationMatchType.FromOrTo,
            vehicleClassOverride: values.vehicleClassOverride.map((el) => {
              return { vehicleClassId: el.vehicleClassId, amount: el.amount };
            }),
            customers: parseCustomers(values.customers),
            clients: parseCustomers(values.clients),
            companies: parseCustomers(values.companies),
          };

          createTransferRate({ variables: { newRateSet: payload } });
        }
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
        errors,
        touched,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.formTitle}>
                  {`${editing ? 'Edit' : 'New'} Transfer Rate`}
                </Typography>
              </Grid>
              {creatingTransferRate || updatingTransferRate ? (
                <CircularProgress color="secondary" />
              ) : (
                <React.Fragment>
                  <Grid item xs={12}>
                    <RateLocationInput
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      locationFieldName="firstLocation"
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  {sm && (
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <RateLocationInput
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      locationFieldName="secondLocation"
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <VehicleRatesTable
                      setFieldValue={setFieldValue}
                      existingRates={(() => {
                        return values.vehicleClassOverride;
                      })()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AsyncAutocomplete
                      label="Client"
                      useLazyQuery={useGetClientsByNameAndAccountLazyQuery}
                      getOptionsFromData={(data) => [
                        { id: 'all', name: 'All' },
                        ...(data?.clientQueries?.getByNameAndAccount ?? []),
                      ]}
                      getOptionLabel={(option) => option?.name ?? ''}
                      value={
                        values?.clients?.length
                          ? values.clients
                          : [{ id: 'all', name: 'All' }]
                      }
                      onChange={async (_, value: AutoCompleteOption[]) => {
                        const diff = differenceBy(
                          value,
                          values?.clients?.length
                            ? values.clients
                            : [{ id: 'all', name: 'All' }],
                          'id',
                        );
                        const isAllSelected =
                          diff.findIndex(({ id }) => id === 'all') !== -1;
                        setFieldValue(
                          'clients',
                          isAllSelected
                            ? []
                            : value.filter(({ id }) => id !== 'all'),
                        );
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            disabled={option.id === 'all'}
                          />
                        ))
                      }
                      executeLazyQuery={(lazyQuery, query) => {
                        lazyQuery({
                          variables: {
                            accountId: user?.accountId,
                            query,
                          },
                        });
                      }}
                      multiple
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AsyncAutocomplete
                      label="Company"
                      useLazyQuery={useFindCompanyByAccountAndNameLazyQuery}
                      getOptionsFromData={(data) => [
                        { id: 'all', name: 'All' },
                        ...(data?.companyQueries?.findByAccountAndName ?? []),
                      ]}
                      getOptionLabel={(option) => option?.name ?? ''}
                      value={
                        values?.companies?.length
                          ? values.companies
                          : [{ id: 'all', name: 'All' }]
                      }
                      onChange={async (_, value: AutoCompleteOption[]) => {
                        const diff = differenceBy(
                          value,
                          values?.companies?.length
                            ? values.customers
                            : [{ id: 'all', name: 'All' }],
                          'id',
                        );
                        const isAllSelected =
                          diff.findIndex(({ id }) => id === 'all') !== -1;
                        setFieldValue(
                          'companies',
                          isAllSelected
                            ? []
                            : value.filter(({ id }) => id !== 'all'),
                        );
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            disabled={option.id === 'all'}
                          />
                        ))
                      }
                      executeLazyQuery={(lazyQuery, query) => {
                        lazyQuery({
                          variables: {
                            accountId: user?.accountId,
                            query,
                          },
                        });
                      }}
                      multiple
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid className={classes.buttonContainer} item xs={6}>
                <Button
                  data-testid="cancel_transfer_rate_button"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    closeForm();
                    cleanMapValues();
                  }}
                  fullWidth
                  disabled={creatingTransferRate}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid className={classes.buttonContainer} item xs={6}>
                <Button
                  data-testid="save_transfer_rate_button"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={creatingTransferRate}
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewTransferRateFormulary;
