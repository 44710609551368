import React, { FC } from 'react';
import { FormControl, Grid, TextField } from '@material-ui/core';
import { useGetVehicleClassesQuery, VehicleClass } from 'src/.gen/graphql';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { useRecoilValue } from 'recoil';

type VehicleClassDropdownProps = {
  setSelectedClass: (vehicleClass: Partial<VehicleClass>) => void;
  selectedClass: Partial<VehicleClass> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
};

const VehicleClassDropdown: FC<VehicleClassDropdownProps> = ({
  setSelectedClass,
  selectedClass,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  values: _values,
}) => {
  const user = useRecoilValue(userAtom);
  const { data } = useGetVehicleClassesQuery({
    variables: { accountId: user?.accountId },
    fetchPolicy: 'no-cache',
  });

  const vehicleClasses = data?.vehicleClasses?.all?.items;

  React.useEffect(() => {
    if (!selectedClass) {
      if (vehicleClasses?.length > 0) {
        setSelectedClass(vehicleClasses[0] as Partial<VehicleClass>);
      }
    }
  }, [selectedClass, vehicleClasses]);

  return (
    <FormControl fullWidth>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {vehicleClasses?.length && (
            <TextField
              fullWidth
              label="Select Vehicle Type"
              name="vehicleClass"
              onChange={(e) => {
                e.preventDefault();
                const vehicleClassSelected = vehicleClasses.find(
                  (el) => el.id === e.target.value,
                );
                setSelectedClass(vehicleClassSelected as VehicleClass);
              }}
              select
              SelectProps={{ native: true }}
              value={selectedClass?.id || vehicleClasses[0]}
              required
              variant="outlined"
            >
              {vehicleClasses?.map((vehicleClass) => (
                <option key={vehicleClass.id} value={vehicleClass.id}>
                  {vehicleClass.name}
                </option>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default VehicleClassDropdown;
