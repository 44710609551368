import React from 'react';
import type { FC, ReactNode } from 'react';
import _snakeCase from 'lodash/snakeCase';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  Home as HomeIcon,
  Users as UsersIcon,
  Map as MapIcon,
  Calendar as CalendarIcon,
  DollarSign,
  PieChart,
  Share2 as ShareIcon,
} from 'react-feather';
import { RiSteering2Fill as SteeringWheelIcon } from 'react-icons/ri';
import {
  MdCreditCard as CreditCardIcon,
  MdBusiness as BusinessIcon,
} from 'react-icons/md';
import CarIcon from '@material-ui/icons/DriveEtaOutlined';

import Logo from 'src/shared/components/Logo';
import {
  ProviderType,
  TierType,
  UserRole,
  VendorRecord,
  VendorType,
} from 'src/.gen/graphql';
import { useRecoilValue } from 'recoil';
import { userAtom, vendorAtom } from 'src/authentication/atoms/AuthState';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Restricted from 'src/shared/components/Permission/Restricted';
import { QuestionAnswer } from '@material-ui/icons';
import { accountTypeUtils } from 'src/shared/utils/accountTypeUtils';
import { useUserProductVariant } from 'src/shared/hooks/useUserProductVariant';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  nonAuthorizeScreen?: boolean;
  showSavoyaLogo?: boolean;
}

interface Item {
  href?: string | ((accountId: string) => string);
  icon?: FC<Record<string, unknown>>;
  info?: FC<Record<string, unknown>>;
  requiresAccount?: boolean;
  requiredRole?: UserRole[];
  items?: Item[];
  title: string;
  hidden?: boolean;
  launchDarklyFlagName?: string;
  minimumTier?: TierType;
  sneak?: boolean;
  vendorProvider?: ProviderType;
  vendorType?: VendorType[];
}

interface Section {
  items?: Item[];
  key: string;
  subheader?: string;
  pathname?: string;
  roles?: Array<UserRole>;
  showVersion?: boolean;
}

interface LDFlagset {
  flagVersion: number;
  trackEvents: boolean;
  value: boolean;
  variation: number;
  version: number;
}

const accountItems: Item[] = [
  {
    title: 'My Company',
    icon: BusinessIcon,
    href: (accountId: string): string => `/accounts/${accountId}/`,
    requiresAccount: true,
  },
  {
    title: 'Users',
    icon: UsersIcon,
    href: '/users',
    vendorProvider: ProviderType.CapitalDrive,
  },
  {
    title: 'Vehicles',
    icon: SteeringWheelIcon,
    href: '/vehicles',
    requiresAccount: true,
    vendorProvider: ProviderType.CapitalDrive,
  },
  {
    title: 'Chauffeurs',
    icon: SteeringWheelIcon,
    href: '/drivers',
    vendorType: [
      VendorType.Garage,
      VendorType.GroundworkGarage,
      VendorType.AsapGarage,
    ],
    vendorProvider: ProviderType.Savoya,
  },
  {
    title: 'Vehicles',
    icon: CarIcon,
    href: '/vehicles',
    vendorProvider: ProviderType.Savoya,
  },
  {
    title: 'Rates',
    icon: DollarSign,
    href: '/rates',
    requiresAccount: true,
  },
  {
    title: 'Settings',
    icon: CreditCardIcon,
    href: `/settings`,
    requiresAccount: true,
    launchDarklyFlagName: 'stripePayment',
    vendorProvider: ProviderType.CapitalDrive,
  },
  {
    title: 'Accounts',
    icon: UsersIcon,
    href: '/accounts',
    requiredRole: [UserRole.CapitalDrive],
    vendorProvider: ProviderType.CapitalDrive,
  },
  // TODO: We are going to uncomment this screen after extra implementation
  // {
  //   title: 'Rate Types',
  //   icon: DollarSign,
  //   href: '/rate-types',
  //   requiresAccount: true,
  // },
];

const sections: Section[] = [
  {
    key: 'default',
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        icon: HomeIcon,
        href: '/',
      },
      {
        title: 'Available Trips',
        icon: MapIcon,
        href: '/shared-trips/list',
        requiresAccount: true,
        vendorProvider: ProviderType.Savoya,
      },
      // {
      //   title: `To Do's`,
      //   icon: ChecklistIcon,
      //   // href: '/todos', Leaving this commented until the To Do Page is created
      //   href: '/todo',
      //   requiresAccount: true,
      //   vendorProvider: ProviderType.Savoya,
      // },
      {
        title: 'My Trips',
        icon: MapIcon,
        href: '/trips/list',
        requiresAccount: true,
      },
      {
        title: 'Schedule',
        icon: CalendarIcon,
        href: '/calendar',
        requiresAccount: true,
      },

      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/customers',
        minimumTier: TierType.Premium,
        sneak: true,
        vendorProvider: ProviderType.CapitalDrive,
      },
      {
        title: 'Network',
        icon: ShareIcon,
        href: `/network`,
        requiresAccount: true,
        minimumTier: TierType.Premium,
        sneak: true,
        vendorProvider: ProviderType.CapitalDrive,
        requiredRole: [
          UserRole.AccountOwner,
          UserRole.Administrator,
          UserRole.SuperAdministrator,
        ],
      },
      {
        title: 'Reports',
        icon: PieChart,
        href: '/reports',
        requiresAccount: true,
        minimumTier: TierType.Premium,
        sneak: true,
        requiredRole: [
          UserRole.AccountOwner,
          UserRole.Administrator,
          UserRole.SuperAdministrator,
        ],
      },
    ],
  },
  {
    key: 'admin',
    subheader: 'Account',
    roles: [
      UserRole.CapitalDrive,
      UserRole.AccountOwner,
      UserRole.SuperAdministrator,
    ],
    items: accountItems,
  },
  {
    key: 'version',
    showVersion: true,
    items: [],
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
  accountId,
  userRole,
  launchDarklyFlags = {},
  vendor,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  accountId?: string;
  userRole?: string;
  launchDarklyFlags?: Record<string, LDFlagset>;
  vendor: Partial<VendorRecord>;
}) {
  return items.length ? (
    <List disablePadding>
      {items
        .filter(
          (item) =>
            !item.launchDarklyFlagName ||
            (launchDarklyFlags[item.launchDarklyFlagName] &&
              !launchDarklyFlags[item.launchDarklyFlagName]?.value),
        )
        .filter((item) => {
          return (
            !item.vendorProvider ||
            !!(vendor?.vendorProviders || []).find(
              (provider) => provider?.providerType === item.vendorProvider,
            )
          );
        })
        .filter((item) => {
          const savoyaProvider = vendor?.vendorProviders?.find(
            (provider) => provider?.providerType === ProviderType.Savoya,
          );
          return (
            !item.vendorType ||
            !!item.vendorType?.includes(savoyaProvider?.vendorType)
          );
        })
        .reduce(
          (acc, item) =>
            reduceChildRoutes({
              acc,
              item,
              pathname,
              depth,
              accountId,
              userRole,
              vendor,
            }),
          [],
        )}
    </List>
  ) : null;
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  accountId,
  userRole,
  vendor,
}: {
  acc: ReactNode[];
  pathname: string;
  item: Item;
  depth: number;
  accountId?: string;
  userRole?: string;
  vendor: Partial<VendorRecord>;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, item.href as string);

    acc.push(
      <NavItem
        data-testid={`navbar_${_snakeCase(item.title)}_button`}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          vendor,
        })}
      </NavItem>,
    );
  } else {
    const hideOnRequiresAccount = item.requiresAccount && !accountId;
    const hideIsOnboarding = userRole === UserRole.AccountOwner && !accountId;
    const hideOnRequiresRole =
      item.requiredRole && !item.requiredRole.includes(userRole as UserRole);

    acc.push(
      <Restricted
        key={key}
        minimumTier={item.minimumTier ?? TierType.Free}
        sneak={item.sneak}
        withIcon
      >
        {({ premiumIcon }) => {
          return (
            <NavItem
              data-testid={`navbar_${_snakeCase(item.title)}_button`}
              depth={depth}
              href={
                typeof item.href === 'string'
                  ? item.href
                  : item.href(accountId || '')
              }
              icon={item.icon}
              info={item.info}
              hidden={
                item.hidden ||
                hideOnRequiresAccount ||
                hideIsOnboarding ||
                hideOnRequiresRole
              }
              key={key}
              title={item.title}
              premiumIcon={premiumIcon}
            />
          );
        }}
      </Restricted>,
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256,
    background: 'inherit',
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    background: 'inherit',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  subheader: {
    marginLeft: theme.spacing(1),
    color: theme.palette.textDark.main,
  },
  versionListItem: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  versionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  version: {
    color: theme.palette.textGray.dark,
    fontSize: '.8rem',
    paddingRight: 10,
  },
  listWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > ul': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  versionListWrapper: {
    padding: 0,
  },
}));

const NavBar: FC<NavBarProps> = ({
  onMobileClose,
  openMobile,
  nonAuthorizeScreen,
  showSavoyaLogo,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useRecoilValue(userAtom);
  const vendor = useRecoilValue(vendorAtom);
  const { isSavoyaProductType } = useUserProductVariant();
  const launchDarklyFlags = useFlags();

  const isSavoyaAccount = accountTypeUtils.isSavoyaAccount(
    vendor?.vendorProviders,
  );
  const hideIsOnboarding =
    user?.userRole === UserRole.AccountOwner &&
    !user?.accountId &&
    !isSavoyaAccount;

  const menuSections = sections.filter((section) => {
    if (
      section?.key === 'admin' &&
      !section?.items?.find((item) => item?.title === 'FAQ')
    ) {
      if (user?.isSavoyaUser || isSavoyaAccount || isSavoyaProductType) {
        section.items?.push({
          title: 'FAQ',
          icon: QuestionAnswer,
          href: `/faq`,
          requiresAccount: true,
          vendorProvider: ProviderType.Savoya,
          launchDarklyFlagName: 'faqTab',
        });
      }
    }
    return !section.roles || section.roles.includes(user?.userRole);
  });

  const content = (
    <Box
      data-testid="navbar_container"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Hidden lgUp>
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink
            data-testid="navbar_logo_link"
            to={
              user?.accountId ||
              user?.userRole === UserRole.CapitalDrive ||
              isSavoyaAccount ||
              showSavoyaLogo
                ? '/'
                : '/onboarding'
            }
          >
            <Logo isSavoya={showSavoyaLogo || isSavoyaAccount} />
          </RouterLink>
        </Box>
      </Hidden>
      <Box flex={1} display="flex" flexDirection="column">
        <br />
        {menuSections.map((section, sectionIndex) => {
          return (
            <List
              className={clsx(
                classes.listWrapper,
                section?.showVersion ? classes.versionListWrapper : undefined,
              )}
              key={`${section.subheader}_${sectionIndex}`}
              subheader={
                !section?.showVersion ? (
                  <ListSubheader
                    data-testid={`navbar_${section.key}_section_subheader`}
                    disableGutters
                    disableSticky
                    key={`${section.key}_${sectionIndex}`}
                    className={classes.subheader}
                  >
                    {hideIsOnboarding ? '' : section.subheader}
                  </ListSubheader>
                ) : null
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                accountId: user?.accountId,
                userRole: user?.userRole,
                launchDarklyFlags,
                vendor,
              })}

              {section.showVersion ? (
                <ListItem
                  className={classes.versionListItem}
                  key={`${section.key}_${sectionIndex}`}
                  disableGutters
                >
                  <Box className={classes.versionWrapper}>
                    <Typography className={classes.version} component="div">
                      {`v${global.appVersion}`}
                    </Typography>
                  </Box>
                </ListItem>
              ) : null}
            </List>
          );
        })}
      </Box>
    </Box>
  );

  return user || nonAuthorizeScreen ? (
    <React.Fragment>
      <Hidden lgUp>
        <Drawer
          data-testid="navbar_drawer"
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {nonAuthorizeScreen ? null : content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          data-testid="navbar_drawer"
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {nonAuthorizeScreen ? null : content}
        </Drawer>
      </Hidden>
    </React.Fragment>
  ) : null;
};

export default NavBar;
