import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { VehicleClassOverrideLite } from 'src/.gen/graphql';
import CustomEditableCell from 'src/rates/components/CustomEditableCell/CustomEditableCell';
import { useVehicleHourlyRates } from 'src/rates/hooks/useVehicleHourlyRates';
import CurrencyField from 'src/shared/components/CurrencyField';
import GeneralTable from 'src/shared/components/GeneralTable/GeneralTable';

const useStyles = makeStyles(() => ({
  VehiclesRatesTable: {
    margin: '10px',
  },
}));

interface VehicleRatesTableInterface {
  setFieldValue: (
    field: string,
    value: string | number,
    shouldValidate?: boolean,
  ) => void;
  existingRates?: Array<VehicleClassOverrideLite>;
}

const VehicleRatesTable: FC<VehicleRatesTableInterface> = ({
  setFieldValue,
  existingRates,
}) => {
  const classes = useStyles();

  const {
    vehicleHourlyRates: dbVehicleHourlyRates,
    loadingVehicleHourlyRates,
  } = useVehicleHourlyRates();
  const [vehicleHourlyRates, setVehicleHourlyRates] = React.useState(null);
  const [newHourlyRates, setNewHourlyRates] = React.useState(null);
  const [nextFormIndex, setNextFormIndex] = React.useState(
    existingRates.length,
  );

  React.useEffect(() => {
    if (!vehicleHourlyRates && dbVehicleHourlyRates?.length) {
      const indexedDBVehicleRates = dbVehicleHourlyRates.map((el, index) => ({
        ...el,
        index,
      }));
      setVehicleHourlyRates([...indexedDBVehicleRates]);
      if (!existingRates.length) {
        indexedDBVehicleRates.forEach((element) => {
          const { hourlyRate, id, name, index } = element;
          setFieldValue(
            `vehicleClassOverride[${index}].amount`,
            Number(hourlyRate),
          );
          setFieldValue(`vehicleClassOverride[${index}].vehicleClassId`, id);
          setFieldValue(`vehicleClassOverride[${index}].name`, name);
        });
      }
    }
  }, [dbVehicleHourlyRates, vehicleHourlyRates, existingRates]);

  React.useEffect(() => {
    if (existingRates?.length && vehicleHourlyRates) {
      const mergedRates = vehicleHourlyRates.map((el) => {
        const rate = { ...el };
        const overlappingRate = existingRates.find(
          ({ vehicleClassId }) => rate.id === vehicleClassId,
        );
        if (overlappingRate) {
          rate.hourlyRate = overlappingRate.amount;
        } else {
          rate.hourlyRate = 0;
        }
        return rate;
      });
      setNewHourlyRates(mergedRates);
    }
  }, [existingRates, vehicleHourlyRates]);

  const TABLE_HEADERS = [
    {
      label: 'Vehicle Type',
      id: 'name',
      editable: 'never' as const,
    },
    {
      label: 'Fixed Rate',
      id: 'hourlyRate',
      format: (hourlyRate, rowData) => {
        return (
          <CustomEditableCell
            type="text"
            inputType="currency"
            value={hourlyRate}
            onSubmit={(newValue: number) => {
              return new Promise<void>((resolve, reject) => {
                try {
                  const hourlyRate = newValue;
                  const { name, id } = rowData;
                  const formIndex = existingRates.findIndex(
                    (el) => el.vehicleClassId === id,
                  );
                  const newIndex = formIndex === -1 ? nextFormIndex : formIndex;
                  setNextFormIndex(nextFormIndex + 1);
                  setVehicleHourlyRates((prev) =>
                    prev.map((vhr) => (vhr.id === id ? rowData : vhr)),
                  );
                  setFieldValue(
                    `vehicleClassOverride[${newIndex}].amount`,
                    Number(hourlyRate),
                  );
                  setFieldValue(
                    `vehicleClassOverride[${newIndex}].vehicleClassId`,
                    `${id}`,
                  );
                  setFieldValue(
                    `vehicleClassOverride[${newIndex}].name`,
                    `${name}`,
                  );
                  resolve();
                } catch (err) {
                  reject(err);
                }
              });
            }}
            customInputComponent={CurrencyField}
          />
        );
      },
    },
  ];

  return (
    <div
      className={classes.VehiclesRatesTable}
      data-testid="vehicle_rates_table"
    >
      <GeneralTable
        columns={TABLE_HEADERS}
        rows={newHourlyRates || vehicleHourlyRates || []}
        hasPagination={false}
        hasSelection={false}
        clickable={false}
        loading={loadingVehicleHourlyRates}
      />
    </div>
  );
};

export default VehicleRatesTable;
