import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { convertHoursToMinutes } from 'src/shared/utils/conversions';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import { handleTripCommandException } from 'src/trips/hooks/useTripCommand';
import OverrideTimeForm, {
  OverrideTimeInitialValues,
} from './OverrideTimeForm';

export type SelectedTime = {
  label: string;
  time: number;
  status: string;
};

interface Props {
  selectedTime: SelectedTime;
  onCancel: () => void;
  onSubmit: (
    values: OverrideTimeInitialValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (command: any, closeDialog: () => void) => void,
  ) => Promise<void>;
}

const OverrideSelectTime: React.FC<Props> = ({
  selectedTime,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();
  const setTimestampsState = useSetRecoilState(tripTimestampsState);

  const { enqueueSnackbar } = useSnackbar();

  const handleErrors = (errors) => {
    errors.forEach((errorMessage) => {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    });
  };

  const callback = (command, closeDialog) => {
    handleTripCommandException(command, (succeeded, errors) => {
      if (!succeeded) {
        handleErrors(errors);
        return;
      }
      setTimestampsState((prev) => ({
        ...prev,
        refetchInvoiceSummary: true,
        updating: true,
      }));
      closeDialog();
      enqueueSnackbar(`Override ${selectedTime.label} successfully`, {
        variant: 'success',
      });
    });
  };

  return (
    <Grid item xs={12}>
      <Box marginBottom={2}>
        <Typography variant="body2" component="p">
          <Typography component="span" className={classes.reportedTimeLabel}>
            Status:
          </Typography>
          {selectedTime?.status}
        </Typography>
      </Box>
      <OverrideTimeForm
        initialValues={{
          newTime: convertHoursToMinutes(selectedTime?.time ?? 0),
        }}
        onCancel={onCancel}
        onSubmit={(values) => onSubmit(values, callback)}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  reportedTimeLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(1),
  },
}));

export default OverrideSelectTime;
