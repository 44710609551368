import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import React, { FC, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  AreaType,
  UpsertPlaceInput,
  useGetPlaceInfoLazyQuery,
  useGetPreictionsLazyQuery,
  ZoneType,
} from 'src/.gen/graphql';
import { ratesMapPlaces } from 'src/rates/atoms/RatesFormAtom';
import TextLineInput from 'src/rates/components/TextLineInput/TextLineInput';
import { coordinatesFromLocationToLatLon } from 'src/rates/hooks/useTransferRates';
import AsyncAutocomplete from 'src/shared/components/AutoComplete/AsyncAutocomplete';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import {
  LocationTypes,
  parseGeoOptionToLocation,
} from 'src/shared/lib/geoHelpers';
import { convertMilesToMeters } from 'src/shared/utils/conversions';
import { InitialValuesInterface } from '../NewTransferRateFormulary';
import { useTransferRateFormStyles } from '../NewTrasferRateFormularyStyles';

const useStyles = makeStyles(() => ({
  MainInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  SmallMainInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '0.8rem',
  },
  TextFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '10px',
  },
  smallTextFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: '10px',
    padding: '0px 10px',
    width: '100%',
  },
  textField: {
    margin: '10px',
    flex: 1,
  },
  toggleOption: {
    // fontSize: '10px',
    padding: '0',
    margin: '0',
    fontSize: '0.8rem !important',
  },
  radioButton: {
    margin: '0',
    padding: '0',
  },
  radioButtonsContainer: {
    marginTop: '10px',
    marginRight: '10px',
  },
  smallRadioButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '10px 0px',
    padding: '0px 10px',
    width: '100%',
  },
}));

const RATE_EXACT_LOCATION = ZoneType.Address;
const RATE_ZONE = ZoneType.Area;

interface RateLocationInputInterface {
  values: InitialValuesInterface;
  setFieldValue: (
    field: string,
    value: number | string | Record<string, unknown>,
    shouldValidate?: boolean,
  ) => void;
  setFieldTouched: (
    field: string,
    value: unknown,
    shouldValidate?: boolean,
  ) => void;
  locationFieldName: string;
  errors: FormikErrors<InitialValuesInterface>;
  touched: FormikTouched<InitialValuesInterface>;
}

const RateLocationInput: FC<RateLocationInputInterface> = ({
  values,
  locationFieldName,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
}) => {
  const classes = useStyles();
  const transferRateStyles = useTransferRateFormStyles();
  const [zoneType, setZoneType] = useState(
    (values || {})[`${locationFieldName}ZoneType`] || RATE_EXACT_LOCATION,
  );
  const setMapCoordinates = useSetRecoilState(ratesMapPlaces);
  const { sm } = useBreakpoints();
  const milesInputRef = useRef(null);

  const [displayValue, setDisplayValue] =
    useState<{ id: string; placeName: string }>(undefined);

  const [getPlaceInfo] = useGetPlaceInfoLazyQuery({
    onCompleted: async (data) => {
      const parsedValue = parseGeoOptionToLocation(
        data?.locations?.getPlaceInfo,
        'google',
        LocationTypes.upsertPlaceInput,
      );
      await setFieldValue(locationFieldName, parsedValue);
      setFieldTouched(locationFieldName, true, true);
    },
  });

  const placeError =
    typeof errors[locationFieldName] === 'string'
      ? errors[locationFieldName]
      : errors[locationFieldName]?.placeName;

  React.useEffect(() => {
    if (values[locationFieldName]) {
      const { latitude, longitude } = coordinatesFromLocationToLatLon(
        values[locationFieldName],
      );
      if (values && latitude && longitude) {
        setMapCoordinates((curr) => ({
          ...curr,
          [locationFieldName]: {
            ...curr[locationFieldName],
            coordinates: [longitude, latitude],
          },
        }));
      }

      const radius =
        convertMilesToMeters(Number(values[`${locationFieldName}RadiusInKm`])) /
        1000;
      setMapCoordinates((curr) => ({
        ...curr,
        [locationFieldName]: {
          ...curr[locationFieldName],
          radiusInKm: radius,
          zoneType: values[`${locationFieldName}ZoneType`],
          geoType: values[`${locationFieldName}GeoType`],
        },
      }));
    }
  }, [values]);

  const handleChange = (newZoneType, numberOfZoneType, geoTypeFieldName) => {
    setFieldValue(numberOfZoneType, newZoneType);
    setFieldValue(
      geoTypeFieldName,
      newZoneType === RATE_EXACT_LOCATION ? AreaType.Place : AreaType.Radius,
    );
    setZoneType(newZoneType);
    setMapCoordinates((curr) => ({
      ...curr,
      [locationFieldName]: {
        ...curr[locationFieldName],
        zoneType: newZoneType,
        geoType:
          newZoneType === RATE_EXACT_LOCATION
            ? AreaType.Place
            : AreaType.Radius,
      },
    }));
  };
  const getDefaultValue = (location: UpsertPlaceInput) => {
    if (location?.placeName) {
      const defaultValue = {
        id: location?.placeName,
        placeName: location?.placeName,
      };
      return defaultValue;
    }
    return null;
  };

  return (
    <div
      className={
        sm ? classes.SmallMainInputContainer : classes.MainInputContainer
      }
    >
      <div
        className={
          sm ? classes.smallTextFieldsContainer : classes.TextFieldsContainer
        }
      >
        <AsyncAutocomplete
          label="From/To"
          size="small"
          useLazyQuery={useGetPreictionsLazyQuery}
          filterOptions={(x) => x}
          getOptionsFromData={(data) => {
            return data?.locations?.getPredictions ?? [];
          }}
          getOptionLabel={(option) => option?.placeName ?? ''}
          value={displayValue || getDefaultValue(values[locationFieldName])}
          onChange={async (_, value) => {
            if (value?.placeId) {
              setDisplayValue({
                id: value?.id,
                placeName: value?.placeName,
              });
              getPlaceInfo({
                variables: { placeId: value?.placeId },
              });
            } else {
              setFieldValue(locationFieldName, null);
            }
          }}
          executeLazyQuery={(lazyQuery, query) => {
            if (!query || query?.length < 3) {
              return [];
            }
            return lazyQuery({
              variables: {
                predictionInput: {
                  query,
                },
              },
            });
          }}
          error={touched[locationFieldName] && !!errors[locationFieldName]}
          helperText={
            errors[locationFieldName] && touched[locationFieldName]
              ? placeError
              : ''
          }
          freeSolo
        />
        {RATE_ZONE === zoneType && (
          <React.Fragment>
            <TextLineInput
              className={
                sm
                  ? transferRateStyles.smallTextLineInput
                  : transferRateStyles.textLineInput
              }
              text="and surrounding $miles miles"
              inputs={[
                {
                  component: () => (
                    <div>
                      <input
                        data-testid={`rate_${locationFieldName}_radius_field`}
                        className={clsx(
                          transferRateStyles.inputStyles,
                          touched[`${locationFieldName}RadiusInKm`] &&
                            errors[`${locationFieldName}RadiusInKm`]
                            ? transferRateStyles.inputStylesError
                            : null,
                        )}
                        defaultValue={values[`${locationFieldName}RadiusInKm`]}
                        type="number"
                        ref={milesInputRef}
                        onBlur={async (event) => {
                          await setFieldValue(
                            `${locationFieldName}RadiusInKm`,
                            Number(event.target.value || 0),
                          );
                          setFieldTouched(
                            `${locationFieldName}RadiusInKm`,
                            true,
                          );
                          setMapCoordinates((curr) => ({
                            ...curr,
                            [locationFieldName]: {
                              ...curr[locationFieldName],
                              radiusInKm:
                                convertMilesToMeters(
                                  Number(event.target.value),
                                ) / 1000,
                            },
                          }));
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            if (milesInputRef && milesInputRef.current) {
                              milesInputRef?.current?.blur();
                            }
                          }
                        }}
                      />
                    </div>
                  ),
                  id: '$miles',
                },
              ]}
            />
            {touched[`${locationFieldName}RadiusInKm`] &&
              errors[`${locationFieldName}RadiusInKm`] && (
                <Typography
                  variant="caption"
                  color="error"
                  className={transferRateStyles.inputErrorMessage}
                >
                  {errors[`${locationFieldName}RadiusInKm`]}
                </Typography>
              )}
          </React.Fragment>
        )}
      </div>
      <RadioGroup
        aria-label="zone-type"
        defaultValue={zoneType}
        name="zone-types-buttons"
        className={
          sm
            ? classes.smallRadioButtonsContainer
            : classes.radioButtonsContainer
        }
        onChange={(_event, newValue) => {
          if (newValue) {
            handleChange(
              newValue,
              `${locationFieldName}ZoneType`,
              `${locationFieldName}GeoType`,
            );
          }
        }}
      >
        <FormControlLabel
          value={RATE_EXACT_LOCATION}
          control={
            <Radio
              data-testid={`rate_exact_${locationFieldName}_checkbox`}
              className={classes.radioButton}
            />
          }
          label="Exact Location"
          className={classes.toggleOption}
        />
        <FormControlLabel
          value={RATE_ZONE}
          control={
            <Radio
              data-testid={`rate_zone_${locationFieldName}_checkbox`}
              className={classes.radioButton}
            />
          }
          label="Zone"
          className={classes.toggleOption}
        />
      </RadioGroup>
    </div>
  );
};

export default RateLocationInput;
