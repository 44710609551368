import React from 'react';
import { DialogActions, Button } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { TripStatus } from 'src/.gen/graphql';
import { parseButtonLabelEditView } from 'src/shared/utils/tripUtils';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';

interface DiscarTrip {
  tripId: string;
  tripStatus: TripStatus;
  customButton?: React.ElementType;
}

const DiscardTrip: React.FC<DiscarTrip> = ({
  tripId,
  tripStatus,
  customButton: CustomButton = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const handleDiscardTrip = async (): Promise<void> => {
    setLoading(true);
    if (location.pathname !== '/trips/list') {
      navigate(-1);
    }
    setLoading(false);
    closeDialog();
  };

  const handleClickAction = () => {
    if (!tripId) {
      navigate('/');
      return;
    }
    setDialogVisible(true);
  };

  const renderButton = () => {
    const defaultProps = {
      disabled: loading,
      onClick: handleClickAction,
    };
    const children = parseButtonLabelEditView('Discard', tripStatus);
    if (CustomButton) {
      return React.cloneElement(
        <CustomButton />,
        { ...defaultProps, 'data-testid': 'discard_trip_button' },
        children,
      );
    }
    return React.cloneElement(
      <Button />,
      {
        ...defaultProps,
        size: 'medium',
        variant: 'outlined',
        'data-testid': 'discard_trip_button',
      },
      children,
    );
  };

  return (
    <React.Fragment>
      {renderButton()}
      <GenericDialog
        openDialog={dialogVisible}
        setCloseDialog={closeDialog}
        dialogTitle="Are you sure that you want to discard your changes?"
        maxWidth="sm"
      >
        <DialogActions>
          <Button onClick={closeDialog} variant="outlined">
            Cancel
          </Button>
          <Button
            data-testid="discard_trip_button"
            startIcon={loading && <CircularProgressIndicator />}
            onClick={handleDiscardTrip}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </GenericDialog>
    </React.Fragment>
  );
};

export default DiscardTrip;
