import React from 'react';
import { useRecoilValue } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserIdentityRole } from 'src/.gen/graphql';
import { userAtom } from 'src/authentication/atoms/AuthState';
import LoadingScreen from 'src/shared/components/LoadingScreen';

const NotAuthGuard: React.FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const user = useRecoilValue(userAtom);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (isAuthenticated) {
      if (user?.isSavoyaUser && user?.role === UserIdentityRole.Driver) {
        navigate('/savoya-driver');
      } else {
        navigate('/');
      }
      return;
    }

    switch (pathname) {
      case '/signup':
        loginWithRedirect({ screen_hint: 'signup' });
        break;
      default:
        break;
    }
  }, [isAuthenticated, pathname]);

  return isLoading ? <LoadingScreen /> : <Outlet />;
};

export default NotAuthGuard;
