import React from 'react';
import { useFetch } from 'src/shared/hooks/useFetch';
import { mapBoxConfig } from 'src/config';

interface UseIsochroneGeojsonParams {
  contoursMinutes: number | null;
  coordinates: number[] | null;
}

interface GeojsonDataInterface {
  geojsonData: {
    type: 'geojson';
    data: {
      type: 'Feature';
      geometry: {
        type: string;
        coordinates: number[][];
      };
      properties: Record<string, string | number>;
    };
  };
  isoCoordinates: number[][];
}

interface UseIsochroneGeojsonFunctionInterface {
  (params: UseIsochroneGeojsonParams): GeojsonDataInterface;
}

export const useIsochroneGeojson: UseIsochroneGeojsonFunctionInterface = ({
  contoursMinutes,
  coordinates,
}) => {
  const queryCoordinates = `${coordinates[0]},${coordinates[1]}`;
  const [geojsonData, setGeojsonData] = React.useState(null);
  const [isoCoordinates, setCoordinates] = React.useState(null);
  const profile = 'mapbox/driving';

  const createUrl = () => {
    return `https://api.mapbox.com/isochrone/v1/${profile}/${queryCoordinates}?contours_minutes=${contoursMinutes}&access_token=${mapBoxConfig.token}`;
  };

  const { data, isLoading, updateUrl } = useFetch({
    initialUrl: createUrl(),
    skip: !contoursMinutes || !coordinates,
  });

  React.useEffect(() => {
    if (contoursMinutes && coordinates) {
      updateUrl(createUrl());
    }
  }, [contoursMinutes, coordinates]);

  React.useEffect(() => {
    if (data && !isLoading) {
      const newGeojsonData = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: data.features[0].geometry,
          },
        ],
      };
      setGeojsonData(newGeojsonData);
      setCoordinates(data.features[0].geometry.coordinates);
    }
  }, [data, setGeojsonData, isLoading]);

  return { geojsonData, isoCoordinates };
};
