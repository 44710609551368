import React, { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Theme,
  Button,
  Typography,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/shared/components/Logo';
import { Restricted } from 'src/shared/components/Permission';
import { useRecoilValue } from 'recoil';
import {
  userAtom,
  subscriptionPlanAtom,
  vendorAtom,
} from 'src/authentication/atoms/AuthState';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserRole, TierType, ProviderType } from 'src/.gen/graphql';
import logoSvg from '../../assets/images/logoLightNoIcon.png';
import Account from './Account';
import Search from './Search';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  searchDisabled?: boolean;
  showSavoyaLogo?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.dark,
  },
  toolbar: {
    minHeight: 64,
  },
  subLogo: {
    width: '10rem',
    marginTop: 'auto',
    marginLeft: '.5rem',
  },
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  showSavoyaLogo,
  ...rest
}) => {
  const classes = useStyles();
  const { subscriptions: subscriptionsEnabled } = useFlags();
  const user = useRecoilValue(userAtom);
  const vendor = useRecoilValue(vendorAtom);

  const savoyaProvider = vendor?.vendorProviders?.find(
    (provider) => provider?.providerType === ProviderType.Savoya,
  );

  const capitalDriveProvider = vendor?.vendorProviders?.find(
    (provider) => provider?.providerType === ProviderType.CapitalDrive,
  );

  const subscription = useRecoilValue(subscriptionPlanAtom);
  const [logoLinkHref, setLogoLinkHref] = React.useState<string>('/');
  const [showNavElements, setShowNavElements] = React.useState<boolean>(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (subscriptionsEnabled) {
      if (user?.accountId && subscription?.id) {
        setLogoLinkHref('/');
        setShowNavElements(true);
      } else if (!subscription?.id) {
        setLogoLinkHref('/subscriptions');
      }
    } else if (!subscriptionsEnabled) {
      if (!user?.accountId) {
        setLogoLinkHref('/onboarding');
      } else {
        setShowNavElements(true);
      }
    }
    if (savoyaProvider && savoyaProvider?.id && !capitalDriveProvider) {
      setLogoLinkHref('/');
      setShowNavElements(false);
    }
  }, [user, subscription]);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink data-testid="navbar_logo_link" to={logoLinkHref}>
            <Logo isSavoya={showSavoyaLogo || !!savoyaProvider} />
          </RouterLink>
        </Hidden>
        {savoyaProvider && capitalDriveProvider ? (
          <Hidden mdDown>
            <Box marginTop="1.2rem">
              <Typography variant="body2">Powered By</Typography>{' '}
            </Box>
            <Box marginTop="1.4rem">
              <img alt="Logo" className={classes.subLogo} src={logoSvg} />
            </Box>
          </Hidden>
        ) : null}
        <Box ml={2} flexGrow={1} />
        {showNavElements ? <Search /> : null}
        <Box ml={2} />
        {showNavElements && user.userRole === UserRole.AccountOwner ? (
          <Restricted minimumTier={TierType.Premium} hideIfNotCompliant>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate('/trips/new')}
              data-testid="new_trip_button"
            >
              New Trip
            </Button>
          </Restricted>
        ) : null}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
