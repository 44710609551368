import React from 'react';
import {
  Box,
  IconButton,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as UpgradeSubscriptionSvg } from 'src/assets/svg/Upgrade.svg';
import { paidFeatureModal } from '../atoms/SubscriptionState';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    backgroundColor: 'rgba(160,160,160,0.5)',
    backdropFilter: 'blur(5px)',
    filter: 'blur(5px)',
    '-webkit-filter': 'blur(5px)',
  },
  dialogContentContent: {
    padding: 20,
  },
  dialogTitleCloseButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  dialogActionButton: {
    margin: theme.spacing(1),
    fontSize: 20,
    fontWeight: 500,
  },
  upgradeSvg: {
    width: 350,
    height: 'auto',
    color: theme.palette.primary.main,
    border: 'none',
  },
}));

const PaidFeatureModal: React.FC = () => {
  const classes = useStyles();
  const [{ open, message }, setOpen] = useRecoilState(paidFeatureModal);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen({ open: false, message: undefined });
  };

  const handleUpgrade = () => {
    setOpen({ open: false, message: undefined });
    navigate('/settings');
  };
  const dialogMessage =
    message ??
    'Upgrade your subscription plan to unlock it and take your business to the next level.';
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ className: classes.backdrop }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.dialogTitleCloseButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.dialogContentContent}>
            <Box display="flex" justifyContent="center" mb={4}>
              <UpgradeSubscriptionSvg className={classes.upgradeSvg} />
            </Box>
            <Box display="flex" justifyContent="center" p={2}>
              <Typography variant="h4" color="textPrimary" align="center">
                This is a Premium feature!
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" p={1}>
              <Typography variant="h6" color="textPrimary" align="center">
                {dialogMessage}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" my={4}>
              <Fab
                variant="extended"
                color="primary"
                aria-label="upgrade-subscription"
                onClick={handleUpgrade}
              >
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.dialogActionButton}
                >
                  Upgrade
                </Typography>
              </Fab>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PaidFeatureModal;
