import React, { FC } from 'react';

interface TabComponentProps {
  value: number | string;
  index: number;
}

const TabComponent: FC<TabComponentProps> = ({ children, value, index }) => {
  return <React.Fragment>{value === index && children}</React.Fragment>;
};

export default TabComponent;
