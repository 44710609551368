import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import {
  SavoyaSharedTripStatus,
  SharedTripStatus,
  TripCompletionStatus,
  TripStatus,
} from 'src/.gen/graphql';
import { getStatusLabel } from 'src/shared/utils/tripUtils';
import { getCompletionStatuses } from 'src/shared/utils/tripStatusUtils';

interface BoxProps {
  status?: TripStatus;
  savoyaSharedStatus?: SavoyaSharedTripStatus;
  sharedStatus?: SharedTripStatus;
  iOwnThisTrip?: boolean;
  isSavoyaTrip?: boolean;
  isAvailableTrip?: boolean;
}

const useStyles = makeStyles((theme) => ({
  tripStatusBox: {
    padding: '0.1rem 0.4rem',
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },
}));

const TripStatusBox: React.FC<BoxProps> = ({
  status,
  savoyaSharedStatus,
  sharedStatus,
  iOwnThisTrip,
  isSavoyaTrip,
  isAvailableTrip,
}) => {
  const classes = useStyles();

  const showBothStatus = !getCompletionStatuses(
    TripCompletionStatus.CompleteOrClosed,
  ).includes(status);

  const statusLabel =
    getStatusLabel(status, savoyaSharedStatus, isSavoyaTrip, isAvailableTrip) ||
    '';
  const displaySharedStatus =
    !iOwnThisTrip &&
    sharedStatus &&
    savoyaSharedStatus !== SavoyaSharedTripStatus.Cancelled;
  const sharedStatusLabel = displaySharedStatus
    ? ` / ${sharedStatus ?? ''}`
    : '';

  return showBothStatus ? (
    <Chip
      color="primary"
      variant="outlined"
      className={clsx(
        classes.tripStatusBox,
        classes[`tripStatusBox${status?.toLocaleLowerCase()}`],
      )}
      label={statusLabel + sharedStatusLabel}
    />
  ) : (
    <Chip
      color="primary"
      variant="outlined"
      className={clsx(
        classes.tripStatusBox,
        classes[`tripStatusBox${status?.toLocaleLowerCase()}`],
      )}
      label={
        iOwnThisTrip || isSavoyaTrip ? statusLabel : `${sharedStatus ?? ''}`
      }
    />
  );
};

export default TripStatusBox;
