import { useContext } from 'react';
import { UserRecord } from 'src/.gen/graphql';
import MobileAuthContext from 'src/contexts/MobileAuthContext';

interface UseMobileAuthReturnType {
  isMobileAuthenticated: boolean;
  authenticatedFromMobile: boolean;
  user: UserRecord | undefined;
  clearMobileTokens: () => void;
}

export const useMobileAuth = (): UseMobileAuthReturnType => {
  const {
    isMobileAuthenticated,
    authenticatedFromMobile,
    user,
    clearMobileTokens,
  } = useContext(MobileAuthContext);
  return {
    isMobileAuthenticated,
    authenticatedFromMobile,
    user,
    clearMobileTokens,
  };
};
