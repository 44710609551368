import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import React, { useEffect } from 'react';
import { DeepPartial } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import {
  TripClientRecord,
  useDeleteTripClientMutation,
  useDeleteTripContactMutation,
} from 'src/.gen/graphql';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import BaseEnhancedDialog from 'src/shared/components/EnhancedDialog/BaseEnhandedDialog';
import { tripUpdateClientState } from 'src/trips/atoms/tripUpdateClientAtom';

interface DeleteTripContactDialogProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  setDialogAction: (newValue: string) => void;
  tripFormIsValid: boolean;
  tripClient: DeepPartial<TripClientRecord> | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  confirmButton: {
    backgroundColor: green[400],
    color: '#ffffff',
    float: 'right',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  cancelButton: {
    backgroundColor: red[400],
    color: '#ffffff',
    float: 'left',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: red[500],
    },
  },
}));

const DeleteTripClientDialog: React.FC<DeleteTripContactDialogProps> = ({
  isOpen,
  setIsOpen,
  setDialogAction,
  tripFormIsValid,
  tripClient,
}) => {
  const classes = useStyles();
  const setTripClientState = useSetRecoilState(tripUpdateClientState);
  const [deleteContact] = useDeleteTripContactMutation();
  const [deleteTripClient, { loading, data }] = useDeleteTripClientMutation();

  useEffect(() => {
    if (data?.deleteTripClient) {
      setDialogAction('');
      setTripClientState(true);
      setIsOpen(false);
    }
  }, [data]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setDialogAction('');
        setIsOpen(false);
      }}
    >
      <BaseEnhancedDialog
        title="Delete trip contact"
        content={
          <Typography>
            Are you sure you want to remove this client from the trip?
          </Typography>
        }
        onClose={() => {
          setDialogAction('');
          setIsOpen(false);
        }}
        actions={
          <Grid container>
            <Grid item xs={6}>
              <Button
                className={classes.cancelButton}
                onClick={() => {
                  setDialogAction('');
                  setIsOpen(false);
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  deleteContact({
                    variables: {
                      id: tripClient?.id,
                    },
                  });
                  deleteTripClient({
                    variables: { tripClientId: tripClient?.id },
                  });
                }}
                disabled={!tripFormIsValid}
                className={classes.confirmButton}
                color="primary"
                startIcon={loading && <CircularProgressIndicator />}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        }
      />
    </Dialog>
  );
};

export default DeleteTripClientDialog;
