import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { AdjustmentCalculation } from 'src/.gen/graphql';

interface DiscountTypeSelectorProps {
  onChange: (newValue: AdjustmentCalculation) => void;
  currency: string;
}

const DiscountTypeSelector: React.FC<DiscountTypeSelectorProps> = ({
  onChange,
  currency = '$',
}) => {
  const [adjustmentType, setAdjustmentType] =
    React.useState<AdjustmentCalculation>(AdjustmentCalculation.Flat);

  const handleChange = (option: AdjustmentCalculation) => {
    setAdjustmentType(option);
    onChange(option);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        style={{
          margin: 0,
          padding: 0,
          width: '20px',
          minWidth: 0,
          height: '20px',
          borderRadius: 0,
        }}
        variant={
          adjustmentType === AdjustmentCalculation.Flat
            ? 'contained'
            : 'outlined'
        }
        color="primary"
        onClick={() => handleChange(AdjustmentCalculation.Flat)}
      >
        $
      </Button>
      <Button
        style={{
          margin: 0,
          marginRight: 5,
          padding: 0,
          width: '20px',
          minWidth: 0,
          height: '20px',
          borderRadius: 0,
        }}
        variant={
          adjustmentType === AdjustmentCalculation.Percentage
            ? 'contained'
            : 'outlined'
        }
        color="primary"
        onClick={() => handleChange(AdjustmentCalculation.Percentage)}
      >
        %
      </Button>
      <Typography variant="body1">
        {AdjustmentCalculation.Flat === adjustmentType ? currency : '%'}
      </Typography>
    </Box>
  );
};

export default DiscountTypeSelector;
