import { SubmitHandler, useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import {
  RatePolicy,
  RatePolicyType,
  useUpdateDefaultRatesMutation,
} from 'src/.gen/graphql';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import TextLineInput from '../../TextLineInput/TextLineInput';
import { useRatesStyles } from '../../RatesDashboard';

interface Inputs {
  airportMinutes: number;
  nonAirportMinutes: number;
}

interface ComplimentaryWaitTimeFormProps {
  policies: Partial<Record<RatePolicyType, RatePolicy>>;
  refreshData: () => void;
}

const ComplimentaryWaitTimeForm: FC<ComplimentaryWaitTimeFormProps> = ({
  policies,
  refreshData,
}) => {
  const classes = useRatesStyles();
  const { sm } = useBreakpoints();
  const airportPolicy = policies[RatePolicyType.AirportWaitTime];
  const nonAirportPolicy = policies[RatePolicyType.WaitTime];

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      airportMinutes: 30,
      nonAirportMinutes: 30,
    },
  });

  const [updateDefaultRate] = useUpdateDefaultRatesMutation();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await Promise.all([
      updateDefaultRate({
        variables: {
          id: airportPolicy.id,
          thresholdValue: Number(data.airportMinutes / 60),
        },
      }),
      updateDefaultRate({
        variables: {
          id: nonAirportPolicy.id,
          thresholdValue: Number(data.nonAirportMinutes / 60),
        },
      }),
    ]);
    refreshData();
  };

  const airportMinutes = register('airportMinutes');
  const nonAirportMinutes = register('nonAirportMinutes');

  useEffect(() => {
    if (airportPolicy?.thresholdValue) {
      const thMinutes = Math.ceil(airportPolicy?.thresholdValue * 60);
      setValue('airportMinutes', thMinutes);
    }
    if (nonAirportPolicy?.thresholdValue) {
      const thMinutes2 = Math.ceil(nonAirportPolicy?.thresholdValue * 60);
      setValue('nonAirportMinutes', thMinutes2);
    }
  }, [airportPolicy, nonAirportPolicy]);

  return (
    <form
      className={sm ? classes.smallFormStyles : classes.formStyles}
      data-testid="complementary_wait_time_form"
    >
      <TextLineInput
        text="Airport pickups include $airportMinutes minutes of complimentary wait time. Non-airport pickups include $nonAirportMinutes minutes of complimentary wait time."
        inputs={[
          {
            component: () => (
              <input
                data-testid="complementary_wait_time_airport_minutes_field"
                className={classes.inputStyles}
                defaultValue={0}
                type="number"
                onChange={airportMinutes.onChange}
                ref={airportMinutes.ref}
                name={airportMinutes.name}
                onBlur={(e) => {
                  airportMinutes.onBlur(e);
                  handleSubmit(onSubmit)();
                }}
              />
            ),
            id: '$airportMinutes',
          },
          {
            component: () => (
              <input
                data-testid="complementary_wait_time_non_airport_minutes_field"
                className={classes.inputStyles}
                defaultValue={0}
                type="number"
                onChange={nonAirportMinutes.onChange}
                ref={nonAirportMinutes.ref}
                name={nonAirportMinutes.name}
                onBlur={(e) => {
                  nonAirportMinutes.onBlur(e);
                  handleSubmit(onSubmit)();
                }}
              />
            ),
            id: '$nonAirportMinutes',
          },
        ]}
      />
    </form>
  );
};

export default ComplimentaryWaitTimeForm;
