import React from 'react';
import { makeStyles, Typography, styled, Theme } from '@material-ui/core';

interface Props {
  sign?: string;
  value: string;
  centerSign?: boolean;
  bold?: boolean;
}

const CurrencyValue: React.FC<Props> = ({
  value,
  sign = '',
  centerSign = true,
  bold = false,
}) => {
  const classes = useStyles();

  return (
    <CustomLabel variant="body2" centerSign={centerSign} bold={bold}>
      {sign && (
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.defaultSign}
        >
          {sign}
        </Typography>
      )}
      ${value} USD
    </CustomLabel>
  );
};

const CustomLabel = styled(Typography)(
  ({
    theme,
    centerSign,
    bold,
  }: {
    theme: Theme;
    centerSign?: boolean;
    bold?: boolean;
  }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: centerSign ? 'unset' : 'baseLine',
    fontWeight: bold ? 700 : theme.typography.fontWeightRegular,
  }),
);

const useStyles = makeStyles((theme) => ({
  defaultSign: {
    marginRight: theme.spacing(1),
  },
}));

export default CurrencyValue;
