import {
  Grid,
  CardActions,
  Button,
  CircularProgress,
  DialogActions,
} from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  GenericDriver,
  GenericVehicle,
  SharedTrip,
  SharedTripStatus,
  Trip,
  TripProviderSource,
  TripStatus,
  useAssignTripMutation,
  useMakeTripConfirmedMutation,
  useMutateSharedTripStatusMutation,
} from 'src/.gen/graphql';
import { WarningButtonTheme } from 'src/shared/components/ButtonThemeProviders';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userAtom, vendorAtom } from 'src/authentication/atoms/AuthState';
import {
  getDialogContentFromTripCommand,
  tripCommandDialogState,
} from 'src/trips/atoms/tripCommandDialogAtom';
import { useShowCancelButton } from 'src/trips/hooks/useShowCancelButton';
import { useNavigate } from 'react-router-dom';
import { accountTypeUtils } from 'src/shared/utils/accountTypeUtils';
import TripAssignmentDialog from 'src/tripsNewClients/components/TripAdditionalInfo/TripAssignment/TripAssignmentDialog';
import Dinero, { Currency } from 'dinero.js';
import { SavoyaVendorAssignment } from 'src/trips/types/SavoyaTripsTypes';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useSnackbar } from 'notistack';
import { handleTripCommandException } from 'src/trips/hooks/useTripCommand';
import {
  defaultTripAssignmentState,
  tripAssignmentSelector,
} from 'src/tripsNewClients/atoms/tripAssignmentAtom';
import { rejectableSharedTripStatuses } from 'src/trips/constants/constants';
import { confirmCancelledAssignment } from 'src/trips/api/SavoyaTripQueries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import AcceptOrClaimSharedTripBtn from '../AcceptOrClaimSharedTripBtn';

type CardActionsProps = TripPassengerCardWithTripProps;

interface TripPassengerCardWithTripProps {
  trip: Partial<Trip>;
  loading?: boolean;
  showEditButton?: boolean;
  showViewButton?: boolean;
  paymentButton?: ReactNode;
  savoyaReservationAssignment?: SavoyaVendorAssignment;
  isAvailableTrip?: boolean;
}

const CardActionsComponent: React.FC<CardActionsProps> = (props) => {
  const {
    showEditButton,
    showViewButton,
    paymentButton,
    loading,
    savoyaReservationAssignment,
    isAvailableTrip,
  } = props;
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const vendor = useRecoilValue(vendorAtom);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const setTripCommandDialog = useSetRecoilState(tripCommandDialogState);
  const [trip, setTrip] = useState<Partial<Trip> | undefined>();
  const setTripAssignment = useSetRecoilState(tripAssignmentSelector);

  const savoyaAssignmentCancelled =
    !isAvailableTrip &&
    savoyaReservationAssignment?.reservation?.status === 'savoya_canceled';
  const savoyaAssignmentCancelledConfirmed =
    savoyaReservationAssignment?.status === 'savoya_canceled_confirmed';

  const [assignTripToDriver] = useAssignTripMutation({
    refetchQueries: ['GetTripById'],
    onCompleted: ({ executeAssignTrip }) => {
      handleTripCommandException(
        executeAssignTrip,
        async (succeeded, errors) => {
          if (!succeeded) {
            errors.forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: 'error',
              });
            });
          }
          if (succeeded) {
            enqueueSnackbar('Success! Trip Assigned', {
              variant: 'success',
              preventDuplicate: true,
            });
            setTripAssignment(defaultTripAssignmentState);
            navigate(`/trips/${trip?.id}`);
          }
        },
      );
    },
  });

  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);
  const [confirmCancelLoading, setConfirmCancelLoading] = useState(false);
  const confirmCancellation = useMutation({
    mutationFn: confirmCancelledAssignment,
    onSuccess: () => {
      queryClient.invalidateQueries(['savoyaReservationAssignment']);
      enqueueSnackbar('Cancellation Confirmed', { variant: 'success' });
      navigate('/');
    },
    onError: () =>
      enqueueSnackbar('Error Confirming Cancellation', { variant: 'error' }),
  });

  const handleAssignTrip = async (
    assignedVehicle: Partial<GenericVehicle>,
    assignedDriver: Partial<GenericDriver>,
  ) => {
    await assignTripToDriver({
      variables: {
        tripId: trip?.id,
        vehicleId: assignedVehicle?.id,
        driverId: assignedDriver?.id,
      },
    });
  };

  const currencySymbol =
    getSymbolFromCurrency(savoyaReservationAssignment?.currencyCode) || '$';

  const lostRevenueText = savoyaReservationAssignment?.estimatedRevenue
    ? `Lost Revenue: ${currencySymbol}${Dinero({
        amount: savoyaReservationAssignment?.estimatedRevenue,
        currency: savoyaReservationAssignment?.currencyCode
          ? (savoyaReservationAssignment?.currencyCode as Currency)
          : 'USD',
      })
        .toUnit()
        .toFixed(2)}`
    : undefined;

  const [isOpen, setIsOpen] = React.useState(false);
  const { sharedAvailableCommands = [] } = trip || {};
  const rejectNetworkOfferCommand = sharedAvailableCommands.find(
    (command) =>
      command.name === 'Decline Network Trip Request' ||
      command.name === 'Decline Network Shared Trip',
  );
  const isSavoyaAccount = accountTypeUtils.isSavoyaAccount(
    vendor?.vendorProviders,
  );

  const [updateSharedTripStatus] = useMutateSharedTripStatusMutation();
  const [acceptQuote] = useMakeTripConfirmedMutation();

  const { showCancelButton, cancelCommand } = useShowCancelButton(trip?.id);

  useEffect(() => {
    setTrip(props.trip);
  }, [props]);

  const handleShow = () => {
    navigate(`/trips/${trip?.id}`);
  };

  const handleEdit = () => {
    const { pickupTime, id: tripId } = trip;
    const pickupDateObject = new Date(pickupTime);

    switch (trip?.status) {
      case TripStatus.Quoted:
        if (trip) {
          acceptQuote({
            variables: {
              tripId: trip.id,
            },
            refetchQueries: ['GetTripById'],
          }).then(() => {
            navigate(
              `/trips/assign/${tripId}?pickupTime=${pickupDateObject}&travelDate=${pickupTime}`,
            );
          });
        }
        break;
      case TripStatus.AwaitingAssignment:
        if (trip?.providerSource === TripProviderSource.Savoya) {
          setIsOpen(true);
        } else {
          navigate(
            `/trips/assign/${tripId}?pickupTime=${pickupDateObject}&travelDate=${pickupTime}`,
          );
        }
        break;
      default:
        navigate(`/trips/edit/${trip?.id}`);
        break;
    }
  };

  const getPrimaryButtonText = () => {
    switch (trip?.status) {
      case TripStatus.Quoted:
        return 'Accept Quote';
      case TripStatus.AwaitingAssignment:
        return 'Assign';
      default:
        return 'Edit';
    }
  };

  const getUpdateSharedTrip = (currentStatus: SharedTripStatus) => {
    switch (currentStatus) {
      case SharedTripStatus.Completed:
        return 'Mark as Billed';
      case SharedTripStatus.Billed:
        return 'Mark as Paid';
      default:
        return 'Update Status';
    }
  };

  const acceptedAwaitingAssignment =
    trip?.sharedTrip?.status === SharedTripStatus.Accepted &&
    trip?.status === TripStatus.AwaitingAssignment;

  const sharedWithMe =
    user?.id === trip?.sharedTrip?.contactId ||
    vendor?.id === trip?.sharedTrip?.vendorExternalId;
  const iOwnThisTrip = user?.accountId === trip?.accountId;
  const isShared = trip?.isShared;
  const showBilledAndPaidButton =
    (!loading &&
      trip?.providerSource !== TripProviderSource.Savoya &&
      isShared &&
      !iOwnThisTrip &&
      trip?.sharedTrip?.status === SharedTripStatus.Completed) ||
    trip?.sharedTrip?.status === SharedTripStatus.Billed;
  const sharedTrip = (trip?.sharedTrip || {}) as Partial<SharedTrip>;
  const isSavoya = trip?.providerSource === TripProviderSource.Savoya;

  return !loading ? (
    <CardActions>
      <Grid container direction="row" spacing={1}>
        {paymentButton && <Grid item>{paymentButton}</Grid>}
        {showCancelButton && iOwnThisTrip && (
          <Grid item>
            <WarningButtonTheme>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setTripCommandDialog((tripCommandDialog) => ({
                    ...tripCommandDialog,
                    isOpen: true,
                    tripId: trip?.id,
                    isSavoyaTrip: isSavoya,
                    savoyaTripId: trip?.providerExternalId,
                    command: cancelCommand,
                    dialogContent: getDialogContentFromTripCommand(
                      cancelCommand,
                      isSavoya,
                    ),
                    succeeded: false,
                  }));
                }}
              >
                Cancel Trip
              </Button>
            </WarningButtonTheme>
          </Grid>
        )}
        {showViewButton && !isSavoyaAccount && (
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              data-testid="trip_view_button"
              size="small"
              onClick={handleShow}
            >
              View
            </Button>
          </Grid>
        )}
        {showEditButton &&
          !savoyaAssignmentCancelled &&
          !savoyaAssignmentCancelledConfirmed &&
          ((iOwnThisTrip && !acceptedAwaitingAssignment) ||
            (sharedWithMe && acceptedAwaitingAssignment)) && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                data-testid={`trip_${getPrimaryButtonText().toLocaleLowerCase()}_button`}
                onClick={handleEdit}
              >
                {getPrimaryButtonText()}
              </Button>
            </Grid>
          )}
        {showBilledAndPaidButton &&
          !savoyaAssignmentCancelled &&
          !savoyaAssignmentCancelledConfirmed && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateSharedTripStatus({
                    variables: { sharedTripId: sharedTrip.id },
                    refetchQueries: [
                      'getSharedTripByOriginalTripId',
                      'getTripById',
                      'searchTrips',
                    ],
                  })
                }
              >
                {getUpdateSharedTrip(sharedTrip.status)}
              </Button>
            </Grid>
          )}
        {!iOwnThisTrip && savoyaAssignmentCancelled ? (
          <React.Fragment>
            {rejectableSharedTripStatuses.includes(trip?.status) ? (
              <Grid item>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={async () => {
                    setShowConfirmCancelDialog(true);
                  }}
                >
                  {confirmCancelLoading ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    'Confirm Cancellation'
                  )}
                </Button>
              </Grid>
            ) : null}
          </React.Fragment>
        ) : null}
        {!iOwnThisTrip &&
        !savoyaAssignmentCancelled &&
        !savoyaAssignmentCancelledConfirmed ? (
          <React.Fragment>
            {trip?.status === TripStatus.PendingNetworkConfirmation ? (
              <Grid item>
                <AcceptOrClaimSharedTripBtn trip={trip} />
              </Grid>
            ) : null}
            {rejectableSharedTripStatuses.includes(trip?.status) ? (
              <Grid item>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setTripCommandDialog((tripCommandDialog) => ({
                      ...tripCommandDialog,
                      isOpen: true,
                      tripId: trip?.id,
                      command: rejectNetworkOfferCommand,
                      customCallback: () => {
                        navigate(`/`);
                      },
                      dialogContent: getDialogContentFromTripCommand(
                        rejectNetworkOfferCommand,
                        isSavoya,
                        lostRevenueText,
                      ),
                      succeeded: false,
                    }));
                  }}
                  disabled={!rejectNetworkOfferCommand}
                >
                  Reject Trip Offer
                </Button>
              </Grid>
            ) : null}
          </React.Fragment>
        ) : null}
        {paymentButton && <Grid item>{paymentButton}</Grid>}
        {trip?.id &&
          !savoyaAssignmentCancelled &&
          !savoyaAssignmentCancelledConfirmed && (
            <TripAssignmentDialog
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              trip={trip as Partial<Trip>}
              onConfirm={handleAssignTrip}
              noCompUpgrade={
                savoyaReservationAssignment?.reservation?.noCompUpgrades
              }
            />
          )}
        <GenericDialog
          openDialog={showConfirmCancelDialog}
          setCloseDialog={() => setShowConfirmCancelDialog(false)}
          dialogTitle="Confirm Cancellation"
          maxWidth="sm"
        >
          <DialogActions>
            <Button
              onClick={() => setShowConfirmCancelDialog(false)}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                setConfirmCancelLoading(true);
                await confirmCancellation.mutateAsync({
                  vendorAssignmentId: savoyaReservationAssignment?.id,
                });
                setConfirmCancelLoading(false);
              }}
              disabled={confirmCancelLoading}
            >
              {confirmCancelLoading ? (
                <CircularProgress size="2rem" />
              ) : (
                'Confirm'
              )}
            </Button>
          </DialogActions>
        </GenericDialog>
      </Grid>
    </CardActions>
  ) : null;
};

export default CardActionsComponent;
