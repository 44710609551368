import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Link,
  Button,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { ReactComponent as AlmostCompletedSvg } from 'src/assets/svg/AlmostCompleted.svg';

const useStyles = makeStyles((theme) => ({
  svgElement: {
    width: 400,
    height: 'auto',
    color: theme.palette.primary.main,
    border: 'none',
  },
}));

const NoPaymentForm: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleConfirm = async (event: React.FormEvent) => {
    event.preventDefault();
    navigate('/subscriptions/payment/succeeded');
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" my={4}>
        <AlmostCompletedSvg className={classes.svgElement} />
      </Box>
      <Box m={4} px={2} display="flex" justifyContent="center">
        <Typography variant="h5" color="textPrimary" component="h2">
          No payment or credit card is required at this time.
        </Typography>
      </Box>
      <Box m={4} p={2} display="flex" justifyContent="center">
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          align="center"
        >
          By clicking <b>&quot;Agree and Continue&quot;</b> you agree to Capital
          Drive, Inc.{' '}
          <Link href="/terms-of-service" rel="noreferrer" target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            href="https://www.capitaldrive.com/privacy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>
      <Divider />
      <Box mx={4} p={2} display="flex" justifyContent="flex-start">
        <Button
          data-testid="no_payment_subscribe_button"
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleConfirm}
        >
          Agree and Continue
        </Button>
      </Box>
    </Box>
  );
};

export default NoPaymentForm;
