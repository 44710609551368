import * as Yup from 'yup';
import gPhoneNumber from 'google-libphonenumber';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

const YUP_PHONE_METHOD = 'phone';
const CLDR_REGION_CODE_SIZE = 2;

const isValidCountryCode = (countryCode: string): boolean =>
  typeof countryCode === 'string' &&
  countryCode.length === CLDR_REGION_CODE_SIZE;

Yup.addMethod(
  Yup.string,
  YUP_PHONE_METHOD,
  function yupPhone(countryCode = 'US', strict = false, errorMessage?: '') {
    let errMsg;
    if (typeof errorMessage === 'string' && errorMessage) {
      errMsg = errorMessage;
    } else if (isValidCountryCode(countryCode)) {
      errMsg = `Please enter a valid phone number for region ${countryCode}`;
    } else {
      errMsg = 'Please enter a valid phone number';
    }

    return this.test(YUP_PHONE_METHOD, errMsg, (value: string) => {
      try {
        if (!value) {
          return true;
        }

        const phoneNumber = phoneUtil.parseAndKeepRawInput(
          value,
          isValidCountryCode(countryCode) ? countryCode : 'US',
        );

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          return false;
        }

        const regionCodeFromPhoneNumber =
          phoneUtil.getRegionCodeForNumber(phoneNumber);

        /* check if the countryCode provided should be used as
          default country code or strictly followed
          */
        if (isValidCountryCode(countryCode) && strict) {
          return phoneUtil.isValidNumberForRegion(
            phoneNumber,
            isValidCountryCode(countryCode) ? countryCode : 'US',
          );
        }

        return phoneUtil.isValidNumberForRegion(
          phoneNumber,
          regionCodeFromPhoneNumber,
        );
      } catch {
        return false;
      }
    });
  },
);
