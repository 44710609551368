import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import React from 'react';
import { DeepPartial } from 'react-hook-form';

import { TripNote, useUpdateTripNoteMutation } from 'src/.gen/graphql';

interface Props {
  tripNote: DeepPartial<TripNote> | undefined;
  editable?: boolean;
}

const ContactTripNotesInput: React.FC<Props> = ({
  tripNote,
  editable = true,
}) => {
  const [notes, setNotes] = React.useState(tripNote?.content || '');
  const { enqueueSnackbar } = useSnackbar();

  const [updateTripNote, { loading }] = useUpdateTripNoteMutation({
    refetchQueries: ['getTripById'],

    onCompleted: () => {
      enqueueSnackbar('Notes updated successfully', {
        variant: 'success',
      });
    },
    onError: (error) => {
      error.clientErrors.forEach((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          preventDuplicate: true,
        });
      });
    },
  });

  const updateContactNotes = async () => {
    updateTripNote({
      variables: {
        tripNote: {
          id: tripNote.id,
          content: notes,
        },
      },
    });
  };

  const cantSave = notes !== (tripNote?.content || '');
  return (
    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          value={notes}
          onChange={(event) => {
            setNotes(event.target.value);
          }}
          fullWidth
          multiline
          rows={5}
          disabled={!editable}
        />
      </Grid>
      {editable ? (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                disabled={!cantSave || loading}
                variant="contained"
                color="primary"
                onClick={updateContactNotes}
              >
                {loading ? 'please wait...' : 'Save'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={loading}
                variant="outlined"
                onClick={() => setNotes('')}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ContactTripNotesInput;
