import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parseISO, differenceInCalendarDays } from 'date-fns';
import {
  Link,
  Box,
  IconButton,
  makeStyles,
  Collapse,
  Typography,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ClassNameMap } from '@material-ui/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { capital } from 'case';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';
import {
  vendorAtom,
  subscriptionPlanAtom,
  userAtom,
} from 'src/authentication/atoms/AuthState';
import { useUserProductVariant } from 'src/shared/hooks/useUserProductVariant';
import { accountTypeUtils } from 'src/shared/utils/accountTypeUtils';
import { SubscriptionRecord, TierType, UserRole } from 'src/.gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    zIndex: theme.zIndex.drawer + 1,
    padding: '5px',
  },
  textBox: {
    width: '100%',
  },
  linkBtn: {
    marginLeft: 5,
  },
}));

const SETTINGS_TAB_ROUTE = '/settings?tab=subscription';

const bannerText = (
  subscriptionPlan: Partial<SubscriptionRecord>,
  classes: ClassNameMap,
  showDefaultMsg?: boolean,
) => {
  if (showDefaultMsg) {
    return (
      <Typography display="inline" variant="body2" color="textPrimary">
        <b>Free Trial:</b> Enjoy your free trial of capital drive with full
        access to all the premium features.
      </Typography>
    );
  }
  if (!subscriptionPlan) {
    return (
      <Box display="flex" className={classes.textBox}>
        <Typography display="inline" variant="body2" color="textPrimary">
          <b>Free Trial:</b> Enjoy your free trial of capital drive with full
          access to all the premium features.
        </Typography>
        <Link
          href={SETTINGS_TAB_ROUTE}
          underline="none"
          className={classes.linkBtn}
        >
          <b>Update Subscription</b>
        </Link>
      </Box>
    );
  }
  if (
    subscriptionPlan.currentTier === TierType.Premium &&
    subscriptionPlan.inTrialPeriod
  ) {
    const endAt = parseISO(subscriptionPlan.periodEndAt);
    const daysLeft = differenceInCalendarDays(endAt, new Date());
    return (
      <Box display="flex" className={classes.textBox}>
        <Typography display="inline" variant="body2" color="textPrimary">
          <b>
            {capital(subscriptionPlan.currentTier)} Plan trial: {daysLeft} days
            left.
          </b>{' '}
          Enjoy your free trial of capital drive with full access to all{' '}
          {subscriptionPlan?.currentTier?.toLowerCase()} features.
        </Typography>
        <Link
          href={SETTINGS_TAB_ROUTE}
          underline="none"
          className={classes.linkBtn}
        >
          <b>Update Subscription</b>
        </Link>
      </Box>
    );
  }
  return (
    <Typography display="inline" variant="body2" color="textPrimary">
      <b>Free Plan:</b>{' '}
      <Link href={SETTINGS_TAB_ROUTE} underline="none">
        <b>Upgrade</b>
      </Link>{' '}
      today to enable all the premium features and other Capital Drive
      Subscription Plan benefits.
    </Typography>
  );
};

const TrialPeriodBanner: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const launchDarklyFlags = useFlags();
  const [openTrialBanner, setOpenTrialBanner] = useState(true);
  const subscriptionPlan = useRecoilValue(subscriptionPlanAtom);
  const vendor = useRecoilValue(vendorAtom);
  const { isSavoyaProductType } = useUserProductVariant();
  const isSavoyaAccount = accountTypeUtils.isSavoyaAccount(
    vendor?.vendorProviders,
  );
  const showDefaultMsg = !launchDarklyFlags?.subscriptions;
  const user = useRecoilValue(userAtom);
  const hideBanner =
    user?.userRole !== UserRole.AccountOwner ||
    location.pathname.includes('/subscriptions') ||
    location.pathname.includes('/onboarding') ||
    location.pathname.includes('/savoya-driver') ||
    location.pathname.includes('verified-email') ||
    subscriptionPlan?.currentTier === TierType.Platinum ||
    isSavoyaProductType ||
    isSavoyaAccount ||
    (subscriptionPlan &&
      subscriptionPlan?.currentTier === TierType.Premium &&
      !subscriptionPlan?.inTrialPeriod);

  if (hideBanner) {
    return null;
  }
  return (
    <Collapse in={openTrialBanner}>
      <Box className={classes.container}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenTrialBanner(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {bannerText(subscriptionPlan, classes, showDefaultMsg)}
        </Alert>
      </Box>
    </Collapse>
  );
};

export default TrialPeriodBanner;
