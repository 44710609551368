import axios from 'axios';
import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { Camelize } from 'src/shared/types/camelize';
import moment from 'moment';
import type {
  SavoyaVendorAssignment,
  SavoyaVendorProfile,
  SavoyaReservationIncidentals,
  SavoyaReservationNote,
  SavoyaVendorExchanegRate,
  SavoyaReservationTravelTime,
  SavoyaVendorCase,
  SavoyaHourlyQuote,
  SavoyaVendorQuote,
  SavoyaReservation,
  SavoyaCloseOutBody,
  SavoyaFileAttachment,
} from '../types/SavoyaTripsTypes';
import {
  CloseoutFromValues,
  SavoyaCostType,
} from '../components/SavoyaPaymentDetails';

export const savoyaApiClient = axios.create({
  baseURL: process.env.REACT_APP_SAVOYA_API,
  headers: {
    authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

export const fetchVendorReservationAssignment = async (
  savoyaReservationId: string,
): Promise<Camelize<SavoyaVendorAssignment>> => {
  const { data } = await savoyaApiClient.get(
    `api/v1/vendor/reservations/${savoyaReservationId}`,
  );
  return objectToCamel(data) as Camelize<SavoyaVendorAssignment>;
};

export const fetchManyVendorReservationAssignment = async (
  savoyaReservationIds: string[],
): Promise<Camelize<SavoyaVendorAssignment>[]> => {
  const { data } = await savoyaApiClient.get(`api/v1/vendor/reservations`, {
    params: {
      ids: savoyaReservationIds,
    },
  });
  return objectToCamel(data) as Camelize<SavoyaVendorAssignment>[];
};

export const fetchVendorProfile = async (): Promise<SavoyaVendorProfile> => {
  const { data } = await savoyaApiClient.get('api/v1/vendor/profile');
  return objectToCamel(data) as SavoyaVendorProfile;
};

export const fetchReservationNotes = async (
  savoyaReservationId: number,
): Promise<SavoyaReservationNote[]> => {
  const { data } = await savoyaApiClient.get(
    `api/v1/vendor/reservations/${savoyaReservationId}/notes`,
  );
  return objectToCamel(data) as SavoyaReservationNote[];
};

export const fetchVendorExchangeRate = async (
  vendorId: number,
): Promise<SavoyaVendorExchanegRate> => {
  const { data } = await savoyaApiClient.get(
    `api/v1/savoya/vendors/${vendorId}/exchange_rate`,
  );
  return objectToCamel(data) as SavoyaVendorExchanegRate;
};

export const fetchVendorCase = async (
  savoyaReservationId: number,
): Promise<SavoyaVendorCase> => {
  const { data } = await savoyaApiClient.get(
    `api/v1/reservations/${savoyaReservationId}/cases/vendor_case`,
  );
  return objectToCamel(data) as SavoyaVendorCase;
};

export const fetchReservationIncidentals = async (
  savoyaReservationId: number,
): Promise<SavoyaReservationIncidentals[]> => {
  const { data } = await savoyaApiClient.get(
    `api/v1/reservations/${savoyaReservationId}/incidentals`,
  );
  return objectToCamel(data) as SavoyaReservationIncidentals[];
};

export const fetchTravelTimeRules = async (
  savoyaReservationId: number,
): Promise<SavoyaReservationTravelTime> => {
  const { data } = await savoyaApiClient.get(
    `api/v1/vendor/reservations/${savoyaReservationId}/closeout/travel_time_rules`,
  );
  return objectToCamel(data) as SavoyaReservationTravelTime;
};

export const fetchCalculateHourlyQuote = async (
  assignmentId: number,
): Promise<SavoyaHourlyQuote> => {
  const { data } = await savoyaApiClient.get(
    'api/v1/vendor_quotes/calculate_hourly_quote',
    {
      params: {
        assignment_id: assignmentId,
      },
    },
  );
  return objectToCamel(data) as SavoyaHourlyQuote;
};

export const confirmCancelledAssignment = async (values: {
  vendorAssignmentId: number;
}): Promise<boolean> => {
  const { vendorAssignmentId } = values;

  await savoyaApiClient.put(
    `api/v1/vendor/vendor_assignments/${vendorAssignmentId}/status`,
    {
      status: 'savoya_canceled_confirmed',
    },
  );
  return true;
};

export const uploadMiscFile = async (values: {
  reservationId: number;
  file: File;
}): Promise<SavoyaFileAttachment> => {
  const { file, reservationId } = values;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);

  const { data } = await savoyaApiClient.post(
    `api/v1/vendor/reservations/${reservationId}/file_attachments`,
    formData,
  );

  return objectToCamel(data) as unknown as SavoyaFileAttachment;
};

export const reservationCloseout = async (values: {
  reservationId: number;
  closeoutValues: CloseoutFromValues;
  vendorQuote: SavoyaVendorQuote;
  foreignCurrency: boolean;
  totalIncidentals: number;
  timeZone: string;
}): Promise<Partial<SavoyaReservation>> => {
  const {
    reservationId,
    closeoutValues,
    vendorQuote,
    foreignCurrency,
    timeZone,
    totalIncidentals = 0,
  } = values;

  const rateExceptionBaseRate =
    (closeoutValues?.rateExceptionTransferBase ||
      closeoutValues?.rateExceptionHourlyBase ||
      0) * 100;

  const requestedTotal =
    closeoutValues?.rateExceptionType === SavoyaCostType.Hourly
      ? closeoutValues?.rateExceptionHourlyMinimumHours *
          rateExceptionBaseRate +
        totalIncidentals * 100
      : rateExceptionBaseRate + totalIncidentals * 100;

  const payload: SavoyaCloseOutBody = {
    id: reservationId,
    compensationPercentage: closeoutValues?.compensationPercentage * 100,
    foreignCurrency,
    additionalStops: closeoutValues?.additionalStops,
    incidentals: {
      parking: {
        reportedAmount: (closeoutValues?.parkingAmount || 0) * 100,
      },
      airportFee: {
        reportedAmount: (closeoutValues?.airportFeeAmount || 0) * 100,
      },
      nyCongestionFee: {
        reportedAmount: (closeoutValues?.nyCongestionFeeAmount || 0) * 100,
      },
      toll: {
        reportedAmount: (closeoutValues?.tollAmount || 0) * 100,
      },
      misc: {
        reportedAmount: (closeoutValues?.miscAmount || 0) * 100,
        note: closeoutValues?.miscFeeNote || '',
      },
      tax: {
        reportedAmount: (closeoutValues?.taxAmount || 0) * 100,
      },
      greeter: {
        reportedAmount: (closeoutValues?.greeterAmount || 0) * 100,
      },
    },
    onboardDateAdjustment: {
      reportedDate: moment(closeoutValues?.onboardDateAdjustment)
        ?.parseZone()
        ?.tz(timeZone, true)
        ?.toISOString(true),
    },
    dropoffDateAdjustment: {
      reportedDate: moment(closeoutValues?.dropoffDateAdjustment)
        ?.parseZone()
        ?.tz(timeZone, true)
        ?.toISOString(true),
    },
    inDateAdjustment: {
      reportedDate: moment(closeoutValues?.inDateAdjustment)
        ?.parseZone()
        ?.tz(timeZone, true)
        ?.toISOString(true),
    },
    outDateAdjustment: {
      reportedDate: moment(closeoutValues?.outDateAdjustment)
        ?.parseZone()
        ?.tz(timeZone, true)
        ?.toISOString(true),
    },
    vendorQuote: vendorQuote || {
      type: closeoutValues?.costType,
      baseCost: (closeoutValues?.baseRate || 0) * 100,
      minimum: closeoutValues?.minimumHours,
      reportedCost: null,
    },
  };

  if (closeoutValues?.rateExceptionType) {
    payload.rateException = {
      type: closeoutValues?.rateExceptionType,
      baseCost: rateExceptionBaseRate,
      requestedTotal,
      note: closeoutValues?.rateExceptionReason,
      reportedMinimum: closeoutValues?.rateExceptionHourlyMinimumHours,
    };
  }

  const { data } = await savoyaApiClient.post(
    `api/v1/vendor/reservations/${reservationId}/closeout`,
    objectToSnake(payload),
  );
  return objectToCamel(data) as Partial<SavoyaReservation>;
};
