import React, { FC, useEffect, useState } from 'react';
import { Radio, Typography } from '@material-ui/core';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { useDefaultRates } from 'src/rates/hooks/useDefaultRates';
import { useUpdateRevertToDynamicForExcessWaitTimeMutation } from 'src/.gen/graphql';
import TextLineInput from '../../TextLineInput/TextLineInput';
import { useRatesStyles } from '../../RatesDashboard';

enum BillingOptions {
  HOURLY_BILLING = 'HOURLY_BILLING',
  THRESHOLD_BILLING = 'THRESHOLD_BILLING',
}

interface AdditionalWaitTimeProps {
  revertToDynamicForExcessWaitTimeFlag: boolean;
  rateId: string;
  refreshData: () => void;
}

const AdditionalWaitTimeForm: FC<AdditionalWaitTimeProps> = ({
  revertToDynamicForExcessWaitTimeFlag,
  rateId,
  refreshData,
}) => {
  const classes = useRatesStyles();
  const { sm } = useBreakpoints();

  const { currentMeteredRateIncrement } = useDefaultRates();

  const [selectedOption, setSelectedOption] = useState(null);
  const [updateBillingMethod] =
    useUpdateRevertToDynamicForExcessWaitTimeMutation();

  const handleChange = async (event) => {
    setSelectedOption(event.target.value);
    await updateBillingMethod({
      variables: {
        rateId,
        revertToDynamicForExcessWaitTime:
          event.target.value === BillingOptions.HOURLY_BILLING,
      },
    });
    refreshData();
  };

  useEffect(() => {
    if (revertToDynamicForExcessWaitTimeFlag) {
      setSelectedOption(BillingOptions.HOURLY_BILLING);
    } else {
      setSelectedOption(BillingOptions.THRESHOLD_BILLING);
    }
  }, [revertToDynamicForExcessWaitTimeFlag]);

  return (
    <form
      className={sm ? classes.smallFormStyles : classes.formStyles}
      data-testid="additional_wait_time_form"
    >
      <TextLineInput
        text={`Once complimentary wait time is exceeded, $${BillingOptions.HOURLY_BILLING} the trip converts to hourly billing $${BillingOptions.THRESHOLD_BILLING} wait time is billed in $minimumMinutesIncrement minutes increments, in addition to the transfer rate`}
        inputs={[
          {
            component: () => (
              <Radio
                data-testid="additional_wait_time_hourly_checkbox"
                checked={selectedOption === BillingOptions.HOURLY_BILLING}
                onChange={handleChange}
                value={BillingOptions.HOURLY_BILLING}
                name="radio-buttons-1"
                inputProps={{ 'aria-label': BillingOptions.HOURLY_BILLING }}
              />
            ),
            id: `$${BillingOptions.HOURLY_BILLING}`,
          },
          {
            component: () => (
              <Radio
                data-testid="additional_wait_time_threshold_checkbox"
                checked={selectedOption === BillingOptions.THRESHOLD_BILLING}
                onChange={handleChange}
                value={BillingOptions.THRESHOLD_BILLING}
                name="radio-buttons-2"
                inputProps={{ 'aria-label': BillingOptions.THRESHOLD_BILLING }}
              />
            ),
            id: `$${BillingOptions.THRESHOLD_BILLING}`,
          },
          {
            component: () => (
              <Typography variant="body1">
                {currentMeteredRateIncrement}
              </Typography>
            ),
            id: '$minimumMinutesIncrement',
          },
        ]}
      />
    </form>
  );
};

export default AdditionalWaitTimeForm;
