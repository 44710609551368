/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useRecoilState } from 'recoil';
import {
  Box,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

import EmptyData from '../EmptyData';
import { generalTableValuesState } from './atoms/generalTableAtom';
import EnhancedTableToolbar from './EnhancedTableToolbar';

export interface Column {
  id: string;
  label: string;
  align?: TableCellProps['align'];
  format?: (value: any, row: any, index: number) => string | React.ReactNode;
}

const useStyles = makeStyles(() => ({
  tableRow: {
    '& .MuiTableCell-root': {
      cursor: 'pointer',
    },
  },
  clickableRow: {
    cursor: 'pointer',
  },
}));
interface PaginationState {
  rowsPerPage: number;
  currentPage: number;
}
export interface TableProps {
  columns: Array<Column>;
  rows: Array<any>;
  totalRowCount?: number;
  loading?: boolean;
  hasPagination?: boolean;
  hasSelection?: boolean;
  actions?: (selectedItems: string[]) => React.ReactNode | null;
  header?: React.ReactNode;
  clickable?: boolean;
  handleClick?: (
    id: string,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRowClick?: (row, index: number) => void | Promise<void>;
  rowsPerPage?: number | null | undefined;
}

export const offset = (pagination: PaginationState): number => {
  return pagination.rowsPerPage * pagination.currentPage;
};

const GeneralTable: React.FC<TableProps> = ({
  columns = [],
  rows = [],
  totalRowCount = 0,
  loading = false,
  hasPagination = true,
  hasSelection = true,
  actions = () => null,
  header = null,
  clickable = false,
  handleClick = () => null,
  onRowClick,
  rowsPerPage = null,
}) => {
  const [stateTable, setStateTable] = useRecoilState(generalTableValuesState);
  const { selectedItems, pagination } = stateTable;
  const classes = useStyles();

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number,
  ): void => {
    const newPaginationState = {
      ...pagination,
      currentPage: newPage,
    };
    setStateTable({
      ...stateTable,
      pagination: {
        ...newPaginationState,
      },
      offset: offset(newPaginationState),
    });
  };

  const handleLimitChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const rowsPerPage = +event.target.value;
    let newPaginationState = { ...pagination, rowsPerPage };
    const totalRecords = offset(newPaginationState);

    if (totalRecords > totalRowCount) {
      newPaginationState = {
        ...newPaginationState,
        currentPage: Math.floor(totalRowCount / rowsPerPage),
      };
    }
    setStateTable({
      ...stateTable,
      pagination: newPaginationState,
      offset: offset(newPaginationState),
    });
  };

  const handleSelectAllItems = (
    event?: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    event.stopPropagation();
    setStateTable({
      ...stateTable,
      selectedItems: event.target.checked ? rows.map((row) => row?.id) : [],
    });
  };

  const handleSelectItem = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ): void => {
    event.stopPropagation();
    if (!selectedItems.includes(id)) {
      setStateTable({ ...stateTable, selectedItems: [...selectedItems, id] });
    } else {
      setStateTable({
        ...stateTable,
        selectedItems: selectedItems.filter((prevId) => prevId !== id),
      });
    }
  };

  const selectedAllItems = selectedItems.length === rows.length;

  return (
    <Box>
      <EnhancedTableToolbar actions={actions} header={header} />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {hasSelection ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllItems}
                    indeterminate={!!selectedItems.length && !selectedAllItems}
                    onChange={handleSelectAllItems}
                  />
                </TableCell>
              ) : null}
              {columns.map(({ id, label, align }) => (
                <TableCell component="th" key={id} align={align}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(loading ? Array.from(new Array(3)) : rows || []).map(
              (row, idx) => {
                const isRowSelected = selectedItems.includes(row?.id);
                return row ? (
                  <TableRow
                    hover
                    className={clsx(
                      onRowClick ? classes.clickableRow : null,
                      clickable ? classes.tableRow : null,
                    )}
                    onClick={() => {
                      handleClick(row.id);
                      if (onRowClick) onRowClick(row, idx);
                    }}
                    key={row.id}
                  >
                    {hasSelection ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isRowSelected}
                          onChange={(event) => handleSelectItem(event, row.id)}
                          value={isRowSelected}
                          onClick={(event) => event.stopPropagation()}
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column) => {
                      const value = column.id.split('.').reduce((obj, i) => {
                        return obj[i];
                      }, row);
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align || 'left'}
                        >
                          {column.format
                            ? column.format(value, row, idx)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ) : (
                  <TableRow key={idx}>
                    {hasSelection ? (
                      <TableCell>
                        <Skeleton width="100%" height={20} />
                      </TableCell>
                    ) : null}
                    {columns.map(({ id }) => (
                      <TableCell key={id}>
                        <Skeleton width="100%" height={20} />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              },
            )}
            {!loading && !rows.length ? (
              <TableRow>
                <TableCell
                  colSpan={hasSelection ? columns.length + 1 : columns.length}
                >
                  <EmptyData label="Items not found" height={160} />
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      {hasPagination && (
        <TablePagination
          component="div"
          count={totalRowCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={stateTable.pagination.currentPage}
          rowsPerPage={
            rowsPerPage !== null
              ? rowsPerPage
              : stateTable.pagination.rowsPerPage
          }
          rowsPerPageOptions={[1, 5, 10, 25]}
          {...(rowsPerPage
            ? {
                labelRowsPerPage: '',
                SelectProps: { style: { visibility: 'hidden' } },
              }
            : {})}
        />
      )}
    </Box>
  );
};

export default GeneralTable;
