import React from 'react';
import type { FC } from 'react';
import _snakeCase from 'lodash/snakeCase';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
  Theme,
  Box,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';

interface Breadcrumb {
  url?: string;
  label: string;
}

interface HeaderProps {
  pageName: string;
  actionUrl?: string;
  actionFunction?: () => void;
  actionName?: string;
  className?: string;
  icon?: JSX.Element;
  loading?: boolean;
  breadcrumbs?: Breadcrumb[];
  title?: string | boolean;
  description?: string | boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(2),
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
}));

const PageHeader: FC<HeaderProps> = ({
  className,
  pageName,
  icon,
  loading,
  actionUrl,
  actionFunction,
  actionName,
  breadcrumbs,
  title = false,
  description,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { sm } = useBreakpoints();
  return (
    <Grid
      data-testid={`${_snakeCase(actionName)}_page_header_container"`}
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid
        item
        data-testid={`${_snakeCase(actionName)}_page_header_left_content`}
      >
        {sm && (
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <RouterLink to="/" style={{ textDecoration: 'none' }}>
              <Typography color="primary" variant="body2">
                Home
              </Typography>
            </RouterLink>
            {breadcrumbs?.map((b) => {
              return b.url ? (
                <RouterLink key={b.url} to={b.url}>
                  <Typography variant="body2" color="primary">
                    {b.label}
                  </Typography>
                </RouterLink>
              ) : (
                <Typography variant="body2" color="textPrimary">
                  {b.label}
                </Typography>
              );
            })}
            <Typography variant="body2" color="textPrimary">
              {pageName}
            </Typography>
          </Breadcrumbs>
        )}
        {title === false ? null : (
          <div className={classes.title}>
            {loading ? (
              <Skeleton
                width={30}
                height={50}
                style={{ borderRadius: '100%', marginRight: 12 }}
              />
            ) : (
              icon
            )}
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                marginLeft: icon ? 10 : 0,
                fontWeight: 'bold',
              }}
            >
              {loading ? <Skeleton width={240} /> : title || pageName}
            </Typography>
          </div>
        )}
        {!description ? null : (
          <Typography
            color="textSecondary"
            variant="body2"
            style={{ marginLeft: icon ? 10 : 0 }}
          >
            {loading ? <Skeleton width={200} /> : description}
          </Typography>
        )}
      </Grid>
      {(actionUrl || actionFunction) && actionName ? (
        <Grid item>
          <Box display="flex" alignItems="center" style={{ height: '100%' }}>
            <Button
              data-testid={`${_snakeCase(actionName)}_page_header_button`}
              color="primary"
              variant="contained"
              startIcon={
                <SvgIcon fontSize="small">
                  <PlusCircleIcon />
                </SvgIcon>
              }
              // component={ComponentLink}
              // href={actionUrl}
              onClick={() => {
                if (actionUrl) {
                  navigate(actionUrl);
                } else {
                  actionFunction();
                }
              }}
            >
              {actionName}
            </Button>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default PageHeader;
