import React, { useEffect, useState } from 'react';
import * as jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import { omit } from 'lodash';
import { useGetUserByTokenLazyQuery, UserRecord } from 'src/.gen/graphql';
import MobileAuthContext from './MobileAuthContext';

export const MOBILE_AUTH_OBJECT_KEY = 'x-cd-mobile-auth-object';
export const MOBILE_APP_SESSION = 'x-cd-mobile-app-session';

type MobileAuthObjectType = {
  token: string;
  expiresAt: number;
  user?: UserRecord;
};

export const setMobileAuthCookie = (options: MobileAuthObjectType): void => {
  const { token, expiresAt, user } = options;
  const urlProtocol = window.location.protocol;
  Cookies.set(
    MOBILE_AUTH_OBJECT_KEY,
    JSON.stringify({ token, expiresAt, user }),
    {
      expires: expiresAt ? new Date(expiresAt * 1000 - 600000) : undefined,
      sameSite: 'strict',
      secure: urlProtocol === 'https:',
    },
  );
};

const MobileAuthProvider: React.FunctionComponent = ({ children }) => {
  const [isMobileAuthenticated, setIsMobileAuthenticated] =
    useState<boolean>(false);
  const [userData, setUserData] = useState<UserRecord | undefined>(undefined);
  const [getUserInfo, { data, loading }] = useGetUserByTokenLazyQuery({
    fetchPolicy: 'network-only',
  });

  const authCookieObject = Cookies.get(MOBILE_AUTH_OBJECT_KEY) || '{}';
  const {
    token = localStorage.getItem('token') || '',
    expiresAt,
    user,
  } = JSON.parse(authCookieObject) as MobileAuthObjectType;

  const clearMobileTokens = () => {
    Cookies.remove(MOBILE_AUTH_OBJECT_KEY);
    localStorage.removeItem(MOBILE_APP_SESSION);
    localStorage.removeItem('token');
  };

  const isValidMobileCookie = (): {
    valid: boolean;
    hasUserObject: boolean;
  } => {
    if (!token) {
      clearMobileTokens();
      return { valid: false, hasUserObject: false };
    }

    if (expiresAt) {
      const tokenTTL = new Date(expiresAt * 1000).getTime();
      if (tokenTTL <= 600) {
        clearMobileTokens();
        return { valid: false, hasUserObject: false };
      }

      if (user?.id && user?.email) {
        localStorage.setItem(MOBILE_APP_SESSION, 'true');
        localStorage.setItem('token', token);
        setUserData(user);
        setIsMobileAuthenticated(true);
        return { valid: true, hasUserObject: true };
      }
    }

    try {
      const decodedToken = jwt.decode(token) as jwt.JwtPayload;
      if (!decodedToken?.exp) {
        clearMobileTokens();
        return { valid: false, hasUserObject: false };
      }

      const tokenTTL =
        new Date(decodedToken.exp * 1000).getTime() - new Date().getTime();
      if (tokenTTL <= 60000) {
        clearMobileTokens();
        return { valid: false, hasUserObject: false };
      }
      localStorage.setItem('token', token);
      return { valid: true, hasUserObject: false };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Unable to verify the mobile user access token', error);
      clearMobileTokens();
      return { valid: false, hasUserObject: false };
    }
  };

  useEffect(() => {
    const { valid: isValidToken } = isValidMobileCookie();
    if (isValidToken) {
      if (!data?.usersQueries?.getUserByToken?.id) {
        getUserInfo({
          context: {
            headers: { authorization: localStorage.getItem('token') },
          },
        });
      }
      if (data && data?.usersQueries?.getUserByToken?.id) {
        const userRecord = omit(data.usersQueries.getUserByToken, [
          '__typename',
        ]) as UserRecord;
        localStorage.setItem(MOBILE_APP_SESSION, 'true');
        setIsMobileAuthenticated(true);
        setUserData(userRecord);
        setMobileAuthCookie({
          token,
          expiresAt,
          user: userRecord,
        });
      }
    }
  }, [data, loading, isMobileAuthenticated]);

  return (
    <MobileAuthContext.Provider
      value={{
        isMobileAuthenticated,
        authenticatedFromMobile: !!localStorage.getItem(MOBILE_APP_SESSION),
        user: userData,
        clearMobileTokens,
      }}
    >
      {children}
    </MobileAuthContext.Provider>
  );
};

export default MobileAuthProvider;
