import React from 'react';
import { Button, Box, styled, CircularProgress } from '@material-ui/core';

interface Props {
  isLastStep: boolean;
  isInitialStep: boolean;
  isSubmitting: boolean;
  onPrev: () => void;
  isValid: boolean;
}

const StepWizardFooter: React.FC<Props> = ({
  isInitialStep,
  isLastStep,
  isSubmitting,
  onPrev,
  isValid,
}) => {
  return (
    <ContainerActions>
      <Button variant="outlined" color="primary" size="large" onClick={onPrev}>
        {!isInitialStep ? 'Back' : 'Cancel'}
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={!isValid || isSubmitting}
        endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
      >
        {!isLastStep ? 'Next' : 'Create'}
      </Button>
    </ContainerActions>
  );
};

export const ContainerActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column-reverse',
  },
  '& > button:last-child': {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
    },
  },
}));

export default StepWizardFooter;
