import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { SavoyaSharedTripStatus, Trip } from 'src/.gen/graphql';
import { green, grey, red } from '@material-ui/core/colors';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import AcceptOrClaimShareTripDialog from './AcceptOrClaimSharedTripDialog';

type AcceptOrClaimSharedTripBtnProps = {
  trip: Partial<Trip>;
  action?: 'accept' | 'claim';
};

const useStyles = makeStyles((theme: Theme) => ({
  tripStatusBox: {
    width: 'fit-content',
    padding: '0.2rem 0.4rem',
    borderRadius: '5px',
    color: green[400],
  },
  tripStatusBoxdraft: {
    color: grey[800],
  },
  tripStatusBoxcancelled: {
    color: red[400],
  },
  tripStatusBoxquoted: {
    color: '#FF9C40',
  },
  tripStatusBoxclosed: {
    color: grey[800],
  },
  dropdownExpenses: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  dropdownDetail: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  dropdownList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  oneLine: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  acceptBtn: {
    backgroundColor: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 4,
    color: theme.palette.primary.light,
  },
}));

const AcceptOrClaimSharedTripBtn: React.FC<AcceptOrClaimSharedTripBtnProps> = ({
  trip,
  action = 'accept',
}) => {
  const classes = useStyles();
  const { sm } = useBreakpoints();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  let btnText = action === 'accept' ? 'Accept Trip' : 'Claim Trip';

  if (
    trip?.sharedTrip?.savoyaAssignmentStatus ===
    SavoyaSharedTripStatus.ModPending
  ) {
    btnText = 'Accept Mod';
  }
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.acceptBtn}
        size="small"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {btnText}
      </Button>
      <GenericDialog
        setCloseDialog={setIsOpen}
        dialogTitle="Reference number"
        openDialog={isOpen}
        maxWidth={sm ? 'xl' : 'sm'}
      >
        <AcceptOrClaimShareTripDialog trip={trip} action={action} />
      </GenericDialog>
    </React.Fragment>
  );
};

export default AcceptOrClaimSharedTripBtn;
