import { Typography } from '@material-ui/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { RatePolicy, useUpdateDefaultRatesMutation } from 'src/.gen/graphql';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import TextLineInput from '../../TextLineInput/TextLineInput';
import { useRatesStyles } from '../../RatesDashboard';

interface Inputs {
  minutesBeforeTrip: number;
  minutesFromMyAddress: number;
}

interface StandardGarageTimeFormProps {
  policy: Partial<RatePolicy>;
}

const StandardGarageTimeForm: FC<StandardGarageTimeFormProps> = ({
  policy,
}) => {
  const classes = useRatesStyles();
  const { sm } = useBreakpoints();

  const { register, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      minutesBeforeTrip: 30,
      minutesFromMyAddress: 60,
    },
  });

  const [updateDefaultRate] = useUpdateDefaultRatesMutation();
  const [localMinutes, setLocalMinutes] = React.useState(null);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!policy) {
      return;
    }

    const { id } = policy;
    setLocalMinutes(data.minutesBeforeTrip);
    await updateDefaultRate({
      variables: {
        id,
        thresholdValue: Number(data.minutesBeforeTrip) / 60,
        thresholdValue2: Number(data.minutesFromMyAddress) / 60,
      },
    });
  };

  useEffect(() => {
    if (policy) {
      if (policy?.thresholdValue) {
        const thMinutes = Math.ceil(policy?.thresholdValue * 60);
        setValue('minutesBeforeTrip', thMinutes);
        setLocalMinutes(thMinutes);
      }
      if (policy?.thresholdValue2) {
        const thMinutes2 = Math.ceil(policy?.thresholdValue2 * 60);
        setValue('minutesFromMyAddress', thMinutes2);
      }
    }
  }, [policy]);

  const minutesBeforeTrip = register('minutesBeforeTrip');
  const minutesFromMyAddress = register('minutesFromMyAddress');

  return (
    <form
      className={sm ? classes.smallFormStyles : classes.formStyles}
      data-testid="standard_hours_form"
    >
      <TextLineInput
        text="Billable time should start $minutesBeforeTrip minutes before the trip start time and end $onlyReadingMinutesBeforeTrip minutes after the final drop time, as long as the pickup and drop locations are within $minutesFromMyAddress minutes of my address. Otherwise, additional garage time will be billed. To see the area your garage time policy covers, view the map below."
        inputs={[
          {
            component: () => (
              <input
                data-testid="standar_garage_minutes_before_trip_field"
                className={classes.inputStyles}
                defaultValue={0}
                type="number"
                onChange={minutesBeforeTrip.onChange}
                ref={minutesBeforeTrip.ref}
                name={minutesBeforeTrip.name}
                onBlur={(e) => {
                  minutesBeforeTrip.onBlur(e);
                  handleSubmit(onSubmit)();
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            ),
            id: '$minutesBeforeTrip',
          },
          {
            component: () => (
              <Typography variant="body1">
                {localMinutes || getValues('minutesBeforeTrip')}
              </Typography>
            ),
            id: '$onlyReadingMinutesBeforeTrip',
          },
          {
            component: () => (
              <input
                data-testid="standar_garage_minutes_from_my_address_field"
                className={classes.inputStyles}
                defaultValue={0}
                type="number"
                onChange={minutesFromMyAddress.onChange}
                ref={minutesFromMyAddress.ref}
                name={minutesFromMyAddress.name}
                onBlur={(e) => {
                  minutesFromMyAddress.onBlur(e);
                  handleSubmit(onSubmit)();
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            ),
            id: '$minutesFromMyAddress',
          },
        ]}
      />
    </form>
  );
};

export default StandardGarageTimeForm;
