import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ActivatePaymentProcessorButtonProps {
  loading: boolean;
  type: 'Square' | 'Stripe' | 'Generic';
}

export const ActivatePaymentProcessorButton: FC<ActivatePaymentProcessorButtonProps> =
  ({ loading, type = 'generic' }) => {
    const navigate = useNavigate();
    const genericCardText =
      'Activate your Square or Stripe account to start processing payments for your customers within Capital Drive';
    const nonGenericCardText = `Setup your ${type} account to start processing payments for your customers within Capital Drive`;
    const cardText = type === 'Generic' ? genericCardText : nonGenericCardText;

    const genericButtonText = 'Settings';
    const nonGenericButtonText = `Setup ${type}`;
    const buttonText =
      type === 'Generic' ? genericButtonText : nonGenericButtonText;

    return (
      <Card style={{ padding: '2rem' }}>
        <CardContent>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <Typography variant="body1">Loading...</Typography>
            ) : (
              <React.Fragment>
                <Typography variant="body1">{cardText}</Typography>
                <Button
                  onClick={() => navigate('/settings')}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '2rem', width: '70%', color: 'white' }}
                >
                  {buttonText}
                </Button>
              </React.Fragment>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  };
