import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ProviderType, TierType, VendorRecord } from 'src/.gen/graphql';
import CapitalDriveTabBar, {
  TabOption,
} from 'src/shared/components/CapitalDriveTabBar/CapitalDriveTabBar';
import { DeepPartial } from 'react-hook-form';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import TabComponent from './TabComponent/TabComponent';
import SavoyaHourlyRatesTab from './Tabs/SavoyaHourlyRatesTab/SavoyaHourlyRatesTab';
import SavoyaTransferRatesTab from './Tabs/SavoyaTransferRatesTab/SavoyaTransferRatesTab';

export const useRatesStyles = makeStyles((theme) => ({
  root: { height: '100%', padding: '20px', marginTop: '30px' },
  logo: { marginTop: '30px', marginLeft: '20px' },
  header: {
    padding: '10px',
  },
  tabElement: {
    color: theme.palette.primary.main,
  },
  inputStyles: {
    border: 'none',
    fontSize: '16px',
    fontWeight: 400,
    height: '20px',
    backgroundColor: '#f0f0f0',
    width: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:focus': {
      outline: 'none !important',
      borderBottom: 'solid black 1px',
      cursor: 'auto',
      boxShadow: 'none',
      backgroundColor: 'none',
    },
  },
  formStyles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  smallFormStyles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

enum RatesTabIds {
  HOURLY = 0,
  TRANSFER = 1,
}

const tabs: TabOption[] = [
  {
    id: RatesTabIds.HOURLY,
    title: 'Hourly Rates',
  },
  {
    id: RatesTabIds.TRANSFER,
    title: 'Transfer Rates',
    minimumTier: TierType.Premium,
  },
];

const SavoyaRatesDashboard: FC<{ vendor: DeepPartial<VendorRecord> }> = ({
  vendor,
}) => {
  const classes = useRatesStyles();
  const { sm } = useBreakpoints();

  const savoyaProvider = vendor?.vendorProviders?.find(
    (provider) => provider.providerType === ProviderType.Savoya,
  );

  const canEdit = !!savoyaProvider?.canEditCosts;

  const [selectedTab, setSelectedTab] = React.useState<number | string>(
    RatesTabIds.HOURLY,
  );

  return savoyaProvider ? (
    <Grid
      container
      direction="column"
      data-testid="rates_and_policies_container"
    >
      <Grid
        item
        container
        direction={sm ? 'column' : 'row'}
        justify={sm ? 'flex-start' : 'space-between'}
      >
        <Grid item xs={sm ? 12 : 6}>
          <Box display="flex" alignItems="center" className={classes.logo}>
            <img alt="Savoya" src="/static/images/SavoyaLogo.svg" />
          </Box>
        </Grid>
        <Grid item xs={sm ? 12 : 6}>
          <Box
            m={1}
            display="flex"
            justifyContent={sm ? 'flex-start' : 'flex-end'}
            alignItems={sm ? 'center' : 'flex-end'}
          >
            <CapitalDriveTabBar
              tabs={tabs}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.root} variant="elevation">
          <TabComponent value={selectedTab} index={RatesTabIds.HOURLY}>
            <SavoyaHourlyRatesTab canEdit={canEdit} />
          </TabComponent>
          <TabComponent value={selectedTab} index={RatesTabIds.TRANSFER}>
            <SavoyaTransferRatesTab canEdit={canEdit} />
          </TabComponent>
        </Card>
      </Grid>
    </Grid>
  ) : null;
};

export default SavoyaRatesDashboard;
