import React, { FC } from 'react';

export interface TabContainerProps {
  tabId: string | number;
  selectedTab: string | number;
}
const TabContainer: FC<TabContainerProps> = ({
  children,
  selectedTab,
  tabId,
}) => {
  return tabId === selectedTab ? (
    <React.Fragment>{children}</React.Fragment>
  ) : null;
};

export default TabContainer;
