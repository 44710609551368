import React from 'react';
import {
  Box,
  List,
  ListItemText,
  Collapse,
  SvgIcon,
  Button,
  ListItem,
  Typography,
  Checkbox,
  Badge,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Smartphone as SmartphoneIcon, Mail as MailIcon } from 'react-feather';
import { makeStyles } from '@material-ui/styles';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';

import {
  useGetNotificationTypesLazyQuery,
  MessageChannel,
  TripEventType,
  usePublicAllLazyQuery,
} from 'src/.gen/graphql';
import _orderBy from 'lodash/orderBy';
import { Field } from 'formik';
import { TripNotificationTypesSubscribed } from 'src/trips/atoms/tripFormAtom/tripFormAtomTypes';

interface NotificationPreferencesProps {
  fieldName: string;
  setFieldValue?: (fieldName: string, value: boolean) => void;
  action: 'edit' | 'new';
  subscriptions?: TripNotificationTypesSubscribed;
  publicTrip?: boolean;
}

const DEFAULT_EVENT_NOTIFICATION_TYPES = {
  [TripEventType.TripAssigned]: [MessageChannel.Email], // Confirmation
  [TripEventType.ArrivePickup]: [MessageChannel.Sms], // On Location
  [TripEventType.InvoiceSubmitted]: [MessageChannel.Email], // Receipt
  [TripEventType.TripCancelled]: [MessageChannel.Email], // Cancellation
  [TripEventType.EnRouteToPickup]: [MessageChannel.Sms], // Enroute to Pickup
};

const useStyles = makeStyles(() => ({
  collapseButton: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
    '& > .span': {
      marginRight: 15,
    },
    '& .MuiBadge-badge': {
      right: -16,
      top: '50%',
    },
  },
}));

const DefaultMailIcon = (props) => {
  return (
    <SvgIcon fontSize="small" {...props}>
      <MailIcon />
    </SvgIcon>
  );
};

const DefaultSmartPhoneIcon = (props) => {
  return (
    <SvgIcon fontSize="small" {...props}>
      <SmartphoneIcon />
    </SvgIcon>
  );
};

const PassengerContactTripNotificationSubscriptions: React.FC<NotificationPreferencesProps> =
  ({ fieldName, setFieldValue, action, subscriptions, publicTrip = false }) => {
    const clasess = useStyles();
    const [getNotificationTypes, { data, loading }] =
      useGetNotificationTypesLazyQuery();
    const [
      getPublicNotificationTypes,
      { data: publicData, loading: publicLoading },
    ] = usePublicAllLazyQuery();

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    const setDefaultNotificationTypes = () => {
      let notificationTypes;
      if (publicTrip) {
        notificationTypes = publicData.tripNotificationTypes.publicAll.items;
      } else {
        notificationTypes = data.tripNotificationTypes.all.items;
      }
      Object.keys(DEFAULT_EVENT_NOTIFICATION_TYPES).forEach((key) => {
        const channels = DEFAULT_EVENT_NOTIFICATION_TYPES[key];
        const notificationType = notificationTypes.find(
          ({ trigger }) => trigger === key,
        );
        if (!notificationType) return;
        channels.forEach((channel) => {
          setFieldValue(
            `${fieldName}.${notificationType.id}.channels.${channel}`,
            true,
          );
        });
      });
    };

    React.useEffect(() => {
      if (publicTrip) {
        getPublicNotificationTypes();
      } else {
        getNotificationTypes();
      }
    }, []);

    React.useEffect(() => {
      if (!loading && data?.tripNotificationTypes && action === 'new')
        setDefaultNotificationTypes();
    }, [data, loading, action]);

    React.useEffect(() => {
      if (
        !publicLoading &&
        publicData?.tripNotificationTypes &&
        action === 'new'
      )
        setDefaultNotificationTypes();
    }, [publicData, publicLoading, action]);

    const countSubscriptionType = React.useMemo(() => {
      return Object.keys(subscriptions).filter((subId) =>
        Object.values(subscriptions[subId].channels).some((check) => check),
      ).length;
    }, [subscriptions]);

    return (
      <Box display="flex" flexDirection="column">
        <Button onClick={handleClick} className={clasess.collapseButton}>
          <Badge badgeContent={countSubscriptionType} color="secondary">
            <Typography variant="subtitle1">
              Notification Preferences
            </Typography>
          </Badge>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open} timeout="auto">
          <List component="div" disablePadding>
            {loading
              ? Array.from(new Array(3)).map((_, index) => (
                  <ListItem key={index}>
                    <Skeleton width="25%" height={30} />
                    <Skeleton width="75%" height={30} />
                  </ListItem>
                ))
              : _orderBy(
                  publicTrip
                    ? publicData?.tripNotificationTypes?.publicAll?.items
                    : data?.tripNotificationTypes?.all?.items,
                  ['order'],
                ).map(({ id, name }) => (
                  <ListItem key={id}>
                    <Box display="flex" justifyContent="space-evenly" mr={1}>
                      {name !== 'Receipt' ? (
                        <Field
                          name={`${fieldName}.${id}.channels.${MessageChannel.Sms}`}
                        >
                          {({ field, form }) => (
                            <Checkbox
                              {...field}
                              data-testid={`${fieldName}.${id}_trip_notifications_icon_checkbox`}
                              icon={<DefaultSmartPhoneIcon />}
                              checkedIcon={
                                <DefaultSmartPhoneIcon color="primary" />
                              }
                              checked={field.value || false}
                              onChange={async (e) => {
                                await form.setFieldValue(
                                  field.name,
                                  e.target.checked,
                                );
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                          )}
                        </Field>
                      ) : (
                        <div style={{ width: '42px' }} />
                      )}
                      <Field
                        name={`${fieldName}.${id}.channels.${MessageChannel.Email}`}
                      >
                        {({ field, form }) => (
                          <Checkbox
                            {...field}
                            data-testid={`${fieldName}.${id}_trip_notifications_label_checkbox`}
                            icon={<DefaultMailIcon />}
                            checkedIcon={<DefaultMailIcon color="primary" />}
                            checked={field.value || false}
                            onChange={async (e) => {
                              await form.setFieldValue(
                                field.name,
                                e.target.checked,
                              );
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                        )}
                      </Field>
                    </Box>
                    <ListItemText primary={name} />
                  </ListItem>
                ))}
          </List>
        </Collapse>
      </Box>
    );
  };

export default PassengerContactTripNotificationSubscriptions;
