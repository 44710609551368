import React from 'react';
import { useGetRateTypesQuery } from 'src/.gen/graphql';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/authentication/atoms/AuthState';
import TripAdjustmentForm from './TripAdjustmentForm';

type Props = {
  tripId: string;
  vehicleClassId?: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const AddTripAdjustment: React.FC<Props> = ({
  tripId,
  vehicleClassId,
  onCancel,
  onSubmit,
}) => {
  const user = useRecoilValue(userAtom);
  const { data } = useGetRateTypesQuery({
    variables: {
      accountId: user?.accountId,
    },
  });

  const options = React.useMemo(() => {
    return data?.rateTypes?.all?.items.filter(({ favorite }) => favorite) || [];
  }, [data]);

  const handleCancel = () => {
    onCancel();
  };

  return (
    <React.Fragment>
      <TripAdjustmentForm
        tripId={tripId}
        vehicleClassId={vehicleClassId}
        rateOptions={options}
        onCancel={handleCancel}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
};

export default AddTripAdjustment;
