import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { savoyaRatesDialogsState } from 'src/rates/atoms/SavoyaRateFormState';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
  SavoyaVehicleType,
  insertHourlyCost,
} from 'src/rates/api/SavoyaRateQueries';
import SavoyaHourlyRateBaseForm from '../SavoyaHourlyRateBaseForm';

interface DialogProps {
  vehicleTypes: SavoyaVehicleType[];
}

const AddSavoyaHourlyRateDialog: FC<DialogProps> = ({ vehicleTypes }) => {
  const [dialogState, setDialogState] = useRecoilState(savoyaRatesDialogsState);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const addSavoyaHourlyRate = useMutation({
    mutationFn: insertHourlyCost,
    onSuccess: () => {
      queryClient.invalidateQueries(['hourlyRates']);
      enqueueSnackbar('Hourly Rate Added', { variant: 'success' });
      closeDialog();
    },
    onError: () =>
      enqueueSnackbar('Error Adding Hourly Rate', { variant: 'error' }),
  });
  const closeDialog = () =>
    setDialogState((prev) => ({
      ...prev,
      openCreateHourlyRateDialog: false,
    }));

  const onSubmit = async (values) => {
    await addSavoyaHourlyRate.mutateAsync({
      ...values,
      amount: Math.round(values.amount * 100),
    });
  };

  return (
    <GenericDialog
      dialogTitle="New Hourly Rate"
      openDialog={dialogState.openCreateHourlyRateDialog}
      setCloseDialog={closeDialog}
      maxWidth="sm"
    >
      <SavoyaHourlyRateBaseForm
        action="create"
        onSubmit={onSubmit}
        onCancel={closeDialog}
        vehicleTypes={vehicleTypes}
        initialValues={{
          vehicleType: vehicleTypes?.length ? vehicleTypes[0] : null,
        }}
      />
    </GenericDialog>
  );
};

export default AddSavoyaHourlyRateDialog;
