import {
  CardHeader,
  Typography,
  Grid,
  makeStyles,
  Theme,
  Box,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { Trip, TripProviderSource } from 'src/.gen/graphql';
import { userAtom } from 'src/authentication/atoms/AuthState';
import TripActionsMenu from '../TripActionsMenu';
import TripStatusBox from '../TripListTile/TripStatusBox';

interface TripPassengerCardHeaderProps {
  trip: Partial<Trip>;
  loading?: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    '& .MuiCardHeader-action': {
      margin: 'auto 0',
    },
    '& .MuiGrid-root.MuiGrid-container': {
      '& > button:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  },
  sharedTripLabel: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    marginLeft: '1rem',
  },
  tripStatus: {
    marginBottom: 'auto',
  },
}));

const TripPassengerCardHeader: React.FC<TripPassengerCardHeaderProps> = ({
  trip,
  loading = false,
}) => {
  const classes = useStyles();

  const user = useRecoilValue(userAtom);
  const iOwnThisTrip = user?.accountId === trip?.accountId;
  return (
    <CardHeader
      data-testid="trip_passenger_card_header"
      className={classes.cardHeader}
      subheader={
        <Box flexDirection="row">
          <Typography data-testid="trip_number">
            {!loading ? `Trip #: ${trip?.tripNumber}` : <Skeleton />}
            {!loading && trip?.isShared && iOwnThisTrip ? (
              <span className={classes.sharedTripLabel}>Shared Trip</span>
            ) : (
              <Fragment />
            )}
            {!loading && trip?.isShared && !iOwnThisTrip ? (
              <span className={classes.sharedTripLabel}>Received Trip</span>
            ) : (
              <Fragment />
            )}
          </Typography>
        </Box>
      }
      title={
        <Grid container data-testid="trip_pickup_time">
          <Grid item xs={12}>
            <Typography variant="h6">
              {loading && <Skeleton />}
              {!loading &&
                trip?.pickupTime &&
                format(
                  new Date(trip?.pickupTime),
                  "eeee MMMM dd, yyyy 'at' hh:mm a",
                )}
              {!loading && !trip?.pickupTime && '---'}
            </Typography>
          </Grid>
        </Grid>
      }
      action={
        !loading && (
          <Grid container direction="row" spacing={1}>
            {trip && (
              <Grid item className={classes.tripStatus}>
                <TripStatusBox
                  status={trip?.status}
                  savoyaSharedStatus={trip?.sharedTrip?.savoyaAssignmentStatus}
                  sharedStatus={trip?.sharedTrip?.status}
                  iOwnThisTrip={iOwnThisTrip}
                  isSavoyaTrip={
                    !!trip?.providerExternalId ||
                    trip?.providerSource === TripProviderSource.Savoya
                  }
                />
              </Grid>
            )}

            {trip && (
              <Grid item>
                <TripActionsMenu
                  type="WithTripid"
                  tripId={trip.id}
                  tripStatus={trip.status}
                  savoyaSharedTripStatus={
                    trip?.sharedTrip?.savoyaAssignmentStatus
                  }
                  tripNumber={trip.tripNumber}
                  isSavoya={trip.providerSource === TripProviderSource.Savoya}
                  savoyaTripId={
                    trip?.providerExternalId || trip?.tripNumber?.toString()
                  }
                  isShared={trip.isShared}
                />
              </Grid>
            )}
          </Grid>
        )
      }
    />
  );
};

export default TripPassengerCardHeader;
