import React, { FC, useState } from 'react';
import {
  makeStyles,
  IconButton,
  Tooltip,
  useTheme,
  Button,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import _snakeCase from 'lodash/snakeCase';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { isArray } from 'lodash';
import {
  SavoyaTransferCostResponse,
  SavoyaTransferRate,
} from 'src/rates/api/SavoyaRateQueries';
import Dinero from 'dinero.js';
import ConfirmationDialog from '../../../../shared/components/ConfirmationDialog/ConfirmationDialog';

const useStyles = makeStyles(() => ({
  MainTableContainer: {},
}));

interface TransferRatesTableProps {
  canEdit: boolean;
  transferRates: SavoyaTransferCostResponse;
  loading: boolean;
  onEdit?: (transferRate: SavoyaTransferRate) => void;
  onDelete?: (id: number) => void;
  setPage?: (page: number) => void;
  setPageSize?: (page: number) => void;
}

const SavoyaTransferRatesTable: FC<TransferRatesTableProps> = ({
  transferRates,
  canEdit,
  loading,
  onEdit,
  onDelete,
  setPage,
  setPageSize,
}) => {
  const classes = useStyles();
  const { sm } = useBreakpoints();
  const theme = useTheme();

  const TABLE_HEADERS: Column<SavoyaTransferRate>[] = sm
    ? [
        {
          title: 'Point 1',
          field: 'point1Name',
          align: 'center',
          sorting: false,
          editable: 'never',
        },
        {
          title: 'Point 2',
          field: 'point2Name',
          align: 'center',
          sorting: false,
          editable: 'never',
        },
      ]
    : [
        {
          title: 'Vehicle Type',
          field: 'vehicleType.name',
          align: 'center',
          sorting: false,
          editable: 'never',
        },
        {
          title: 'Cost',
          field: 'transferCost',
          align: 'center',
          sorting: false,
          editable: 'never',
          render: (transferRate) => {
            const formatted = Dinero({
              amount: parseFloat(transferRate?.transferCost) || 0,
              currency: 'USD',
            }).toFormat();
            return (
              <TextField
                aria-readonly
                value={formatted}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            );
          },
        },
        {
          title: 'Point 1',
          field: 'point1Name',
          align: 'center',
          sorting: false,
          editable: 'never',
        },
        {
          title: 'Point 2',
          field: 'point2Name',
          align: 'center',
          sorting: false,
          editable: 'never',
        },
        {
          title: 'Cancel Hours',
          field: 'cancellationHours',
          align: 'center',
          sorting: false,
          editable: 'never',
        },
        {
          title: 'Start Date',
          field: 'startDate',
          align: 'center',
          sorting: false,
          editable: 'never',
        },
        {
          title: 'End Date',
          field: 'endDate',
          align: 'center',
          sorting: false,
          editable: 'never',
          render: (transferRate) => {
            return (
              <TextField
                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                aria-readonly
                value={transferRate?.endDate || '--'}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            );
          },
        },
      ];

  const [confirmationDialogState, setConfirmationDialogState] =
    useState<boolean>(false);
  const [elementToDelete, setElementToDelete] = useState(null);

  const handleDelete = () => {
    if (Array.isArray(elementToDelete)) {
      if (elementToDelete.length) {
        onDelete(elementToDelete[0].id);
      }
    } else {
      onDelete(elementToDelete.id);
    }
  };

  const renderIcon = (key, props) => {
    switch (key) {
      case 'add':
        return <AddIcon {...props} />;
      case 'edit':
        return <EditIcon {...props} />;
      case 'delete':
        return <DeleteIcon {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.MainTableContainer}>
      <ConfirmationDialog
        open={confirmationDialogState}
        title="Are you sure you want to delete this Transfer Rate?"
        setOpen={setConfirmationDialogState}
        onConfirm={handleDelete}
        warning
      />
      <MaterialTable
        style={{ marginTop: 0, paddingTop: 0 }}
        title=""
        columns={TABLE_HEADERS as Column<SavoyaTransferRate>[]}
        data={
          transferRates?.paginated?.records
            ? transferRates?.paginated?.records
            : []
        }
        totalCount={transferRates?.paginated?.total}
        page={transferRates?.paginated?.page - 1}
        isLoading={loading}
        onChangePage={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        onChangeRowsPerPage={(pageSize) => {
          setPageSize(pageSize);
        }}
        components={{
          Container: (props) => <div {...props} elevation={0} />,
          Action: (props) => {
            const { action, data } = props;
            return (
              <Tooltip title={action?.tooltip}>
                {action.icon === 'add' ? (
                  <Button
                    data-testid={`list_item_${
                      !action.isFreeAction ? data?.tableData?.id : 'free'
                    }_${_snakeCase(action?.tooltip)}_button`}
                    variant="contained"
                    color="primary"
                    onClick={(event) => action?.onClick(event, data)}
                    startIcon={
                      <SvgIcon fontSize="small">
                        <PlusCircleIcon />
                      </SvgIcon>
                    }
                  >
                    Transfer Rate
                  </Button>
                ) : (
                  <IconButton
                    data-testid={`list_item_${
                      !action.isFreeAction ? data?.tableData?.id : 'free'
                    }_${_snakeCase(action?.tooltip)}_button`}
                    onClick={(event) => action?.onClick(event, data)}
                  >
                    {renderIcon(action?.icon, action?.iconProps)}
                  </IconButton>
                )}
              </Tooltip>
            );
          },
        }}
        options={{
          paging: true,
          pageSize: 5,
          pageSizeOptions: [5, 10, 15, 20, 25, 30],
          search: false,
          actionsColumnIndex: -1,
          rowStyle: {
            fontSize: sm ? '0.7rem' : '0.8rem',
          },
          headerStyle: {
            fontSize: sm ? '0.7rem' : '1rem',
          },
          toolbar: false,
        }}
        actions={
          canEdit
            ? [
                {
                  icon: 'edit',
                  tooltip: 'Edit rate',
                  iconProps: {
                    color: 'primary',
                  },
                  onClick: (_event, rowElement) => {
                    const data = rowElement;
                    if (!isArray(data)) {
                      onEdit(data);
                    }
                  },
                },
                {
                  icon: 'delete',
                  tooltip: 'Delete rate',
                  iconProps: {
                    style: { color: theme.palette.error.main },
                  },
                  onClick: (event, rowElement) => {
                    setElementToDelete(rowElement);
                    setConfirmationDialogState(true);
                  },
                },
              ]
            : null
        }
      />
    </div>
  );
};

export default SavoyaTransferRatesTable;
