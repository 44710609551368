import React from 'react';
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  fade,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core';
import {
  InvoiceStatus,
  PaymentMethodType,
  TransactionType,
  Trip,
  TripStatus,
  useDeleteTripAddonMutation,
} from 'src/.gen/graphql';
import { Skeleton } from '@material-ui/lab';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import { useRecoilState } from 'recoil';
import { getRateBreakdownColumns, getRateBreakdownRows } from './utils';
import TripAdjustmentButton from './TripAdjustmentButton';
import TripRefundButton from './TripRefundButton';
import TransactionDetailsButton from '../TransactionDetailsButton';
import UseDifferentRateButton from './UseDifferentRateButton';

interface Props {
  trip: Partial<Trip>;
}
export const COMPLETED_STATUS = [
  TripStatus.TripComplete,
  TripStatus.Billed,
  TripStatus.Paid,
  TripStatus.Cancelled,
  TripStatus.Closed,
];

export const BILLED_STATUS = [
  TripStatus.Billed,
  TripStatus.Paid,
  TripStatus.Cancelled,
  TripStatus.Closed,
];

const TripRateBreakdown: React.FC<Props> = ({ trip }) => {
  const classes = useStyles();
  const [{ loading, updating, summary }, setTimestampsState] =
    useRecoilState(tripTimestampsState);
  const [deleteTripAddon] = useDeleteTripAddonMutation({
    refetchQueries: ['getTripById', 'getInvoiceSummaryByTrip'],
  });

  const deleteAddOn = async (tripAddonId: string) => {
    await deleteTripAddon({
      variables: {
        tripAddonId,
      },
    });
    setTimestampsState((prev) => ({
      ...prev,
      refetchInvoiceSummary: true,
      updating: true,
    }));
  };

  React.useEffect(() => {
    if (trip?.status === TripStatus.TripComplete) {
      setTimestampsState((prev) => ({
        ...prev,
        refetchInvoiceSummary: true,
        updating: true,
      }));
    }
  }, [trip?.status]);

  const columns = getRateBreakdownColumns(
    summary?.calculation,
    !!trip?.flatRateOverride,
  );
  const rawRows = getRateBreakdownRows(summary, trip, deleteAddOn);
  let rows = rawRows;
  // Remove the balance row in case the trip is not completed yet
  if (trip && !COMPLETED_STATUS.includes(trip?.status)) {
    rows = rawRows.filter((el) => el.name !== 'Balance');
  }
  const refundAvailable = (summary?.transactions || []).find((transaction) => {
    return (
      transaction.type === TransactionType.Payment &&
      (!transaction.refundedAmount ||
        transaction.amount > transaction.refundedAmount)
    );
  });

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} style={{ paddingBottom: '10px' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {(updating || loading
                    ? Array.from(new Array(2))
                    : columns
                  ).map((column, index) => {
                    if (!column) {
                      return (
                        <TableCell key={index}>
                          <Skeleton width="100%" />
                        </TableCell>
                      );
                    }
                    return <TableCell key={index}>{column.label}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {(updating || loading ? Array.from(new Array(2)) : rows).map(
                  (row, index) => {
                    if (!row) {
                      return (
                        <TableRow key={index} className={classes.tableRow}>
                          {Array.from(new Array(2)).map((_, index) => (
                            <TableCell key={index}>
                              <Skeleton width="100%" />
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    }

                    return (
                      <TableRow key={index} className={classes.tableRow}>
                        {columns.map((column, index) => {
                          const { transaction } = row;
                          const transactionBtn =
                            index === 0 &&
                            transaction &&
                            transaction.paymentMethodType ===
                              PaymentMethodType.CreditCard ? (
                              <TransactionDetailsButton
                                transaction={transaction}
                              />
                            ) : (
                              ''
                            );
                          const value = row[column.id];
                          return (
                            <CustomCell
                              key={column.id}
                              highlight={row?.highlight}
                            >
                              {value}
                              {transactionBtn}
                            </CustomCell>
                          );
                        })}
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={4} alignItems="center" justify="space-between">
            {!BILLED_STATUS.includes(trip?.status) &&
              (!trip?.invoice ||
                trip?.invoice?.status !== InvoiceStatus.Paid) && (
                <TripAdjustmentButton
                  tripId={trip?.id}
                  vehicleClassId={trip?.preferredVehicleClass.id}
                />
              )}
          </Grid>
          <Grid item xs={4} alignItems="center" justify="space-between">
            {!BILLED_STATUS.includes(trip?.status) &&
              (!trip?.invoice ||
                trip?.invoice?.status !== InvoiceStatus.Paid) && (
                <UseDifferentRateButton trip={trip} />
              )}
          </Grid>
          {refundAvailable && (
            <Grid item xs={4} alignItems="center" justify="space-between">
              <TripRefundButton
                tripId={trip?.id}
                paymentMethod={{
                  ...trip?.paymentMethod,
                  type: trip?.paymentMethodType,
                }}
                grandTotal={
                  refundAvailable.amount - refundAvailable.refundedAmount ||
                  trip?.invoice?.payments ||
                  0
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const useStyles = makeStyles((theme) => ({
  tableHead: {
    '& > .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      color: theme.palette.textDark.main,
      '& > .MuiTableCell-root.MuiTableCell-head:not(:first-child)': {
        textAlign: 'right',
      },
    },
  },
  tableBody: {
    '& > .MuiTableRow-root:nth-child(even)': {
      backgroundColor: theme.palette.backgroundColor.light,
    },
    '& > .MuiTableRow-root:last-child > td': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  tableRow: {
    '& > .MuiTableCell-root.MuiTableCell-body:not(:first-child)': {
      textAlign: 'right',
      maxWidth: '120px',
    },
  },
}));

const CustomCell = styled(TableCell)(
  ({ theme, highlight }: { theme: Theme; highlight?: boolean }) => ({
    fontWeight: highlight
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightRegular,
    backgroundColor: highlight
      ? fade(theme.palette.backgroundColor.main, 0.2)
      : 'transparent',
  }),
);

export default TripRateBreakdown;
