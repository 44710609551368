export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  redirectLink: process.env.REACT_APP_AUTH0_REDIRECT_LINK,
  nonUserRedirectLink: process.env.REACT_APP_AUTH0_NON_USER_REDIRECT_LINK,
  nonVerifiedEmailLink:
    process.env.REACT_APP_AUTH0_NON_VERIFIED_EMAIL_REDIRECT_LINK,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export const getstreamConfig = {
  apiKey: process.env.REACT_APP_GETSTREAM_API_KEY,
  appId: process.env.REACT_APP_GETSTREAM_APP_ID,
};

export const appConfig = {
  apiUrl: process.env.REACT_APP_CAPITAL_DRIVE_API_URL,
};

export const cookiesConfig = {
  CAPITALDRIVE_USER_DATA: 'cdud',
  USER_COOKIES_KEYS: 'uck',
};

export const cubeJsConfig = {
  cubeJsApiUrl: process.env.REACT_APP_CUBE_JS_API_URL,
};

export const mapBoxConfig = {
  token: process.env.REACT_APP_MAPBOX_TOKEN,
};

export function getFEUrl(): string {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://app.capitaldrive.com';
    case 'qa':
      return 'https://qa.capitaldrive.com';
    case 'development':
      return 'https://dev.capitaldrive.com';
    default:
      return 'http://localhost:3000';
  }
}
