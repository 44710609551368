import { useEffect, RefObject } from 'react';

interface useDoubleClickArgs {
  ref: RefObject<HTMLElement>;
  latency: number;
  onSingleClick: (e: MouseEvent) => void;
  onDoubleClick: (e: MouseEvent) => void;
}

const useDoubleClick = ({
  ref,
  latency = 300,
  onSingleClick = () => undefined,
  onDoubleClick = () => undefined,
}: useDoubleClickArgs): void => {
  useEffect(() => {
    const clickRef = ref.current;
    let clickCount = 0;
    const handleClick = (e: MouseEvent) => {
      clickCount += 1;

      setTimeout(() => {
        if (clickCount === 1) onSingleClick(e);
        else if (clickCount === 2) onDoubleClick(e);

        clickCount = 0;
      }, latency);
    };

    // Add event listener for click events
    clickRef.addEventListener('click', handleClick);

    // Remove event listener
    return () => clickRef.removeEventListener('click', handleClick);
  });
};

export default useDoubleClick;
