import { atom, RecoilState } from 'recoil';

interface SavoyaRatesDialogsInterface {
  openEditTransferRateDialog: boolean;
  openCreateTransferRateDialog: boolean;
  openEditHourlyRateDialog: boolean;
  openCreateHourlyRateDialog: boolean;
}

export const savoyaRatesDialogsState: RecoilState<SavoyaRatesDialogsInterface> =
  atom({
    key: 'savoyaRatesDialogs',
    default: {
      openEditTransferRateDialog: false,
      openCreateTransferRateDialog: false,
      openEditHourlyRateDialog: false,
      openCreateHourlyRateDialog: false,
    },
  });
