import { cloneDeep } from 'lodash';
import _omit from 'lodash/omit';
import {
  DistancesAndTime,
  InsertTrip,
  InsertTripNotificationSubscription,
  TripWaypointRole,
  UpdateTrip,
  UpdateTripContact,
  UpsertTripWaypointInput,
  TripNotificationSubscription,
  UpdateTripNotificationSubscription,
} from 'src/.gen/graphql';
import { getIATCodeFromPlaceName } from 'src/shared/utils/addressUtils';
import {
  convertHoursToMinutes,
  convertMinutesToSeconds,
} from 'src/shared/utils/conversions';
import { DeepPartial } from 'react-hook-form';
import {
  TripContactParsedValues,
  TripFormFormattedPickupOrDropoffWaypoint,
  TripFormFormattedWaypoint,
  TripFormValues,
  TripNotificationTypesSubscribed,
} from './tripFormAtomTypes';

export const parseFormattedTripWaypoints = (
  formatedWaypoints: Array<TripFormFormattedWaypoint>,
): Array<UpsertTripWaypointInput> => {
  return formatedWaypoints?.map(parseFormattedTripWaypoint);
};

export const parseFormattedTripWaypoint = (
  formattedWaypoint: TripFormFormattedWaypoint,
): UpsertTripWaypointInput => {
  const {
    placeCategory,
    addressLine1,
    city,
    state,
    postalCode,
    longitude,
    latitude,
    placeType,
    placeName,
    airlineId,
    fboId,
    flightNumber,
    estimatedDurationInMinutes,
    role,
  } = formattedWaypoint || {};
  const isAirport = placeCategory === 'airport';
  const parsedAirline = airlineId || null;
  const parsedFbo = fboId || null;

  if (
    (placeName && role === TripWaypointRole.Stop) ||
    role === TripWaypointRole.Garage
  ) {
    return {
      place: {
        addressLine1: addressLine1 || '',
        city: city || '',
        state: state || '',
        postalCode: postalCode || '',
        coordinates: {
          type: 'Point',
          coordinates: [longitude || 0, latitude || 0],
        },
        placeType: placeType || '',
        placeCategory: placeCategory || '',
        placeName: placeName || '',
      },
      role,
      airlineId: isAirport ? parsedAirline : null,
      fboId: isAirport ? parsedFbo : null,
      iataCode: isAirport ? getIATCodeFromPlaceName(placeName) : null,
      flightNumber: isAirport ? flightNumber : null,
      estimatedDurationInMinutes:
        role === TripWaypointRole.Stop
          ? Number(estimatedDurationInMinutes) * 60 || 60
          : undefined,
    };
  }
  return undefined;
};

export const parseFormattedPickupOrDropoffTripWaypoint = (
  formattedPickupWaypoint: TripFormFormattedPickupOrDropoffWaypoint,
  isPickup: boolean,
  initialFormattedDropoffLocation?: TripFormFormattedPickupOrDropoffWaypoint,
): UpsertTripWaypointInput => {
  const {
    placeCategory,
    addressLine1,
    city,
    state,
    postalCode,
    longitude,
    latitude,
    placeType,
    placeName,
    airlineId,
    fboId,
    flightNumber,
    placeFoursquareId,
    placeWikidataId,
    verifiedFlight,
    flightTime,
  } = formattedPickupWaypoint;
  const {
    airlineId: initialAirlineId,
    flightNumber: initialFligtNumber,
    verifiedFlight: initialVerifiedFlight,
  } = initialFormattedDropoffLocation || {};
  const isAirport = placeCategory === 'airport';
  const flightHasBeenVerified = !initialVerifiedFlight
    ? !!verifiedFlight
    : initialVerifiedFlight !== verifiedFlight;
  const flightHasChanged =
    (airlineId && initialAirlineId !== airlineId) ||
    (fboId && initialAirlineId !== fboId) ||
    initialFligtNumber !== flightNumber;

  if (placeName) {
    return {
      place: {
        placeFoursquareId: placeFoursquareId || null,
        placeWikidataId: placeWikidataId || null,
        addressLine1: addressLine1 || '',
        city: city || '',
        state: state || '',
        postalCode: postalCode || '',
        coordinates: {
          type: 'Point',
          coordinates: [longitude || 0, latitude || 0],
        },
        placeType: placeType || '',
        placeCategory: placeCategory || '',
        placeName: placeName || '',
      },
      role: isPickup ? TripWaypointRole.Pickup : TripWaypointRole.Dropoff,
      airlineId: isAirport && airlineId !== '' ? airlineId : null,
      fboId: isAirport && fboId !== '' ? fboId : null,
      iataCode: isAirport ? getIATCodeFromPlaceName(placeName) : null,
      flightNumber: isAirport && flightNumber !== '' ? flightNumber : null,
      verifiedFlight:
        flightHasChanged && !flightHasBeenVerified
          ? null
          : verifiedFlight || null,
      flightTime: flightTime || null,
    };
  }
  return undefined;
};

export const parseNotificationTypesSubscribed = (
  items: TripNotificationTypesSubscribed,
  tripContactId?: string,
): Array<
  InsertTripNotificationSubscription & UpdateTripNotificationSubscription
> => {
  const notificationSubcriptions = Object.keys(items).reduce((result, key) => {
    const subscription = items[key];
    const channels = Object.keys(subscription.channels)
      .filter((key) => subscription.channels[key])
      .map((key) => key);

    if (!channels.length) return result;

    // NOTE: deleting the notificationType property from the subscription
    // to avoid grapghql errors since the updateTrip mutation does not expect it
    delete subscription?.notificationType;

    return [
      ...result,
      {
        channels,
        notificationTypeId: key,
        tripContactId: tripContactId || subscription.tripContactId,
      },
    ];
  }, []);
  return notificationSubcriptions;
};

export const parseNotificationSubscriptions = (
  items: Array<DeepPartial<TripNotificationSubscription>> = [],
): TripNotificationTypesSubscribed => {
  return items.reduce(
    (result, subscription) => ({
      ...result,
      [subscription.notificationTypeId]: {
        ..._omit(subscription, ['__typename']),
        channels: subscription.channels.reduce(
          (result, key) => ({ ...result, [key]: true }),
          {},
        ),
      },
    }),
    {},
  );
};

export const parseToInsertTripFormValues = (
  values: TripFormValues,
  accountId: string,
  submitAction: string,
  distancesAndTime: Array<DistancesAndTime>,
): InsertTrip => {
  const {
    pickupTime,
    passenger,
    luggageCount,
    additionalPassengers,
    waypoints: formattedWaypoints,
    pickupLocation: formattedPickupLocation,
    dropoffLocation: formattedDropoffLocation,
    preferredVehicleClass,
    booking,
    wasBookedByPassenger,
    isNewBooking,
    isNewPassenger,
    existingBookingContact,
    isDirected,
    estimatedDurationInMinutesOverride,
  } = values;

  const waypoints = parseFormattedTripWaypoints(formattedWaypoints);
  const pickupLocation =
    formattedPickupLocation &&
    parseFormattedPickupOrDropoffTripWaypoint(formattedPickupLocation, true);
  const dropoffLocation = isDirected
    ? {
        place: {
          ...pickupLocation.place,
        },
        role: TripWaypointRole.Dropoff,
      }
    : formattedDropoffLocation &&
      parseFormattedPickupOrDropoffTripWaypoint(
        formattedDropoffLocation,
        false,
      );
  const { notificationTypesSubscribed, ...actualPassenger } = passenger;

  const notificationSubscriptions = parseNotificationTypesSubscribed(
    notificationTypesSubscribed,
  );
  const filteredWaypoints = [];
  const stopWaypoints = waypoints.filter(
    (waypoint) => waypoint && waypoint.role === TripWaypointRole.Stop,
  );
  const garageWaypoints = waypoints.filter(
    (waypoint) => waypoint && waypoint.role === TripWaypointRole.Garage,
  );
  if (garageWaypoints.length > 0) filteredWaypoints.push(garageWaypoints[0]);
  if (pickupLocation?.place?.placeName) filteredWaypoints.push(pickupLocation);
  if (stopWaypoints.length > 0) filteredWaypoints.push(...stopWaypoints);
  if (dropoffLocation?.place?.placeName)
    filteredWaypoints.push(dropoffLocation);
  let parsedBooking;
  if (garageWaypoints.length > 0) filteredWaypoints.push(garageWaypoints[1]);
  if (isNewBooking && booking !== undefined) parsedBooking = booking;
  else if (!isNewBooking && booking !== undefined) {
    parsedBooking = {
      name: booking?.name,
      email: booking?.email,
      phoneNumber: booking?.phoneNumber,
      customerContactId: booking?.clientId,
    };
  }
  const submitDistancesAndTime = cloneDeep(distancesAndTime);
  const parseEstimatedDurationInMinutes = +convertHoursToMinutes(
    estimatedDurationInMinutesOverride,
  ).toFixed(2);

  if (isDirected) {
    submitDistancesAndTime.splice(-2, 1, {
      distance: 0,
      duration: convertMinutesToSeconds(parseEstimatedDurationInMinutes),
    });
  }

  const actualPassengerCopy = { ...actualPassenger };
  if (!actualPassengerCopy.id) {
    delete actualPassengerCopy.id;
  }
  const bookingName = parsedBooking?.name;
  return {
    accountId,
    luggageCount: luggageCount ? Number(luggageCount) : 0,
    passengerCount: additionalPassengers ? Number(additionalPassengers) : 0,
    pickupTime,
    passengerContact: !isNewPassenger
      ? { ...actualPassengerCopy, notificationSubscriptions }
      : {
          name: passenger.name,
          email: passenger.email,
          phoneNumber: passenger.phoneNumber,
          clientNote: passenger?.note,
          notificationSubscriptions,
        },
    waypoints: filteredWaypoints,
    distancesAndTime: submitDistancesAndTime,
    submit: submitAction === 'assign',
    bookingContact: !isNewBooking
      ? parsedBooking
      : {
          name: bookingName,
          email: parsedBooking.email,
          phoneNumber: parsedBooking.phoneNumber,
          existingContactId: existingBookingContact?.id,
        },
    wasBookedByPassenger, // Important to be able to assign
    preferredVehicleClassId: preferredVehicleClass.id ?? null,
    isDirected,
    estimatedDurationInMinutesOverride: parseEstimatedDurationInMinutes || null,
  };
};

export const parseToUpdateTripContact = (
  values: TripFormValues,
): TripContactParsedValues => {
  const {
    isNewBooking,
    isNewPassenger,
    existingPassengerContact,
    existingBookingContact,
    wasBookedByPassenger,
    booking,
  } = values;
  let bookingContact: Partial<UpdateTripContact>;
  let passengerContact: Partial<UpdateTripContact>;

  const { notificationTypesSubscribed, ...passenger } = values.passenger;

  const notificationSubscriptions = notificationTypesSubscribed
    ? parseNotificationTypesSubscribed(notificationTypesSubscribed)
    : [];

  if (booking && wasBookedByPassenger === false) {
    bookingContact = {
      name: booking?.name,
      phoneNumber: booking?.phoneNumber,
      email: booking?.email,
    };
    if (existingBookingContact?.email === booking?.email) {
      bookingContact.existingContactId = existingBookingContact?.id;
    }
    if (!isNewBooking) {
      bookingContact.customerContactId = booking?.clientId;
      bookingContact.overrideCustomerContactInfo = true;
    }
  }
  if (passenger) {
    passengerContact = {
      name: passenger?.name,
      phoneNumber: passenger?.phoneNumber,
      email: passenger?.email,
      notificationSubscriptions,
    };
    if (existingPassengerContact?.email === passenger?.email) {
      passengerContact.existingContactId = existingPassengerContact?.id;
    }
    if (!isNewPassenger) {
      passengerContact.customerContactId = passenger?.clientId;
      passengerContact.overrideCustomerContactInfo = true;
    }
  }
  return {
    bookingContact,
    passengerContact,
  };
};

export const parseToUpdateTripFormValues = (
  tripId: string,
  values: TripFormValues,
  accountId: string,
  submitAction: string,
  distancesAndTime: Array<DistancesAndTime>,
  initialValues: TripFormValues,
): UpdateTrip => {
  const {
    pickupTime,
    luggageCount,
    additionalPassengers,
    preferredVehicleClass,
    waypoints: formattedWaypoints,
    pickupLocation: formattedPickupLocation,
    dropoffLocation: formattedDropoffLocation,
    wasBookedByPassenger,
    isDirected,
    estimatedDurationInMinutesOverride,
    referenceNumber,
  } = values;

  const {
    pickupLocation: initialFormattedPickupLocation,
    dropoffLocation: initialFormattedDropoffLocation,
  } = initialValues;

  const parseEstimatedDurationInMinutes = +convertHoursToMinutes(
    estimatedDurationInMinutesOverride,
  ).toFixed(2);
  const submitDistancesAndTime = cloneDeep(distancesAndTime);

  if (isDirected) {
    submitDistancesAndTime.splice(-2, 1, {
      distance: 0,
      duration: convertMinutesToSeconds(parseEstimatedDurationInMinutes),
    });
  }

  const waypoints = parseFormattedTripWaypoints(formattedWaypoints);
  const pickupLocation =
    formattedPickupLocation &&
    parseFormattedPickupOrDropoffTripWaypoint(
      formattedPickupLocation,
      true,
      initialFormattedPickupLocation,
    );
  const dropoffLocation = isDirected
    ? {
        place: {
          ...pickupLocation.place,
        },
        role: TripWaypointRole.Dropoff,
      }
    : formattedDropoffLocation &&
      parseFormattedPickupOrDropoffTripWaypoint(
        formattedDropoffLocation,
        false,
        initialFormattedDropoffLocation,
      );
  const filteredWaypoints = [];
  const stopWaypoints = waypoints.filter(
    (waypoint) => waypoint && waypoint.role === TripWaypointRole.Stop,
  );
  const garageWaypoints = waypoints.filter(
    (waypoint) => waypoint && waypoint.role === TripWaypointRole.Garage,
  );
  if (garageWaypoints.length > 0) filteredWaypoints.push(garageWaypoints[0]);
  if (formattedPickupLocation?.placeName)
    filteredWaypoints.push(pickupLocation);
  if (stopWaypoints.length > 0) filteredWaypoints.push(...stopWaypoints);
  if (formattedDropoffLocation?.placeName)
    filteredWaypoints.push(dropoffLocation);
  if (garageWaypoints.length > 0) filteredWaypoints.push(garageWaypoints[1]);

  return {
    id: tripId,
    accountId,
    luggageCount: luggageCount ? Number(luggageCount) : 0,
    passengerCount: additionalPassengers ? Number(additionalPassengers) : 0,
    pickupTime,
    waypoints: filteredWaypoints,
    distancesAndTime: submitDistancesAndTime,
    submit: submitAction === 'assign',
    preferredVehicleClassId: preferredVehicleClass.id ?? null,
    wasBookedByPassenger,
    isDirected,
    referenceNumber,
    estimatedDurationInMinutesOverride: parseEstimatedDurationInMinutes || null,
    ...parseToUpdateTripContact(values),
  };
};
