import React from 'react';
import * as Yup from 'yup';
import {
  Grid,
  Box,
  Button,
  makeStyles,
  Theme,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';

export type OverrideTimeInitialValues = {
  newTime: number;
};

interface Props {
  initialValues?: OverrideTimeInitialValues;
  onCancel: () => void;
  onSubmit?: (values: OverrideTimeInitialValues) => void;
}

const OverrideTimeForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        newTime: Yup.number().min(0, 'Minimum values is zero'),
      })}
      onSubmit={onSubmit}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="New Time"
                    name="newTime"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newTime}
                    variant="outlined"
                    type="number"
                    inputProps={{
                      step: '15',
                      'data-testid': 'override_time',
                    }}
                    error={Boolean(touched.newTime && errors.newTime)}
                    helperText={touched.newTime && errors.newTime}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item xs={12} md={6}>
                  <Box display="flex" flex={1} justifyContent="flex-end">
                    <Button
                      data-testid="override_time_cancel_button"
                      variant="outlined"
                      color="primary"
                      onClick={onCancel}
                    >
                      cancel
                    </Button>
                    <Button
                      data-testid="override_time_save_button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      className={classes.submitButton}
                    >
                      {isSubmitting ? 'Loading..' : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default OverrideTimeForm;
