import { VendorProviderRecord, ProviderType } from 'src/.gen/graphql';
import { AccountTypes } from 'src/shared/types/accountTypes';

export const accountTypeUtils = {
  isSavoyaAccount: (providers: VendorProviderRecord[]): boolean => {
    return (providers || []).some(
      (p) => p.providerType === ProviderType.Savoya,
    );
  },
  types: (providers: VendorProviderRecord[]): AccountTypes => {
    return (providers || []).reduce<AccountTypes>(
      (types: AccountTypes, provider) => {
        return {
          SAVOYA:
            types?.SAVOYA || provider.providerType === ProviderType.Savoya,
          CAPITAL_DRIVE:
            types?.CAPITAL_DRIVE ||
            provider.providerType === ProviderType.CapitalDrive,
        };
      },
      {} as AccountTypes,
    );
  },
};
