import React from 'react';
import { RatePolicyType, useGetDefaultRatesQuery } from 'src/.gen/graphql';

interface CandidateAccountRatePolicies {
  minimumBookingHours: number;
  cancellationPolicy: number;
  increment: number;
}

export const useAccountRatePolicyDetails = (
  accountId: string,
): CandidateAccountRatePolicies => {
  const [accountPolicies, setAccountPolicies] =
    React.useState<CandidateAccountRatePolicies | null>(null);

  const { data } = useGetDefaultRatesQuery({
    variables: {
      accountId,
    },
    skip: !accountId,
    fetchPolicy: 'no-cache',
  });

  const extractRateValues = (data): CandidateAccountRatePolicies => {
    const { rates } = data;

    const meteredRateIncrement =
      rates?.searchDefaultRates?.items[0].rates[0].meteredRateIncrement;
    const { thresholdValue: minimumBooking } =
      rates?.searchDefaultRates?.items[0].rates[0].ratePolicies.find(
        ({ type }) => type === RatePolicyType.MinimumCharge,
      );
    const { thresholdValue: cancellationPolicy } =
      rates?.searchDefaultRates?.items[0].rates[0].ratePolicies.find(
        ({ type }) => type === RatePolicyType.Cancellation,
      );
    return {
      increment: meteredRateIncrement,
      minimumBookingHours: minimumBooking,
      cancellationPolicy,
    };
  };

  React.useEffect(() => {
    if (data?.rates) {
      try {
        const rateValues = extractRateValues(data);
        setAccountPolicies(rateValues);
      } catch (error) {
        setAccountPolicies(null);
      }
    }
  }, [data]);

  return accountPolicies;
};
