import React from 'react';
import { useSnackbar } from 'notistack';
import {
  PaymentMethodType,
  useExecuteApplyRefundMutation,
} from 'src/.gen/graphql';
import { Button, useTheme } from '@material-ui/core';
import { splitByCamelCase } from 'src/shared/utils/stringUtils';
import TripRefundForm from './TripRefundForm';
import { RefundPaymentMethod } from '../TripRateBreakdown/TripRefundButton';

type Props = {
  tripId: string;
  paymentMethod: RefundPaymentMethod;
  grandTotal: number;
  onSubmit: () => void;
  onCancel: () => void;
};

const AddTripRefund: React.FC<Props> = ({
  tripId,
  onSubmit,
  onCancel,
  paymentMethod,
  grandTotal,
}) => {
  const [showRefundForm, setShowRefundForm] = React.useState(false);
  const theme = useTheme();
  const [applyRefund, { loading }] = useExecuteApplyRefundMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values, actions) => {
    const { amountRefunded, refundReason, note } = values;
    const refund = {
      tripId,
      amountRefunded: Number(amountRefunded),
      refundReason,
      note,
    };
    try {
      const response = await applyRefund({
        variables: {
          ...refund,
        },
      });

      if (response.data.executeApplyRefund.succeeded) {
        actions.setStatus({ success: true });
        actions.setSubmitting(false);
        enqueueSnackbar(`Refund has been applied!`, {
          variant: 'success',
        });
        onSubmit();
      } else {
        enqueueSnackbar(response.data.executeApplyRefund.errors.pop(), {
          variant: 'error',
        });
      }
    } catch (err) {
      enqueueSnackbar(`Error while applying refund`, {
        variant: 'error',
      });
    } finally {
      actions.setStatus({ success: false });
      actions.setSubmitting(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return showRefundForm ? (
    <TripRefundForm
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      loading={loading}
      initialValues={{
        amountRefunded: grandTotal,
        refundReason: '',
        note: '',
      }}
    />
  ) : (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '1rem',
      }}
    >
      <Button
        variant="outlined"
        style={{
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        }}
        onClick={() => setShowRefundForm(true)}
      >{`Refund - ${
        paymentMethod.type === PaymentMethodType.CreditCard
          ? paymentMethod.description.split('-').pop()
          : splitByCamelCase(paymentMethod.type)
      }`}</Button>
      <Button
        variant="outlined"
        style={{
          color: theme.palette.grey[400],
          borderColor: theme.palette.grey[400],
        }}
        disabled
      >
        Refund and Change Payment
      </Button>
    </div>
  );
};

export default AddTripRefund;
