import React, { FC } from 'react';
import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { grey } from '@material-ui/core/colors';
import StripePaymentMethod from 'src/shared/components/StripePaymentMethod/StripePaymentMethod';
import {
  PaymentMethodType,
  Trip,
  TripStatus,
  useExecuteProcessPaymentMutation,
  useExecuteSetPaymentMethodMutation,
  useGetInvoicePdfLazyQuery,
  InvoiceSummary,
  useExecuteCreateInvoiceDeliveryRequestMutation,
  TripEventType,
  CreditCardProcessor,
  useVerifySquareTokenQuery,
  useStripeVerifyUserMutation,
  TripClientRecord,
  useGetNotificationSubscriptionByTripClientIdLazyQuery,
} from 'src/.gen/graphql';
import OtherPaymentOptions from 'src/shared/components/StripePaymentMethod/OtherPaymentOptions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SquarePaymentMethodForTrip from 'src/shared/components/SquarePaymentMethod/SquarePaymentMethodForTrip';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { ActivatePaymentProcessorButton } from 'src/shared/components/ActivatePaymentProcessorButton';
import { CloudDownload } from '@material-ui/icons';
import { DeepPartial } from 'react-hook-form';
import { getPassengerClient } from 'src/shared/lib/clientHelpers';
import InvoicePDFDialog from './InvoicePDFDialog';
import { tripTimestampsState } from '../atoms/tripTimestampsAtom';
import { InvoiceConfirmation } from './InvoiceConfirmation/InvoiceConfirmation';
import { BILLED_STATUS } from './TripRateBreakdown/TripRateBreakdown';

type SelectPaymentMethodProps = {
  trip: Partial<Trip>;
  tripClients: DeepPartial<TripClientRecord>[];
  returnUrl: string;
  withTitle?: boolean;
  editable?: boolean;
  withBalance?: boolean;
  chargeable?: boolean;
  showInvoice?: boolean;
  closeModal?: () => void;
  invoice: InvoiceSummary;
};

type SelectPaymentMethodWrapperWithModalProps = {
  trip: Partial<Trip>;
  tripClients: DeepPartial<TripClientRecord>[];
  returnUrl: string;
  withTitle?: boolean;
  display: 'inModal';
  withBalance?: boolean;
  chargeable?: boolean;
  showInvoice?: boolean;
  modalBtnTitle?: string;
  closeModal?: () => void;
};

interface SelectPaymentMethodWrapperProps {
  display: 'default';
  trip: Partial<Trip>;
  tripClients: DeepPartial<TripClientRecord>[];
  returnUrl: string;
  withTitle?: boolean;
  editable?: boolean;
  withBalance?: boolean;
  chargeable?: boolean;
  showInvoice?: boolean;
}

const CREDITCARD = PaymentMethodType.CreditCard;

export const CHARGEABLE_STATUS = [
  TripStatus.TripComplete,
  TripStatus.Cancelled,
];

const SelectPaymentMethod: FC<SelectPaymentMethodProps> = ({
  trip,
  tripClients,
  withTitle = false,
  editable = true,
  returnUrl,
  withBalance = false,
  chargeable = false,
  showInvoice = false,
  closeModal,
  invoice,
}) => {
  const launchDarklyFlags = useFlags();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const user = useRecoilValue(userAtom);
  const { summary } = useRecoilValue(tripTimestampsState);
  const [paymentMethodType, setPaymentMethodType] = React.useState(
    trip?.paymentMethodType || PaymentMethodType.Other,
  );
  const [paymentMethodId, setPaymentMethodId] = React.useState(
    trip?.paymentMethodId || '',
  );
  const [paymentMethodSource] = React.useState(
    trip?.paymentMethod?.source || '',
  );
  const [paymentMethodDescription, setPaymentMethodDescription] =
    React.useState(undefined);

  const [methodMenu, setMethodMenu] = React.useState(trip?.paymentMethodType);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPDFDialogOpen, setIsPDFDialogOpen] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [setupInProgress, setInProgress] = React.useState(false);
  const { data: verifySquareToken, loading: verifySquareTokenLoading } =
    useVerifySquareTokenQuery({
      fetchPolicy: 'cache-and-network',
    });
  const passenger = getPassengerClient(tripClients, trip?.id);
  const passengerEmail = passenger?.client?.emails?.length
    ? passenger?.client?.emails[0]?.email
    : null;

  const [
    getTripClientNotificaiton,
    { data: notificationSubcriptions, loading: notificationsLoading },
  ] = useGetNotificationSubscriptionByTripClientIdLazyQuery();

  React.useEffect(() => {
    if (passenger?.id) {
      getTripClientNotificaiton({
        variables: { tripClientId: passenger?.id },
      });
    }
  }, [passenger]);
  const isSquare =
    user?.account?.activeCardProcessor === CreditCardProcessor.Square;
  const squareActive = isSquare && verifySquareToken?.square?.verifySquareToken;
  const squareInactive =
    isSquare && !verifySquareToken?.square?.verifySquareToken;
  const invoiceButtonText =
    trip?.invoice?.invoiceProcessor === CreditCardProcessor.Stripe
      ? 'DOWNLOAD INVOICE'
      : 'VIEW INVOICE';

  const [createInvoiceDeliveryRequest] =
    useExecuteCreateInvoiceDeliveryRequestMutation();

  const sendInvoice = async (trip, email) => {
    const command = {
      tripId: trip.id,
      toEmailAddress: email,
    };
    try {
      const {
        data: { executeCreateInvoiceDeliveryRequest: result },
      } = await createInvoiceDeliveryRequest({
        variables: { command },
      });
      if (!result?.succeeded) {
        enqueueSnackbar(result?.errors[0], {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Your invoice has been sent correctly', {
          variant: 'success',
        });
      }
    } catch (err) {
      enqueueSnackbar(`Error sending invoice ${err?.message}`, {
        variant: 'error',
      });
    }
  };

  React.useEffect(() => {
    if (trip?.id) {
      const clientSecretParam = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret',
      );

      if (clientSecretParam) {
        setMethodMenu(PaymentMethodType.CreditCard);
        setEditing(true);
      }
    }
  }, []);

  React.useEffect(() => {
    if (trip?.id) {
      const paymentSuccess = searchParams.get('payment_succeeded');

      if (
        paymentSuccess &&
        paymentSuccess === 'true' &&
        invoice?.balance <= 0
      ) {
        searchParams.delete('payment_succeeded');
        const queryParams = searchParams.toString();
        setSearchParams(queryParams, { replace: false });
        enqueueSnackbar('Success! Your payment has been processed.', {
          variant: 'success',
          preventDuplicate: false,
        });
      }
    }
  }, []);

  const setCurrentMethod = (
    methodId: string,
    methodType: PaymentMethodType,
    methodDescription: PaymentMethodType,
  ) => {
    setPaymentMethodId(methodId);
    setPaymentMethodType(methodType);
    setPaymentMethodDescription(methodDescription || undefined);
    setMethodMenu(methodType);
  };

  const resetMethod = () => {
    setPaymentMethodType(trip?.paymentMethodType || PaymentMethodType.Other);
    setMethodMenu(trip?.paymentMethodType);
    setPaymentMethodId(trip?.paymentMethodId || undefined);
    setEditing(false);
  };

  const [setPaymentMethod] = useExecuteSetPaymentMethodMutation();

  const savePaymentMethod = async (
    paymentMethodId: string | undefined,
    paymentMethodType: PaymentMethodType,
  ) => {
    const result = await setPaymentMethod({
      variables: {
        tripId: trip.id,
        paymentMethodId,
        paymentMethodType,
      },
    });

    if (result?.data?.executeSetPaymentMethod?.succeeded) {
      setPaymentMethodId(paymentMethodId);
      setPaymentMethodType(paymentMethodType);
      setPaymentMethodDescription(paymentMethodDescription);
      setMethodMenu(paymentMethodType);
      setEditing(false);
      enqueueSnackbar('Success! Your payment method has been saved.', {
        variant: 'success',
        preventDuplicate: true,
      });
    }
  };

  const [
    processPayment,
    { loading: processingPayment, error: errorProcessingPayment },
  ] = useExecuteProcessPaymentMutation();

  const processPaymentMethod = async () => {
    const paymentProcessed = await processPayment({
      variables: {
        tripId: trip.id,
      },
    });
    if (passengerEmail) {
      if (
        notificationSubcriptions.tripNotificationSubscriptions?.byTripClientId
          ?.items?.length
      ) {
        const notification =
          notificationSubcriptions?.tripNotificationSubscriptions?.byTripClientId?.items?.find(
            (notif) => {
              return (
                notif.notificationType.trigger ===
                  TripEventType.InvoiceSubmitted &&
                notif.channels.find((channel) => {
                  return channel.toLocaleLowerCase() === 'email';
                })
              );
            },
          );
        if (notification) {
          await sendInvoice(trip, passengerEmail);
        }
      }
    }

    if (paymentProcessed.data.executeProcessPayment.succeeded) {
      closeModal();
      window.location.assign(`${returnUrl}?payment_succeeded=true`);
      // navigate(`${returnUrl}?payment_succeeded=true`, { replace: true });
    } else {
      enqueueSnackbar(
        paymentProcessed.data.executeProcessPayment.errors?.pop(),
        {
          variant: 'error',
          preventDuplicate: false,
        },
      );
    }
  };

  const [pdf, setPDF] = React.useState(null);
  const [getPDF, { loading: loadingPDF, data }] = useGetInvoicePdfLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const fetchPDF = async () => {
    await getPDF({
      variables: {
        invoiceId: invoice?.id || trip?.quote?.id,
        timeZone,
      },
    });
    setPDF(data?.invoices?.getPDF);
  };

  React.useEffect(() => {
    if (data?.invoices?.getPDF) {
      setPDF(data?.invoices?.getPDF);
      setIsPDFDialogOpen(true);
    }
  }, [data, timeZone]);

  const scrollRef = React.useRef();
  React.useEffect(() => {
    if (trip?.id) {
      const clientSecretParam = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret',
      );
      const paymentSuccess = new URLSearchParams(window.location.search).get(
        'payment_succeeded',
      );

      if (clientSecretParam || paymentSuccess) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (scrollRef.current as any).scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  return (
    <Grid
      container
      spacing={1}
      style={{
        marginTop: '2rem',
        marginBottom: '2rem',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
      innerRef={scrollRef}
    >
      {withTitle ? (
        <Grid container item spacing={1} style={{ marginBottom: '2rem' }}>
          <Grid item xs="auto">
            <CheckCircleIcon style={{ color: grey[600] }} />
          </Grid>
          <Grid item xs>
            <Typography variant="h5" color="textPrimary">
              Payment Method
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      {withBalance ? (
        <Grid
          container
          item
          spacing={1}
          style={{ marginBottom: '2rem' }}
          xs={12}
          justify="space-around"
        >
          <Typography variant="h6" color="textPrimary">
            Final Payment Amount
          </Typography>
          <Typography variant="h6" color="textPrimary">
            {`$ ${summary?.balance || invoice?.balance}`}
          </Typography>
        </Grid>
      ) : null}
      <Grid container item spacing={3} xs={12} style={{ position: 'relative' }}>
        <Grid
          container
          item
          spacing={3}
          xs={12}
          justify="center"
          style={{ margin: 'auto' }}
        >
          {launchDarklyFlags.stripePayment ? (
            <Button
              variant={methodMenu === CREDITCARD ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => {
                setCurrentMethod(undefined, undefined, undefined);
                setMethodMenu(CREDITCARD);
              }}
              style={{
                flex: 1,
                color:
                  methodMenu === CREDITCARD
                    ? theme.palette.textLight.main
                    : theme.palette.primary.main,
                borderRadius: 0,
                fontSize: '0.8rem',
              }}
              disabled={!editing}
            >
              Credit Card
            </Button>
          ) : null}
          <Button
            variant={methodMenu !== CREDITCARD ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setMethodMenu(PaymentMethodType.Other)}
            style={{
              flex: 1,
              color:
                methodMenu !== CREDITCARD
                  ? theme.palette.textLight.main
                  : theme.palette.primary.main,
              borderRadius: 0,
              fontSize: '0.8rem',
            }}
            disabled={!editing}
          >
            Cash, Check, Other
          </Button>
        </Grid>
        <Grid
          container
          item
          spacing={3}
          xs={12}
          justify="center"
          style={{ margin: 'auto' }}
        >
          {trip &&
          !editing &&
          methodMenu === CREDITCARD &&
          paymentMethodId &&
          paymentMethodSource === CreditCardProcessor.Stripe ? (
            <StripePaymentMethod
              trip={{
                ...trip,
                paymentMethodId,
                paymentMethodType,
                paymentMethod: {
                  ...trip?.paymentMethod,
                  description:
                    paymentMethodDescription ||
                    trip?.paymentMethod?.description,
                  source: CreditCardProcessor.Stripe,
                },
              }}
              returnUrl={returnUrl}
              setCurrentMethod={setCurrentMethod}
              editable={editable}
              editing={editing}
              setInProgress={setInProgress}
              setupInProgress={setupInProgress}
            />
          ) : null}
          {trip &&
          !editing &&
          methodMenu === CREDITCARD &&
          paymentMethodId &&
          paymentMethodSource === CreditCardProcessor.Square &&
          squareActive ? (
            <SquarePaymentMethodForTrip
              trip={{
                ...trip,
                paymentMethodId,
                paymentMethodType,
                paymentMethod: {
                  ...trip?.paymentMethod,
                  description:
                    paymentMethodDescription ||
                    trip?.paymentMethod?.description,
                  source: CreditCardProcessor.Square,
                },
              }}
              tripClients={tripClients}
              setCurrentMethod={setCurrentMethod}
              editing={editing}
              setInProgress={setInProgress}
              setupInProgress={setupInProgress}
            />
          ) : null}
          {trip &&
          methodMenu === CREDITCARD &&
          (!user.account.activeCardProcessor || squareInactive) &&
          (editing || !paymentMethodId) ? (
            <ActivatePaymentProcessorButton
              loading={verifySquareTokenLoading}
              type="Generic"
            />
          ) : null}
          {trip &&
          editing &&
          methodMenu === CREDITCARD &&
          user.account.activeCardProcessor === CreditCardProcessor.Stripe ? (
            <StripePaymentMethod
              trip={{
                ...trip,
                paymentMethodId,
                paymentMethodType,
                paymentMethod: {
                  ...trip?.paymentMethod,
                  description:
                    paymentMethodDescription ||
                    trip?.paymentMethod?.description,
                  source: CreditCardProcessor.Stripe,
                },
              }}
              returnUrl={returnUrl}
              setCurrentMethod={setCurrentMethod}
              editable={editable}
              editing={editing}
              setInProgress={setInProgress}
              setupInProgress={setupInProgress}
            />
          ) : null}
          {trip && editing && methodMenu === CREDITCARD && squareActive ? (
            <SquarePaymentMethodForTrip
              trip={{
                ...trip,
                paymentMethodId,
                paymentMethodType,
                paymentMethod: {
                  ...trip?.paymentMethod,
                  description:
                    paymentMethodDescription ||
                    trip?.paymentMethod?.description,
                  source: CreditCardProcessor.Square,
                },
              }}
              tripClients={tripClients}
              setCurrentMethod={setCurrentMethod}
              editing={editing}
              setInProgress={setInProgress}
              setupInProgress={setupInProgress}
            />
          ) : null}
          {trip && methodMenu !== CREDITCARD ? (
            <OtherPaymentOptions
              trip={{
                ...trip,
                paymentMethodId,
                paymentMethodType,
              }}
              setCurrentMethod={setCurrentMethod}
              editing={editing}
            />
          ) : null}
        </Grid>
        {processingPayment ? (
          <Grid
            container
            item
            spacing={1}
            style={{ marginBottom: '2rem' }}
            xs={12}
            justify="center"
          >
            <Typography
              variant="body1"
              color="textPrimary"
              style={{ fontStyle: 'italic' }}
            >
              Submitting payment... Please wait
            </Typography>
          </Grid>
        ) : null}
        {errorProcessingPayment ? (
          <Grid
            container
            item
            spacing={1}
            style={{ marginBottom: '2rem' }}
            xs={12}
            justify="center"
          >
            <Typography
              variant="body1"
              style={{ fontStyle: 'italic', color: theme.palette.error.main }}
            >
              {errorProcessingPayment.message}
            </Typography>
          </Grid>
        ) : null}
        {editing && !setupInProgress ? (
          <Grid
            container
            item
            spacing={3}
            xs={12}
            justify="space-evenly"
            direction="row"
            style={{ margin: 'auto', marginTop: '1rem' }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => resetMethod()} // CLOSE EDITION
              style={{
                color: theme.palette.primary.main,
                borderRadius: 0,
                fontSize: '0.8rem',
                width: '40%',
              }}
              disabled={processingPayment}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                savePaymentMethod(paymentMethodId, paymentMethodType)
              }
              style={{
                color: theme.palette.textLight.main,
                borderRadius: 0,
                fontSize: '0.8rem',
                width: '40%',
              }}
              disabled={processingPayment}
            >
              Save
            </Button>
          </Grid>
        ) : null}
        {editable && !editing ? (
          <Grid
            container
            item
            spacing={3}
            xs={12}
            justify="flex-start"
            direction="row"
            style={{ margin: 'auto', marginTop: '1rem' }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setEditing(true)} // CLOSE MODAL
              style={{
                color: theme.palette.primary.main,
                borderRadius: 0,
                fontSize: '0.8rem',
                width: '40%',
              }}
              disabled={processingPayment}
            >
              Edit Payment
            </Button>
          </Grid>
        ) : null}
        {chargeable &&
        !editing &&
        !notificationsLoading &&
        CHARGEABLE_STATUS.includes(trip.status) ? (
          <Grid
            container
            item
            spacing={3}
            xs={12}
            justify="space-evenly"
            direction="row"
            style={{ margin: 'auto', marginTop: '4rem' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => processPaymentMethod()} // CHARGE THE METHOD SELECTED
              style={{
                color: theme.palette.textLight.main,
                borderRadius: 0,
                fontSize: '0.8rem',
                width: '40%',
              }}
              disabled={processingPayment}
            >
              SUBMIT
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => closeModal()} // CLOSE MODAL
              style={{
                color: theme.palette.primary.main,
                borderRadius: 0,
                fontSize: '0.8rem',
                width: '40%',
              }}
              disabled={processingPayment}
            >
              CANCEL
            </Button>
          </Grid>
        ) : null}
      </Grid>
      {showInvoice &&
      !editing &&
      BILLED_STATUS.includes(trip?.status) &&
      !trip?.invoice?.invoiceProcessor ? (
        <Grid
          container
          item
          spacing={3}
          xs={12}
          justify="space-evenly"
          direction="row"
          style={{ margin: 'auto', marginTop: '4rem' }}
        >
          <InvoicePDFDialog
            trip={trip}
            tripClients={tripClients}
            pdf={pdf}
            loadingPDF={loadingPDF}
            isPDFDialogOpen={isPDFDialogOpen}
            setIsPDFDialogOpen={setIsPDFDialogOpen}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => fetchPDF()} // CHARGE THE METHOD SELECTED
            style={{
              color: theme.palette.primary.main,
              borderRadius: 0,
              fontSize: '0.8rem',
              width: '40%',
            }}
            disabled={loadingPDF}
          >
            VIEW INVOICE
          </Button>
        </Grid>
      ) : null}
      {showInvoice &&
      !editing &&
      trip?.invoice?.externalInvoiceLink &&
      trip?.invoice?.invoiceProcessor &&
      BILLED_STATUS.includes(trip?.status) ? (
        <Grid
          container
          item
          spacing={3}
          xs={12}
          justify="space-evenly"
          direction="row"
          style={{ margin: 'auto', marginTop: '4rem' }}
        >
          <Button
            target="_blank"
            startIcon={
              trip?.invoice?.invoiceProcessor === CreditCardProcessor.Stripe ? (
                <CloudDownload />
              ) : null
            }
            href={trip?.invoice?.externalInvoiceLink}
            variant="outlined"
            color="primary"
            style={{
              color: theme.palette.primary.main,
              borderRadius: 0,
              fontSize: '0.8rem',
              width: '40%',
            }}
          >
            {invoiceButtonText}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

// TODO: Refactor this component into a new one that separate "Charge client" & "Set/Create payment method"
const SelectPaymentMethodWrapper: FC<
  SelectPaymentMethodWrapperProps | SelectPaymentMethodWrapperWithModalProps
> = (props) => {
  const { trip, display, tripClients } = props;
  const user = useRecoilValue(userAtom);
  const inModal = display === 'inModal';
  // eslint-disable-next-line react/destructuring-assignment
  const closeModal = display === 'inModal' ? props.closeModal : undefined;
  // eslint-disable-next-line react/destructuring-assignment
  const modalBtnTitle = display === 'inModal' ? props.modalBtnTitle : undefined;
  const theme = useTheme();
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [invoiceModalOpen, setInvoiceModal] = React.useState(false);
  const { summary: invoice } = useRecoilValue(tripTimestampsState);
  const { data: verifySquareToken, loading: verifySquareTokenLoading } =
    useVerifySquareTokenQuery({
      fetchPolicy: 'no-cache',
    });

  const [
    verifyStripeUser,
    { data: verifiedStripeUser, loading: loadingStripeVerification },
  ] = useStripeVerifyUserMutation({
    fetchPolicy: 'no-cache',
  });
  React.useEffect(() => {
    if (user?.accountId) {
      verifyStripeUser({
        variables: {
          accountId: user.accountId,
        },
      });
    }
  }, []);
  const squareActive =
    user?.account?.activeCardProcessor === CreditCardProcessor.Square &&
    verifySquareToken?.square?.verifySquareToken;
  const isStripe =
    user?.account?.activeCardProcessor === CreditCardProcessor.Stripe;
  const stripeActive =
    isStripe && verifiedStripeUser?.verifyUser?.chargesEnabled;
  const invoiceDialogTitle =
    squareActive || stripeActive
      ? `Confirm ${user?.account?.activeCardProcessor} Invoice`
      : 'Setup Payment Processor';

  React.useEffect(() => {
    if (trip?.id) {
      const clientSecretParam = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret',
      );

      if (clientSecretParam && inModal) {
        setModalOpen(true);
      }
    }
  }, []);

  const closeModalLocally = () => {
    if (closeModal) {
      closeModal();
    }
    setModalOpen(false);
  };

  const closeInvoiceModal = () => {
    setInvoiceModal(false);
  };

  return inModal ? (
    <React.Fragment>
      <SelectPaymentMethod
        {...props}
        editable={false}
        withBalance={false}
        chargeable={false}
        invoice={invoice}
        tripClients={tripClients}
      />
      {invoice && invoice?.balance > 0 && (
        <Grid container spacing={1} justify="center">
          <Grid item justify="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(true)}
              style={{
                flex: 1,
                color: theme.palette.textLight.main,
                borderRadius: 0,
                fontSize: '0.8rem',
                margin: 'auto',
              }}
            >
              {modalBtnTitle || 'Charge Client'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setInvoiceModal(true)}
              style={{
                flex: 1,
                color: theme.palette.textLight.main,
                borderRadius: 0,
                fontSize: '0.8rem',
                margin: 'auto',
              }}
            >
              SEND INVOICE
            </Button>
          </Grid>
        </Grid>
      )}
      <GenericDialog
        openDialog={isModalOpen}
        setCloseDialog={() => setModalOpen(false)}
        dialogTitle="Payment Method"
        maxWidth="sm"
      >
        <SelectPaymentMethod
          {...props}
          editable
          closeModal={closeModalLocally}
          invoice={invoice}
          tripClients={tripClients}
        />
      </GenericDialog>
      <GenericDialog
        openDialog={invoiceModalOpen}
        setCloseDialog={() => setInvoiceModal(false)}
        dialogTitle={invoiceDialogTitle}
        maxWidth="sm"
      >
        <InvoiceConfirmation
          trip={trip}
          tripClients={tripClients}
          closeModal={closeInvoiceModal}
          activeProcesser={squareActive || stripeActive}
          processorType={user?.account?.activeCardProcessor}
          loading={verifySquareTokenLoading || loadingStripeVerification}
        />
      </GenericDialog>
    </React.Fragment>
  ) : (
    <SelectPaymentMethod
      {...props}
      invoice={invoice}
      tripClients={tripClients}
    />
  );
};

export default SelectPaymentMethodWrapper;
