import React, { FC } from 'react';

import GeneralTable from 'src/shared/components/GeneralTable/GeneralTable';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  SvgIcon,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import Dinero from 'dinero.js';
import { SavoyaHourlyRate } from 'src/rates/api/SavoyaRateQueries';

interface HourlyRatesTableProps {
  hourlyRates: Array<SavoyaHourlyRate>;
  onCreate: () => void;
  onEdit?: (transferRate: SavoyaHourlyRate) => void;
  onDelete?: (id: string) => void;
  canEdit: boolean;
}

const useStyles = makeStyles((theme) => ({
  deleteButton: { color: theme.palette.error.main },
}));

const SavoyaStandardHourlyRatesTable: FC<HourlyRatesTableProps> = ({
  hourlyRates,
  onCreate,
  onEdit,
  onDelete,
  canEdit,
}) => {
  const classes = useStyles();

  const TABLE_HEADERS = [
    { label: 'Vehicle Type', id: 'vehicleType.name' },
    {
      label: 'Rate per hour',
      id: 'amount',
      format: (amount) => {
        const formatted = Dinero({
          amount: parseFloat(amount) || 0,
          currency: 'USD',
        }).toFormat();
        return (
          <TextField
            aria-readonly
            value={formatted}
            style={{ width: '5rem' }}
            InputProps={{
              disableUnderline: true,
            }}
          />
        );
      },
    },
    {
      label: 'Minimum Booking Hours',
      id: 'minimum',
      format: (minimum) => {
        return (
          <TextField
            aria-readonly
            value={minimum}
            type="number"
            style={{ width: '5rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <InputLabel>Hours</InputLabel>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        );
      },
    },
    {
      label: 'Cancellation Policy',
      id: 'cancellationHours',
      format: (cancellationHours) => {
        return (
          <TextField
            aria-readonly
            value={cancellationHours}
            type="number"
            style={{ width: '5rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <InputLabel>Hours</InputLabel>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        );
      },
    },
    {
      label: 'Start Date',
      id: 'startDate',
    },
    {
      label: 'End Date',
      id: 'endDate',
      format: (endDate) => {
        return endDate || '--';
      },
    },
  ];

  if (canEdit) {
    TABLE_HEADERS.push({
      label: 'Actions',
      id: 'id',
      format: (id) => {
        return (
          <Grid container direction="row">
            <Tooltip title="Edit Rate">
              <IconButton
                color="primary"
                onClick={() => {
                  const hourlyRate = hourlyRates?.find(
                    (hourlyRate) => hourlyRate?.id === id,
                  );
                  onEdit(hourlyRate);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Rate">
              <IconButton
                className={classes.deleteButton}
                onClick={() => {
                  onDelete(id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      },
    });
  }

  return (
    <Grid container direction="column" spacing={1} xs={12}>
      {canEdit ? (
        <Grid item>
          <Button
            data-testid="add-savoya-hourly-rate-button"
            variant="contained"
            color="primary"
            onClick={onCreate}
            startIcon={
              <SvgIcon fontSize="small">
                <PlusCircleIcon />
              </SvgIcon>
            }
          >
            Hourly Rate
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <GeneralTable
          columns={TABLE_HEADERS}
          rows={hourlyRates?.sort((a, b) => {
            return a?.vehicleType?.name < b?.vehicleType?.name ? -1 : 1;
          })}
          hasPagination={false}
          hasSelection={false}
          clickable={false}
        />
      </Grid>
    </Grid>
  );
};

export default SavoyaStandardHourlyRatesTable;
