import React from 'react';
import {
  TableContainer,
  Button,
  Paper,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
  fade,
} from '@material-ui/core';
import { Trip, TripStatusTimestamp } from 'src/.gen/graphql';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { Skeleton } from '@material-ui/lab';
import {
  getRows,
  parseTripStatusTimestampToTripLogRow,
  parseTripStatusTimestampToTripLogTotalRow,
  TRIP_LOG_COLUMNS,
} from './utils';
import AdjustTimestamp from './AdjustTimestamp';

interface LogProps {
  trip: Partial<Trip>;
}

const TripLog: React.FC<LogProps> = ({ trip }) => {
  const { timestamps = [] } = trip ?? {};
  const classes = useStylesTableTimestamps();

  const [selectedTimeStamp, setTimestamp] =
    React.useState<Partial<TripStatusTimestamp>>();
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const rows = getRows(timestamps);
  const closeDialog = () => {
    setDialogVisible(false);
  };

  const handleAdjustTimestamp = (value: Partial<TripStatusTimestamp>) => {
    setTimestamp(value);
    setDialogVisible(true);
  };

  const renderContentColumn = (
    value: string,
    columnId: string,
    timestamp: Partial<TripStatusTimestamp>,
  ): React.ReactNode => {
    const isActualColumn = columnId === 'actual';
    switch (true) {
      case isActualColumn && !!value:
        return (
          <Button
            className={classes.button}
            variant="text"
            onClick={() => handleAdjustTimestamp(timestamp)}
          >
            {value}
          </Button>
        );

      default:
        return value;
    }
  };

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {TRIP_LOG_COLUMNS.map((column, index) => (
                <TableCell key={index}>
                  {!trip ? <Skeleton width="100%" /> : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {(!trip ? Array.from(new Array(3)) : rows).map(
              (timestamp, index) => {
                if (!timestamp) {
                  return (
                    <TableRow key={index} className={classes.tableRow}>
                      {Array.from(new Array(TRIP_LOG_COLUMNS.length)).map(
                        (_, index) => (
                          <TableCell key={index}>
                            <Skeleton width="100%" />
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  );
                }
                const tripLogColumns =
                  parseTripStatusTimestampToTripLogRow(timestamp);
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    {TRIP_LOG_COLUMNS.map((column) => {
                      const value = tripLogColumns[column.id];
                      return (
                        <TableCell key={column.id}>
                          {renderContentColumn(value, column.id, timestamp)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              },
            )}
            {trip && (
              <TableRow className={classes.tableRow}>
                {Object.values(
                  parseTripStatusTimestampToTripLogTotalRow(timestamps),
                ).map((value, column) => {
                  return <TableCell key={column}>{value}</TableCell>;
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <GenericDialog
        openDialog={dialogVisible}
        setCloseDialog={closeDialog}
        dialogTitle={`Adjust ${
          selectedTimeStamp
            ? parseTripStatusTimestampToTripLogRow(selectedTimeStamp).status
            : 'not defined'
        } Timestamp`}
        maxWidth="sm"
      >
        <AdjustTimestamp
          timestamps={rows}
          timestamp={selectedTimeStamp}
          closeDialog={closeDialog}
        />
      </GenericDialog>
    </Grid>
  );
};

export const useStylesTableTimestamps = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(1),
  },
  button: {
    textTransform: 'lowercase',
    textDecoration: 'underline',
    fontSize: theme.typography.body2.fontSize,
    padding: 0,
  },
  tableHead: {
    '& > .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      color: theme.palette.textDark.main,
      '& > .MuiTableCell-root.MuiTableCell-head:not(:first-child)': {
        textAlign: 'center',
      },
    },
  },
  tableBody: {
    '& > .MuiTableRow-root:last-child': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
    },
  },
  tableRow: {
    '& > .MuiTableCell-root.MuiTableCell-body:not(:first-child)': {
      textAlign: 'center',
    },
    '& > .MuiTableCell-root.MuiTableCell-body': {
      padding: `${theme.spacing(1)}px !important`,
    },
  },
}));

export default TripLog;
