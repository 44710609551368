import React, { FC, useState } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface NotesInputProps {
  onSubmit?: (note: string) => void;
}

const useStyles = makeStyles({
  fullwidthButton: {
    width: '100%',
    height: '40px',
  },
});

const NotesInput: FC<NotesInputProps> = ({ onSubmit }) => {
  const classes = useStyles();
  const [note, setNote] = useState('');

  const handleSubmit = () => {
    if (onSubmit && note) onSubmit(note);
    setNote('');
  };

  return (
    <Grid container spacing={1} direction="row" data-testid="trip_notes_form">
      <Grid item xs={12} md={9}>
        <TextField
          data-testid="trip_note_field"
          key="note"
          size="small"
          value={note}
          fullWidth
          name="note"
          variant="outlined"
          type="text"
          label="Add Note to Trip Activity"
          onChange={(event) => {
            setNote(event.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter' && note) {
              handleSubmit();
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          data-testid="add_trip_note_button"
          className={classes.fullwidthButton}
          disabled={!note}
          onClick={handleSubmit}
          variant="contained"
          size="large"
        >
          Add Note
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotesInput;
