import React, { FC, useState } from 'react';
import { makeStyles, IconButton, Tooltip, useTheme } from '@material-ui/core';
import _snakeCase from 'lodash/snakeCase';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import {
  prepareEditInitialValues,
  TableRate,
} from 'src/rates/hooks/useTransferRates';
import {
  ClientsByRateResponse,
  CompaniesByRateResponse,
  RateSet,
} from 'src/.gen/graphql';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { DeepPartial } from 'react-hook-form';
import { EditionInitialValuesInterface } from '../NewTransferRateFormulary/NewTransferRateFormulary';
import ConfirmationDialog from '../../../../shared/components/ConfirmationDialog/ConfirmationDialog';
import TransferRatesSummary from './TransferRatesSummary';

const useStyles = makeStyles(() => ({
  MainTableContainer: {},
}));

interface TransferRatesTableProps {
  transferRates: Array<TableRate>;
  transferRateClients: DeepPartial<ClientsByRateResponse>[];
  transferRateCompanies: DeepPartial<CompaniesByRateResponse>[];
  onCreate: () => void;
  onEdit?: (transferRate: EditionInitialValuesInterface) => void;
  onDelete?: (id: string) => void;
}

const TransferRatesTable: FC<TransferRatesTableProps> = ({
  transferRates,
  transferRateClients,
  transferRateCompanies,
  onCreate,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const { sm } = useBreakpoints();
  const theme = useTheme();

  const TABLE_HEADERS: Column<TableRate>[] = sm
    ? [
        { title: 'Point A', field: 'pointA', align: 'center' },
        { title: 'Point B', field: 'pointB', align: 'center' },
      ]
    : [
        { title: 'Point A', field: 'pointA', align: 'center' },
        { title: 'Miles Radius A', field: 'milesRadiusA', align: 'center' },
        { title: 'Point B', field: 'pointB', align: 'center' },
        {
          title: 'Miles Radius B',
          field: 'milesRadiusB',
          align: 'center',
        },
        {
          title: 'Vehicle Type',
          field: 'vehicleType',
          headerStyle: { flex: 1 },
          cellStyle: { padding: '4px' },
          render: (rowData) => <TransferRatesSummary tableRate={rowData} />,
        },
        { title: 'Clients', field: 'clients', align: 'center' },
        { title: 'Companies', field: 'companies', align: 'center' },
      ];

  const [confirmationDialogState, setConfirmationDialogState] =
    useState<boolean>(false);
  const [elementToDelete, setElementToDelete] = useState(null);

  const handleDelete = () => {
    if (Array.isArray(elementToDelete)) {
      if (elementToDelete.length) {
        onDelete(elementToDelete[0].id);
      }
    } else {
      onDelete(elementToDelete.id);
    }
  };

  const renderIcon = (key, props) => {
    switch (key) {
      case 'add':
        return <AddIcon {...props} />;
      case 'edit':
        return <EditIcon {...props} />;
      case 'delete':
        return <DeleteIcon {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.MainTableContainer}>
      <ConfirmationDialog
        open={confirmationDialogState}
        title="Are you sure you want to delete this Transfer Rate?"
        setOpen={setConfirmationDialogState}
        onConfirm={handleDelete}
        warning
      />
      <MaterialTable
        title="Transfer Rates"
        columns={TABLE_HEADERS as Column<TableRate>[]}
        data={transferRates.length ? transferRates : []}
        components={{
          Container: (props) => <div {...props} elevation={0} />,
          Action: (props) => {
            const { action, data } = props;
            return (
              <Tooltip title={action?.tooltip}>
                <IconButton
                  data-testid={`list_item_${
                    !action.isFreeAction ? data?.tableData?.id : 'free'
                  }_${_snakeCase(action?.tooltip)}_button`}
                  onClick={(event) => action?.onClick(event, data)}
                >
                  {renderIcon(action?.icon, action?.iconProps)}
                </IconButton>
              </Tooltip>
            );
          },
        }}
        options={{
          paging: true,
          pageSize: 5,
          pageSizeOptions: [5, 10, 15, 20, 25, 30],
          search: false,
          actionsColumnIndex: -1,
          toolbarButtonAlignment: 'left',
          rowStyle: {
            fontSize: sm ? '0.7rem' : '0.8rem',
          },
          headerStyle: {
            fontSize: sm ? '0.7rem' : '1rem',
          },
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add rate',
            iconProps: {
              color: 'primary',
            },
            onClick: () => {
              onCreate();
            },
            isFreeAction: true,
          },
          {
            icon: 'edit',
            tooltip: 'Edit rate',
            iconProps: {
              color: 'primary',
            },
            onClick: (_event, rowElement) => {
              const data = rowElement as Pick<TableRate, 'originalObject'>;
              const elementToUpdate = prepareEditInitialValues(
                data?.originalObject as RateSet,
                transferRateClients,
                transferRateCompanies,
              );
              onEdit(elementToUpdate);
            },
          },
          {
            icon: 'delete',
            tooltip: 'Delete rate',
            iconProps: {
              style: { color: theme.palette.error.main },
            },
            onClick: (event, rowElement) => {
              setElementToDelete(rowElement);
              setConfirmationDialogState(true);
            },
          },
        ]}
      />
    </div>
  );
};

export default TransferRatesTable;
