import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { RestrictedBackdrop } from 'src/shared/components/Permission';
import {
  TrialPeriodBanner,
  PaymentIssueBanner,
} from 'src/subscription/components';
import PaidFeatureModal from 'src/subscription/components/PaidFeatureModal';
import { TierType } from 'src/.gen/graphql';
import NavBar from './NavBar';
import TopBar from './TopBar/TopBar';

interface DashboardLayoutProps {
  children?: ReactNode;
  nonAuthorizeScreen?: boolean;
  hideNavbar?: boolean;
  theme?: 'light' | 'white' | 'dark' | 'lightDark';
  minimumTier?: TierType;
  showSavoyaLogo?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  lightThemeRoot: {
    backgroundColor: theme.palette.backgroundColor.light,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
  whiteThemeRoot: {
    backgroundColor: '#fff',
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
  darkThemeRoot: {
    backgroundColor: theme.palette.backgroundColor.dark,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
  lightDarkThemeRoot: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  wrapperWide: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({
  children,
  nonAuthorizeScreen,
  hideNavbar,
  theme = 'light',
  minimumTier,
  showSavoyaLogo,
}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const themeClass = {
    light: classes.lightThemeRoot,
    white: classes.whiteThemeRoot,
    dark: classes.darkThemeRoot,
    lightDark: classes.lightDarkThemeRoot,
  };

  return (
    /* key added to improve render performance */
    <div key="drawerLayout" className={themeClass[theme]}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} showSavoyaLogo />
      {hideNavbar ? null : (
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          nonAuthorizeScreen={nonAuthorizeScreen}
          showSavoyaLogo={showSavoyaLogo}
        />
      )}

      <div className={hideNavbar ? classes.wrapperWide : classes.wrapper}>
        <div className={classes.contentContainer}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <PaymentIssueBanner />
            </Grid>
            <Grid item xs={12}>
              <TrialPeriodBanner />
            </Grid>
          </Grid>

          <PaidFeatureModal />
          <div className={classes.content}>
            <RestrictedBackdrop minimumTier={minimumTier}>
              {children}
            </RestrictedBackdrop>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
