import { useMediaQuery, useTheme } from '@material-ui/core';

export interface Breakpoints {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

const useBreakpoints = (): Breakpoints => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    xs,
    sm,
    md,
    lg,
    xl,
  };
};

export default useBreakpoints;
