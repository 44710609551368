import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  subscriptionPlanAtom,
  useLogout,
  userAtom,
} from 'src/authentication/atoms/AuthState';
import { useRecoilValue } from 'recoil';
import { useGetSignedUrlsQuery, UserRole } from 'src/.gen/graphql';
import { Skeleton } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const Account: FC = () => {
  const user = useRecoilValue(userAtom);
  const subscription = useRecoilValue(subscriptionPlanAtom);
  const classes = useStyles();
  const navigate = useNavigate();
  const ref = useRef<Element>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const { subscriptions: subscriptionsEnabled } = useFlags();

  const logout = useLogout();

  const { data: signedProfileUrl, loading } = useGetSignedUrlsQuery({
    variables: {
      bucket: 'cd-dropzone-files',
      key: `contacts/users/${user?.id}/profileImage`,
    },
    skip: isEmpty(user),
  });

  const { url = '' } =
    signedProfileUrl?.media?.filesFromPath?.signedUrls[0] ?? {};

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={ref}
        data-testid="top_bar_profile_button"
      >
        {loading ? (
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        ) : (
          <Avatar
            alt="User"
            className={classes.avatar}
            src={url}
            data-testid="top_bar_profile_avatar"
          />
        )}
        <Hidden smDown>
          <Typography
            variant="subtitle2"
            color="inherit"
            data-testid="top_bar_profile_name"
          >
            {user?.name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        data-testid="top_bar_profile_dropdown"
      >
        {(user?.accountId && (!!subscription || !subscriptionsEnabled)) ||
        user?.userRole === UserRole.CapitalDrive ? (
          <MenuItem
            data-testid="top_bar_profile_dropdown_profile_button"
            onClick={() => {
              navigate(`/users/profile/${user?.id}`);
              handleClose();
            }}
          >
            User Profile
          </MenuItem>
        ) : null}
        <MenuItem
          data-testid="top_bar_profile_dropdown_logout_button"
          onClick={logout}
        >
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default Account;
