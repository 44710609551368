import { makeStyles } from '@material-ui/core';

export const useTransferRateFormStyles = makeStyles((theme) => ({
  inputStyles: {
    border: 'solid 1px black',
    borderRadius: '5px',
    height: '30px',
    width: '30px',
    textAlign: 'center',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:focus': {
      outline: 'none !important',
      border: 'none',
      boxShadow: 'none',
    },
  },
  inputStylesError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  inputErrorMessage: {
    marginLeft: '1rem',
    display: 'block',
  },
  textLineInput: {
    margin: '10px',
  },
  smallTextLineInput: {
    margin: '5px 0 10px 0',
    fontSize: '0.8rem',
  },
  button: {
    margin: '10px',
    width: '100%',
    color: 'white',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formTitle: {
    margin: '0 10px',
  },
  divider: {
    margin: '10px',
  },
}));
