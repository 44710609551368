import { SubmitHandler, useForm } from 'react-hook-form';
import React, { FC } from 'react';
import {
  RatePolicy,
  useUpdateDefaultRatesMutation,
  useUpdateMeteredRateIncrementMutation,
} from 'src/.gen/graphql';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import TextLineInput from '../../TextLineInput/TextLineInput';
import { useRatesStyles } from '../../RatesDashboard';

interface Inputs {
  hours: number;
  minutes: number;
}

interface MinimumBookingTimeFormProps {
  policy: Partial<RatePolicy>;
  currentMeteredRateIncrement: number;
  rateSetId: string;
}

const MinimumBookingTimeForm: FC<MinimumBookingTimeFormProps> = ({
  policy,
  rateSetId,
  currentMeteredRateIncrement,
}) => {
  const classes = useRatesStyles();
  const { sm } = useBreakpoints();

  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
      minutes: 0,
      hours: 0,
    },
  });

  const { thresholdValue } = policy || {};

  const [updateDefaultRate] = useUpdateDefaultRatesMutation();
  const [updateMeteredRate] = useUpdateMeteredRateIncrementMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!policy) {
      return;
    }
    const { id } = policy;
    await updateDefaultRate({
      variables: {
        id,
        thresholdValue: Number(data.hours),
      },
    });
    await updateMeteredRate({
      variables: {
        meteredRateIncrement: Number(data.minutes),
        rateId: rateSetId,
      },
      refetchQueries: ['getDefaultRates'],
    });
  };

  const hours = register('hours');
  const minutes = register('minutes');

  React.useEffect(() => {
    if (thresholdValue) {
      setValue('hours', thresholdValue);
    }
  }, [thresholdValue]);
  React.useEffect(() => {
    if (currentMeteredRateIncrement) {
      setValue('minutes', currentMeteredRateIncrement);
    }
  }, [currentMeteredRateIncrement]);

  return (
    <form
      className={sm ? classes.smallFormStyles : classes.formStyles}
      data-testid="minimun_booking_hours_form"
    >
      <TextLineInput
        text="$hours hours with additional time charged in $minutes minutes increments. This value can be configured individually for each vehicle class"
        inputs={[
          {
            component: () => (
              <input
                data-testid="minimum_booking_hours_field"
                className={classes.inputStyles}
                defaultValue={0}
                type="number"
                onChange={hours.onChange}
                ref={hours.ref}
                name={hours.name}
                onBlur={(e) => {
                  hours.onBlur(e);
                  handleSubmit(onSubmit)();
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            ),
            id: '$hours',
          },
          {
            component: () => (
              <input
                data-testid="minimum_booking_minutes_field"
                className={classes.inputStyles}
                defaultValue={0}
                type="number"
                onChange={minutes.onChange}
                ref={minutes.ref}
                name={minutes.name}
                onBlur={(e) => {
                  minutes.onBlur(e);
                  handleSubmit(onSubmit)();
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            ),
            id: '$minutes',
          },
        ]}
      />
    </form>
  );
};

export default MinimumBookingTimeForm;
