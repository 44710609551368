import React from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../authentication/atoms/AuthState';
import { useCurrentGarageQuery } from '../../.gen/graphql';

export const useGarageCoordinates = (): {
  garageCoordinates: [number, number];
} => {
  const user = useRecoilValue(userAtom);
  const { data: garageData, loading: loadingGarage } = useCurrentGarageQuery({
    variables: {
      accountId: user?.accountId,
      driverId: user?.driver?.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [garageCoordinates, setGarageCoordinates] = React.useState<
    [number, number] | null
  >(null);

  React.useEffect(() => {
    if (garageData && !loadingGarage) {
      const { location: garageLocation } = garageData?.garages?.current || {};

      if (garageLocation?.coordinates) {
        setGarageCoordinates(garageLocation?.coordinates?.coordinates);
      }
    }
  }, [garageData, loadingGarage]);

  return { garageCoordinates };
};
