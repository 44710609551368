import { Box, Typography } from '@material-ui/core';
import ReactMapboxGl, { GeoJSONLayer, Marker } from 'react-mapbox-gl';
import React, { FC } from 'react';
import { useIsochroneGeojson } from 'src/rates/hooks/useIsochroneGeojson';
import { RatePolicy, RatePolicyType } from 'src/.gen/graphql';
import { useSnackbar } from 'notistack';
import { getBounds } from 'src/shared/utils/mapUtils';
import { mapBoxConfig } from 'src/config';

const Map = ReactMapboxGl({
  accessToken: mapBoxConfig.token,
});

interface IsochroneMapInterface {
  coordinates: [number, number];
  getPolicy: (policy: RatePolicyType) => Partial<RatePolicy>;
}

const IsochroneMap: FC<IsochroneMapInterface> = ({
  coordinates,
  getPolicy,
}) => {
  const [minutesFromMyAdderss, setMinutesFromMyAddress] = React.useState<
    number | null
  >(null);

  const { geojsonData, isoCoordinates } = useIsochroneGeojson({
    contoursMinutes: minutesFromMyAdderss,
    coordinates,
  });
  const { enqueueSnackbar } = useSnackbar();

  const valuesGarageTime = getPolicy(RatePolicyType.GarageTime);

  React.useEffect(() => {
    if (valuesGarageTime) {
      if (valuesGarageTime?.thresholdValue2) {
        const thMinutes2 = Math.ceil(valuesGarageTime?.thresholdValue2 * 60);
        setMinutesFromMyAddress(thMinutes2 > 60 ? 60 : thMinutes2);
        if (thMinutes2 > 60) {
          enqueueSnackbar(
            "Times over 60 minutes can't be displayed in the map",
            {
              variant: 'warning',
              preventDuplicate: true,
            },
          );
        }
      } else {
        setMinutesFromMyAddress(60);
      }
    }
  }, [valuesGarageTime]);

  return (
    <Box height={400} padding="5px" paddingBottom="15px">
      {Math.ceil(valuesGarageTime?.thresholdValue2 * 60) > 60 && (
        <Typography variant="body1" style={{ color: 'red' }}>
          Times over 60 minutes can not be displayed in the map
        </Typography>
      )}
      {!!geojsonData && (
        <Map
          style="mapbox://styles/mapbox/streets-v11"
          movingMethod="jumpTo"
          center={coordinates}
          fitBounds={getBounds(false, null, isoCoordinates)}
          fitBoundsOptions={{
            padding: { top: 50, bottom: 20, left: 30, right: 30 },
            duration: 0,
          }}
          containerStyle={{
            height: '100%',
            maxHeight: '100vh',
            maxWidth: '100vw',
            width: '100%',
            borderRadius: '5px',
          }}
        >
          <Marker key={0} coordinates={coordinates} anchor="bottom">
            <img
              alt="Marker"
              src="https://icons.iconarchive.com/icons/paomedia/small-n-flat/32/map-marker-icon.png"
            />
          </Marker>
          <GeoJSONLayer
            data={geojsonData}
            fillPaint={{
              'fill-opacity': 0.3,
              'fill-color': 'red',
            }}
          />
        </Map>
      )}
    </Box>
  );
};

export default IsochroneMap;
