import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { savoyaRatesDialogsState } from 'src/rates/atoms/SavoyaRateFormState';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  SavoyaVehicleType,
  updateHourlyCost,
} from 'src/rates/api/SavoyaRateQueries';
import SavoyaHourlyRateBaseForm, {
  SavoyaHourlyFormInitialValues,
} from '../SavoyaHourlyRateBaseForm';

interface DialogProps {
  initialValues: SavoyaHourlyFormInitialValues;
  vehicleTypes: SavoyaVehicleType[];
}
const EditSavoyaHourlyRateDialog: FC<DialogProps> = ({
  initialValues,
  vehicleTypes,
}) => {
  const [dialogState, setDialogState] = useRecoilState(savoyaRatesDialogsState);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const updateSavoyaHourlyRate = useMutation({
    mutationFn: updateHourlyCost,
    onSuccess: () => {
      queryClient.invalidateQueries(['hourlyRates']);
      enqueueSnackbar('Hourly Rate Updated', { variant: 'success' });
      closeDialog();
    },
    onError: () =>
      enqueueSnackbar('Error Updating Hourly Rate', { variant: 'error' }),
  });
  const closeDialog = () =>
    setDialogState((prev) => ({
      ...prev,
      openEditHourlyRateDialog: false,
    }));

  const onSubmit = async (values) => {
    await updateSavoyaHourlyRate.mutateAsync({
      ...values,
      amount: Math.round(values.amount * 100),
    });
  };

  return (
    <GenericDialog
      dialogTitle="Edit Hourly Rate"
      openDialog={dialogState.openEditHourlyRateDialog}
      setCloseDialog={closeDialog}
      maxWidth="sm"
    >
      <SavoyaHourlyRateBaseForm
        action="edit"
        onSubmit={onSubmit}
        onCancel={closeDialog}
        initialValues={initialValues}
        vehicleTypes={vehicleTypes}
      />
    </GenericDialog>
  );
};

export default EditSavoyaHourlyRateDialog;
