import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './nprogress/nprogress.css';
import './shared/utils/yup-phone';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'src/serviceWorker';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import App from 'src/App';
import theme from 'src/shared/theme';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@material-ui/styles';
import CacheBuster from 'react-cache-buster';
import { Button, SnackbarOrigin } from '@material-ui/core';
import { version } from '../package.json';
import LoadingScreen from './shared/components/LoadingScreen';

global.appVersion = version;

Sentry.init({
  dsn: 'https://40c233e9c1f34e009a0305c2791822ec@o538498.ingest.sentry.io/5656642',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  integrations: [new Sentry.BrowserTracing() as any],
  tracesSampleRate: 0.2,
});

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      if (
        // eslint-disable-next-line no-alert
        window.confirm('New version available! refresh to update your app?')
      ) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  },
};

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (notistackRef.current as any).closeSnackbar(key);
};

const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

ReactDOM.render(
  <RecoilRoot>
    <CacheBuster
      currentVersion={version}
      isEnabled
      isVerboseMode={false}
      loadingComponent={<LoadingScreen />}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          dense
          maxSnack={3}
          ref={notistackRef}
          anchorOrigin={anchorOrigin}
          action={(key) => (
            <Button
              onClick={onClickDismiss(key)}
              style={{ color: theme.palette.textLight.main }}
            >
              Dismiss
            </Button>
          )}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </CacheBuster>
  </RecoilRoot>,
  document.getElementById('root'),
);

serviceWorker.register(configuration);
