import React, { useEffect, useState } from 'react';
import { title } from 'case';
import { useRecoilState } from 'recoil';
import {
  Grid,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  makeStyles,
  fade,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Field, useFormikContext } from 'formik';
import type {
  SavoyaVendorAssignment,
  SavoyaReservationTravelTime,
} from 'src/trips/types/SavoyaTripsTypes';
import { calculateReportedTripTime } from 'src/trips/components/TripRateBreakdown/savoyaTripRateCalculationsUtils';
import { savoyaTripCloseoutState } from 'src/trips/atoms/savoyaChargesAtom';
import type { Camelize } from 'src/shared/types/camelize';
import {
  CloseoutFromValues,
  SavoyaCostType,
} from 'src/trips/components/SavoyaPaymentDetails';
import { timeUtils } from 'src/shared/utils/timeUtils';
import getSymbolFromCurrency from 'currency-symbol-map';
import { SavoyaRateValueField } from './components/SavoyaRateValueField';
import type { RateItem } from './types';

type SavoyaRatesBreakdownProps = {
  vendorAssignment: Camelize<SavoyaVendorAssignment>;
  travelTimeRules: Camelize<SavoyaReservationTravelTime>;
  loading?: boolean;
  editable?: boolean;
};

const SavoyaRatesBreakdown: React.FC<SavoyaRatesBreakdownProps> = ({
  vendorAssignment,
  travelTimeRules,
  loading,
  editable,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext<CloseoutFromValues>();
  const { currencyCode = 'USD' } = vendorAssignment || {};
  const needsHourlyCost = values?.costType === SavoyaCostType.Hourly;
  const needsTransferCost = values?.costType === SavoyaCostType.Transfer;

  const rateUnit =
    values?.costType === SavoyaCostType.Hourly ? 'Hour' : undefined;
  const [subtotalAmount, setSubtotalAmount] = useState(0.0);
  const [savoyaTripCloseout, setSavoyaTripCloseoutValues] = useRecoilState(
    savoyaTripCloseoutState,
  );

  const [reportedTime, setReportedTime] = useState<number>(
    timeUtils.durationBetween(
      values?.outDateAdjustment,
      values?.inDateAdjustment,
    ),
  );

  React.useEffect(() => {
    const hours = timeUtils.durationBetween(
      values?.outDateAdjustment,
      values?.inDateAdjustment,
    );

    setReportedTime(hours);
  }, [values?.outDateAdjustment, values?.inDateAdjustment]);

  const { baseRate, minimumHours, costType } = values;

  useEffect(() => {
    const baseCost = values?.baseRate || 0;
    const costType = values?.costType || SavoyaCostType.Transfer;
    let subtotal = baseCost;
    if (costType === SavoyaCostType.Hourly) {
      const billed = Math.max(values?.minimumHours || 0, reportedTime || 0);
      subtotal = billed && billed > 0 ? baseCost * billed : baseCost;
    }

    subtotal += savoyaTripCloseout?.waitTimeCost || 0;
    setSubtotalAmount(subtotal);
    setSavoyaTripCloseoutValues((oldValues) => ({
      ...oldValues,
      subtotalAmount: subtotal,
    }));
  }, [vendorAssignment, reportedTime, savoyaTripCloseout?.waitTimeCost]);

  useEffect(() => {
    const { reservation: savoyaReservation } = vendorAssignment || {};
    setReportedTime(
      calculateReportedTripTime(
        values,
        travelTimeRules,
        savoyaReservation?.pickupDate,
      ),
    );
  }, [vendorAssignment, travelTimeRules]);

  useEffect(() => {
    const costType = values?.costType || SavoyaCostType.Transfer;
    let subtotal = baseRate;
    if (costType === SavoyaCostType.Hourly) {
      const cost = Number.isNaN(baseRate) ? 0 : baseRate;
      const minHours =
        Number.isNaN(minimumHours) || minimumHours === 0 ? 1 : minimumHours;
      const billed = Math.max(minHours || 0, reportedTime || 0);
      subtotal = cost * billed;
    }
    subtotal += savoyaTripCloseout?.waitTimeCost || 0;
    setSubtotalAmount(subtotal);
    setSavoyaTripCloseoutValues((oldValues) => ({
      ...oldValues,
      subtotalAmount: subtotal,
    }));
  }, [
    minimumHours,
    baseRate,
    reportedTime,
    costType,
    savoyaTripCloseout?.waitTimeCost,
  ]);

  const rateRows: RateItem[] = [
    {
      key: 'type',
      name: 'Type',
      value: costType ? title(costType) : '',
      show: true,
      highlight: false,
      editable: true,
    },
    {
      key: 'baseRate',
      name: 'Base Rate',
      value: (baseRate || 0).toFixed(2),
      show: true,
      highlight: false,
      editable: true,
    },
    {
      key: 'minimumHours',
      name: 'Minimum Hours',
      value: (minimumHours || 0).toFixed(2),
      show: needsHourlyCost,
      highlight: false,
      editable: true,
    },
    {
      key: 'reportedHours',
      name: 'Reported Hours',
      value: (reportedTime || 0).toFixed(2),
      show: values?.costType !== SavoyaCostType.Transfer,
      highlight: false,
      editable: true,
    },
    {
      key: 'waitTimeAmount',
      name: 'Wait Time Amount',
      value: (savoyaTripCloseout?.waitTimeCost || 0).toFixed(2),
      show: !!savoyaTripCloseout?.waitTimeCost,
      highlight: false,
      editable: false,
    },
  ];

  const subtotal = {
    label: 'Subtotal',
    value: (subtotalAmount || 0).toFixed(2),
  };

  return (
    <Grid item className={classes.grid} xs={12} md={5}>
      <Box className={classes.container}>
        <Box className={classes.head} display="flex" flexDirection="row">
          <Typography variant="subtitle2" color="initial">
            Charges
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" padding="10px">
          {loading ? (
            <Skeleton height={200} />
          ) : (
            rateRows.map((item: RateItem, index: number) => {
              if (!item.show) {
                return null;
              }
              return (
                <Box
                  key={`${item.name}-${index}`}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop={index === 0 ? '0px' : '14px'}
                  className={classes.row}
                >
                  <Typography variant="body1" className={classes.text}>
                    {item.name}
                  </Typography>
                  {item.name === 'Type' &&
                  (needsHourlyCost || needsTransferCost) ? (
                    <Field id="costType" name="costType">
                      {({ field, form }) => (
                        <RadioGroup
                          {...field}
                          row
                          name="costType"
                          checked={values?.costType === SavoyaCostType.Transfer}
                          value={field.value || 'transfer'}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                        >
                          <FormControlLabel
                            disabled={
                              !editable &&
                              values?.costType !== SavoyaCostType.Transfer
                            }
                            value="transfer"
                            control={<Radio color="primary" size="small" />}
                            label={
                              <Typography
                                className={classes.rateTypeRadioButtonText}
                              >
                                Transfer
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="hourly"
                            disabled={
                              !editable &&
                              values?.costType !== SavoyaCostType.Hourly
                            }
                            checked={values?.costType === SavoyaCostType.Hourly}
                            control={<Radio color="primary" size="small" />}
                            className={classes.rateTypeRadioButtonLabel}
                            label={
                              <Typography
                                className={classes.rateTypeRadioButtonText}
                              >
                                Hourly
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      )}
                    </Field>
                  ) : (
                    <SavoyaRateValueField
                      item={item}
                      needsHourlyCost={needsHourlyCost}
                      needsTransferCost={needsTransferCost}
                      baseCost={baseRate}
                      minimumHours={minimumHours}
                      currencyCode={currencyCode}
                      rateUnit={rateUnit}
                      editable={editable && item.editable}
                    />
                  )}
                </Box>
              );
            })
          )}
        </Box>

        <Box className={classes.footer}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            {loading ? (
              <Skeleton width="100%" height={30} />
            ) : (
              <React.Fragment>
                <Typography variant="subtitle2" className={classes.text}>
                  {subtotal?.label}
                </Typography>
                <Typography variant="subtitle2" className={classes.text}>
                  {subtotal?.value
                    ? `${getSymbolFromCurrency(currencyCode)} ${
                        subtotal?.value
                      }`
                    : '-'}
                </Typography>{' '}
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: '10px',
    minHeight: '330px',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '15px',
    minHeight: '330px',
    justifyContent: 'space-between',
  },
  row: {
    padding: theme.spacing(1),
    height: 40,
  },
  head: {
    backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
    color: theme.palette.textDark.main,
    width: '100%',
    padding: '1rem',
    borderTopLeftRadius: '14px',
    borderTopRightRadius: '14px',
  },
  footer: {
    backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
    color: theme.palette.textDark.main,
    width: '100%',
    padding: '1rem',
    borderBottomLeftRadius: '14px',
    borderBottomRightRadius: '14px',
    alignItems: 'center',
    marginTop: 'auto',
  },
  rateTypeRadioButtonText: {
    fontSize: 14,
  },
  rateTypeRadioButtonLabel: {
    marginRight: 0,
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export default SavoyaRatesBreakdown;
