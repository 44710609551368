/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useCallback } from 'react';
import type { HTMLProps, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import track from 'src/shared/utils/analytics';
import { makeStyles, Theme } from '@material-ui/core';

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', ...rest }, ref) => {
    const location = useLocation();
    const classes = useStyles();

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        pagePath: location.pathname,
      });
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <div ref={ref} {...rest} className={classes.pageContainer}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    height: '100%',
    overflowY: 'auto',
  },
}));

export default Page;
