import {
  Card,
  Typography,
  Grid,
  CardContent,
  Box,
  CardActions,
  Button,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  ClientRole,
  SavoyaSharedTripStatus,
  SharedTrip,
  SharedTripStatus,
  Trip,
  TripClientRecord,
  TripCompletionStatus,
  TripProviderSource,
  TripStatus,
  useGetTripByIdLazyQuery,
  useMakeTripConfirmedMutation,
  useMutateSharedTripStatusMutation,
} from 'src/.gen/graphql';
import clsx from 'clsx';
import { green, grey, red } from '@material-ui/core/colors';
import { getStatusLabel } from 'src/shared/utils/tripUtils';
import { getCompletionStatuses } from 'src/shared/utils/tripStatusUtils';
import { DeepPartial } from 'react-hook-form';
import { WarningButtonTheme } from 'src/shared/components/ButtonThemeProviders';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userAtom, vendorAtom } from 'src/authentication/atoms/AuthState';
import {
  getDialogContentFromTripCommand,
  tripCommandDialogState,
} from 'src/trips/atoms/tripCommandDialogAtom';
import { useShowCancelButton } from 'src/trips/hooks/useShowCancelButton';
import { useNavigate } from 'react-router-dom';
import { accountTypeUtils } from 'src/shared/utils/accountTypeUtils';
import TripAssignmentDialog from 'src/tripsNewClients/components/TripAdditionalInfo/TripAssignment/TripAssignmentDialog';
import { rejectableSharedTripStatuses } from 'src/trips/constants/constants';
import { vehicleLabel } from './helpers';
import TripPassengerCardDataItem from './TripPassengerCardDataItem';
import TripPassengerCardHeader from './TripPassengerCardHeader';
import AcceptOrClaimSharedTripBtn from '../AcceptOrClaimSharedTripBtn';

type TripPassengerCardProps =
  | TripPassengerCardFetchProps
  | TripPassengerCardWithTripProps;

interface TripPassengerCardFetchProps {
  type: 'Fetch';
  tripId: string;
  showEditButton?: boolean;
  showViewButton?: boolean;
  paymentButton?: ReactNode;
}

interface TripPassengerCardWithTripProps {
  type: 'WithTrip';
  trip: Partial<Trip>;
  loading?: boolean;
  showEditButton?: boolean;
  showViewButton?: boolean;
  paymentButton?: ReactNode;
  tripClients?: DeepPartial<TripClientRecord>[];
}

const useStyles = makeStyles((theme: Theme) => ({
  tripStatusBox: {
    width: 'fit-content',
    padding: '0.2rem 0.4rem',
    borderRadius: '5px',
    color: green[400],
  },
  tripStatusBoxdraft: {
    color: grey[800],
  },
  tripStatusBoxcancelled: {
    color: red[400],
  },
  tripStatusBoxquoted: {
    color: '#FF9C40',
  },
  tripStatusBoxclosed: {
    color: grey[800],
  },
  dropdownExpenses: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  dropdownDetail: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  dropdownList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  oneLine: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  acceptBtn: {
    backgroundColor: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 4,
    color: theme.palette.primary.light,
  },
}));

const TripPassengerCard: React.FC<TripPassengerCardProps> = (props) => {
  const { showEditButton, showViewButton, paymentButton } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const vendor = useRecoilValue(vendorAtom);
  const setTripCommandDialog = useSetRecoilState(tripCommandDialogState);
  const [fetchTripById, { loading, data }] = useGetTripByIdLazyQuery();
  const [trip, setTrip] = useState<Partial<Trip> | undefined>();
  const [tripClients, setTripClients] = useState<
    DeepPartial<TripClientRecord>[] | undefined
  >();
  const [isOpen, setIsOpen] = React.useState(false);
  const { sharedAvailableCommands = [] } = trip || {};
  const rejectNetworkOfferCommand = sharedAvailableCommands.find(
    (command) =>
      command.name === 'Decline Network Trip Request' ||
      command.name === 'Decline Network Shared Trip',
  );
  const isSavoyaAccount = accountTypeUtils.isSavoyaAccount(
    vendor?.vendorProviders,
  );

  const [updateSharedTripStatus] = useMutateSharedTripStatusMutation();
  const [acceptQuote] = useMakeTripConfirmedMutation();

  const { showCancelButton, cancelCommand } = useShowCancelButton(trip?.id);

  useEffect(() => {
    if (props.type === 'Fetch') {
      fetchTripById({
        variables: { tripId: props.tripId },
      });
    } else {
      setTrip(props.trip);
      setTripClients(props?.tripClients);
    }
  }, [props]);

  useEffect(() => {
    if (data) {
      setTrip(data?.trips?.byId as Trip);
      setTripClients(data?.clientQueries?.getTripClientsByTrip);
    }
  }, [data]);

  const handleShow = () => {
    navigate(`/trips/${trip?.id}`);
  };

  const handleEdit = () => {
    const { pickupTime, id: tripId } = trip;
    const pickupDateObject = new Date(pickupTime);

    switch (trip?.status) {
      case TripStatus.Quoted:
        if (trip) {
          acceptQuote({
            variables: {
              tripId: trip.id,
            },
            refetchQueries: ['GetTripById'],
          }).then(() => {
            navigate(
              `/trips/assign/${tripId}?pickupTime=${pickupDateObject}&travelDate=${pickupTime}`,
            );
          });
        }
        break;
      case TripStatus.AwaitingAssignment:
        navigate(
          `/trips/assign/${tripId}?pickupTime=${pickupDateObject}&travelDate=${pickupTime}`,
        );
        break;
      default:
        navigate(`/trips/edit/${trip?.id}`);
        break;
    }
  };

  const getPrimaryButtonText = () => {
    switch (trip?.status) {
      case TripStatus.Quoted:
        return 'Accept Quote';
      case TripStatus.AwaitingAssignment:
        return 'Assign';
      default:
        return 'Edit';
    }
  };

  const getUpdateSharedTrip = (currentStatus: SharedTripStatus) => {
    switch (currentStatus) {
      case SharedTripStatus.Completed:
        return 'Mark as Billed';
      case SharedTripStatus.Billed:
        return 'Mark as Paid';
      default:
        return 'Update Status';
    }
  };

  const passengerClient = tripClients?.find(
    (tripClient) => tripClient?.role === ClientRole.Passenger,
  );
  const passengerName = passengerClient?.client?.name;
  const passengerEmail = passengerClient?.client?.emails?.length
    ? passengerClient?.client?.emails[0]?.email
    : null;
  const passengerPhoneNumber = passengerClient?.client?.phoneNumbers?.length
    ? passengerClient?.client?.phoneNumbers[0]?.phoneNumber
    : null;
  const passengerCompanyName = passengerClient?.client?.companies?.length
    ? passengerClient?.client?.companies[0]?.name
    : null;

  const bookerClient = tripClients?.find(
    (tripClient) => tripClient?.role === ClientRole.Booker,
  );

  const bookerName = bookerClient?.client?.name;
  const bookerEmail = bookerClient?.client?.emails?.length
    ? bookerClient?.client?.emails[0]?.email
    : null;
  const bookerPhoneNumber = bookerClient?.client?.phoneNumbers?.length
    ? bookerClient?.client?.phoneNumbers[0]?.phoneNumber
    : null;
  const bookerCompanyName = bookerClient?.client?.companies?.length
    ? bookerClient?.client?.companies[0]?.name
    : null;

  const passenger = {
    name: passengerName,
    phoneNumber: passengerPhoneNumber,
    email: passengerEmail,
    companyName: passengerCompanyName,
  };
  const booker = {
    name: bookerName,
    phoneNumber: bookerPhoneNumber,
    email: bookerEmail,
    companyName: bookerCompanyName,
  };

  const acceptedAwaitingAssignment =
    trip?.sharedTrip?.status === SharedTripStatus.Accepted &&
    trip?.status === TripStatus.AwaitingAssignment;

  const sharedWithMe =
    user?.id === trip?.sharedTrip?.contactId ||
    vendor?.id === trip?.sharedTrip?.vendorExternalId;
  const iOwnThisTrip = user?.accountId === trip?.accountId;
  const isShared = trip?.isShared;
  const showBilledAndPaidButton =
    (!loading &&
      trip?.providerSource !== TripProviderSource.Savoya &&
      isShared &&
      !iOwnThisTrip &&
      trip?.sharedTrip?.status === SharedTripStatus.Completed) ||
    trip?.sharedTrip?.status === SharedTripStatus.Billed;
  const sharedTrip = (trip?.sharedTrip || {}) as Partial<SharedTrip>;
  const isSavoya = trip?.providerSource === TripProviderSource.Savoya;

  const isLoading = (): boolean =>
    loading || (props.type === 'WithTrip' && props.loading);

  const showBothStatus = !getCompletionStatuses(
    TripCompletionStatus.CompleteOrClosed,
  ).includes(trip?.status);

  const displaySharedStatus =
    trip?.isShared &&
    trip?.sharedTrip?.savoyaAssignmentStatus !==
      SavoyaSharedTripStatus.Cancelled;

  return (
    <Card data-testid="trip_passenger_card">
      <TripPassengerCardHeader trip={trip} loading={isLoading()} />
      <CardContent data-testid="trip_passenger_card_content">
        <Grid container spacing={2}>
          <TripPassengerCardDataItem
            loading={isLoading()}
            label="Passenger"
            value={
              <Box display="flex" alignItems="baseline" gridGap=".5rem">
                <Typography variant="body2" className={classes.oneLine}>
                  {passenger?.name}
                </Typography>
                <Typography variant="h6" className={classes.oneLine}>
                  +
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  gridGap=".2rem"
                  borderRight="solid black 2px"
                  paddingRight=".4rem"
                >
                  <Typography variant="body2" className={classes.oneLine}>
                    {trip?.passengerCount ?? 0}
                  </Typography>
                  <PersonOutlinedIcon />
                </Box>
                <Box display="flex" alignItems="center" gridGap=".2rem">
                  <Typography variant="body2" className={classes.oneLine}>
                    {trip?.luggageCount ?? 0}
                  </Typography>
                  <WorkOutlineOutlinedIcon />
                </Box>
              </Box>
            }
          />
          <TripPassengerCardDataItem
            loading={isLoading()}
            label="Email"
            value={passenger?.email || booker?.email}
          />
          <TripPassengerCardDataItem
            loading={isLoading()}
            label="Phone Number"
            value={passenger?.phoneNumber || booker?.phoneNumber}
          />
          {passenger?.companyName ? (
            <TripPassengerCardDataItem
              loading={isLoading()}
              label="Company"
              value={passenger?.companyName}
            />
          ) : null}
          <TripPassengerCardDataItem
            loading={isLoading()}
            label="Driver Assigned"
            value={trip ? trip?.driver?.name || 'Unassigned' : ''}
          />
          <TripPassengerCardDataItem
            loading={isLoading()}
            label="Vehicle Assigned"
            value={vehicleLabel(trip)}
          />
          <TripPassengerCardDataItem
            loading={isLoading()}
            label={
              showBothStatus && trip?.isShared
                ? 'Trip Status / Shared Status'
                : 'Trip Status'
            }
            value={
              showBothStatus ? (
                <Typography
                  className={clsx(
                    classes.tripStatusBox,
                    classes[
                      `tripStatusBox${trip?.status?.toLocaleLowerCase()}`
                    ],
                  )}
                  variant="subtitle1"
                >
                  {getStatusLabel(
                    trip?.status,
                    trip?.sharedTrip?.savoyaAssignmentStatus,
                  )}
                  {displaySharedStatus &&
                    ` / ${trip?.sharedTrip?.status ?? ''}`}
                </Typography>
              ) : (
                <Typography
                  className={clsx(
                    classes.tripStatusBox,
                    classes[
                      `tripStatusBox${trip?.status?.toLocaleLowerCase()}`
                    ],
                  )}
                  variant="subtitle1"
                >
                  {getStatusLabel(
                    trip?.status,
                    trip?.sharedTrip?.savoyaAssignmentStatus,
                  )}
                </Typography>
              )
            }
          />
          <TripPassengerCardDataItem
            loading={isLoading()}
            label="Preferred Vehicle Class"
            value={trip?.preferredVehicleClass?.name}
          />
          <Grid xs={12} item container justify="flex-end">
            <Box>
              {trip?.referenceNumber ? (
                <Typography
                  variant="caption"
                  color="textSecondary"
                >{`Reference #: ${trip?.referenceNumber}`}</Typography>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      {!isLoading() ? (
        <CardActions>
          <Grid container direction="row" spacing={1}>
            {paymentButton && <Grid item>{paymentButton}</Grid>}
            {showCancelButton && iOwnThisTrip && (
              <Grid item>
                <WarningButtonTheme>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setTripCommandDialog((tripCommandDialog) => ({
                        ...tripCommandDialog,
                        isOpen: true,
                        tripId: trip?.id,
                        isSavoyaTrip:
                          trip?.providerSource === TripProviderSource.Savoya,
                        savoyaTripId: trip?.providerExternalId,
                        command: cancelCommand,
                        dialogContent: getDialogContentFromTripCommand(
                          cancelCommand,
                          isSavoya,
                        ),
                        succeeded: false,
                      }));
                    }}
                  >
                    Cancel Trip
                  </Button>
                </WarningButtonTheme>
              </Grid>
            )}
            {showViewButton && !isSavoyaAccount && (
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  data-testid="trip_view_button"
                  size="small"
                  onClick={handleShow}
                >
                  View
                </Button>
              </Grid>
            )}
            {showEditButton &&
              ((iOwnThisTrip && !acceptedAwaitingAssignment) ||
                (sharedWithMe && acceptedAwaitingAssignment)) && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    data-testid={`trip_${getPrimaryButtonText().toLocaleLowerCase()}_button`}
                    onClick={handleEdit}
                  >
                    {getPrimaryButtonText()}
                  </Button>
                </Grid>
              )}
            {showBilledAndPaidButton && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    updateSharedTripStatus({
                      variables: { sharedTripId: sharedTrip.id },
                      refetchQueries: [
                        'getSharedTripByOriginalTripId',
                        'getTripById',
                        'searchTrips',
                      ],
                    })
                  }
                >
                  {getUpdateSharedTrip(sharedTrip.status)}
                </Button>
              </Grid>
            )}
            {!iOwnThisTrip && (
              <React.Fragment>
                {trip?.status === TripStatus.PendingNetworkConfirmation ? (
                  <Grid item>
                    <AcceptOrClaimSharedTripBtn trip={trip} />
                  </Grid>
                ) : null}
                {rejectableSharedTripStatuses.includes(trip?.status) ? (
                  <Grid item>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setTripCommandDialog((tripCommandDialog) => ({
                          ...tripCommandDialog,
                          isOpen: true,
                          tripId: trip?.id,
                          command: rejectNetworkOfferCommand,
                          customCallback: () => {
                            navigate(`/`);
                          },
                          dialogContent: getDialogContentFromTripCommand(
                            rejectNetworkOfferCommand,
                            isSavoya,
                          ),
                          succeeded: false,
                        }));
                      }}
                      disabled={!rejectNetworkOfferCommand}
                    >
                      Reject Trip Offer
                    </Button>
                  </Grid>
                ) : null}
              </React.Fragment>
            )}
            {paymentButton && <Grid item>{paymentButton}</Grid>}
            {trip?.id && (
              <TripAssignmentDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                trip={trip as Partial<Trip>}
              />
            )}
          </Grid>
        </CardActions>
      ) : null}
    </Card>
  );
};

export default TripPassengerCard;
