import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import AdditionalWaitTimeForm from './AdditionalWaitTimeForm/AdditionalWaitTimeForm';

interface AdditionalWaitTimeProps {
  revertToDynamicForExcessWaitTimeFlag: boolean;
  rateId: string;
  refreshData: () => void;
}
const AdditionalWaitTime: FC<AdditionalWaitTimeProps> = ({
  revertToDynamicForExcessWaitTimeFlag,
  rateId,
  refreshData,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Additional Wait Time</Typography>
      </Grid>
      <Grid item xs={12}>
        <AdditionalWaitTimeForm
          revertToDynamicForExcessWaitTimeFlag={
            revertToDynamicForExcessWaitTimeFlag
          }
          rateId={rateId}
          refreshData={refreshData}
        />
      </Grid>
    </Grid>
  );
};

export default AdditionalWaitTime;
