import { useContext } from 'react';
import { TierType } from 'src/.gen/graphql';
import PermissionContext from 'src/contexts/PermissionContext';
import { PaidFeatureEntities } from 'src/shared/types/subscription';

interface UsePermissionReturnType {
  tierCompliant: boolean;
  entityLimitCompliant: boolean;
}

const usePermission = (options: {
  tierType: TierType;
  entity?: PaidFeatureEntities | null;
  entityCount?: number;
}): UsePermissionReturnType => {
  const { tierType, entity = null, entityCount = 0 } = options;
  const { isTierCompliant, isLimitCompliant } = useContext(PermissionContext);
  return {
    tierCompliant: isTierCompliant(tierType),
    entityLimitCompliant:
      entity && entityCount
        ? isLimitCompliant(tierType, entity, entityCount)
        : false,
  };
};

export default usePermission;
