import React, { FC } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  CreditCardProcessor,
  PaymentMethodType,
  Trip,
  useGetPaymentMethodByTripQuery,
} from 'src/.gen/graphql';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { useNavigate } from 'react-router-dom';

type RegisteredPaymentOptionsProps = {
  beginSetup: () => void;
  setCurrentMethod: (
    methodId: string,
    methodType: PaymentMethodType,
    methodDescription: string | undefined,
  ) => void;
  trip: Partial<Trip>;
  editing: boolean;
};

const RegisteredSquarePaymentOptions: FC<RegisteredPaymentOptionsProps> = ({
  beginSetup,
  setCurrentMethod,
  trip,
  editing = false,
}) => {
  const user = useRecoilValue(userAtom);
  const navigate = useNavigate();
  const { data: customerPaymentMethods, loading: loadingPaymentMethods } =
    useGetPaymentMethodByTripQuery({
      variables: {
        input: { tripId: trip.id, source: CreditCardProcessor.Square },
      },
    });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    trip.paymentMethodId || undefined,
  );
  const [availablePaymentMethod, setAvailablePaymentMethod] = React.useState(
    [],
  );
  const [methodDescriptions, setDescriptions] = React.useState<
    Record<string, string>
  >({});

  const cardText =
    'Setup your Square account to start processing payments within Capital Drive';
  const buttonText = 'Setup Square';

  React.useEffect(() => {
    if (customerPaymentMethods?.paymentMethods?.byTrip?.items.length > 0) {
      setAvailablePaymentMethod(
        customerPaymentMethods?.paymentMethods?.byTrip?.items,
      );
      setDescriptions(() =>
        customerPaymentMethods?.paymentMethods?.byTrip?.items.reduce(
          (acc, curr) => {
            return { ...acc, [curr.id]: curr.description };
          },
          {},
        ),
      );
      setAvailablePaymentMethod(
        customerPaymentMethods.paymentMethods.byTrip.items,
      );
    }
  }, [customerPaymentMethods]);

  React.useEffect(() => {
    if (!editing) {
      setSelectedPaymentMethod(trip.paymentMethodId || undefined);
    }
  }, [editing]);

  const loading = loadingPaymentMethods;

  return loading || !user?.account?.squareAccessToken ? (
    <Card style={{ padding: '2rem' }}>
      <CardContent>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <React.Fragment>
              <Typography variant="body1">{cardText}</Typography>
              <Button
                onClick={() => navigate('/settings')}
                variant="contained"
                color="primary"
                style={{ marginTop: '2rem', width: '70%', color: 'white' }}
              >
                {buttonText}
              </Button>
            </React.Fragment>
          )}
        </Box>
      </CardContent>
    </Card>
  ) : (
    <React.Fragment>
      <FormControl fullWidth>
        <Select
          value={selectedPaymentMethod}
          onChange={(e) => {
            e.preventDefault();
            const paymentMethodId = e.target.value;
            if (paymentMethodId === 'new') {
              beginSetup();
            } else {
              setCurrentMethod(
                paymentMethodId as string,
                PaymentMethodType.CreditCard,
                methodDescriptions[paymentMethodId as string] || undefined,
              );
              setSelectedPaymentMethod(paymentMethodId);
            }
          }}
          inputProps={{
            name: 'paymentMethod',
          }}
          variant="outlined"
          color="primary"
          disabled={!editing}
        >
          <MenuItem value="new">Add new card</MenuItem>
          {trip?.paymentMethod?.description &&
            trip.paymentMethod.source === CreditCardProcessor.Square && (
              <MenuItem value={trip.paymentMethodId} key={trip.paymentMethodId}>
                <em>{trip?.paymentMethod?.description}</em>
              </MenuItem>
            )}
          {availablePaymentMethod.map(
            (method) =>
              method.id !== trip?.paymentMethodId && (
                <MenuItem value={method.id} key={method.id}>
                  <em>{method.description}</em>
                </MenuItem>
              ),
          )}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default RegisteredSquarePaymentOptions;
