import { sortBy } from 'lodash';
import { differenceInMinutes, format } from 'date-fns';
import { TripStatus, TripStatusTimestamp } from 'src/.gen/graphql';
import { convertMinutesToHours } from 'src/shared/utils/conversions';
import { getTripStatusTimestampLabel } from 'src/shared/utils/tripUtils';

interface TripStatusTimestampRow {
  status: string;
  estimated: string;
  actual: string;
}

interface Column {
  id: keyof TripStatusTimestampRow;
  label: string;
}

export const TRIP_LOG_COLUMNS: Column[] = [
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'estimated',
    label: 'Estimated',
  },
  {
    id: 'actual',
    label: 'Actual',
  },
];

const tripStatusTimestampArray: string[] = [
  'EnRouteToPickup',
  'OnLocation',
  'PassengerOnboard',
  'TransportComplete',
  'TripComplete',
];

export const getRows = (
  timestamps: Partial<TripStatusTimestamp>[],
): Partial<TripStatusTimestamp>[] => {
  return sortBy(timestamps, ({ status }) =>
    tripStatusTimestampArray.indexOf(status),
  );
};

export const parseTripStatusTimestampToTripLogRow = (
  timestamp: Partial<TripStatusTimestamp>,
): TripStatusTimestampRow => {
  return {
    status: getTripStatusTimestampLabel(timestamp.status),
    estimated: formatISODate(timestamp.estimatedTime, 'hh:mm aaa'),
    actual: formatISODate(timestamp.effectiveTime, 'hh:mm aaa'),
  };
};

export const parseTripStatusTimestampToTripLogTotalRow = (
  timestamps: Partial<TripStatusTimestamp>[],
): TripStatusTimestampRow => {
  const startTripStatusTimestamp = timestamps.find(
    ({ status }) => status === TripStatus.EnRouteToPickup,
  );
  const endTripStatusTimeStamp = timestamps.find(
    ({ status }) => status === TripStatus.TripComplete,
  );

  return {
    status: 'Total',
    estimated:
      startTripStatusTimestamp &&
      differenceByHours(
        startTripStatusTimestamp.estimatedTime,
        endTripStatusTimeStamp.estimatedTime,
      ),
    actual:
      startTripStatusTimestamp &&
      differenceByHours(
        startTripStatusTimestamp.effectiveTime,
        endTripStatusTimeStamp.effectiveTime,
      ),
  };
};

export function formatISODate(ISOdate: string, pattern: string): string {
  if (!ISOdate) {
    return '';
  }
  return format(new Date(ISOdate), pattern);
}

export function differenceByHours(
  startISODate: string,
  endISODate: string,
): string {
  if (!startISODate || !endISODate) {
    return '';
  }

  const diffInMin = differenceInMinutes(
    new Date(endISODate),
    new Date(startISODate),
  );

  return `${convertMinutesToHours(diffInMin).toFixed(2)} hours`;
}
