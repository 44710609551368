import React from 'react';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { getDataFromPath } from 'src/shared/utils/utils';
import { parse, format } from 'date-fns';
import { formatTimeString } from 'src/trips/utils/timeSelector';

interface FieldProps {
  setFieldValue: (
    field: string,
    value: number | string,
    shouldValidate?: boolean,
  ) => void;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => void;
  label?: string;
  name: string;
  value?: string;
  variant?: 'standard' | 'outlined';
  optionLabelAccessor?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
}

const TimeSelector: React.FC<FieldProps> = ({
  setFieldValue,
  value,
  label,
  name,
  variant,
  optionLabelAccessor = 'label',
  setFieldTouched,
  size = 'medium',
  disabled = false,
}) => {
  const getValues = () => {
    const values = [];
    for (let i = 0; i < 96; i++) {
      const baseTime = parse('00:00', 'HH:mm', new Date());
      const baseTimeInMs = baseTime.getTime();
      const newTime = new Date(baseTimeInMs + i * 15 * 60 * 1000);
      const newTimeString = format(newTime, 'hh:mm aaaa')
        .replace('a.m.', 'AM')
        .replace('p.m.', 'PM');
      values.push({ label: newTimeString, value: newTimeString });
    }
    return values;
  };

  const getLabelFromValue = (timeValue) => {
    const timeObject = timeValue
      ? parse(timeValue, 'HH:mm', new Date())
      : new Date();
    const newFormat = format(timeObject, 'hh:mm aaaa')
      .replace('a.m.', 'AM')
      .replace('p.m.', 'PM');
    return newFormat;
  };

  const values = getValues();

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Autocomplete
          data-testid="time_autocomplete_field"
          id={`${label}-time-autocomplete`}
          freeSolo
          disabled={disabled}
          options={values}
          onBlur={(event) => {
            const { value: newValue } = event.target as unknown as {
              value: string;
            };
            setFieldValue(name, formatTimeString(newValue, value));
            if (setFieldTouched) {
              setFieldTouched(name, true);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              const { value: newValue } = event.target as unknown as {
                value: string;
              };
              setFieldValue(name, formatTimeString(newValue, value));
              if (setFieldTouched) {
                setFieldTouched(name, true);
              }
            }
          }}
          value={{ label: getLabelFromValue(value), value }}
          getOptionLabel={(option) =>
            getDataFromPath(option, optionLabelAccessor) || ''
          }
          renderInput={(params) => (
            <Field name={name}>
              {({ meta }) => (
                <TextField
                  id={label}
                  disabled={disabled}
                  {...params}
                  label={label}
                  onFocus={(event) => {
                    event.currentTarget.select();
                  }}
                  size={size}
                  variant={variant || 'outlined'}
                  fullWidth
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error ? meta.error : ''}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            </Field>
          )}
        />
      </Grid>
    </React.Fragment>
  );
};

export default TimeSelector;
