import { atom } from 'recoil';

export const tripUpdateClientState = atom<boolean>({
  key: 'tripUpdateClientState',
  default: false,
});

type DuplicatedEmailClientKeyValueType = string | boolean;

export const duplicatedEmailClient = atom<
  Record<string, DuplicatedEmailClientKeyValueType> | undefined
>({
  key: 'duplicatedEmailClient',
  default: undefined,
});

export const tripClientFormIsValid = atom<boolean>({
  key: 'tripClientFormIsValid',
  default: true,
});
