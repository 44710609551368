import React, { FC } from 'react';
import { Tabs, TabsProps } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { TierType } from 'src/.gen/graphql';
import { paidFeatureModal } from 'src/subscription/atoms/SubscriptionState';
import { tabsWithPermissions } from '../Permission/TabsWithPermissions';

export interface TabOption {
  title: string;
  id: number | string;
  minimumTier?: TierType;
  style?: React.CSSProperties;
}
interface CapitalDriveTabBarProps {
  selectedTab: number | string;
  onTabChange: (newTab: number | string) => void;
  tabs: TabOption[];
  customTabLabel?: (tab: TabOption) => React.ReactNode;
  variant?: TabsProps['variant'];
}

const CapitalDriveTabBar: FC<CapitalDriveTabBarProps> = ({
  onTabChange,
  customTabLabel,
  selectedTab,
  tabs,
  variant = 'scrollable',
}) => {
  const [{ open }, setModalState] = useRecoilState(paidFeatureModal);
  return (
    <Tabs
      value={selectedTab}
      variant={variant}
      centered={variant !== 'scrollable'}
      indicatorColor="secondary"
      textColor="secondary"
      scrollButtons="auto"
      onChange={(_, value) => {
        onTabChange(value);
      }}
    >
      {tabsWithPermissions({
        tabs,
        customTabLabel,
        setModalState,
        modelOpenState: open,
      })}
    </Tabs>
  );
};

export default CapitalDriveTabBar;
