import React from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import { Trip } from 'src/.gen/graphql';
import { Skeleton } from '@material-ui/lab';
import { useRecoilValue } from 'recoil';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import {
  getInvoiceTimesColumns,
  getInvoiceTimesRows,
  isTripRateTypeHourly,
  parseInvoiceTimeTotal,
} from './utils';
import OverrideGarageTime from './OverrideGarageTime';
import { useStylesTableTimestamps } from '../TripLog/TripLog';
import OverrideWaitTime from './OverrideWaitTime';

interface InvoiceTimesProps {
  trip: Partial<Trip>;
}

const InvoiceTimes: React.FC<InvoiceTimesProps> = ({ trip }) => {
  const classes = useStylesTableTimestamps();
  const ref = React.useRef(null);
  const { loading, updating, summary } = useRecoilValue(tripTimestampsState);
  const rows = getInvoiceTimesRows(summary);
  const columns = getInvoiceTimesColumns(summary?.calculation);
  const isHourly = isTripRateTypeHourly(summary?.calculation);

  const renderContentColumn = (value, column, row): React.ReactNode => {
    switch (true) {
      case column.id === 'garageTime' && value != null:
        return (
          <OverrideGarageTime
            tripId={trip?.id}
            value={value}
            column={column}
            row={row}
          />
        );
      case column.id === 'waitTime' && value != null:
        return (
          <OverrideWaitTime
            tripId={trip?.id}
            value={value}
            column={column}
            row={row}
          />
        );
      default:
        return value;
    }
  };

  return (
    <Grid item xs={12} ref={ref} style={{ maxWidth: ref.current?.offsetWidth }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {(loading || updating ? Array.from(new Array(4)) : columns).map(
                (column, index) => {
                  if (!column) {
                    return (
                      <TableCell key={index}>
                        <Skeleton width="100%" />
                      </TableCell>
                    );
                  }
                  return <TableCell key={column.id}>{column.label}</TableCell>;
                },
              )}
            </TableRow>
          </TableHead>
          <TableBody className={isHourly ? classes.tableBody : ''}>
            {(loading || updating ? Array.from(new Array(3)) : rows).map(
              (timestamp, index) => {
                if (!timestamp) {
                  return (
                    <TableRow key={index} className={classes.tableRow}>
                      {Array.from(new Array(4)).map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton width="100%" />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                }
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    {columns.map((column) => {
                      const value = timestamp[column.id];
                      return (
                        <TableCell key={column.id}>
                          {renderContentColumn(value, column, timestamp)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              },
            )}
            {!(loading || updating) && isHourly && (
              <TableRow className={classes.tableRow}>
                {Object.values(parseInvoiceTimeTotal(summary)).map(
                  (value, column) => {
                    return <TableCell key={column}>{value}</TableCell>;
                  },
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default InvoiceTimes;
