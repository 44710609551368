import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { format, subHours } from 'date-fns';
import {
  AppliedRateDefinitions,
  GenericVehicleClass,
  Rate,
  RateCalculation,
  RatePolicyType,
  VehicleClass,
} from '../../.gen/graphql';

interface RatePoliciesParagraphPolicies {
  rateData: AppliedRateDefinitions;
  pickupTime: string;
  vehicleClass: GenericVehicleClass;
}

const hourlyParagraph = (
  pickupTime: string | Date,
  canellationHours: number,
  minimumBillableHours: number,
  minuteIncrements: number,
  positioningTime: number,
  minutesAway: number,
) => {
  const pickupDate =
    typeof pickupTime === 'string' ? new Date(pickupTime) : pickupTime;

  const cancellationLimit = format(
    subHours(pickupDate, canellationHours || 0),
    'MM/dd/yyyy hh:mm aaaa',
  );
  return (
    <Typography variant="body1" color="textPrimary">
      Free cancellation until <strong>{cancellationLimit}</strong>. A minimum of{' '}
      <strong>{minimumBillableHours}</strong> billable hours will be applied.
      Additional time will be billed in <strong>{minuteIncrements}</strong>{' '}
      minute increments. There will be <strong>{positioningTime}</strong>{' '}
      minutes of positioning time applied to the pickup and drop off. Trips with
      a pickup or drop off location greater than <strong>{minutesAway}</strong>{' '}
      minutes away will be billed for additional time.
    </Typography>
  );
};

const transferParagraph = (
  pickupTime: Date | string,
  canellationHours: number,
  minuteIncrements: number,
  waitTime: number,
  revertToDynamicForExcessWaitTime: boolean,
) => {
  const pickupDate =
    typeof pickupTime === 'string' ? new Date(pickupTime) : pickupTime;

  const cancellationLimit = format(
    subHours(pickupDate, canellationHours || 0),
    'MM/dd/yyyy hh:mm aaaa',
  );
  return revertToDynamicForExcessWaitTime ? (
    <Typography variant="body1" color="textPrimary">
      Free cancellation until <strong>{cancellationLimit}</strong>. Upon pickup
      time, there will be <strong>{minuteIncrements}</strong> minutes of
      complimentary wait time, wait time in excess of this will result in the
      reservation converting to an <strong>hourly</strong> trip.
    </Typography>
  ) : (
    <Typography variant="body1" color="textPrimary">
      Free cancellation until <strong>{cancellationLimit}</strong>. Upon pickup
      time, there will be <strong>{minuteIncrements}</strong> minutes of
      complimentary wait time, wait time in excess of this will be billed in{' '}
      <strong>{waitTime}</strong> minute increments at an hourly rate.
    </Typography>
  );
};
const RatePoliciesParagraph: React.FC<RatePoliciesParagraphPolicies> = ({
  rateData,
  pickupTime,
  vehicleClass,
}) => {
  const hourly = rateData?.baseRate?.calculation === RateCalculation.PerHour;
  const vehicleClassMinimumHours = vehicleClass?.minimumHours;
  const vehicleClassCancellation = vehicleClass?.cancellationPolicyHours;

  return rateData?.baseRate && rateData?.policies ? (
    <Grid item xs={12}>
      {hourly
        ? hourlyParagraph(
            pickupTime,
            vehicleClassCancellation ??
              rateData.policies.cancellation.thresholdValue,
            vehicleClassMinimumHours ??
              rateData.policies.minimumCharge.thresholdValue,
            rateData.baseRate.meteredRateIncrement ||
              rateData.defaultBillingIncrement,
            rateData.policies.garageTime.thresholdValue * 60,
            rateData.policies.garageTime.thresholdValue2 * 60,
          )
        : transferParagraph(
            pickupTime,
            rateData.policies.cancellation.thresholdValue,
            rateData.policies.waitTime.thresholdValue * 60,
            rateData.baseRate.meteredRateIncrement ||
              rateData.defaultBillingIncrement,
            rateData.baseRate.revertToDynamicForExcessWaitTime,
          )}
    </Grid>
  ) : (
    <React.Fragment />
  );
};

// I had to make another component, it seemed better for me than adding edge cases inside the original component
// Or having to write an adapter function. These two components just get the same result differently
export const RatePoliciesParagraphWithNormalRate: React.FC<
  { pickupTime: Date; preferredVehicleClass: VehicleClass } & Rate
> = ({
  pickupTime,
  calculation,
  ratePolicies,
  meteredRateIncrement,
  meteredBillingStrategy,
  revertToDynamicForExcessWaitTime,
  preferredVehicleClass,
}) => {
  const hourly = calculation === RateCalculation.PerHour;

  const cancelationPolicy = ratePolicies.find(
    (cp) => cp.type === RatePolicyType.Cancellation,
  );
  const minimunChargePolicy = ratePolicies.find(
    (cp) => cp.type === RatePolicyType.MinimumCharge,
  );
  const waitTimePolicy = ratePolicies.find(
    (cp) => cp.type === RatePolicyType.WaitTime,
  );
  const garageTimePolicy = ratePolicies.find(
    (cp) => cp.type === RatePolicyType.GarageTime,
  );
  const vehicleClassCancellation =
    preferredVehicleClass?.cancellationPolicyHours;
  const vehicleClassMinimumHours = preferredVehicleClass?.minimumHours;
  return (meteredRateIncrement || meteredBillingStrategy) && ratePolicies ? (
    <Grid item xs={12}>
      {hourly
        ? hourlyParagraph(
            pickupTime,
            vehicleClassCancellation ?? cancelationPolicy.thresholdValue,
            vehicleClassMinimumHours ?? minimunChargePolicy.thresholdValue,
            meteredRateIncrement,
            garageTimePolicy.thresholdValue * 60,
            garageTimePolicy.thresholdValue2 * 60,
          )
        : transferParagraph(
            pickupTime,
            cancelationPolicy.thresholdValue,
            waitTimePolicy.thresholdValue * 60,
            meteredRateIncrement,
            revertToDynamicForExcessWaitTime,
          )}
    </Grid>
  ) : (
    <React.Fragment />
  );
};

export default RatePoliciesParagraph;
