import React from 'react';
import { useSnackbar } from 'notistack';
import {
  RateCalculation,
  Trip,
  useExecuteApplyFlatRateOverrideMutation,
  useExecuteUseDifferentClassRateMutation,
} from 'src/.gen/graphql';
import { useRecoilState } from 'recoil';
import { estimatedInvoiceState } from 'src/tripsNewClients/atoms/estimatedInvoiceAtom';
import { CircularProgress } from '@material-ui/core';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import UseDifferentRateForm from './UseDifferentRateForm';

type Props = {
  trip: Partial<Trip>;
  onSubmit: () => void;
  onCancel: () => void;
};

const UseDifferentRate: React.FC<Props> = ({ trip, onSubmit, onCancel }) => {
  const [executeUseDifferentClassRate] =
    useExecuteUseDifferentClassRateMutation();
  const [applyFlatRate] = useExecuteApplyFlatRateOverrideMutation();
  const [{ summary: estimatedSummary }] = useRecoilState(estimatedInvoiceState);
  const [{ summary }] = useRecoilState(tripTimestampsState);

  const { enqueueSnackbar } = useSnackbar();
  const tripSummary = estimatedSummary || summary;
  const handleSubmit = async (values, actions) => {
    const { preferredClassId, flatRate, method } = values;
    const isTransferRate =
      !trip?.flatRateOverride &&
      tripSummary?.calculation === RateCalculation.Flat;
    if (!method) {
      enqueueSnackbar('Invalid Input', {
        variant: 'error',
      });
      return;
    }
    try {
      if (method === RateCalculation.Flat && trip?.id) {
        if (flatRate === 0) {
          enqueueSnackbar('Flat Rate must be greater than 0', {
            variant: 'error',
          });
          return;
        }
        if (!flatRate || !flatRate.toString().match(/^(\d*\.{0,1}\d{0,2}$)/)) {
          enqueueSnackbar('Invalid Flat Rate', {
            variant: 'error',
          });
          return;
        }
        try {
          const result = await applyFlatRate({
            variables: { command: { tripId: trip.id, flateRate: flatRate } },
            refetchQueries: ['getEstimatedSummary', 'GetTripById'],
          });
          if (result?.data?.executeApplyFlatRateOverride?.succeeded) {
            actions.setStatus({ success: true });
            enqueueSnackbar(`Flat Rate Applied`, {
              variant: 'success',
            });
            onSubmit();
          } else {
            actions.setStatus({ success: false });
            enqueueSnackbar(
              result?.data?.executeApplyFlatRateOverride?.errors[0],
              {
                variant: 'error',
              },
            );
          }
        } catch (err) {
          actions.setStatus({ success: false });
          enqueueSnackbar(`Error Applying Flat Rate`, {
            variant: 'error',
          });
        } finally {
          actions.setSubmitting(false);
        }
      } else {
        if (!preferredClassId) {
          enqueueSnackbar('Invalid Vehicle Class', {
            variant: 'error',
          });
          return;
        }
        const result = await executeUseDifferentClassRate({
          variables: {
            command: {
              tripId: trip.id,
              rateId: tripSummary.rateId,
              requestedVehicleClassId: preferredClassId,
              isTransfer: isTransferRate,
            },
          },
          refetchQueries: ['getEstimatedSummary', 'GetTripById'],
        });
        if (result.data.executeUseDifferentClassRate.succeeded) {
          actions.setStatus({ success: true });
          enqueueSnackbar(`Rate has been updated`, {
            variant: 'success',
          });
          onSubmit();
        } else {
          actions.setStatus({ success: false });
          enqueueSnackbar(
            result?.data?.executeUseDifferentClassRate?.errors[0],
            {
              variant: 'error',
            },
          );
        }
      }
    } catch (err) {
      actions.setStatus({ success: false });
      enqueueSnackbar(`Error while changing rate`, {
        variant: 'error',
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return tripSummary ? (
    <UseDifferentRateForm
      preferredVehicleClass={{
        id: trip?.preferredVehicleClass?.id,
        name: trip?.preferredVehicleClass?.name,
      }}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  ) : (
    <CircularProgress color="secondary" />
  );
};

export default UseDifferentRate;
