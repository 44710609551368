import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  fade,
  makeStyles,
  styled,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import type { Camelize } from 'src/shared/types/camelize';
import type { SavoyaRateException } from 'src/trips/types/SavoyaTripsTypes';

type SavoyaRateExceptionInfoProps = {
  rateException: Camelize<SavoyaRateException>;
  currencyCode: string;
};

type ColumnData = {
  name: string;
  value: string;
  show: boolean;
};

const SavoyaRateExceptionInfo: React.FC<SavoyaRateExceptionInfoProps> = ({
  rateException,
  currencyCode,
}) => {
  const classes = useStyles();
  const columns = [
    { label: 'Rate Exception', key: 'name' },
    { label: '', key: 'value' },
  ];

  const rateExceptionData: ColumnData[] = [
    {
      name: 'Status',
      value: rateException?.status,
      show: true,
    },
    {
      name: `${
        rateException?.type === 'hourly' ? 'Hourly' : 'Transfer'
      } Base Rate`,
      value: `${getSymbolFromCurrency(currencyCode)} ${(
        (rateException?.baseCost || 0) / 100
      )?.toString()}`,
      show: rateException?.type === 'hourly',
    },
    {
      name: 'Minimum Hours',
      value: rateException?.reportedMinimum?.toString(),
      show: rateException?.type === 'hourly',
    },
    {
      name: 'Reason',
      value: rateException?.note,
      show: true,
    },
    {
      name: 'Requested Total',
      value: `${getSymbolFromCurrency(currencyCode)} ${(
        (rateException?.requestedTotal || 0) / 100
      )?.toString()}`,
      show: true,
    },
  ];

  return (
    <Grid item className={classes.grid}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column, index) => {
                if (!column) {
                  return (
                    <TableCell key={index}>
                      <Skeleton width="100%" />
                    </TableCell>
                  );
                }
                return <TableCell key={index}>{column.label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {rateExceptionData.map((row, index) => {
              if (!row) {
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    {Array.from(new Array(2)).map((_, index) => (
                      <TableCell key={index}>
                        <Skeleton width="100%" />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              }

              return (
                <TableRow key={index} className={classes.tableRow}>
                  {columns.map((column) => {
                    const { name, show } = row;
                    const value = row[column.key];

                    if (!show) {
                      return null;
                    }
                    if (column?.key === 'name') {
                      return (
                        <CustomCell
                          key={`${column.key}-${name}`}
                          style={{ width: '80%' }}
                        >
                          {value}
                        </CustomCell>
                      );
                    }
                    return (
                      <CustomCell
                        key={`${column.key}-${name}`}
                        style={{
                          textAlign: 'right',
                          paddingRight: 25,
                        }}
                      >
                        {value}
                      </CustomCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
    padding: '10px',
  },
  tableHead: {
    '& > .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      color: theme.palette.textDark.main,
      '& > .MuiTableCell-root.MuiTableCell-head:not(:first-child)': {
        textAlign: 'right',
      },
      '& > .MuiTableCell-root.MuiTableCell-head': {
        paddingLeft: theme.spacing(2),
      },
    },
  },
  tableBody: {
    '& > .MuiTableRow-root:last-child': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      alignItems: 'center',
      jsutifyContent: 'center',
    },
  },
  tableRow: {
    '& > .MuiTableCell-root.MuiTableCell-body:not(:first-child)': {
      textAlign: 'right',
    },
    '& > .MuiTableCell-root.MuiTableCell-body': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 45,
      textAlign: 'left',
    },
  },
  text: {
    fontSize: 14,
  },
}));

const CustomCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  backgroundColor: 'transparent',
}));

export default SavoyaRateExceptionInfo;
