import React, { Fragment, ReactNode } from 'react';
import { atom, RecoilState } from 'recoil';
import { TripCommandMeta } from 'src/.gen/graphql';
import ArrivePickupTripCommandDialog from 'src/trips/components/TripCommandDialog/ArrivePickupTripCommandDialog';
import CancelTripCommandDialog from 'src/trips/components/TripCommandDialog/CancelTripCommandDialog';
import ConfirmTripCommandDialog from 'src/trips/components/TripCommandDialog/ConfirmTripCommandDialog';
import DeclineNetworkOfferTripCommandDialog from '../components/TripCommandDialog/DeclineTripCommand';

export interface TripCommandDialogState {
  isOpen: boolean;
  command: TripCommandMeta;
  tripId: string;
  isSavoyaTrip: boolean;
  savoyaTripId: string;
  dialogContent: ReactNode;
  succeeded: boolean;
  customCallback?: () => void;
}

export const tripCommandDialogState: RecoilState<TripCommandDialogState> = atom(
  {
    key: 'tripCommandDialogState',
    default: {
      isOpen: false,
      command: undefined,
      tripId: undefined,
      isSavoyaTrip: false,
      savoyaTripId: undefined,
      succeeded: false,
      customCallback: null,
      dialogContent: <Fragment />,
    },
  },
);

export const getDialogContentFromTripCommand = (
  command: TripCommandMeta,
  isSavoyaTrip: boolean,
  bodyText?: string,
): ReactNode => {
  if (command) {
    switch (command.typeName) {
      case 'StartTripPreparation':
        return <ConfirmTripCommandDialog title="Start trip preparation?" />;
      case 'CancelTrip':
        return <CancelTripCommandDialog />;
      case 'CloseTrip':
        return <ConfirmTripCommandDialog title="Close trip?" />;
      case 'EnRouteToPickup':
      case 'Circling':
      case 'Waiting':
      case 'DepartPickup':
      case 'ArriveStop':
      case 'DepartStop':
      case 'ArriveGarage':
      case 'DepartDropoff':
        return (
          <ConfirmTripCommandDialog
            title={`Change trip status to "${command.name}"`}
          />
        );
      case 'ArriveDropoff':
        return (
          <ConfirmTripCommandDialog
            title={`Change trip status to "Passenger Dropped"`}
          />
        );
      case 'InvoiceSubmitted':
        return (
          <ConfirmTripCommandDialog title={`Change trip status to "Billed"`} />
        );
      case 'InvoicePaid':
        return (
          <ConfirmTripCommandDialog title={`Change trip status to "Paid"`} />
        );
      case 'ArrivePickup':
        return <ArrivePickupTripCommandDialog />;
      case 'ReassignmentRequested':
        return (
          <ConfirmTripCommandDialog title="Are you sure you want to unassign the driver from this trip?" />
        );
      case 'DeclineNetworkOffer':
        return (
          <DeclineNetworkOfferTripCommandDialog
            isSavoyaTrip={isSavoyaTrip}
            body={bodyText}
          />
        );
      case 'DeclineNetworkConfirmationRequest':
        return (
          <ConfirmTripCommandDialog
            title="Are you sure you want to Reject this Trip Offer?"
            body={bodyText}
          />
        );
      case 'RevokeNetworkSharedTrip':
        return (
          <ConfirmTripCommandDialog title="Are you sure you want to revoke this trip?" />
        );
      default:
        return <ConfirmTripCommandDialog title="Do command?" />;
    }
  }
  return <Fragment />;
};
