export const shorten = (
  str: string,
  maxLen: number,
  separator = ' ',
): string => {
  // if string length is shorten
  if (str.length <= maxLen) return str;
  const shortenStr = str.substr(0, str.lastIndexOf(separator, maxLen));
  return shortenStr?.length === 0 ? str : shortenStr;
};

export const splitByCamelCase = (value: string): string =>
  value && value.replace(/([a-z])([A-Z])/g, '$1 $2');
