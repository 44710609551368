import React from 'react';
import { makeStyles } from '@material-ui/core';
import cdLogo from '../assets/images/logoLight.png';
import savoyaLogo from '../assets/images/SavoyaLogoWithIcon.svg';

const useStyles = makeStyles({
  logoContainer: {
    width: '14rem',
    height: '4rem',
  },
  logo: {
    width: '14rem',
    margin: 'auto auto',
    marginTop: '1.0rem',
    marginLeft: '-.7rem',
  },
  bottomLogo: {
    width: '14rem',
    margin: '0.4rem',
  },
});

interface LogoProps {
  [key: string]: unknown;
  bottomLogo?: boolean;
  isSavoya?: boolean;
}

const Logo: React.FC<LogoProps> = ({ bottomLogo, isSavoya }: LogoProps) => {
  const classes = useStyles();
  return (
    <div className={classes.logoContainer}>
      <img
        className={bottomLogo ? classes.bottomLogo : classes.logo}
        alt="Logo"
        src={isSavoya ? savoyaLogo : cdLogo}
      />
    </div>
  );
};

export default Logo;
