export enum SubscriptionTierEnum {
  FREE = 'Free',
  PREMIUM = 'Premium',
  PLATINUM = 'Platinum',
}

export enum PaidFeatureEntities {
  VEHICLES,
  USERS,
}
