import React, { FC, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import { StreamApp, Activity, FlatFeed } from 'react-activity-feed';
import 'react-activity-feed/dist/index.css';
import { getstreamConfig } from 'src/config';
import { format, parseISO } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { Trip } from 'src/.gen/graphql';

interface GarageEditFormProps {
  token: string;
  trip: Partial<Trip>;
}

const ActivitiesDashborad: FC<GarageEditFormProps> = ({ token, trip }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const toastElement: HTMLElement = document.querySelector(
        '.raf-new-activities-notification',
      ) as HTMLElement;
      if (toastElement) {
        toastElement.click();
      }
    }, 500);
    return () => clearInterval(interval);
  });
  const user = useRecoilValue(userAtom);
  const iOwnThisTrip = user?.accountId === trip?.accountId;

  return (
    <Box pt={3}>
      <Typography variant="h5">Trip Activity Log</Typography>
      <StreamApp
        apiKey={getstreamConfig.apiKey}
        appId={getstreamConfig.appId}
        token={token}
      >
        <FlatFeed
          data-testid="activity_history_list"
          feedGroup="trip"
          notify
          Activity={(props) => {
            if (!props.activity?.message) {
              return null;
            }

            const actor =
              typeof props.activity?.actor === 'string'
                ? props.activity?.actor
                : props.activity?.actor?.data?.name || 'N/A';

            const username = user?.name?.toLowerCase();
            if (![username, 'system'].includes(actor?.toLowerCase())) {
              return null;
            }

            if (
              ((props.activity?.message as string) || '').includes(
                'Estimated revenue',
              ) &&
              !iOwnThisTrip
            ) {
              return null;
            }
            return (
              <Activity
                {...props}
                Footer={() => {
                  return (
                    <div style={{ marginLeft: 15 }}>
                      {props.activity.message}
                    </div>
                  );
                }}
                Header={() => {
                  return (
                    <div
                      style={{
                        marginLeft: 15,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h6">{actor}</Typography>
                      <Typography variant="body1">
                        {props.activity?.time
                          ? format(
                              parseISO(`${props.activity?.time as string}Z`),
                              'MM/dd/yyyy HH:mm:ss',
                            )
                          : 'N/A'}
                      </Typography>
                    </div>
                  );
                }}
              />
            );
          }}
        />
      </StreamApp>
    </Box>
  );
};

export default ActivitiesDashborad;
