import { atom, RecoilState } from 'recoil';

export type SavoyaAdditionalChargeItem = {
  name: string;
  key: string;
  value: string;
  show: boolean;
  editable: boolean;
};

interface SavoyaTripCloseoutAtomInterface {
  invertedCompensation?: number;
  waitTimeCost?: number;
  subtotalAmount?: number;
}

const defaultSavoyaTripCloseoutValues: SavoyaTripCloseoutAtomInterface = {
  invertedCompensation: 0,
  waitTimeCost: 0,
  subtotalAmount: 0,
};

export const savoyaTripCloseoutState: RecoilState<SavoyaTripCloseoutAtomInterface> =
  atom({
    key: 'savoyaTripCloseoutState',
    default: defaultSavoyaTripCloseoutValues,
  });

export const savoyaAdditionalChargesState: RecoilState<
  SavoyaAdditionalChargeItem[]
> = atom({
  key: 'savoyaAdditionalChargesState',
  default: [],
});
