import React from 'react';
import {
  CircularProgress,
  Box,
  List,
  ListSubheader,
  ListItem,
  Link,
  Button,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    paddingBottom: 20,
  },
  secureCheckout: {
    display: 'flex',
    justifContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 10,
  },
  lockIcon: {
    color: theme.palette.success.main,
    paddingLeft: 0,
    paddingRight: 5,
  },
}));

const PaymentForm: React.FC = () => {
  const classes = useStyles();
  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href.split('?')[0]}/succeeded`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
      });
    }
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.

    return true;
  };

  React.useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element.on('ready', () => {
        setIsLoading(false);
      });
    }
  }, [elements]);

  return (
    <form onSubmit={handleSubmit} data-testid="subscription_payment_form">
      <Box>
        <Box m={4} px={2}>
          <Typography
            variant="h6"
            color="textPrimary"
            component="p"
            className={classes.sectionTitle}
          >
            <b>Billing Address</b>
          </Typography>
          {isLoading ? (
            <Box display="flex" justifyContent="center" p={2} m={2}>
              <CircularProgress color="secondary" />
            </Box>
          ) : null}
          <AddressElement
            data-testid="payment_form_address_element"
            options={{
              mode: 'billing',
            }}
          />
        </Box>
        <Box m={4} px={2}>
          <Typography
            variant="h6"
            color="textPrimary"
            component="p"
            className={classes.sectionTitle}
          >
            <b>Payment Method</b>
          </Typography>
          {isLoading ? (
            <Box display="flex" justifyContent="center" p={2} m={2}>
              <CircularProgress color="secondary" />
            </Box>
          ) : null}
          <PaymentElement
            data-testid="payment_form_payment_element"
            options={{
              fields: {
                billingDetails: {
                  name: 'auto',
                },
              },
            }}
          />
        </Box>
        <Box mx={4} p={2}>
          <List disablePadding>
            <ListSubheader component="div" disableGutters>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                component="span"
                className={classes.secureCheckout}
              >
                <LockOutlined className={classes.lockIcon} />
                <b>Secure Checkout</b>
              </Typography>
            </ListSubheader>
            <ListItem dense disableGutters>
              <Typography variant="body2" color="textSecondary" component="p">
                * Your payment is secured by an industry best encryption
                service.
              </Typography>
            </ListItem>
            <ListItem dense disableGutters>
              <Typography variant="body2" color="textSecondary" component="p">
                * By clicking <b>&quot;Start trial&quot;</b> you agree to
                Capital Drive, Inc.{' '}
                <Link href="/terms-of-service" rel="noreferrer" target="_blank">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  href="https://www.capitaldrive.com/privacy"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </Link>{' '}
                .
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box mx={4} p={2} display="flex" justifyContent="flex-start">
          <Button
            data-testid="payment_form_subscribe_button"
            variant="contained"
            type="submit"
            color="primary"
            disabled={!stripe || !elements}
          >
            Start Trial
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default PaymentForm;
