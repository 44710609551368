import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { RatePolicy, RatePolicyType } from 'src/.gen/graphql';
import MinimumBookingTimeForm from './MinimumBookintHoursForm/MinimumBookingHoursForm';

interface MinimumBookingTimeProps {
  getPolicy: (policy: RatePolicyType) => Partial<RatePolicy>;
  rateData: {
    currentMeteredRateIncrement: number;
    rateSetId: string;
  };
}

const MinimumBookingTime: FC<MinimumBookingTimeProps> = ({
  getPolicy,
  rateData,
}) => {
  const policy = getPolicy(RatePolicyType.MinimumCharge);
  const { currentMeteredRateIncrement = 0, rateSetId = '' } = rateData;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Minimum Booking Hours</Typography>
      </Grid>
      <Grid item xs={12}>
        <MinimumBookingTimeForm
          policy={policy}
          currentMeteredRateIncrement={currentMeteredRateIncrement}
          rateSetId={rateSetId}
        />
      </Grid>
    </Grid>
  );
};

export default MinimumBookingTime;
