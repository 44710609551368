import { atom, RecoilState } from 'recoil';
import {
  GeneralTableValues,
  generalTableValues,
} from './generalTableAtomTypes';

export const generalTableValuesState: RecoilState<GeneralTableValues> = atom({
  key: 'generalTableState',
  default: generalTableValues,
});
