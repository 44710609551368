import React from 'react';
import { Toolbar, Typography, Theme, fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useRecoilValue } from 'recoil';
import { generalTableValuesState } from './atoms/generalTableAtom';

interface EnhancedTableToolbarProps {
  actions?: (selectedItems: string[]) => React.ReactNode | null;
  header?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  toolbarActive: {
    backgroundColor: fade(
      theme.palette.primary.main,
      theme.palette.action.activatedOpacity,
    ),
  },
  toolbarInactive: {
    minHeight: 0,
  },
}));

const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
  actions,
  header = null,
}) => {
  const { selectedItems } = useRecoilValue(generalTableValuesState);
  const classes = useStyles();

  return (
    <Toolbar
      className={
        selectedItems.length ? classes.toolbarActive : classes.toolbarInactive
      }
    >
      {selectedItems.length > 0 ? (
        <Typography
          style={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selectedItems.length} selected
        </Typography>
      ) : (
        header
      )}
      {actions && actions(selectedItems)}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
