import React from 'react';
import { Button, useTheme } from '@material-ui/core';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { useSetRecoilState } from 'recoil';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import { PaymentMethod, PaymentMethodType } from 'src/.gen/graphql';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import AddTripRefund from '../TripRefund/AddTripRefund';

export interface RefundPaymentMethod extends PaymentMethod {
  type: PaymentMethodType;
}

interface Props {
  tripId: string;
  paymentMethod: RefundPaymentMethod;
  grandTotal: number;
}

const TripRefundButton: React.FC<Props> = ({
  tripId,
  paymentMethod,
  grandTotal,
}) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const setTimestampsState = useSetRecoilState(tripTimestampsState);
  const theme = useTheme();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = () => {
    setTimestampsState((prev) => ({
      ...prev,
      refetchInvoiceSummary: true,
      updating: true,
    }));
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        style={{
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main,
        }}
        onClick={() => setOpenDialog(true)}
        startIcon={<CompareArrowsIcon fontSize="small" />}
      >
        Refund
      </Button>
      <GenericDialog
        openDialog={openDialog}
        setCloseDialog={() => setOpenDialog(false)}
        dialogTitle="Refund Customer"
        maxWidth="sm"
      >
        <AddTripRefund
          tripId={tripId}
          paymentMethod={paymentMethod}
          grandTotal={grandTotal}
          onCancel={handleCloseDialog}
          onSubmit={onSubmit}
        />
      </GenericDialog>
    </React.Fragment>
  );
};

export default TripRefundButton;
