import { Dialog } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { tripCommandDialogState } from 'src/trips/atoms/tripCommandDialogAtom';

const TripCommandDialog: React.FC = () => {
  const [tripCommandDialogContent, setTripCommandDialog] = useRecoilState(
    tripCommandDialogState,
  );
  return (
    <Dialog
      open={tripCommandDialogContent.isOpen}
      onClose={() => {
        setTripCommandDialog((tripCommandDialog) => ({
          ...tripCommandDialog,
          isOpen: false,
          succeeded: false,
        }));
      }}
    >
      {tripCommandDialogContent.dialogContent}
    </Dialog>
  );
};

export default TripCommandDialog;
