import { parse, format, parseISO } from 'date-fns';

const addZero = (timeValue) => (timeValue < 10 ? `0${timeValue}` : timeValue);

const PM_OPTIONS = ['pm', 'p', 'p.m.'];
const AM_OPTIONS = ['am', 'a', 'a.m.'];

export const formatTimeString = (
  timeString: string,
  originalValue: string,
): string => {
  const onlyLetters = timeString
    .split('')
    .filter((char) => /[a-zA-Z]/.test(char))
    .join('')
    .toLowerCase();
  const isPm = PM_OPTIONS.includes(onlyLetters);
  const isAm = AM_OPTIONS.includes(onlyLetters);

  const onlyNumbers = Number(
    timeString
      .split('')
      .filter((char) => /^\d+$/.test(char))
      .join(''),
  );
  if (onlyNumbers > 2359 || onlyNumbers < 0) {
    return originalValue;
  }

  if (onlyNumbers > 0 && onlyNumbers < 24) {
    const simpleHours =
      onlyNumbers < 12 && isPm ? onlyNumbers + 12 : onlyNumbers;
    return `${addZero(simpleHours)}:00`;
  }
  if (onlyNumbers >= 24 && onlyNumbers < 100) {
    return originalValue;
  }

  const minutes = onlyNumbers % 100;
  const rawHours = Math.floor(onlyNumbers / 100);
  let hours;

  if (rawHours === 12) {
    hours = isAm ? rawHours - 12 : rawHours;
  } else {
    hours = rawHours < 12 && isPm ? rawHours + 12 : rawHours;
  }
  if (minutes > 59 || hours > 23) {
    return originalValue;
  }

  const newTime = `${addZero(hours)}:${addZero(minutes)}`;
  return newTime;
};

export const formatAmPmTimeTo24 = (time: string): string => {
  if (time.includes('a.m.') || time.includes('p.m.')) {
    const dateObject = parse(time, 'hh:mm aaaa', new Date());
    const rightFormat = format(dateObject, 'HH:mm');
    return rightFormat;
  }
  return time;
};

export const setHoursToDate = (date: Date | string, time: string): Date => {
  const parseDate = typeof date === 'string' ? new Date(date) : date;
  return parseISO(`${format(parseDate, 'yyy-MM-dd')}T${time}`);
};
