import { Grid, Theme, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
import { IconType } from 'react-icons';

export type SavoyaStyledLinkProps = {
  onClick?: () => void;
  Icon?: IconType;
  label: string;
  loading: boolean;
  RightElement?: JSX.Element;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    marginRight: theme.spacing(2),
    borderRadius: 5,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  content: {
    maxWidth: '80%',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    textDecoration: 'underline',
    display: 'flex',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  unclickableLabel: {
    color: theme.palette.grey[700],
    display: 'flex',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
}));

const SavoyaStyledLink: FC<SavoyaStyledLinkProps> = ({
  onClick,
  Icon,
  label,
  loading,
  RightElement,
  disabled,
}) => {
  const clickable = onClick && !disabled;
  const classes = useStyles();
  return loading ? (
    <Skeleton width="100%" />
  ) : (
    <Grid
      item
      xs={6}
      direction="row"
      className={classes.content}
      style={clickable ? { cursor: 'pointer' } : {}}
    >
      {Icon ? (
        <div
          className={classes.iconContainer}
          onClick={clickable ? onClick : undefined}
        >
          <Icon size={24} />
        </div>
      ) : null}
      <Typography
        variant="subtitle1"
        color="primary"
        className={clickable ? classes.label : classes.unclickableLabel}
        onClick={clickable ? onClick : undefined}
      >
        {label}
      </Typography>
      {RightElement || null}
    </Grid>
  );
};

export default SavoyaStyledLink;
