import {
  Typography,
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import { FieldArray, Field, FormikProps } from 'formik';
import React from 'react';
import {
  AdjustmentCalculation,
  GetAccountAdjustmentsQuery,
} from 'src/.gen/graphql';

export type AccountAdjustmentsResult =
  GetAccountAdjustmentsQuery['accountAdjustments']['all']['items'];
interface Props extends FormikProps<{ currentAdjustments }> {
  validateAdjustmentsRef: (id: string) => void;
}

const SelectTripAdjustments: React.FC<Props> = ({
  values,
  setFieldValue,
  validateAdjustmentsRef,
}) => {
  const cols = [
    { field: 'adjustment', headerName: 'ADJUSTMENT' },
    { field: 'amount', headerName: 'AMOUNT' },
    {
      field: 'defaultNote',
      headerName: 'NOTE (OPTIONAL)',
    },
  ];

  const classes = useStyles();
  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          {cols.map((column) => (
            <TableCell className={classes.tableHead} key={column.field}>
              {column.headerName}
            </TableCell>
          ))}
        </TableHead>
        <TableBody className={classes.tableBodyContainer}>
          <FieldArray
            name="currentAdjustments"
            render={() => (
              <React.Fragment>
                {values?.currentAdjustments?.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell className={classes.tableCell}>
                      <Field
                        data-testid="currentAdjustments_rateType_name_field"
                        as="h5"
                        name={`currentAdjustments.${index}.rateType.name`}
                      >
                        <Typography variant="body1">
                          {row.rateType.name}
                        </Typography>
                      </Field>
                    </TableCell>
                    <TableCell className={classes.tableCellAmount}>
                      <Field
                        name={`currentAdjustments.${index}.amount`}
                        data-testid="currentAdjustments_amount_field"
                      >
                        {({ field, meta }) => (
                          <TextField
                            className={classes.input}
                            name={`currentAdjustments.${index}.amount`}
                            variant="outlined"
                            label="AMOUNT"
                            {...field}
                            error={!!meta.error && meta.touched}
                            helperText={
                              !!meta.error && meta.touched ? meta.error : ''
                            }
                            onChange={(e) => {
                              const regex = /^\d+(\.\d{0,2})?$/;
                              const { value } = e.target;
                              if (!value || regex.test(value)) {
                                setFieldValue(
                                  `currentAdjustments.${index}.amount`,
                                  value,
                                );
                              }
                              validateAdjustmentsRef(row.id);
                            }}
                            fullWidth
                            placeholder="Enter amount"
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <Field
                                  name={`currentAdjustments.${index}.calculation`}
                                  data-testid="currentAdjustments_calculation_field"
                                >
                                  {() => (
                                    <Select
                                      onChange={(e) => {
                                        setFieldValue(
                                          `currentAdjustments.${index}.calculation`,
                                          e.target.value,
                                        );
                                        validateAdjustmentsRef(row.id);
                                      }}
                                      value={row.calculation || ''}
                                    >
                                      <MenuItem
                                        value={AdjustmentCalculation.Flat}
                                      >
                                        $
                                      </MenuItem>
                                      <MenuItem
                                        value={AdjustmentCalculation.Percentage}
                                      >
                                        %
                                      </MenuItem>
                                    </Select>
                                  )}
                                </Field>
                              ),
                            }}
                            value={row.amount || ''}
                          />
                        )}
                      </Field>
                    </TableCell>
                    <TableCell>
                      <Field
                        data-testid="currentAdjustments_note_field"
                        name={`currentAdjustments.${index}.note`}
                      >
                        {({ field, meta }) => (
                          <TextField
                            variant="outlined"
                            label="NOTE"
                            placeholder="Enter note"
                            {...field}
                            error={!!meta.error && meta.touched}
                            helperText={
                              !!meta.error && meta.touched ? meta.error : ''
                            }
                            onChange={(
                              e: React.ChangeEvent<{
                                value: string;
                              }>,
                            ) => {
                              setFieldValue(
                                `currentAdjustments.${index}.note`,
                                e.target.value,
                              );
                              validateAdjustmentsRef(row.id);
                            }}
                            type="text"
                            value={row?.note?.length > 0 ? row?.note : ''}
                            fullWidth
                          />
                        )}
                      </Field>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectTripAdjustments;

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    '& .MuiTableHead-root > th': {
      borderBottom: '2px solid #966440',
    },
  },
  tableRow: {
    bottomBorder: '1px solid blue',
  },
  tableCell: {
    width: '18%',
  },
  tableCellAmount: {
    width: '35%',
  },
  tableHead: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.1em',
    fontSize: theme.typography.body2.fontSize,
  },
  tableBodyContainer: {
    '& .MuiTableBody-root': {
      backgroundColor: `${theme.palette.backgroundColor.dark} !important`,
    },
  },
  button: {
    '&:hover': {
      backgroundColor: '#966440',
      color: '#fff',
    },
  },
  root: {
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'clip',
  },
  input: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
}));
