import React from 'react';
import { camel, snake } from 'case';
import { Typography, TextField, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import getSymbolFromCurrency from 'currency-symbol-map';
import type { RateItem } from '../types';

type RateValueFieldProps = {
  item: RateItem;
  needsHourlyCost: boolean;
  needsTransferCost: boolean;
  baseCost: number;
  minimumHours: number;
  currencyCode?: string;
  rateUnit?: string;
  editable?: boolean;
};

export const SavoyaRateValueField: React.FC<RateValueFieldProps> = ({
  item,
  needsHourlyCost,
  needsTransferCost,
  baseCost,
  minimumHours,
  currencyCode = 'USD',
  rateUnit,
  editable,
}) => {
  const classes = useStyles();
  const { key, name, value, highlight } = item;
  if (
    (needsHourlyCost || needsTransferCost) &&
    ['minimumHours', 'baseRate'].includes(key)
  ) {
    return (
      <Field id={camel(name)} name={camel(name)}>
        {({ field, form }) => {
          let fieldValue = '';
          if (key === 'baseRate' && baseCost) {
            fieldValue = baseCost?.toFixed(2);
          } else if (key === 'minimumHours' && minimumHours) {
            fieldValue = minimumHours?.toFixed(2);
          }
          return (
            <TextField
              {...field}
              data-testid={`savoya_rate_${snake(name)}`}
              placeholder="0.00"
              disabled={!editable}
              type="number"
              variant="outlined"
              size="small"
              style={{ width: 100 }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              onChange={(e) => {
                form.setFieldValue(field.name, parseFloat(e.target.value) || 0);
              }}
              value={field.value || fieldValue}
            />
          );
        }}
      </Field>
    );
  }

  return (
    <Typography
      variant={highlight ? 'subtitle2' : 'body1'}
      className={classes.text}
    >
      {key === 'baseRate'
        ? `${getSymbolFromCurrency(currencyCode)} ${baseCost?.toFixed(2)} ${
            rateUnit ? `/ ${rateUnit}` : ''
          }`
        : value}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  rateTypeRadioButtonText: {
    fontSize: 14,
  },
  rateTypeRadioButtonLabel: {
    marginRight: 0,
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
  },
}));
