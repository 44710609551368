import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import {
  InsertTripAddon,
  RateType,
  UpdateTripAddon,
  useDeleteTripAddonMutation,
  useGetAccountAdjustmentsQuery,
  useGetEstimatedSummaryQuery,
  useInsertTripAddonMutation,
  useUpdateTripAddonMutation,
} from 'src/.gen/graphql';
import { cloneDeep, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userAtom } from 'src/authentication/atoms/AuthState';
import LoadingScreen from 'src/shared/components/LoadingScreen';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { tripTimestampsState } from 'src/trips/atoms/tripTimestampsAtom';
import SelectTripAdjustments from './SelectTripAdjustments';
import SelectTripAdjustmentsMobile from './SelectTripAdjustmentsMobile';

type Props = {
  tripId: string;
  vehicleClassId?: string;
  rateOptions: ({ __typename?: 'RateType' } & Pick<
    RateType,
    'id' | 'name' | 'description' | 'category' | 'favorite'
  >)[];
  onCancel: () => void;
  onSubmit: () => void;
};

const TripAdjustmentForm: React.FC<Props> = ({
  tripId,
  vehicleClassId,
  rateOptions,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();
  const [insertExpense] = useInsertTripAddonMutation();
  const [updateExpense] = useUpdateTripAddonMutation();
  const [deleteTripAddon] = useDeleteTripAddonMutation({
    refetchQueries: ['getTripById', 'getInvoiceSummaryByTrip'],
  });
  const setTimestampsState = useSetRecoilState(tripTimestampsState);
  const user = useRecoilValue(userAtom);
  const { xs } = useBreakpoints();
  const { data, loading } = useGetAccountAdjustmentsQuery({
    variables: {
      accountId: user.accountId,
    },
  });
  const { data: estimatedSummaryData } = useGetEstimatedSummaryQuery({
    fetchPolicy: 'no-cache',
    variables: { tripId, vehicleClassId },
    skip: !tripId || !vehicleClassId,
  });
  const currentAddonsArr =
    estimatedSummaryData?.invoices?.getEstimatedSummary.otherItems;
  const accountAdjustments = cloneDeep(data?.accountAdjustments?.all?.items);
  const [currentAdjustments, setCurrentAdjustments] = useState([]);

  const handleAdjustmentOrder = (unsortedAdjustments) => {
    const sortedCategoryList: string[] = [
      'Parking',
      'Misc Fee',
      'Toll',
      'Tax',
      'Airport Fee',
      'Discount',
      'Fuel Surcharge',
      'Gratuity',
    ];

    unsortedAdjustments?.sort((a, b) => {
      return (
        sortedCategoryList.indexOf(a?.rateType?.name) -
        sortedCategoryList.indexOf(b?.rateType?.name)
      );
    });
  };

  const handleInitialAccountAdjustments = () => {
    const updatedAddons = currentAddonsArr?.map((addOn) => {
      return {
        amount: addOn.name.includes('%') ? addOn.unitPrice : addOn.total,
        note: addOn.description,
        rateType: {
          name: addOn.name.split(' ').slice(0, -1).join(' '),
        },
        addonId: addOn.addonId,
        unitPrice: addOn.unitPrice,
        calculation: addOn.name.includes('%') ? 'Percentage' : 'Flat',
      };
    });
    if (
      // check for when there is no default adjustments
      (currentAddonsArr?.[0]?.category === 'Base' &&
        !currentAddonsArr?.[1]?.addonId) ||
      // check for when there is no base rate
      !currentAddonsArr ||
      currentAddonsArr.length === 0 ||
      !updatedAddons ||
      updatedAddons.length === 0
    ) {
      // checks if adjustments were cleared
      const clearedResult = accountAdjustments?.map((item, i) => {
        const obj = updatedAddons?.find(
          (o) => o.rateType.name === item.rateType.name,
        );
        return {
          ...item,
          ...obj,
          id: rateOptions[i]?.id,
          amount: null,
          note: null,
          unitPrice: null,
        };
      });
      handleAdjustmentOrder(clearedResult);
      setCurrentAdjustments(clearedResult);
    } else if (currentAddonsArr?.[0]?.category === 'FeePassthrough') {
      // checks if adjustments were cleared without a base rate
      const clearedResultWithoutBaseRate = accountAdjustments?.map(
        (item, i) => {
          const obj = updatedAddons?.find(
            (o) => o.rateType.name === item.rateType.name,
          );
          return {
            ...item,
            ...obj,
            id: rateOptions[i]?.id,
            amount: obj?.amount,
            note: obj?.note,
            unitPrice: obj?.unitPrice,
          };
        },
      );
      handleAdjustmentOrder(clearedResultWithoutBaseRate);
      setCurrentAdjustments(clearedResultWithoutBaseRate);
    } else {
      // if adjustments were not cleared
      const result = accountAdjustments?.map((item, i) => {
        const obj = updatedAddons?.find(
          (o) => o.rateType.name === item.rateType.name,
        );
        if (item.active === false) {
          return {
            ...item,
            ...obj,
            id: rateOptions[i]?.id,
            amount: obj?.amount,
            note: obj?.note,
            unitPrice: obj?.unitPrice,
          };
        }
        if (
          item.active === true &&
          obj?.rateType?.name !== item.rateType.name
        ) {
          return {
            ...item,
            ...obj,
            id: rateOptions[i]?.id,
            amount: null,
            note: null,
            unitPrice: null,
          };
        }
        return { ...item, ...obj, id: rateOptions[i]?.id };
      });
      handleAdjustmentOrder(result);
      setCurrentAdjustments(result);
    }
  };

  React.useEffect(() => {
    if (tripId) {
      handleInitialAccountAdjustments();
    }
  }, [tripId, currentAddonsArr, vehicleClassId]);

  const { enqueueSnackbar } = useSnackbar();

  const accountAdjustmentsRef = React.useRef(new Set<string>());

  const validateAdjustmentsRef = (id: string) => {
    if (!accountAdjustmentsRef.current.has(id)) {
      accountAdjustmentsRef.current.add(id);
    }
  };

  const handleSubmit = async (values: { currentAdjustments }) => {
    onSubmit();
    const newAddonsArr = [];
    const addonsToUpdateArr = [];

    // values for a new trip addon/adjustment
    values.currentAdjustments.map((accAdjustment, i: number) =>
      newAddonsArr.push({
        id: accAdjustment.addonId || null,
        unitPrice: parseFloat(accAdjustment.amount) || 0,
        quantity: 1,
        total: parseFloat(accAdjustment.amount) || 0,
        calculation: accAdjustment.calculation || 'Flat',
        note: accAdjustment.note,
        tripId: tripId ?? null,
        rateTypeId: rateOptions[i].id,
      }),
    );

    // values to update a trip addon/adjustment
    newAddonsArr?.map((curr, i) => {
      if (
        curr?.id &&
        (curr.note !== currentAdjustments[i].note ||
          curr.total !== currentAdjustments[i].amount ||
          curr.calculation !== currentAdjustments[i].calculation)
      ) {
        addonsToUpdateArr.push({
          rateTypeId: rateOptions[i].id,
          total: curr.total,
          tripId: tripId ?? null,
          quantity: 1,
          unitPrice: curr.unitPrice,
          note: curr.note,
          calculation: curr.calculation,
          id: curr.addonId || curr.id,
        });
      }
      return addonsToUpdateArr;
    });
    addonsToUpdateArr.forEach((tripAddon) => {
      handleUpdateTripAddOn(tripAddon);
    });
    const newFilteredAddonsArr = newAddonsArr.filter(
      (newItem) =>
        !addonsToUpdateArr.find(
          (currItem) => newItem.rateTypeId === currItem.rateTypeId,
        ),
    );
    newFilteredAddonsArr.forEach((tripAddon: InsertTripAddon) => {
      if (!tripAddon.id && (tripAddon.total || tripAddon.unitPrice) > 0) {
        handleAddOn(tripAddon);
      }
    });

    setTimestampsState((prev) => ({
      ...prev,
      refetchInvoiceSummary: true,
      updating: true,
    }));
  };

  const handleAddOn = async (tripAddon: InsertTripAddon) => {
    try {
      await insertExpense({
        variables: {
          tripAddon,
        },
        refetchQueries: [
          'GetTripById',
          'getEstimatedSummary',
          'createOrUpdateInvoiceSummaryForTrip',
        ],
      });
      onCancel();
      enqueueSnackbar(`Adjustments have been saved!`, {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (err) {
      enqueueSnackbar(`Error while saving adjustments.`, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const handleClearAll = async () => {
    if (currentAddonsArr.length) {
      currentAddonsArr?.forEach((tripAddon) => {
        if (tripAddon?.addonId) {
          handleDeleteAddOn(tripAddon.addonId);
        }
      });
    }
  };

  const handleDeleteAddOn = async (tripAddonId: string) => {
    try {
      await deleteTripAddon({
        variables: {
          tripAddonId,
        },
        refetchQueries: [
          'GetTripById',
          'getEstimatedSummary',
          'createOrUpdateInvoiceSummaryForTrip',
        ],
      });
      onCancel();
      enqueueSnackbar(`Adjustments have been cleared!`, {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (err) {
      enqueueSnackbar(`Error while clearing adjustments.`, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const handleUpdateTripAddOn = async (tripAddon: UpdateTripAddon) => {
    try {
      await updateExpense({
        variables: {
          tripAddon,
        },
        refetchQueries: [
          'GetTripById',
          'getEstimatedSummary',
          'createOrUpdateInvoiceSummaryForTrip',
        ],
      });
      onCancel();
      enqueueSnackbar(`Adjustments have been updated!`, {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (err) {
      enqueueSnackbar(`Error while updating adjustments.`, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const preventSubmission = (keyEvent) => {
    if ((keyEvent.code || keyEvent.code) === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  if (loading) return <LoadingScreen />;

  return (
    <Formik
      initialValues={{ currentAdjustments }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        currentAdj: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            rateType: Yup.object().shape({
              __typename: Yup.string(),
              category: Yup.string(),
              name: Yup.string(),
            }),
            active: Yup.bool(),
            note: Yup.string()
              .nullable()
              .max(255, 'You have exceeded the max length for this field.'),
            calculation: Yup.string().nullable(),
            amount: Yup.string()
              .nullable()
              .typeError('You must specify a number.')
              .min(0, 'Min value 0.'),
            __typename: Yup.string(),
          }),
        ),
      })}
      onSubmit={handleSubmit}
    >
      {(
        props: FormikProps<{
          currentAdjustments: typeof currentAdjustments;
        }>,
      ) => {
        return (
          <div className={classes.container}>
            <Form onSubmit={props.handleSubmit} onKeyDown={preventSubmission}>
              <Grid item>
                {!loading && !isEmpty(data) && !xs ? (
                  <SelectTripAdjustments
                    {...props}
                    validateAdjustmentsRef={validateAdjustmentsRef}
                  />
                ) : null}
                {!loading && !isEmpty(data) && xs ? (
                  <SelectTripAdjustmentsMobile
                    {...props}
                    validateAdjustmentsRef={validateAdjustmentsRef}
                  />
                ) : null}
              </Grid>
              <Grid container justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    disabled={props.isSubmitting}
                    className={classes.clearButton}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      handleClearAll();
                    }}
                  >
                    Clear All
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={
                      props.isSubmitting ||
                      accountAdjustmentsRef.current.size === 0
                    }
                    className={classes.saveButton}
                    size="large"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default TripAdjustmentForm;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 10,
    '& > form': {
      display: 'grid',
    },
  },
  saveButton: {
    justifySelf: 'end',
    marginTop: theme.spacing(2),
    borderColor: '#966440',
    '&:hover': {
      backgroundColor: '#966440',
      color: '#fff',
    },
  },
  clearButton: {
    justifySelf: 'end',
    marginTop: theme.spacing(2),
    backgroundColor: '#000',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d3d3d3',
      color: '#000',
      border: ' 0.5px solid #000',
    },
  },
}));
