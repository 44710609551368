import { makeStyles, styled, Theme } from '@material-ui/core';
import React from 'react';

interface Props {
  color?: 'light' | 'dark';
}

const CircularProgressIndicator: React.FC<Props> = ({ color = 'dark' }) => {
  const classes = useStyles();
  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className={classes.circle}
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <Path
        fill="currentColor"
        color={color}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};

const Path = styled('path')(
  ({ theme, color }: { theme: Theme; color: Props['color'] }) => ({
    opacity: 0.75,
    color: theme.palette.backgroundColor[color],
  }),
);

const useStyles = makeStyles(() => ({
  svg: {
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationDirection: 'normal',
    animationFillMode: 'none',
    animationPlayState: 'running',
    color: '#ffffff',
    width: '1.25rem',
    height: '1.25rem',
    margineft: '.25rem',
    animation: `$spin 1s linear infinite;`,
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  circle: {
    opacity: '.25',
  },
}));

export default CircularProgressIndicator;
