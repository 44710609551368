import React from 'react';
import { useRecoilState } from 'recoil';
import {
  InvoiceSummary,
  useCreateOrUpdateInvoiceSummaryForTripMutation,
} from 'src/.gen/graphql';
import {
  tripTimestampsState,
  initialTriptimestampsValue,
  TripTimestampsValues,
} from 'src/trips/atoms/tripTimestampsAtom';

interface Hook {
  ({ tripId }: { tripId: string }): {
    loading: boolean;
    updating: boolean;
    summary: InvoiceSummary;
  };
}

const useInvoiceSummary: Hook = ({ tripId }) => {
  const [
    { refetchInvoiceSummary, loading, updating, summary },
    setTimestampsState,
  ] = useRecoilState(tripTimestampsState);

  const [createOrUpdateInvoiceSummaryForTrip] =
    useCreateOrUpdateInvoiceSummaryForTripMutation();

  React.useEffect(() => {
    const fetchInvoiceSummary = async () => {
      const result = await createOrUpdateInvoiceSummaryForTrip({
        variables: {
          tripId,
        },
      });
      setTimestampsState(
        (prev) =>
          ({
            ...prev,
            refetchInvoiceSummary: false,
            loading: false,
            updating: false,
            summary: result.data.createOrUpdateInvoiceSummaryForTrip,
          } as TripTimestampsValues),
      );
    };
    if (refetchInvoiceSummary && tripId) {
      fetchInvoiceSummary();
    }
  }, [refetchInvoiceSummary, tripId]);

  React.useEffect(() => {
    return () => {
      setTimestampsState(initialTriptimestampsValue);
    };
  }, []);
  return { loading, updating, summary };
};

export default useInvoiceSummary;
