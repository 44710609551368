import React from 'react';
import {
  TextField,
  makeStyles,
  Theme,
  InputAdornment,
} from '@material-ui/core';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import { TextFieldExtendedProps } from 'src/shared/components/CurrencyField';

const useClasses = makeStyles((theme: Theme) => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0',
    padding: '0',
    border: 'none',
    width: 'fit-content',
  },
  cellInput: {
    width: '120px',
    transition: theme.transitions.create(['border-color']),
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
}));

export interface RowDataInterface {
  hourlyRate: number;
  id: string;
  name: string;
  tableData: {
    id: number;
  };
}
interface CustomEditableCellProps {
  type: string;
  value: number;
  onSubmit: (value: string | number) => Promise<void>;
  inputType?: 'currency' | 'normal';
  customInputComponent?: React.JSXElementConstructor<TextFieldExtendedProps>;
}

const CustomEditableCell: React.FC<CustomEditableCellProps> = ({
  type,
  value,
  onSubmit,
  inputType,
  customInputComponent: CustomInput,
}) => {
  const classes = useClasses();
  const inputRef = React.useRef<HTMLInputElement>();
  const [localValue, setLocalValue] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const Input = CustomInput ?? TextField;

  const handleChange = (event) => {
    setLocalValue(event.target.value);
  };

  const setInitialLocalValue = () => {
    let parsedValue;
    function decimalCount(value: string): number {
      const [, decimals] = value.split('.');
      return decimals?.length ?? 0;
    }
    switch (true) {
      case inputType === 'currency' && decimalCount(`${value}`) < 2:
        parsedValue = (+value).toFixed(2);
        break;
      default:
        parsedValue = value;
        break;
    }
    if (parsedValue !== localValue) {
      setLocalValue(parsedValue);
    }
  };

  const handleSubmit = async (newValue) => {
    let parsedValue;
    switch (inputType) {
      case 'currency':
        parsedValue = +newValue;
        break;
      default:
        parsedValue = newValue;
        break;
    }

    setLoading(true);
    await onSubmit(parsedValue);
    setLoading(false);
  };

  React.useEffect(() => {
    setInitialLocalValue();
  }, [value]);

  return (
    <div className={classes.cellContainer}>
      <Input
        ref={inputRef}
        date-testid="custom_editable_cell"
        className={classes.cellInput}
        type={type}
        value={localValue}
        onChange={handleChange}
        onKeyUp={(e) => {
          e.preventDefault();
          if (e.key === 'Enter') {
            inputRef.current.blur();
          }
        }}
        onBlur={(e) => {
          e.preventDefault();
          if (+localValue !== value) {
            handleSubmit(localValue ?? 1);
          }
        }}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {loading && <CircularProgressIndicator />}
                  </InputAdornment>
                ),
              }
            : null
        }
      />
    </div>
  );
};

export default CustomEditableCell;
