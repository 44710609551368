import { useState, useEffect } from 'react';
import axios, { AxiosInstance } from 'axios';

interface UseFetchParams {
  initialUrl: string;
  initialParams?: Record<string, string | number | boolean>;
  skip?: boolean;
  initialBody?: Record<string, string | number | boolean>;
  method?: string;
  axiosInstance?: AxiosInstance;
}

interface UseFetchResult {
  data: Record<string, string | number | boolean>;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  updateUrl: (newValue: string) => void;
  updateParams: (newValue: Record<string, string | number | boolean>) => void;
  refetch: () => void;
  updateBody: (newValue: Record<string, string | number | boolean>) => void;
}

export const useFetch = (argParams: UseFetchParams): UseFetchResult => {
  const {
    initialUrl,
    initialParams = {},
    skip,
    initialBody,
    method = 'get',
    axiosInstance = axios,
  } = argParams;

  const [url, updateUrl] = useState(initialUrl);
  const [params, updateParams] = useState(initialParams);
  const [body, updateBody] = useState(initialBody);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [refetchIndex, setRefetchIndex] = useState(0);
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join('&');
  const refetch = (): void =>
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (skip) return;
      setIsLoading(true);
      try {
        const response = await axiosInstance[method](
          `${url}${queryString ? '?' : ''}${queryString}`,
          body,
        );
        const result = await response.data;
        setData(result);
      } catch (err) {
        setHasError(true);
        setErrorMessage(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url, params, refetchIndex, body, skip]);
  return {
    data,
    isLoading,
    hasError,
    errorMessage,
    updateUrl,
    updateParams,
    refetch,
    updateBody,
  };
};
