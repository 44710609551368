import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Formik, Form, Field } from 'formik';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  fade,
  CircularProgress,
  Chip,
  IconButton,
  DialogActions,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  savoyaTripCloseoutState,
  savoyaAdditionalChargesState,
} from 'src/trips/atoms/savoyaChargesAtom';
import SavoyaRatesBreakdown from 'src/tripsNewClients/components/TripAdditionalInfo/Quote/SavoyaRatesBreakdown';
import type { Camelize } from 'src/shared/types/camelize';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { utcToZonedTime } from 'date-fns-tz';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { isAxiosError } from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useInvoiceSubmittedMutation } from 'src/.gen/graphql';
import SavoyaLocationAutocomplete from 'src/rates/components/SavoyaTransferRates/SavoyaLocationAutocomplete';
import { Add } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import SavoyaTripSummary from './TripLog/SavoyaTripSummary';
import SavoyaAdditionalChargesBreakdown from './TripRateBreakdown/SavoyaAdditionalChargesBreakdown';
import {
  fetchVendorExchangeRate,
  fetchVendorCase,
  fetchTravelTimeRules,
  reservationCloseout,
  fetchCalculateHourlyQuote,
} from '../api/SavoyaTripQueries';
import {
  SavoyaAddtionalStop,
  SavoyaLocation,
  SavoyaTripStatus,
  SavoyaVendorAssignment,
  SavoyaVendorProfile,
} from '../types/SavoyaTripsTypes';
import { getTripSummary } from './TripRateBreakdown/savoyaTripRateCalculationsUtils';
import RateExceptionForm from './RateExceptionForm';
import SavoyaRateExceptionInfo from './TripRateBreakdown/SavoyaRateException';

interface SavoyaPaymentDetailsProps {
  tripId: string;
  savoyaVendorAssignment: Camelize<SavoyaVendorAssignment>;
  vendorProfile: SavoyaVendorProfile;
  initialDataLoading?: boolean;
  queryKey?: (string | Record<string, unknown>)[];
}

export enum SavoyaCostType {
  Transfer = 'transfer',
  Hourly = 'hourly',
}

export type CloseoutFromValues = {
  dropoffDateAdjustment: Date;
  outDateAdjustment: Date;
  onboardDateAdjustment: Date;
  inDateAdjustment: Date;
  airportFeeAmount?: number;
  fuelFeeAmount?: number;
  tollAmount?: number;
  miscAmount?: number;
  miscFeeNote?: string;
  parkingAmount?: number;
  nyCongestionFeeAmount?: number;
  taxAmount?: number;
  greeterAmount?: number;
  compensationPercentage?: number;
  baseRate?: number;
  minimumHours?: number;
  additionalStops?: SavoyaAddtionalStop[];
  costType?: SavoyaCostType;
  rateExceptionHourlyBase?: number;
  rateExceptionHourlyMinimumHours?: number;
  rateExceptionTransferBase?: number;
  rateExceptionReason?: string;
  rateExceptionType?: SavoyaCostType;
};

const SAVOYA_CLOSED_STATUSES: SavoyaTripStatus[] = [
  'billed',
  'paid',
  'closed_out',
  'finalized',
];

const SavoyaPaymentDetailsView: React.FC<SavoyaPaymentDetailsProps> = ({
  tripId,
  savoyaVendorAssignment,
  vendorProfile,
  initialDataLoading = false,
  queryKey,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showRateExceptionDialog, setShowRateExceptionDialog] = useState(false);
  const [showBillTripDialog, setShowBillTripDialog] = useState(false);
  const [grandTotalAmount, setGrandTotalAmount] = useState(0.0);
  const [compensationPercentage, setCompensationPercentage] = useState(0.0);
  const [newAdditionalStopAddress, setNewAdditionalStopAddress] =
    useState<string>();
  const [newAdditionalStopCity, setNewAdditionalStopCity] =
    useState<Partial<SavoyaLocation>>();

  const additionalChargesValues = useRecoilValue(savoyaAdditionalChargesState);
  const [savoyaTripCloseoutValues, setSavoyaTripCloseoutValues] =
    useRecoilState(savoyaTripCloseoutState);
  let disableCompensation = false;

  const {
    outDateAdjustment,
    onboardDateAdjustment,
    dropoffDateAdjustment,
    inDateAdjustment,
  } = getTripSummary(savoyaVendorAssignment);

  const billable =
    savoyaVendorAssignment?.reservation?.status === 'completed' ||
    savoyaVendorAssignment?.status === 'billable';

  const vendorId = savoyaVendorAssignment?.vendorId;
  const assignmentId = savoyaVendorAssignment?.id;
  const { isLoading: exchangeRateLoading, data: exchangeRate } = useQuery({
    queryKey: ['savoyaVendorExchangeRate', vendorId],
    keepPreviousData: true,
    queryFn: () => fetchVendorExchangeRate(vendorId),
    enabled: !!vendorId,
  });

  const { isLoading: hourlyQuoteLoading, data: hourlyQuote } = useQuery({
    queryKey: ['savoyaCalculatedHourlyQuote', assignmentId],
    keepPreviousData: true,
    queryFn: () => fetchCalculateHourlyQuote(assignmentId),
    enabled: !!assignmentId,
  });
  const submitCloseout = useMutation({
    mutationFn: reservationCloseout,
    onSuccess: () => {
      queryClient.invalidateQueries([
        'savoyaReservationAssignmentForFullTripView',
      ]);
      setShowBillTripDialog(false);
      enqueueSnackbar('Closeout Succesful', { variant: 'success' });
    },
    onError: (e) => {
      let errMessage = 'Error Closing Out Reservation';
      if (isAxiosError(e)) {
        errMessage = e?.response?.data?.length ? e?.response?.data[0] : null;
      }
      enqueueSnackbar(errMessage, { variant: 'error' });
    },
  });
  const [billTrip] = useInvoiceSubmittedMutation({
    variables: { tripId },
    refetchQueries: ['GetTripById'],
  });

  const reservationId = savoyaVendorAssignment?.reservation?.id;
  const timezone =
    savoyaVendorAssignment?.reservation?.stops?.[0]?.city?.timezone ||
    savoyaVendorAssignment?.reservation?.stops?.[0]?.airport?.timezone ||
    savoyaVendorAssignment?.reservation?.timezone;
  const { isLoading: vendorCaseLoading, data: vendorCase } = useQuery({
    queryKey: ['savoyaVendorCase', reservationId],
    queryFn: () => fetchVendorCase(reservationId),
    enabled: !!reservationId,
  });

  const { isLoading: travelTimeRulesLoading, data: travelTimeRules } = useQuery(
    {
      queryKey: ['savoyaReservationTravelTime', reservationId],
      queryFn: () => fetchTravelTimeRules(reservationId),
      enabled: !!reservationId,
    },
  );

  const { reservation, currencyCode = 'USD' } = savoyaVendorAssignment || {};

  const totalIncidentals = additionalChargesValues.reduce(
    (sum: number, charge) => {
      return sum + Number(charge.value);
    },
    0,
  );

  const submitCloseoutSavoyaTrip = async (formData: CloseoutFromValues) => {
    const firstStop = savoyaVendorAssignment?.reservation?.stops?.length
      ? savoyaVendorAssignment?.reservation?.stops[0]
      : null;
    try {
      await submitCloseout.mutateAsync({
        reservationId: savoyaVendorAssignment?.reservation?.id,
        foreignCurrency:
          savoyaVendorAssignment?.currencyCode &&
          savoyaVendorAssignment?.currencyCode !== 'USD',
        vendorQuote: savoyaVendorAssignment?.vendorQuote,
        closeoutValues: formData,
        totalIncidentals,
        timeZone:
          firstStop?.city?.timezone ||
          firstStop?.airport?.timezone ||
          savoyaVendorAssignment?.reservation?.timezone,
      });
      const billTripResult = await billTrip();
      if (billTripResult?.data?.executeInvoiceSubmitted?.succeeded) {
        navigate(`/trips/${tripId}`);
      }
    } catch (e) {
      enqueueSnackbar('Failed To Closeout Trip', { variant: 'error' });
    }
  };

  const calculateGrandTotalAmount = () => {
    const { invertedCompensation = 0, subtotalAmount = 0 } =
      savoyaTripCloseoutValues;

    setGrandTotalAmount(
      subtotalAmount * invertedCompensation + Number(totalIncidentals),
    );
  };

  useEffect(() => {
    calculateGrandTotalAmount();
  }, [savoyaVendorAssignment]);

  useEffect(() => {
    calculateGrandTotalAmount();
  }, [savoyaTripCloseoutValues, additionalChargesValues]);

  useEffect(() => {
    if ((vendorCase?.vendorCompensationPercentage || 0) > 0) {
      disableCompensation = true;
      setCompensationPercentage(vendorCase?.vendorCompensationPercentage / 100);
    } else {
      disableCompensation = false;
    }
    const percentage = Number.isNaN(compensationPercentage)
      ? 0
      : compensationPercentage;
    const invertedCompensation = (100 - percentage) / 100;
    setSavoyaTripCloseoutValues((oldValues) => ({
      ...oldValues,
      invertedCompensation,
    }));
    calculateGrandTotalAmount();
  }, [vendorCase?.vendorCompensationPercentage, compensationPercentage]);

  const compensation = {
    label: 'Compensation',
    value: compensationPercentage,
    show: !!compensationPercentage || reservation?.status === 'completed',
    editable: reservation?.status === 'completed',
  };

  const loading =
    initialDataLoading ||
    exchangeRateLoading ||
    hourlyQuoteLoading ||
    vendorCaseLoading ||
    travelTimeRulesLoading;

  const isClosedOut = SAVOYA_CLOSED_STATUSES?.includes(
    savoyaVendorAssignment?.reservation?.status,
  );
  const initialOutDate = isClosedOut
    ? outDateAdjustment?.vendorDate
    : outDateAdjustment?.reportedDate;
  const initialDropoffDate = isClosedOut
    ? dropoffDateAdjustment?.vendorDate
    : dropoffDateAdjustment?.reportedDate;
  const initialOnboardDate = isClosedOut
    ? onboardDateAdjustment?.vendorDate
    : onboardDateAdjustment?.reportedDate;
  const initialInDate = isClosedOut
    ? inDateAdjustment?.vendorDate
    : inDateAdjustment?.reportedDate;

  return (
    <React.Fragment>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{
          outDateAdjustment: initialOutDate
            ? utcToZonedTime(initialOutDate, timezone)
            : undefined,
          dropoffDateAdjustment: initialDropoffDate
            ? utcToZonedTime(initialDropoffDate, timezone)
            : undefined,
          onboardDateAdjustment: initialOnboardDate
            ? utcToZonedTime(initialOnboardDate, timezone)
            : undefined,
          inDateAdjustment: initialInDate
            ? utcToZonedTime(initialInDate, timezone)
            : undefined,
          baseRate: savoyaVendorAssignment?.vendorQuote?.baseCost
            ? (savoyaVendorAssignment?.vendorQuote?.baseCost || 0) / 100
            : null,
          parkingAmount:
            (savoyaVendorAssignment?.reservation?.incidentals?.parking
              ?.reportedAmount || 0) / 100,
          taxAmount:
            (savoyaVendorAssignment?.reservation?.incidentals?.tax
              ?.reportedAmount || 0) / 100,
          tollAmount:
            (savoyaVendorAssignment?.reservation?.incidentals?.toll
              ?.reportedAmount || 0) / 100,
          greeterAmount:
            (savoyaVendorAssignment?.reservation?.incidentals?.greeter
              ?.reportedAmount || 0) / 100,
          airportFeeAmount:
            (savoyaVendorAssignment?.reservation?.incidentals?.airportFee
              ?.reportedAmount || 0) / 100,
          miscAmount:
            (savoyaVendorAssignment?.reservation?.incidentals?.misc
              ?.reportedAmount || 0) / 100,
          nyCongestionFeeAmount:
            (savoyaVendorAssignment?.reservation?.incidentals?.nyCongestionFee
              ?.reportedAmount || 0) / 100,
          miscFeeNote:
            savoyaVendorAssignment?.reservation?.incidentals?.misc?.note,
          minimumHours: savoyaVendorAssignment?.vendorQuote?.minimum,
          additionalStops: [],
          costType:
            savoyaVendorAssignment?.vendorQuote?.type === 'transfer'
              ? SavoyaCostType.Transfer
              : SavoyaCostType.Hourly,
        }}
        onSubmit={submitCloseoutSavoyaTrip}
        validationSchema={Yup.object().shape({
          onboardDateAdjustment: Yup.date()
            .required()
            .min(
              Yup.ref('outDateAdjustment'),
              'Onboard time must be after Garage Out time',
            ),
          dropoffDateAdjustment: Yup.date()
            .required('Passenger Dropped Date is Required')
            .min(
              Yup.ref('onboardDateAdjustment'),
              'Dropoff time must be after Onboard time',
            ),
          inDateAdjustment: Yup.date().required('Garage In Date is Required'),
          outDateAdjustment: Yup.date().required('Garage Out Date is Required'),
          parkingAmount: Yup.number().nullable(),
          airportFeeAmount: Yup.number().nullable(),
          fuelFeeAmount: Yup.number().nullable(),
          tollAmount: Yup.number().nullable(),
          miscAmount: Yup.number().nullable(),
          taxAmount: Yup.number().nullable(),
          baseRate: Yup.number().required('Base Rate Required'),
          costType: Yup.mixed<SavoyaCostType>()
            .oneOf(Object.values(SavoyaCostType))
            .required('Cost Type is Required'),
          minimumHours: Yup.number()
            .nullable()
            .when('costType', {
              is: SavoyaCostType.Hourly,
              then: Yup.number().required('Minimum Hours is Required'),
            }),
          miscFeeNote: Yup.string()
            .nullable()
            .when('miscAmount', (miscAmount, schema) => {
              if (miscAmount > 0) {
                return schema.required('Misc Fee Note is Required');
              }
              return schema;
            }),
          greeterAmount: Yup.number().nullable(),
          compensationPercentage: Yup.number().nullable(),
          rateExceptionHourlyBase: Yup.number()
            .nullable()
            .when('rateExceptionType', {
              is: SavoyaCostType.Hourly,
              then: Yup.number().required('Hourly Base Rate is Required'),
            }),
          rateExceptionHourlyMinimumHours: Yup.number()
            .nullable()
            .when('rateExceptionType', {
              is: SavoyaCostType.Hourly,
              then: Yup.number().required('Minimum Hours is Required'),
            }),
          rateExceptionTransferBase: Yup.number()
            .nullable()
            .when('rateExceptionType', {
              is: SavoyaCostType.Transfer,
              then: Yup.number().required('Transfer Base Rate is Required'),
            }),
          rateExceptionReason: Yup.string()
            .nullable()
            .when('rateExceptionType', {
              is: (type) => !!type,
              then: Yup.string().required('Exception Reason is Required'),
            }),
          rateExceptionType: Yup.mixed<SavoyaCostType>().oneOf([
            ...Object.values(SavoyaCostType),
            null,
          ]),
        })}
      >
        {({ isValid, isSubmitting, setFieldValue, values, submitForm }) => {
          const onCloseRateExceptionDialog = () => {
            setFieldValue('rateExceptionHourlyBase', null);
            setFieldValue('rateExceptionHourlyMinimumHours', null);
            setFieldValue('rateExceptionTransferBase', null);
            setFieldValue('rateExceptionReason', null);
            setFieldValue('rateExceptionType', null);
            setShowRateExceptionDialog(false);
          };

          const miscValid =
            !values?.miscAmount ||
            savoyaVendorAssignment?.reservation?.fileAttachments?.length;

          return (
            <Form>
              <Grid
                container
                spacing={1}
                direction="column"
                alignItems="stretch"
                justify="space-between"
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  container
                  justify="space-between"
                  alignItems="flex-start"
                  direction="row"
                >
                  <SavoyaTripSummary
                    vendorAssignment={savoyaVendorAssignment}
                    editable={billable}
                  />
                  <SavoyaRatesBreakdown
                    loading={loading}
                    vendorAssignment={savoyaVendorAssignment}
                    travelTimeRules={travelTimeRules}
                    editable={billable && !savoyaVendorAssignment?.vendorQuote}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  container
                  justify="space-between"
                  alignItems="flex-start"
                  direction="row"
                >
                  {reservation?.status === 'completed' ? (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      container
                      justify="center"
                      direction="row"
                    >
                      <Box
                        className={classes.compensationAndAdditionalStops}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={12}>
                            <Typography className={classes.sectionTitles}>
                              Additional Stops
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              data-testid="savoya_trip_closeout_additional_stop_address"
                              label="Address"
                              type="text"
                              variant="outlined"
                              size="small"
                              onChange={(e) => {
                                setNewAdditionalStopAddress(e?.target?.value);
                              }}
                              value={newAdditionalStopAddress}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <SavoyaLocationAutocomplete
                              data-testid="savoya_trip_closeout_additional_stop_city"
                              label="City"
                              type="city"
                              size="small"
                              onSelected={(location) => {
                                setNewAdditionalStopCity(location?.value);
                              }}
                              value={{
                                label: newAdditionalStopCity?.displayName,
                                value: newAdditionalStopCity,
                              }}
                              lockType
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              data-testid="add_stop_button"
                              disabled={
                                !newAdditionalStopAddress ||
                                !newAdditionalStopCity
                              }
                              onClick={() => {
                                setFieldValue('additionalStops', [
                                  ...values?.additionalStops,
                                  {
                                    address: newAdditionalStopAddress,
                                    city: newAdditionalStopCity,
                                  },
                                ]);
                                setNewAdditionalStopAddress('');
                                setNewAdditionalStopCity(null);
                              }}
                            >
                              <Add
                                color={
                                  !newAdditionalStopAddress ||
                                  !newAdditionalStopCity
                                    ? 'secondary'
                                    : 'primary'
                                }
                              />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container direction="row">
                              {values?.additionalStops?.map((stop) => {
                                return (
                                  <Grid item>
                                    <Chip label={stop?.address} />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ) : null}
                  {compensation.show ? (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      container
                      justify="center"
                      direction="row"
                    >
                      <Box
                        className={classes.compensationAndAdditionalStops}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {loading ? (
                          <Skeleton width="100%" height={50} />
                        ) : (
                          <React.Fragment>
                            <Typography
                              variant="subtitle2"
                              className={classes.sectionTitles}
                            >
                              {compensation.label}
                            </Typography>
                            {compensation.editable ? (
                              <Field
                                id="compensationPercentage"
                                name="compensationPercentage"
                              >
                                {({ field, form }) => (
                                  <TextField
                                    {...field}
                                    data-testid="savoya_trip_closeout_compensation"
                                    placeholder="0.00"
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 100 }}
                                    value={
                                      field.value ||
                                      +compensationPercentage.toFixed(2)
                                    }
                                    onWheel={(e) =>
                                      e.target instanceof HTMLElement &&
                                      e.target.blur()
                                    }
                                    onChange={(e) => {
                                      setCompensationPercentage(
                                        parseFloat(e.target.value),
                                      );
                                      form.setFieldValue(
                                        field.name,
                                        parseFloat(e.target.value) || null,
                                      );
                                    }}
                                    disabled={disableCompensation}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              </Field>
                            ) : (
                              <Typography
                                variant="subtitle2"
                                className={classes.text}
                              >
                                {compensation.value.toFixed(2)}%
                              </Typography>
                            )}
                          </React.Fragment>
                        )}
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  container
                  justify="space-between"
                  alignItems="flex-start"
                  direction="row"
                >
                  {savoyaVendorAssignment?.rateException ? (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      container
                      justify="center"
                      direction="row"
                    >
                      <SavoyaRateExceptionInfo
                        rateException={savoyaVendorAssignment?.rateException}
                        currencyCode={
                          savoyaVendorAssignment?.currencyCode || 'USD'
                        }
                      />
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    md={savoyaVendorAssignment?.rateException ? 6 : 12}
                    container
                    justify="center"
                    direction="row"
                  >
                    <SavoyaAdditionalChargesBreakdown
                      vendorAssignment={savoyaVendorAssignment}
                      vendorProfile={vendorProfile}
                      exchangeRate={exchangeRate}
                      hourlyQuote={hourlyQuote}
                      vendorCase={vendorCase}
                      loading={loading}
                      editable
                      queryKey={queryKey}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  container
                  justify="center"
                  direction="row"
                >
                  <Box
                    className={classes.footer}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    {loading ? (
                      <Skeleton width="100%" height={50} />
                    ) : (
                      <React.Fragment>
                        <Typography variant="subtitle1" color="initial">
                          Grand Total
                        </Typography>
                        <Typography variant="subtitle1">
                          {getSymbolFromCurrency(currencyCode)}{' '}
                          {(grandTotalAmount || 0).toFixed(2)}
                        </Typography>
                      </React.Fragment>
                    )}
                  </Box>
                </Grid>
                {reservation?.status === 'completed' ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    justify="flex-end"
                    direction="row"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.formButton}
                      disabled={!isValid || !miscValid}
                      onClick={() => setShowBillTripDialog(true)}
                    >
                      Bill Trip
                    </Button>
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.formButton}
                      disabled={!isValid}
                      onClick={() => {
                        setFieldValue(
                          'rateExceptionType',
                          SavoyaCostType.Hourly,
                        );
                        setShowRateExceptionDialog(true);
                      }}
                    >
                      Request Rate Exception
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
              <GenericDialog
                openDialog={showRateExceptionDialog}
                setCloseDialog={onCloseRateExceptionDialog}
                dialogTitle="Request Rate Exception"
                maxWidth="md"
              >
                <RateExceptionForm
                  vendorAssignment={savoyaVendorAssignment}
                  onComplete={() => setShowRateExceptionDialog(false)}
                  onClose={onCloseRateExceptionDialog}
                  vendorProfile={vendorProfile}
                  exchangeRate={exchangeRate}
                  hourlyQuote={hourlyQuote}
                  vendorCase={vendorCase}
                  grandTotalAmount={grandTotalAmount}
                  totalIncidentals={totalIncidentals}
                />
              </GenericDialog>
              <GenericDialog
                openDialog={showBillTripDialog}
                setCloseDialog={() => setShowBillTripDialog(false)}
                dialogTitle="Closeout and Bill Trip"
                maxWidth="sm"
              >
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.submitModalText}
                >
                  {`Confirm Billing this Trip for: `}
                </Typography>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.submitModalText}
                >
                  {`${getSymbolFromCurrency(currencyCode)} ${(
                    grandTotalAmount || 0
                  ).toFixed(2)}`}
                </Typography>
                <DialogActions>
                  <Button
                    onClick={() => setShowBillTripDialog(false)}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      'Bill Trip'
                    )}
                  </Button>
                </DialogActions>
              </GenericDialog>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  compensationAndAdditionalStops: {
    color: theme.palette.textDark.main,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '15px',
    width: '100%',
    padding: '1rem',
    marginRight: '10px',
    marginLeft: '10px',
    alignItems: 'center',
  },
  footer: {
    backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
    color: theme.palette.textDark.main,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '15px',
    width: '100%',
    padding: '1rem',
    marginRight: '10px',
    marginLeft: '10px',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
  },
  submitModalText: {
    fontWeight: 'bold',
  },
  sectionTitles: {
    fontWeight: 'bold',
  },
  formButton: {
    marginRight: 10,
  },
}));

export default SavoyaPaymentDetailsView;
