import React from 'react';
import { useRecoilValue } from 'recoil';
import { tripCommandDialogState } from 'src/trips/atoms/tripCommandDialogAtom';
import {
  TripCommandMeta,
  useGetAvailableCommandsByTripIdQuery,
} from 'src/.gen/graphql';

interface ShowCancelButtonHook {
  (tripId: string): {
    showCancelButton: boolean;
    cancelCommand: TripCommandMeta;
  };
}

export const useShowCancelButton: ShowCancelButtonHook = (tripId) => {
  const { succeeded } = useRecoilValue(tripCommandDialogState);
  const [cancelCommand, setCancelCommand] =
    React.useState<TripCommandMeta>(null);
  const { data: availableCommands, refetch } =
    useGetAvailableCommandsByTripIdQuery({
      fetchPolicy: 'no-cache',
      variables: { tripId },
      skip: !tripId,
    });

  React.useEffect(() => {
    if (succeeded && tripId) {
      refetch();
    }
  }, [succeeded, tripId]);

  React.useEffect(() => {
    if (availableCommands?.trips?.byId?.availableCommands) {
      const showCancel = availableCommands.trips.byId.availableCommands.find(
        (command) => command.typeName === 'CancelTrip',
      );
      setCancelCommand(showCancel);
    }
  }, [availableCommands, succeeded]);

  return { cancelCommand, showCancelButton: !!cancelCommand };
};
