import React from 'react';
import { Tab, TabProps, withStyles } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { SetterOrUpdater } from 'recoil';
import { TabOption } from 'src/shared/components/CapitalDriveTabBar/CapitalDriveTabBar';
import usePermission from 'src/shared/hooks/usePermission';
import { PaidFeatureModalState } from 'src/subscription/atoms/SubscriptionState';

interface Props {
  tabs: TabOption[];
  customTabLabel?: (tab: TabOption) => React.ReactNode;
  setModalState?: SetterOrUpdater<PaidFeatureModalState>;
  modelOpenState?: boolean;
}

const TabWithPermission = withStyles({
  root: {},
  labelIcon: {
    '& $wrapper > *:first-child': {
      marginBottom: 0,
      marginRight: 5,
      fontSize: 18,
    },
  },
  wrapper: {
    flexDirection: 'row-reverse',
    '& svg, .material-icons': {
      marginLeft: 4,
    },
  },
})(Tab);

const a11yProps = (id: number | string) => {
  return {
    id: `simple-tab-${id}`,
    'aria-controls': `simple-tabpanel-${id}`,
  };
};

const TabWithPermissionWrapper: React.FC<TabProps> = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};

export const tabsWithPermissions = ({
  tabs,
  customTabLabel,
  setModalState,
  modelOpenState,
}: Props): JSX.Element[] => {
  return tabs.map((tab) => {
    const { tierCompliant: hasPermission } = usePermission({
      tierType: tab.minimumTier,
    });
    if (hasPermission) {
      return (
        <TabWithPermission
          key={tab.id}
          value={tab.id}
          label={customTabLabel ? customTabLabel(tab) : tab.title}
          style={tab?.style}
          {...a11yProps(tab.id)}
        />
      );
    }
    return (
      <TabWithPermissionWrapper key={tab.id}>
        <TabWithPermission
          value={tab.id}
          label={customTabLabel ? customTabLabel(tab) : tab.title}
          icon={<Lock color="primary" />}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            setModalState({ open: !modelOpenState, message: undefined });
          }}
          {...a11yProps(tab.id)}
        />
      </TabWithPermissionWrapper>
    );
  });
};
