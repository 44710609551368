import { FitBounds } from 'react-mapbox-gl/lib/map';
import { MarkerDetails } from 'src/shared/components/Map/MapWaypoint';
import { ExtendedTripWaypoint } from 'src/trips/components/TripMapTile/TripMapTile';
import {
  TripSegment,
  TripWaypoint,
  DistancesAndTime,
  TripWaypointRole,
  Trip,
  MonitoringSummary,
} from '../../.gen/graphql';
import { convertMinutesToFormattedTime } from './conversions';

type MinifiedTrip = {
  waypoints?: Partial<TripWaypoint>[];
  segments?: Partial<TripSegment>[];
};

export const orderWaypoints = (
  waypoints: Array<ExtendedTripWaypoint>,
): Array<ExtendedTripWaypoint> => {
  const pickupWaypoints = waypoints.filter(
    ({ role }) => role === TripWaypointRole.Pickup,
  );
  const stopWaypoints = waypoints
    .filter(({ role }) => role === TripWaypointRole.Stop)
    .sort((a, b) => {
      const orderA = a?.stopNumber || a?.order;
      const orderB = b?.stopNumber || b?.order;
      if (orderA <= orderB) {
        return -1;
      }
      return 1;
    });
  const garageWaypoints = waypoints.filter(
    ({ role }) => role === TripWaypointRole.Garage,
  );
  const dropoffWaypoints = waypoints.filter(
    ({ role }) => role === TripWaypointRole.Dropoff,
  );
  const orderedWaypoints = [
    garageWaypoints[0],
    ...pickupWaypoints,
    ...stopWaypoints,
    ...dropoffWaypoints,
    garageWaypoints[1],
  ].filter((e) => !!e);

  return orderedWaypoints;
};

export const getRouteCoordinates = (
  waypoints: Array<TripWaypoint>,
): Array<Array<number>> => {
  if (waypoints) {
    const orderedWaypoints = orderWaypoints(waypoints);
    const waypointCoordinates = orderedWaypoints.reduce((acc, curr) => {
      const pickupCoordinates = curr.location?.coordinates.coordinates || [];
      const pickupLatitude = pickupCoordinates[1];
      if (Math.abs(pickupLatitude) > 90) {
        return [...acc, [0, 0]];
      }
      return [
        ...acc,
        [
          curr.location.coordinates.coordinates[0] || 0,
          curr.location.coordinates.coordinates[1] || 0,
        ],
      ];
    }, []);

    return waypointCoordinates;
  }
  return [];
};

export const getMainTime = (args: {
  trip: Partial<Trip>;
  distancesAndTime: DistancesAndTime[];
}): number => {
  const { trip, distancesAndTime } = args;
  return distancesAndTime
    .filter((_distance, index) => {
      const orderedWaypoints = orderWaypoints(trip?.waypoints);
      return (
        orderedWaypoints[index]?.role !== TripWaypointRole.Garage &&
        orderedWaypoints[index]?.role !== TripWaypointRole.Dropoff
      );
    })
    .reduce(
      (accumulator, currentDistance) => accumulator + currentDistance.duration,
      0,
    );
};

export const getMarkerDetails = (trip: MinifiedTrip): MarkerDetails[] => {
  if (trip && trip.waypoints) {
    const waypointCoordinates = trip?.waypoints
      ?.filter(({ role }) => role !== TripWaypointRole.Garage)
      .map((waypoint) => {
        return {
          title: waypoint?.location?.addressLine1,
          waitTime: convertMinutesToFormattedTime(
            waypoint?.estimatedDurationInMinutes || 0,
          ),
          address: waypoint?.location?.name,
          role: waypoint?.role,
        };
      });
    return waypointCoordinates;
  }
  return [];
};

const southBound = [-130.382826, 49.433468];
const northBound = [-76.231753, 24.018932];

const getBound = (
  calcRoute: boolean,
  routeCoordinatesArray: number[][],
  routeCoordinates: number[][],
  isLongitude,
  bound: number[],
) => {
  // Smallest latitude, Smallest longitud
  let smallest =
    bound[0] === northBound[0] && bound[1] === northBound[1]
      ? -Infinity
      : Infinity;
  const coordinates = calcRoute ? routeCoordinatesArray : routeCoordinates;
  if (coordinates && coordinates.length > 0) {
    coordinates.forEach((coordinate) => {
      const current = coordinate[isLongitude ? 0 : 1];
      if (current) {
        // Since the waypoints are by default [0,0]
        if (bound[0] === northBound[0] && bound[1] === northBound[1]) {
          smallest = Math.max(smallest, current);
        } else {
          smallest = Math.min(smallest, current);
        }
      }
    });
    if (smallest === Infinity || smallest === -Infinity) {
      return bound[isLongitude ? 0 : 1];
    }
    return smallest;
  }
  // Default location
  return bound[isLongitude ? 0 : 1];
};

export const getBounds = (
  calcRoute: boolean,
  routeCoordinatesArray: number[][],
  routeCoordinates: number[][],
): FitBounds => {
  const southLonCorner = getBound(
    calcRoute,
    routeCoordinatesArray,
    routeCoordinates,
    true,
    southBound,
  );
  const southLatCorner = getBound(
    calcRoute,
    routeCoordinatesArray,
    routeCoordinates,
    false,
    southBound,
  );
  const northLonCorner = getBound(
    calcRoute,
    routeCoordinatesArray,
    routeCoordinates,
    true,
    northBound,
  );
  const northLatCorner = getBound(
    calcRoute,
    routeCoordinatesArray,
    routeCoordinates,
    false,
    northBound,
  );

  return [
    [southLonCorner, southLatCorner], // southwestern corner of the bounds
    [northLonCorner, northLatCorner],
  ];
};

const PI_2 = Math.PI * 2;
const toDegreeConversion = 180 / Math.PI;

export function differenceAngles(a: number, b: number): number {
  const c = Math.abs(a - b) % PI_2;
  return c > Math.PI ? PI_2 - c : c;
}

export function toDegrees(radians: number): number {
  return radians * toDegreeConversion;
}

export const getMapboxUrl = (coordinatesArray: number[][]): string => {
  const coordinatesString = coordinatesArray.reduce((acc, curr) => {
    if (curr && curr.length >= 2) {
      return `${acc}${curr[0]},${curr[1]};`;
    }
    return acc;
  }, '');

  const BASE_URL = 'https://api.mapbox.com/directions/v5/mapbox/driving/';
  return BASE_URL + coordinatesString.slice(0, -1);
};

export const getMonitoringWaypointsArray = (
  monitoringSummary: MonitoringSummary,
): number[][] => {
  const { destination = null, position = null } = monitoringSummary ?? {};
  function getCoordinates(item): number[] {
    if (item.location) {
      return item.location.coordinates.coordinates;
    }
    return item.coordinates.coordinates;
  }
  if (!destination || !position) {
    return [];
  }

  return [position, destination].reduce(
    (acc, item) => [...acc, getCoordinates(item)],
    [],
  );
};

export const getCoordinatesFromPayload = (
  apiPayload: Record<string, unknown>,
): number[][] => {
  try {
    if (apiPayload?.routes[0]) {
      return apiPayload?.routes[0].geometry?.coordinates || [];
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getDistancesFromPayload = (
  apiPayload: Record<string, unknown>,
): DistancesAndTime[] => {
  try {
    if (apiPayload?.routes[0]) {
      return apiPayload?.routes[0].legs.map((el) => ({
        distance: el.distance || 0,
        duration: el.duration,
      }));
    }
    return [];
  } catch (error) {
    return [];
  }
};
