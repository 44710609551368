import React from 'react';
import { Button, Typography, useTheme } from '@material-ui/core';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import {
  PaymentMethod,
  PaymentMethodType,
  TransactionItemSummary,
} from 'src/.gen/graphql';
import { format } from 'date-fns';

export interface RefundPaymentMethod extends PaymentMethod {
  type: PaymentMethodType;
}

interface Props {
  transaction: TransactionItemSummary;
}

const TransactionDetailsButton: React.FC<Props> = ({ transaction }) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        style={{
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontSize: '1rem',
          marginLeft: '5px',
        }}
        onClick={() => setOpenDialog(true)}
        size="small"
      >
        Details
      </Button>
      <GenericDialog
        openDialog={openDialog}
        setCloseDialog={() => setOpenDialog(false)}
        dialogTitle="Transaction"
        maxWidth="sm"
      >
        {transaction?.stripePaymentIntentId && (
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.main }}
          >
            Stripe ID:
          </Typography>
        )}
        {transaction?.stripePaymentIntentId && (
          <Typography variant="body1">
            {transaction?.stripePaymentIntentId}
          </Typography>
        )}
        <Typography
          variant="h6"
          style={{ color: theme.palette.primary.main, marginTop: '1rem' }}
        >
          Timestamp:
        </Typography>
        <Typography variant="body1">
          {format(new Date(transaction.createdAt), 'MM/dd/yyyy hh:mm aaa')}
        </Typography>
        <Typography
          variant="h6"
          style={{ color: theme.palette.primary.main, marginTop: '1rem' }}
        >
          Type:
        </Typography>
        <Typography variant="body1">{transaction?.type}</Typography>
        {transaction?.note && (
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.main, marginTop: '1rem' }}
          >
            Note:
          </Typography>
        )}
        {transaction?.note && (
          <Typography variant="body1">{transaction?.note || ''}</Typography>
        )}
        <Typography
          variant="h6"
          style={{ color: theme.palette.primary.main, marginTop: '1rem' }}
        >
          Status:
        </Typography>
        <Typography variant="body1">{transaction?.status}</Typography>
        <Typography
          variant="h6"
          style={{ color: theme.palette.primary.main, marginTop: '1rem' }}
        >
          Amount:
        </Typography>
        <Typography variant="body1" style={{ marginBottom: '1rem' }}>
          ${transaction?.amount.toFixed(2)}
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpenDialog(false)}
          >
            Close
          </Button>
        </div>
      </GenericDialog>
    </React.Fragment>
  );
};

export default TransactionDetailsButton;
