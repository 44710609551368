import React, { Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { TierType } from './.gen/graphql';
import AuthGuard from './authentication/components/AuthGuard';
import NotAuthGuard from './authentication/components/NotAuthGuard';
import LoadingScreen from './shared/components/LoadingScreen';
import { Restricted } from './shared/components/Permission';
import FaqPage from './faq/components/Faq';

const DashboardView = React.lazy(
  () => import('src/dashboard/views/DashboardView'),
);
const TripListView = React.lazy(() => import('src/trips/views/TripsListView'));

const SharedTripListView = React.lazy(
  () => import('src/tripSharing/views/SharedTripsListView'),
);

const NewTripsCreateView = React.lazy(
  () => import('src/tripsNewClients/views/TripCreateView'),
);
const ClientCreateView = React.lazy(
  () => import('src/tripsNewClients/views/ClientCreateView'),
);

const TripsSingleView = React.lazy(
  () => import('src/trips/views/TripsSingleView'),
);
const TripsUpdateView = React.lazy(
  () => import('src/trips/views/TripsUpdateView'),
);

// TODO: REMOVE THIS COMMENT ONCE THE NEW FLOW IS FINISHED
// const TripsAssignView = React.lazy(
//   () => import('src/trips/views/TripsAssignView'),
// );

const TripsAdditionalInfoView = React.lazy(
  () => import('src/tripsNewClients/views/TripsAdditionalInfoView'),
);

const ShareableTrip = React.lazy(
  () =>
    import('src/tripsNewClients/components/TripAdditionalInfo/ShareableTrip'),
);

const TripMonitoringView = React.lazy(
  () => import('src/trips/views/TripMonitoringView'),
);

const SharedTripPublicView = React.lazy(
  () => import('src/tripSharing/views/PublicInfoView'),
);

const SharedTripFullView = React.lazy(
  () => import('src/tripSharing/views/FullInfoView'),
);

const NetworkView = React.lazy(
  () => import('src/tripSharing/views/NetworkView'),
);

const FeedBackInvitationView = React.lazy(
  () => import('src/tripSharing/views/FeedBackInvitationView'),
);

const RatesDashboardView = React.lazy(
  () => import('src/rates/views/RatesDashboardView'),
);

const ReportsView = React.lazy(() => import('src/reports/views/ReportsView'));

const RateTypesView = React.lazy(() => import('src/rates/views/RateTypesView'));

const VehicleClassesSingleView = React.lazy(
  () => import('src/vehicle_classes/views/VehicleClassSingleView'),
);

const UsersView = React.lazy(() => import('src/users/views/UsersView'));

const UserProfileView = React.lazy(
  () => import('src/users/views/UserProfileView'),
);

const UserSingleView = React.lazy(
  () => import('src/users/views/UserSingleView'),
);

const Onboarding = React.lazy(
  () => import('src/onboarding/views/OnboardingView/OnboardingView'),
);

const SubscriptionView = React.lazy(
  () => import('src/subscription/views/SubscriptionView'),
);

const PaymentView = React.lazy(
  () => import('src/subscription/views/PaymentView'),
);

const PaymentSucceededView = React.lazy(
  () => import('src/subscription/views/PaymentSucceededView'),
);

const CalendarView = React.lazy(
  () => import('src/calendar/views/CalendarView'),
);

const DriverCalendarView = React.lazy(
  () => import('src/calendar/views/DriverCalendarView'),
);

const AccountsView = React.lazy(
  () => import('src/accounts/views/AccountsView'),
);

const AccountEditForm = React.lazy(
  () => import('src/accounts/views/AccountEditForm'),
);

const AccountCreateForm = React.lazy(
  () => import('src/accounts/views/AccountCreateForm'),
);

const VehicleListView = React.lazy(
  () => import('src/vehicles/views/VehiclesListView'),
);

const VehiclesSingleView = React.lazy(
  () => import('src/vehicles/views/VehiclesSingleView'),
);

const GarageView = React.lazy(() => import('src/garages/views/GaragesView'));

const AccountView = React.lazy(() => import('src/account/views/AccountView'));

const CustomerView = React.lazy(
  () => import('src/clients/views/CustomersView'),
);

const CompanySingleView = React.lazy(
  () => import('src/clients/views/ CompanySingleView'),
);

const ClientSingleView = React.lazy(
  () => import('src/clients/views/ClientSingleView'),
);

const NotFoundView = React.lazy(
  () => import('src/shared/views/errors/NotFoundView'),
);

const PaidFeatureView = React.lazy(
  () => import('src/shared/views/errors/PaidFeatureView'),
);

const MobileMapWebView = React.lazy(
  () => import('src/mobileMapWebview/views/MobileMapWebView'),
);

const MobileWebView = React.lazy(
  () => import('src/mobileWebview/views/MobileWebView'),
);

const NonVerifiedEmail = React.lazy(
  () => import('src/shared/views/nonVerifiedEmail/NonVerifiedEmail'),
);
const VerifiedEmail = React.lazy(
  () => import('src/shared/views/VerifiedEmail/VerifiedEmail'),
);

const NonUserView = React.lazy(
  () => import('src/shared/views/nonUser/NonUserView'),
);

const Unauthorized = React.lazy(
  () => import('src/shared/views/unauthorized/Unauthorized'),
);

const SavoyaDriverLandingPage = React.lazy(
  () => import('src/shared/views/savoyaDriverLanding/SavoyaDriverLandingPage'),
);

const SettingsView = React.lazy(
  () => import('src/settings/views/SettingsView'),
);

const BookingView = React.lazy(
  () => import('src/trips/views/TripCreateBooking'),
);

const BookingSuccessView = React.lazy(
  () => import('src/trips/views/TripBookingSucceeded'),
);

const TermsOfServiceView = React.lazy(
  () => import('src/shared/components/TermsOfService/TermsOfService'),
);

const ToDoView = React.lazy(() => import('src/todo/views/ToDosView'));

const Router: React.FC = () => {
  const routes: Array<RouteObject> = [
    {
      path: '/',
      element: <AuthGuard />,
      children: [
        {
          path: '/',
          element: <DashboardView />,
        },
        {
          path: '/trips/list',
          element: <TripListView />,
        },
        {
          path: '/shared-trips/list',
          element: <SharedTripListView />,
        },
        {
          path: '/trips/new',
          element: (
            <Restricted
              minimumTier={TierType.Premium}
              fallbackComponent={<PaidFeatureView />}
            >
              <NewTripsCreateView />
            </Restricted>
          ),
        },
        {
          path: '/trips/new/:tripId',
          element: (
            <Restricted
              minimumTier={TierType.Premium}
              fallbackComponent={<PaidFeatureView />}
            >
              <NewTripsCreateView />
            </Restricted>
          ),
        },
        {
          path: '/trips/:tripId',
          element: <TripsSingleView />,
        },
        {
          path: '/trips/edit/:tripId',
          element: <TripsUpdateView />,
        },
        {
          path: '/trips/assign/:tripId',
          element: <TripsAdditionalInfoView />,
        },
        {
          path: '/trips/shared/:tripId',
          element: (
            <Restricted minimumTier={TierType.Premium}>
              <ShareableTrip />
            </Restricted>
          ),
        },
        {
          path: '/network',
          element: <NetworkView />,
        },
        {
          path: '/rates',
          element: <RatesDashboardView />,
        },
        {
          path: '/reports',
          element: <ReportsView />,
        },
        {
          path: '/rates',
          element: <RatesDashboardView />,
        },
        {
          path: '/rate-types',
          element: <RateTypesView />,
        },
        {
          path: '/vehicle-classes/:vehicleClassId',
          element: <VehicleClassesSingleView />,
        },
        {
          // Savoya only route
          path: '/drivers',
          element: <UsersView />,
        },
        {
          // Savoya only route
          path: '/todo',
          element: <ToDoView />,
        },
        {
          path: '/users',
          element: <UsersView />,
        },
        {
          path: '/users/profile/:userId',
          element: <UserProfileView />,
        },
        {
          path: '/users/:userId',
          element: <UserSingleView />,
        },
        {
          path: '/onboarding',
          element: <Onboarding />,
        },
        {
          path: '/calendar',
          element: <CalendarView />,
        },
        {
          path: '/my-calendar',
          element: <DriverCalendarView />,
        },
        {
          path: '/accounts',
          element: <AccountsView />,
        },
        {
          path: '/accounts/edit/:accountId',
          element: <AccountEditForm />,
        },
        {
          path: '/accounts/new',
          element: <AccountCreateForm />,
        },
        {
          path: '/accounts/:accountId',
          element: <AccountsView />,
        },
        {
          path: '/vehicles',
          element: <VehicleListView />,
        },
        {
          path: '/vehicles/:vehicleId',
          element: <VehiclesSingleView />,
        },
        {
          path: '/garages/:garageId',
          element: <GarageView />,
        },
        {
          path: '/account',
          element: <AccountView />,
        },
        {
          path: '/customers',
          element: <CustomerView />,
        },
        {
          path: '/companies/:companyId',
          element: <CompanySingleView />,
        },
        {
          path: '/clients/:clientId',
          element: <ClientSingleView />,
        },
        {
          path: '/customers/create',
          element: <ClientCreateView />,
        },
        {
          path: '/customers/create/:customerType',
          element: <ClientCreateView />,
        },

        {
          path: '/settings',
          element: <SettingsView />,
        },

        {
          path: '/faq',
          element: <FaqPage />,
        },
        {
          path: '/shared-trip/full/:token',
          element: <SharedTripFullView />,
        },
        {
          path: '/paid-feature',
          element: <PaidFeatureView />,
        },
        {
          path: '/404',
          element: <NotFoundView />,
        },
        {
          path: '*',
          element: <NotFoundView />,
        },
        {
          path: '/subscriptions',
          element: <SubscriptionView />,
        },
        {
          path: '/subscriptions/payment',
          element: <PaymentView />,
        },
        {
          path: '/subscriptions/payment/succeeded',
          element: <PaymentSucceededView />,
        },
        {
          path: '/onboarding/succeeded',
          element: <PaymentSucceededView />,
        },
      ],
    },
    {
      path: '/',
      element: <NotAuthGuard />,
      children: [
        {
          path: '/signup',
          element: <React.Fragment />,
        },
        {
          path: '/non-user',
          element: <NonUserView />,
        },
        {
          path: '/unauthorized',
          element: <Unauthorized />,
        },
        {
          path: '/savoya-driver',
          element: <SavoyaDriverLandingPage />,
        },
        {
          path: '/404',
          element: <NotFoundView />,
        },
        {
          path: '/mobile-map',
          element: <MobileMapWebView />,
        },
        {
          path: '/mobile-webview',
          element: <MobileWebView />,
        },
        {
          path: '*',
          element: <NotFoundView />,
        },
      ],
    },
    {
      path: '/trips/monitor/:token',
      element: <TripMonitoringView />,
    },
    {
      path: '/shared-trip/:token',
      element: <SharedTripPublicView />,
    },
    {
      path: '/booking/:token',
      element: <BookingView />,
    },
    {
      path: '/bookingSuccess/:token',
      element: <BookingSuccessView />,
    },
    {
      path: '/invitation',
      element: <FeedBackInvitationView />,
    },
    {
      path: '/non-verified-email',
      element: <NonVerifiedEmail />,
    },
    {
      path: '/verified-email',
      element: <VerifiedEmail />,
    },
    {
      path: '/terms-of-service',
      element: <TermsOfServiceView />,
    },
    {
      path: '/not-found',
      element: <NotFoundView />,
    },
  ];

  const routing = useRoutes(routes);

  return <Suspense fallback={<LoadingScreen />}>{routing}</Suspense>;
};

export default Router;
