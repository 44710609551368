import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import _snakeCase from 'lodash/snakeCase';

interface NextTripInfoItemProps {
  label?: string;
  value: ReactNode;
  loading?: boolean;
  oneLine?: boolean;
  travelTime?: number;
  note?: string;
  showGroundWorkLogo?: boolean;
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.textGray.dark,
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
  },
  travelTime: {
    fontSize: 14,
  },
  icon: {
    fontSize: '2.5rem',
  },
  groundworkLogo: {
    width: '1rem',
    filter: 'brightness(0%)',
    verticalAlign: 'middle',
    float: 'right',
  },
}));

const NextTripInfoItem: React.FC<NextTripInfoItemProps> = ({
  label,
  value,
  loading = false,
  oneLine,
  travelTime,
  note,
  showGroundWorkLogo,
}) => {
  const classes = useStyles();
  const primaryDataTestId = `${_snakeCase(label)}_trip_info_item_content`;
  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      data-testid={primaryDataTestId}
    >
      <Grid
        item
        xs={oneLine ? 3 : 12}
        data-testid={`${primaryDataTestId}_title`}
      >
        <div>
          <Typography
            variant="caption"
            className={classes.label}
            display="inline"
          >
            {loading ? <Skeleton /> : `${label}:`}
          </Typography>
          {showGroundWorkLogo ? (
            <img
              alt="Savoya"
              src="/static/images/GroundworkLogo.svg"
              className={classes.groundworkLogo}
            />
          ) : null}
        </div>
      </Grid>
      <Grid
        item
        xs={oneLine ? 9 : 12}
        data-testid={`${primaryDataTestId}_body"`}
      >
        {loading && (
          <Typography>
            <Skeleton />
          </Typography>
        )}
        {!loading && typeof value === 'string' && (
          <Typography variant="body2" className={classes.value}>
            {value}
          </Typography>
        )}
        {!loading && travelTime ? (
          <Typography variant="body2" className={classes.travelTime}>
            <b>Travel time:</b> {travelTime} min
          </Typography>
        ) : null}
        {!loading && typeof value !== 'string' && value}
        {!loading && note ? (
          <Typography variant="caption">
            <b>Note:</b> {note}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default NextTripInfoItem;
