import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'src/shared/components/Page';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { identify } from 'react-fullstory';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredBox: {
    flex: 1,
    width: '22rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  description: {
    width: '20rem',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const SomethingWentWrongView: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  React.useEffect(() => {
    identify(user?.id, {
      displayName: user?.name,
      email: user?.email,
    });
  }, [user]);

  return (
    <Page>
      <Container>
        <Grid container item xs={12}>
          <Card className={classes.card}>
            <Box className={classes.centeredBox}>
              <Typography className={classes.title} variant="h5" align="center">
                Something went wrong!
              </Typography>
              <Typography variant="h5" color="textSecondary" align="center">
                We are working on fixing the problem. Try again later or contact
                support if the error persists.
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => navigate('/trips/list')}
              >
                Return to home
              </Button>
            </Box>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
};

export default SomethingWentWrongView;
