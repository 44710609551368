import axios from 'axios';
import { camelCase, mapKeys, snakeCase } from 'lodash';
import { SavoyaLocation } from 'src/trips/types/SavoyaTripsTypes';
import { SavoyaTransferRateInitialValues } from '../components/SavoyaTransferRates/SavoyaTransferRateBaseForm';

export interface SavoyaHourlyRate {
  amount: number;
  id: number;
  minimum: number;
  cancellationHours: number;
  vendorId: number;
  vehicleType: {
    id: number;
    name: string;
    canBeQuotedWithoutRate: boolean;
    greeter: boolean;
    classification: string;
  };
  startDate: string;
  endDate: string;
  groundwork: boolean;
}

export interface SavoyaTransferRate {
  id: number;
  vendorId: number;
  groundwork: boolean;
  specialEvent: boolean;
  vehicleType: SavoyaVehicleType;
  transferCost: string;
  point1Name: string;
  point2Name: string;
  cancellationHours: string;
  startDate: string;
  endDate: string;
}

export interface SavoyaVehicleType {
  id: number;
  name: string;
  canBeQuotedWithoutRate: boolean;
  greeter: boolean;
  classification: string;
}

export interface SavoyaTransferCostResponse {
  paginated: {
    records: SavoyaTransferRate[];
    total: number;
    page: number;
    perPage: number;
  };
  filterableVehicleTypes: SavoyaVehicleType[];
}

export interface SavoyaVendorBlackout {
  id: number;
  vendorId: number;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface SavoyaVendorRecurringBlackout {
  day: string;
  times: {
    id: number;
    startTime: number;
    endTime: number;
  }[];
}

export interface SavoyaVendorLocationPreferences {
  id: number;
  displayName: string;
  locationId: number;
  locationType: 'City' | 'Airport';
  preference: 'blacklisted' | 'preferred';
}

export interface SavoyaVendoMissedOpportunities {
  missedOpportunitiesRevenue: number;
  blacklistedLocations: {
    name: string;
    total: number;
  }[];
  recurringBlackouts: {
    startTime: number;
    endTime: number;
    day: string;
    total: number;
  }[];
  shortNotice: number;
}

const daysObject = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

const parseTimeForSavoyaApi = (dateString: string): number => {
  const date = new Date(dateString);

  const minutes = date?.getMinutes();
  const hours = date?.getHours();
  const parsedMinutes = (minutes * 100) / 60;

  return parseFloat(`${hours}.${parsedMinutes}`);
};

export const fetchHourlyCosts = async (): Promise<SavoyaHourlyRate[]> => {
  const res = await axios.get(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/hourly_costs`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );
  const data = res?.data?.records?.map((record) => {
    return mapKeys(record, (v, k) => camelCase(k));
  });
  return data;
};

export const fetchVehicleTypes = async (): Promise<SavoyaVehicleType[]> => {
  const res = await axios.get(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vehicle_types`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );
  const data = res?.data?.map((record) => {
    return mapKeys(record, (v, k) => camelCase(k));
  });

  return data;
};

export const updateHourlyCost = async (
  values: SavoyaHourlyRate,
): Promise<SavoyaVehicleType> => {
  const payload = mapKeys(values, (v, k) => snakeCase(k));
  const data = await axios.put(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/hourly_costs/${values?.id}`,
    payload,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return mapKeys(data, (v, k) => camelCase(k)) as SavoyaVehicleType;
};

export const insertHourlyCost = async (
  values: SavoyaHourlyRate,
): Promise<SavoyaVehicleType> => {
  const payload = mapKeys(values, (v, k) => snakeCase(k));
  const data = await axios.post(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/hourly_costs/`,
    payload,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return mapKeys(data, (v, k) => camelCase(k)) as SavoyaVehicleType;
};

export const deleteHourlyCost = async (id: string): Promise<boolean> => {
  await axios.delete(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/hourly_costs/${id}`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return true;
};

export const fetchTransferRates = async (
  page: number,
  pageSize: number,
  fromLocation?: Partial<SavoyaLocation>,
  toLocation?: Partial<SavoyaLocation>,
  vehicleType?: Partial<SavoyaVehicleType>,
): Promise<SavoyaTransferCostResponse> => {
  const locationIds = [];
  if (fromLocation) {
    locationIds.push(fromLocation?.id);
  }
  if (toLocation) {
    locationIds.push(toLocation?.id);
  }
  let baseUrl = `${
    process.env.REACT_APP_SAVOYA_API
  }/api/v1/vendor/transfer_costs/?page=${
    page + 1
  }&per_page=${pageSize}&location_ids=${encodeURIComponent(
    JSON.stringify(locationIds),
  )}`;

  if (vehicleType?.id) {
    baseUrl = `${baseUrl}&vehicle_type_id=${vehicleType?.id}`;
  }

  const res = await axios.get(baseUrl, {
    headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
  });
  const records = res?.data?.paginated?.records?.map((record) => {
    return mapKeys(record, (v, k) => camelCase(k));
  });

  const vehicleTypes = res?.data?.filterable_vehicle_types?.map(
    (vehicleType) => {
      return mapKeys(vehicleType, (v, k) => camelCase(k));
    },
  );

  return {
    paginated: {
      total: res?.data?.paginated?.total,
      perPage: res?.data?.paginated?.per_page,
      page: res?.data?.paginated?.page,
      records,
    },
    filterableVehicleTypes: vehicleTypes,
  };
};

export const fetchLocations = async (
  query: string,
  type: 'city' | 'airport' | 'preferences',
  preferenceType?: 'city' | 'airport',
): Promise<SavoyaLocation[]> => {
  if (type === 'preferences') {
    let baseUrl = `${process.env.REACT_APP_SAVOYA_API}/api/v1//vendor/location_preferences/search?q=${query}`;

    if (preferenceType) {
      baseUrl = `${baseUrl}&location_type=${preferenceType}`;
    }
    const res = await axios.get(baseUrl, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    const data = res?.data?.map((record) => {
      return mapKeys(record, (v, k) => camelCase(k));
    });

    return data;
  }
  const res = await axios.get(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/${
      type === 'airport' ? 'airports' : 'cities'
    }/?q=${query}${type === 'airport' ? '&limit_results_to=20' : ''}`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  const data = res?.data?.records?.map((record) => {
    return mapKeys(record, (v, k) => camelCase(k));
  });

  return data;
};

export const addTransferCost = async (
  values: SavoyaTransferRateInitialValues,
): Promise<SavoyaTransferRate> => {
  const payload = mapKeys(values, (v, k) => {
    if (k === 'point1' || k === 'point2') {
      return k;
    }
    return snakeCase(k);
  });
  const { data } = await axios.post(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/transfer_costs/`,
    payload,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return mapKeys(data, (v, k) => camelCase(k)) as SavoyaTransferRate;
};

export const updateTransferCost = async (
  values: SavoyaTransferRateInitialValues,
): Promise<SavoyaTransferRate> => {
  const payload = mapKeys(values, (v, k) => snakeCase(k));
  const { data } = await axios.put(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/transfer_costs/${values?.id}`,
    payload,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return mapKeys(data, (v, k) => camelCase(k)) as SavoyaTransferRate;
};

export const deleteTransferCost = async (args: {
  id: number;
}): Promise<boolean> => {
  await axios.delete(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/transfer_costs/${args?.id}`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return true;
};

export const fetchBlackouts = async (): Promise<SavoyaVendorBlackout[]> => {
  const res = await axios.get(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/blackouts`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );
  const data = res?.data?.map((record) => {
    return mapKeys(record, (v, k) => camelCase(k));
  });

  return data;
};

export const fetchRecurringBlackouts = async (): Promise<
  SavoyaVendorRecurringBlackout[]
> => {
  const res = await axios.get(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/recurring_blackouts`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );
  const data = res?.data?.map((record) => {
    const times = record?.times?.map((time) =>
      mapKeys(time, (v, k) => camelCase(k)),
    );
    return Object.assign(record, { times });
  });

  return data;
};

export const fetchLocationPreferences = async (): Promise<
  SavoyaVendorLocationPreferences[]
> => {
  const res = await axios.get(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/location_preferences`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );
  const data = res?.data?.map((record) => {
    return mapKeys(record, (v, k) => camelCase(k));
  });

  return data;
};

export const addLocationPreference = async (values: {
  locationId: number;
  preference: 'blacklisted' | 'preferred';
}): Promise<SavoyaVendorLocationPreferences> => {
  const payload = {
    location_id: values.locationId,
    preference: values.preference,
  };
  const { data } = await axios.post(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/location_preferences/`,
    payload,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return mapKeys(data, (v, k) =>
    camelCase(k),
  ) as SavoyaVendorLocationPreferences;
};

export const fetchMissedOpportunities =
  async (): Promise<SavoyaVendoMissedOpportunities> => {
    const res = await axios.get(
      `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/missed_opportunities`,
      { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
    );

    const recurringBlackouts = res?.data?.recurring_blackouts?.map((record) => {
      return mapKeys(record, (v, k) => camelCase(k));
    });
    return Object.assign(
      mapKeys(res?.data, (v, k) => camelCase(k)),
      { recurringBlackouts },
    ) as SavoyaVendoMissedOpportunities;
  };

export const addBlackout = async (values: {
  startDate: string;
  endDate: string;
}): Promise<SavoyaVendorBlackout> => {
  const payload = { start_date: values.startDate, end_date: values.endDate };
  const { data } = await axios.post(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/blackouts/`,
    payload,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return mapKeys(data, (v, k) => camelCase(k)) as SavoyaVendorBlackout;
};

export const addRecurringBlackout = async (values: {
  days: string[];
  startTime: string;
  endTime: string;
}): Promise<SavoyaVendorRecurringBlackout> => {
  const parsedDays = values?.days?.map((day) => daysObject[day]);
  const payload = {
    start_time: parseTimeForSavoyaApi(values?.startTime),
    end_time: parseTimeForSavoyaApi(values?.endTime),
    day: parsedDays,
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/recurring_blackouts/`,
    payload,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return mapKeys(data, (v, k) => camelCase(k)) as SavoyaVendorRecurringBlackout;
};

export const deleteBlackout = async (id: number): Promise<boolean> => {
  await axios.delete(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/blackouts/${id}`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return true;
};

export const deleteRecurringBlackout = async (id: number): Promise<boolean> => {
  await axios.delete(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/recurring_blackouts/${id}`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return true;
};

export const deleteLocationPreference = async (
  id: number,
): Promise<boolean> => {
  await axios.delete(
    `${process.env.REACT_APP_SAVOYA_API}/api/v1/vendor/location_preferences/${id}`,
    { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
  );

  return true;
};
