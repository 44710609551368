import React, { useState } from 'react';
import { Box, Chip, Theme, makeStyles } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { useRecoilState } from 'recoil';
import { TierType } from 'src/.gen/graphql';
import usePermission from 'src/shared/hooks/usePermission';
import { paidFeatureModal } from 'src/subscription/atoms/SubscriptionState';

type RestrictedChildrenProps = {
  handleMouseEvents?: (event: React.MouseEvent) => void;
  mouseOver?: boolean;
  premiumIcon?: React.ReactNode;
};

type RestrictedProps = {
  minimumTier: TierType;
  fallbackComponent?:
    | JSX.Element
    | React.ReactNode
    | React.ReactElement
    | string;
  withIcon?: boolean;
  sneak?: boolean;
  hideIfNotCompliant?: boolean;
  children?:
    | React.ReactNode
    | React.ReactElement
    | ((props: RestrictedChildrenProps) => React.ReactNode);
};

type PremiumIconProps = {
  sneak: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  premiumIcon: {
    padding: '0.25rem',
  },
  premiumLockIcon: {
    fontSize: '0.8rem',
  },
  premiumChipRoot: {
    height: 23,
    border: '2px solid #966440',
  },
  premiumChipLabel: {
    fontSize: 12,
    fontWeight: 600,
    paddingLeft: 6,
    paddingRight: 6,
    color: theme.palette.primary.main,
    '-webkit-font-smoothing': 'antialiased',
  },
}));

const PremiumIcon: React.FC<PremiumIconProps> = ({ sneak }) => {
  const classes = useStyles();
  return sneak ? (
    <Box className={classes.premiumIcon}>
      <Chip
        variant="outlined"
        label="Premium"
        color="primary"
        classes={{
          root: classes.premiumChipRoot,
          label: classes.premiumChipLabel,
        }}
      />
    </Box>
  ) : (
    <Lock className={classes.premiumLockIcon} />
  );
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FC<RestrictedProps> = ({
  minimumTier,
  fallbackComponent,
  children,
  withIcon = false,
  sneak = false,
  hideIfNotCompliant = false,
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [{ open }, setModalState] = useRecoilState(paidFeatureModal);

  // We "connect" to the provider thanks to the permission hook
  const { tierCompliant: allowed } = usePermission({
    tierType: minimumTier,
  });

  const handleMouseEvents = (event: React.MouseEvent) => {
    setMouseOver(event.type === 'mouseover');
  };

  // If the user has that permission, render the children
  if (allowed) {
    return (
      <React.Fragment>
        {typeof children === 'function'
          ? children({ handleMouseEvents, mouseOver })
          : children}
      </React.Fragment>
    );
  }

  if (!allowed && hideIfNotCompliant) {
    return null;
  }

  if (fallbackComponent) {
    return <React.Fragment>{fallbackComponent}</React.Fragment>;
  }

  return (
    <Box
      display="flex"
      position="relative"
      onClick={(event: React.MouseEvent) => {
        event.preventDefault();
        if (sneak) {
          setModalState({ open: !open, message: undefined });
        }
      }}
    >
      {typeof children === 'function'
        ? children({
            handleMouseEvents,
            mouseOver,
            premiumIcon: withIcon ? <PremiumIcon sneak={sneak} /> : null,
          })
        : children}
    </Box>
  );
};

export default Restricted;
