import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Link,
  Box,
  IconButton,
  makeStyles,
  Collapse,
  Typography,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';
import {
  subscriptionPlanAtom,
  userAtom,
} from 'src/authentication/atoms/AuthState';
import { TierType, UserRole } from 'src/.gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    zIndex: theme.zIndex.drawer + 1,
    padding: '5px',
  },
  textBox: {
    width: '100%',
  },
  linkBtn: {
    marginLeft: 5,
  },
}));

const SETTINGS_TAB_ROUTE = '/settings?tab=subscription';

const PaymentIssueBanner: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { subscriptions: subscriptionsEnabled } = useFlags();
  const [openPaymentIssueBanner, setOpenPaymentIssueBanner] = useState(true);
  const subscriptionPlan = useRecoilValue(subscriptionPlanAtom);
  const user = useRecoilValue(userAtom);
  const paymethMethodExists =
    subscriptionPlan?.paymentMethod?.cardType &&
    subscriptionPlan?.paymentMethod?.cardLastFour &&
    subscriptionPlan?.paymentMethod?.zipCode;
  const hideBanner =
    user?.userRole !== UserRole.AccountOwner ||
    location.pathname.includes('/subscriptions') ||
    location.pathname.includes('/onboarding') ||
    location.pathname.includes('verified-email') ||
    location.pathname.includes('/settings') ||
    !subscriptionPlan?.currentTier ||
    paymethMethodExists ||
    subscriptionPlan?.currentTier === TierType.Free;

  if (!subscriptionsEnabled || hideBanner) {
    return null;
  }
  return (
    <Collapse in={openPaymentIssueBanner}>
      <Box className={classes.container}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenPaymentIssueBanner(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Box display="flex" className={classes.textBox}>
            <Typography display="inline" variant="body2" color="textPrimary">
              Your Capital Drive account is missing a payment method, Please
              update your payment method to avoid service disruption.
            </Typography>
            <Link
              href={SETTINGS_TAB_ROUTE}
              underline="none"
              className={classes.linkBtn}
            >
              <b>Update Payment Method</b>
            </Link>
          </Box>
        </Alert>
      </Box>
    </Collapse>
  );
};

export default PaymentIssueBanner;
