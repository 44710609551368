import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { green, red } from '@material-ui/core/colors';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { Fragment } from 'react';
import { useRecoilState } from 'recoil';
import { useArrivePickupMutation } from 'src/.gen/graphql';
import CircularProgressIndicator from 'src/shared/components/CircularLoadingIndicator';
import { tripCommandDialogState } from 'src/trips/atoms/tripCommandDialogAtom';
import * as Yup from 'yup';
import { handleTripCommandException } from 'src/trips/hooks/useTripCommand';
import BaseTripCommandDialog from './BaseTripCommandDialog';

const useStyles = makeStyles((theme: Theme) => ({
  confirmButton: {
    backgroundColor: green[400],
    color: '#ffffff',
    float: 'right',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  cancelButton: {
    backgroundColor: red[400],
    color: '#ffffff',
    float: 'left',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: red[500],
    },
  },
}));

const arrivePickupSchema = Yup.object().shape({
  stagingNotes: Yup.string().required('Required'),
});

const ArrivePickupTripCommandDialog: React.FC = () => {
  const [tripCommandDialog, setTripCommandDialog] = useRecoilState(
    tripCommandDialogState,
  );
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [arrivePickup, { loading }] = useArrivePickupMutation();
  const closeModal = (succeeded: boolean) => {
    setTripCommandDialog((tripCommandDialogState) => ({
      ...tripCommandDialogState,
      isOpen: false,
      loading: false,
      succeeded,
    }));
  };

  const handleSubmit = async (values) => {
    const {
      data: { executeArrivePickup },
    } = await arrivePickup({
      variables: {
        ...values,
        tripId: tripCommandDialog.tripId,
      },
    });

    handleTripCommandException(executeArrivePickup, (succeeded, errors) => {
      if (!succeeded) {
        errors.forEach((errorMessage) => {
          enqueueSnackbar(errorMessage, {
            variant: 'error',
          });
          Sentry.captureException(new Error(errorMessage));
        });
        closeModal(false);
        return;
      }
      closeModal(executeArrivePickup.succeeded);
    });
  };

  return (
    <Formik
      initialValues={{
        stagingNotes: '',
      }}
      validationSchema={arrivePickupSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <BaseTripCommandDialog
          title="Arrive to pickup"
          content={
            <Fragment>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Field
                    component={TextField}
                    label="Notes"
                    name="stagingNotes"
                    fullWidth
                    multiline
                    minRows={3}
                    rows={3}
                    maxRows={6}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Fragment>
          }
          actions={
            <Grid container>
              <Grid item xs={6}>
                <Button
                  className={classes.cancelButton}
                  onClick={() => {
                    closeModal(false);
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  startIcon={loading && <CircularProgressIndicator />}
                  className={classes.confirmButton}
                  type="submit"
                  color="primary"
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          }
        />
      </Form>
    </Formik>
  );
};

export default ArrivePickupTripCommandDialog;
