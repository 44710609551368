import React from 'react';
import { Button } from '@material-ui/core';
import {
  convertHoursToMinutes,
  convertMinutesToHours,
} from 'src/shared/utils/conversions';
import GenericDialog from 'src/shared/components/GenericDialog/GenericDialog';
import { useExecuteOverrideWaitTimeMutation } from 'src/.gen/graphql';
import { useStylesTableTimestamps } from '../TripLog/TripLog';
import { ColumnInvoiceTimes, InvoiceTimesRow } from './utils';
import OverrideSelectTime, { SelectedTime } from './OverrideSelectTime';

interface Props {
  tripId: string;
  value: number;
  column: InvoiceTimesRow;
  row: ColumnInvoiceTimes;
}

const OverrideWaitTime: React.FC<Props> = ({ tripId, value, column, row }) => {
  const classes = useStylesTableTimestamps();
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const [selectedWaitTime, setWaitTime] = React.useState<SelectedTime>();
  const [executeOverrideWaitTimeMutation] =
    useExecuteOverrideWaitTimeMutation();

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const handleSelectWaitTime = (value, column, row) => {
    const newWaitTime = {
      time: value,
      label: column.label,
      status: row.status,
    };
    setWaitTime(newWaitTime);
    setDialogVisible(true);
  };

  const handleOverrideWaitTime = async (values, manageCommandActions) => {
    const {
      data: { executeOverrideWaitTime },
    } = await executeOverrideWaitTimeMutation({
      variables: {
        command: {
          tripId,
          newWaitTime: convertMinutesToHours(values.newTime),
        },
      },
      refetchQueries: ['GetTripById'],
    });
    manageCommandActions(executeOverrideWaitTime, closeDialog);
  };

  return (
    <React.Fragment>
      <Button
        className={classes.button}
        variant="text"
        onClick={() => handleSelectWaitTime(value, column, row)}
      >
        {`${convertHoursToMinutes(value).toFixed(0)} min`}
      </Button>
      <GenericDialog
        openDialog={dialogVisible}
        setCloseDialog={closeDialog}
        dialogTitle={`Override ${selectedWaitTime?.label}`}
        maxWidth="sm"
      >
        <OverrideSelectTime
          selectedTime={selectedWaitTime}
          onCancel={closeDialog}
          onSubmit={handleOverrideWaitTime}
        />
      </GenericDialog>
    </React.Fragment>
  );
};

export default OverrideWaitTime;
