/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, utcToZonedTime } from 'date-fns-tz';
import _ from 'lodash';
import enUS from 'date-fns/locale/en-US';

export const getInitials = (name = ''): string => {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');
};
export const getFirstDayOfCurrentMonth = (): Date => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};
export const getDataFromPath = (obj: unknown, path: string): any => {
  try {
    return path.split('.').reduce((obj, i) => {
      const result = obj[i];
      return result;
    }, obj);
  } catch {
    return null;
  }
};

export function getDifference(origObj: unknown, newObj: unknown): unknown {
  return Object.keys(newObj).reduce((result, key) => {
    const value = newObj[key];
    if (!_.isEqual(value, origObj[key])) {
      return { ...result, [key]: value };
    }
    return result;
  }, {});
}

export const formatTimeZone = (
  timeZone: string,
  formatString: string,
): string => {
  return format(utcToZonedTime(new Date(), timeZone), formatString, {
    timeZone,
    locale: enUS,
  });
};

export default getInitials;
