import React from 'react';
import {
  Grid,
  Box,
  Button,
  makeStyles,
  Theme,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import { TripStatusTimeCorrectionReason } from 'src/.gen/graphql';
import { setHoursToDate } from 'src/trips/utils/timeSelector';
import { format } from 'date-fns';
import { splitByCamelCase } from 'src/shared/utils/stringUtils';
import { DatePicker } from '@material-ui/pickers';
import TimeSelector from '../TimeSelector/TimeSelector';

type initialValues = {
  reason?: TripStatusTimeCorrectionReason;
  note: string;
  newTimestamp: Date;
};

interface Props {
  initialValues?: initialValues;
  onCancel: () => void;
  onSubmit?: (values: initialValues) => void;
}

const TRIP_STATUS_TIME_CORRECTION_REASONS = Object.keys(
  TripStatusTimeCorrectionReason,
);

const AdjustTimestampForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          data-testid="adjustment_trip_status_timestamp_form"
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    onChange={(date) => {
                      const month = date.getMonth();
                      const day = date.getDate();
                      const year = date.getFullYear();
                      const newTimestamp = new Date(values.newTimestamp);
                      newTimestamp.setDate(day);
                      newTimestamp.setFullYear(year);
                      newTimestamp.setMonth(month);
                      setFieldValue('newTimestamp', newTimestamp);
                    }}
                    format="MM/dd/yyyy"
                    value={values.newTimestamp}
                    inputVariant="outlined"
                    label="Actual Date"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TimeSelector
                    setFieldValue={(field, value) =>
                      setFieldValue(
                        field,
                        setHoursToDate(values.newTimestamp, value as string),
                      )
                    }
                    label="Actual Time"
                    value={
                      values.newTimestamp
                        ? format(values.newTimestamp, 'HH:mm')
                        : '12:00'
                    }
                    name="newTimestamp"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Reason"
                    name="reason"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.reason}
                    variant="outlined"
                    inputProps={{
                      'data-testid': 'adjustment_trip_status_timestamp_reason',
                    }}
                  >
                    {TRIP_STATUS_TIME_CORRECTION_REASONS.map((reason) => (
                      <option key={reason} value={reason}>
                        {splitByCamelCase(reason)}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Note"
                    name="note"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.note}
                    variant="outlined"
                    multiline
                    rows={2}
                    inputProps={{
                      'data-testid': 'adjustment_trip_status_timestamp_note',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item xs={12} md={6}>
                  <Box display="flex" flex={1} justifyContent="flex-end">
                    <Button
                      data-testid="adjustment_trip_status_timestamp_cancel_button"
                      variant="outlined"
                      color="primary"
                      onClick={onCancel}
                    >
                      cancel
                    </Button>
                    <Button
                      data-testid="adjustment_trip_status_timestamp_save_button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      className={classes.submitButton}
                    >
                      {isSubmitting ? 'Loading..' : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default AdjustTimestampForm;
