import { atom } from 'recoil';

export interface PaidFeatureModalState {
  open: boolean;
  message: string | undefined;
}

export const paidFeatureModal = atom<PaidFeatureModalState>({
  key: 'paidFeatureModal',
  default: { open: false, message: undefined },
});
