import React, { FC, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { savoyaRatesDialogsState } from 'src/rates/atoms/SavoyaRateFormState';
import { CircularProgress } from '@material-ui/core';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  deleteHourlyCost,
  fetchHourlyCosts,
  fetchVehicleTypes,
} from 'src/rates/api/SavoyaRateQueries';
import Dinero from 'dinero.js';
import { useSnackbar } from 'notistack';
import ConfirmationDialog from 'src/shared/components/ConfirmationDialog/ConfirmationDialog';
import SavoyaStandardHourlyRatesTable from './SavoyaStandardHourlyRatesTable/SavoyaStandardHourlyRatesTable';
import AddSavoyaHourlyRateDialog from './SavoyaHourlyRateDialog/AddSavoyaHourlyRateDialog';
import EditSavoyaHourlyRateDialog from './SavoyaHourlyRateDialog/EditSavoyaHourlyRateDialog';
import { SavoyaHourlyFormInitialValues } from './SavoyaHourlyRateBaseForm';

const SavoyaStandardHourlyRates: FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const { isLoading: hourlyRatesLoading, data: hourlyRates } = useQuery({
    queryKey: ['hourlyRates'],
    queryFn: fetchHourlyCosts,
  });

  const { isLoading: vehicleTypesLoading, data: vehicleTypes } = useQuery({
    queryKey: ['vehcileTypes'],
    queryFn: fetchVehicleTypes,
  });
  const setDialogState = useSetRecoilState(savoyaRatesDialogsState);
  const [editingInfo, setEditingInfo] =
    useState<SavoyaHourlyFormInitialValues>();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const deleteSavoyaHourlyRate = useMutation({
    mutationFn: deleteHourlyCost,
    onSuccess: () => {
      queryClient.invalidateQueries(['hourlyRates']);
      enqueueSnackbar('Hourly Rate Deleted', { variant: 'success' });
    },
    onError: () =>
      enqueueSnackbar('Error Deleting Hourly Rate', { variant: 'error' }),
  });

  const [deleteDialog, setDeleteDialog] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  return hourlyRatesLoading || vehicleTypesLoading ? (
    <CircularProgress color="secondary" />
  ) : (
    <div data-testid="savoya_standard_rates_list">
      <SavoyaStandardHourlyRatesTable
        canEdit={canEdit}
        hourlyRates={hourlyRates}
        onCreate={() => {
          setDialogState((prev) => ({
            ...prev,
            openCreateHourlyRateDialog: true,
          }));
        }}
        onEdit={(initialRate) => {
          setEditingInfo({
            ...initialRate,
            amount: Dinero({
              amount: initialRate?.amount || 0,
              currency: 'USD',
            })
              .toUnit()
              .toFixed(2),
          });
          setDialogState((prev) => ({
            ...prev,
            openEditHourlyRateDialog: true,
          }));
        }}
        onDelete={(id) => {
          setDeleteId(id);
          setDeleteDialog(true);
        }}
      />
      <AddSavoyaHourlyRateDialog vehicleTypes={vehicleTypes} />
      <EditSavoyaHourlyRateDialog
        initialValues={editingInfo}
        vehicleTypes={vehicleTypes}
      />
      <ConfirmationDialog
        open={deleteDialog}
        title="Delete Hourly Rate"
        confirmationText="Permanently Delete this Hourly Rate?"
        setOpen={setDeleteDialog}
        warning
        onConfirm={async () => {
          await deleteSavoyaHourlyRate.mutateAsync(deleteId);
          setDeleteId(null);
        }}
      />
    </div>
  );
};

export default SavoyaStandardHourlyRates;
