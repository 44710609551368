import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import theme from '../theme';

export const WarningButtonTheme: React.FC = ({ children }) => {
  const overrideTheme = createMuiTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: theme.palette.error.main,
      },
    },
  });
  return <ThemeProvider theme={overrideTheme}>{children}</ThemeProvider>;
};

export const BaseButtonTheme: React.FC = ({ children }) => {
  const overrideTheme = createMuiTheme(theme);
  return <ThemeProvider theme={overrideTheme}>{children}</ThemeProvider>;
};
